import { useContext, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import { integrationService } from '../../../services/integration.service';
import { AdjustInvoiceType } from '@chiroup/core/types/Invoice.type';
import { BusinessRuleItemType } from '@chiroup/core/constants/globals';

const TOAST_TITLE = {
  apply: 'Adjust Invoice',
};

const getIntegrationInvoice = ({
  clinicId,
  id,
}: {
  clinicId: number;
  id: number;
}) => {
  return async (context: QueryFunctionContext) => {
    if (!id || id === -1) return null;
    return integrationService.invoiceById({
      clinicId,
      id,
    });
  };
};

const useIntegrationInvoice = ({
  clinicId,
  id,
}: {
  clinicId: number;
  id: number;
  onStartupCallback?: (data: any) => void;
}) => {
  const [isRestActive, setIsRestActive] = useState(false);
  const [restFailed, setRestFailed] = useState(false);
  const { createToast } = useContext(ToastContext);
  const queryClient = useQueryClient();

  const { data, isFetching, refetch } = useQuery(
    ['integrationInvoice', id],
    getIntegrationInvoice({ clinicId, id }),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      keepPreviousData: true,
      retry: 0,
      onError: (error: any) => {
        setRestFailed(true);
        createToast({
          title: `Fetch Invoice`,
          description: `${
            error?.response?.data?.message ?? error?.message ?? 'Unknown error.'
          }`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
      },
    },
  );

  const destroyData: any = () => {
    queryClient.setQueryData(['integrationInvoice', id], null);
    // queryClient.invalidateQueries(['integrationInvoice', id]);
  };

  const setInvoiceData = (data: any) => {
    queryClient.setQueryData(['integrationInvoice', id], data);
  };

  const toggleElectronicBilling = async () => {
    if (!data) return;

    let resp: any = null;
    setIsRestActive(true);

    try {
      resp = (await integrationService.toggleInvoiceElectronicBilling({
        clinicId,
        invoice: data, // We pass the whole thing as it has the patient id on it.
      })) as {
        electronicBilling: boolean;
        billingKey: string;
        legalName: string;
      } | null;

      queryClient.setQueryData(['integrationInvoice', id], (prev: any) => ({
        ...prev,
        electronicBilling: resp?.electronicBilling,
      }));
    } catch (e: any) {
      createToast({
        title: `Toggle Electronic Billing`,
        description: `${
          e?.response?.data?.message ?? e?.message ?? 'Unknown error.'
        }`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    }

    setIsRestActive(false);
    return resp;
  };

  const adjust = async (args: AdjustInvoiceType) => {
    try {
      const { clinicId, id, actions } = args,
        issues = [];

      if (!clinicId) {
        issues.push('Clinic ID is required.');
      }
      if (!id) {
        issues.push('An invoice id is required.');
      }
      if (!actions?.length) {
        issues.push('At least one action is required.');
      }

      const userIssues = actions.filter((a) => a.action === 'user');

      if (userIssues.length) {
        issues.push(`All user actions must be acknoledged.`);
      }

      if (issues.length) {
        createToast({
          title: `Adjust Invoice`,
          description: issues.join(' '),
          type: ToastTypes.Fail,
          duration: 5000,
        });
        return;
      }

      setIsRestActive(true);

      const res = await integrationService.adjust({
        ...args,
      });
      if (res?.issues.length) {
        createToast({
          title: TOAST_TITLE.apply,
          description: res.issues
            .map((i: BusinessRuleItemType) => i.text)
            .join(' '),
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }
      if (res?.applied.length) {
        createToast({
          title: TOAST_TITLE.apply,
          description: res.applied
            .map((i: BusinessRuleItemType) => i.text)
            .join(' '),
          type: ToastTypes.Success,
          duration: 5000,
        });
      }

      return res;
    } catch (e) {
      // Nothing, it will be loggeed in the console already.
    } finally {
      setIsRestActive(false);
    }
  };

  return {
    adjust,
    data: data || null,
    destroyData,
    isFetching,
    isRestActive,
    refetch,
    restFailed,
    setRestFailed,
    setInvoiceData,
    toggleElectronicBilling,
  };
};

export default useIntegrationInvoice;
