import { Currency, TrivialTooltip } from '@chiroup/components';
import { STRING_NUMBER_HASH } from '@chiroup/core/constants/globals';
import { subtotalAndTaxFromItems } from '@chiroup/core/functions/balanceFromItems';
import { Responsibilities } from '@chiroup/core/functions/calculateResponsibilities';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import {
  PatientTransaction,
  PatientTransactionItemType,
  TransactionItemSubtypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import { useContext, useMemo } from 'react';
import { MeContext } from '../../../../../../contexts/me.context';
import useProductTaxes from '../../../../../../hooks/useProductTaxes';

type Props = {
  treatmentName?: string;
  editing?: boolean;
  setWorkingTransaction?: React.Dispatch<
    React.SetStateAction<PatientTransaction | null>
  >;
  insurancesToUse: {
    payorId: number;
    payorName: string;
    billingPriority: number;
    deductible: number;
    coPay: number;
    coInsurance: number;
    allowedAmount: number;
    serviceAllowedAmounts: STRING_NUMBER_HASH;
    maxPerVisit: number;
  }[];
  responsibilities:
    | Responsibilities
    | {
        payorResponsibilities: Record<
          number,
          {
            amount: string;
            name: string;
            payorId: number;
          }
        >;
        finalPatientResponsibility: number;
      };
  payorResponsibilityTotal: any;
  patientResponsibility: any;
  balanceAllocatedToPatient: Decimal;
  workingTransaction: PatientTransaction | null;
};
const EncounterBillingView: React.FC<Props> = ({
  treatmentName = '',
  editing,
  setWorkingTransaction,
  insurancesToUse,
  responsibilities,
  payorResponsibilityTotal,
  patientResponsibility,
  balanceAllocatedToPatient,
  workingTransaction,
}) => {
  const { me } = useContext(MeContext);
  const { data: canItBeTaxed } = useProductTaxes();

  const items = useMemo(() => {
    return workingTransaction?.items ?? [];
  }, [workingTransaction]);

  const services = useMemo(() => {
    return items?.filter(
      (item) => item.subtype === TransactionItemSubtypeEnum.Service,
    );
  }, [items]);

  const patientServices = useMemo(() => {
    return items?.filter(
      (item) => item.subtype === TransactionItemSubtypeEnum.PatientService,
    );
  }, [items]);

  const savedTax = useMemo(() => {
    return items?.find(
      (item) => item.subtype === TransactionItemSubtypeEnum.Tax,
    )?.amount;
  }, [items]);

  const hasService = useMemo(() => {
    return !!services?.length || !!patientServices?.length;
  }, [services, patientServices]);

  const itemsToUse = useMemo(() => {
    if (!hasService) {
      return items;
    }
    return items?.filter(
      (item) =>
        item.subtype === TransactionItemSubtypeEnum.Service ||
        item.subtype === TransactionItemSubtypeEnum.PatientService,
    );
  }, [items, hasService]);

  const locationId = useMemo(() => {
    return workingTransaction?.locationId as number;
  }, [workingTransaction?.locationId]);

  const disciplineId = useMemo(() => {
    return workingTransaction?.disciplineId as number;
  }, [workingTransaction?.disciplineId]);

  const isBillingStarted = useMemo(() => {
    return !!workingTransaction?.isBillingStarted;
  }, [workingTransaction?.isBillingStarted]);

  const { subtotal, tax, total, balance } = useMemo(() => {
    const locationToUse = me?.selectedClinic?.locations?.find(
      (loc) => loc.ID === locationId,
    );
    const productTaxRate = locationToUse?.productTaxRate || 0;
    const serviceTaxRate = locationToUse?.serviceTaxRate || 0;
    // TODO: itemsToUse is weird!!! Is this correct?
    const packageApplied = itemsToUse?.some(
      (item) =>
        item?.subtype === TransactionItemSubtypeEnum.Adjustment &&
        !!item?.packageId,
    );
    const hasSalesTax = itemsToUse?.some((item) => item?.salesTax === true);
    return subtotalAndTaxFromItems({
      items: items ?? [],
      productTaxRate: productTaxRate,
      serviceTaxRate: serviceTaxRate,
      makeRateZero: !hasSalesTax || packageApplied,
    });
  }, [me?.selectedClinic?.locations, items, itemsToUse, locationId]);

  const applyTax = itemsToUse?.some((item) => item?.salesTax) === true;

  const showTaxButton =
    editing &&
    itemsToUse?.some(
      (item) =>
        (item.subtype === TransactionItemSubtypeEnum.Supplement &&
          item.description &&
          canItBeTaxed?.supplementsItemTaxHash[item?.description]) ||
        (item?.subtype === TransactionItemSubtypeEnum.Treatment &&
          item.description &&
          canItBeTaxed?.treatmentsItemTaxHash[disciplineId]),
    );

  const figureOutIfWeCanChangeSalesTax = (
    subtype: TransactionItemSubtypeEnum | null,
    item: PatientTransactionItemType,
  ) => {
    if (subtype === TransactionItemSubtypeEnum.Supplement) {
      return item?.description &&
        canItBeTaxed?.supplementsItemTaxHash[item?.description]
        ? !item?.salesTax
        : false;
    }
    if (subtype === TransactionItemSubtypeEnum.Treatment) {
      return disciplineId && canItBeTaxed?.treatmentsItemTaxHash[disciplineId]
        ? !item?.salesTax
        : false;
    }
    return false;
  };

  const changeSalesTax = () => {
    if (!setWorkingTransaction) return;
    setWorkingTransaction((prev) => {
      if (prev === null) return null;
      //itemtaxhash lets us know if the item can be taxed or not
      return {
        ...prev,
        items: prev?.items?.map((item) => {
          return {
            ...item,
            salesTax: figureOutIfWeCanChangeSalesTax(item.subtype, item),
          };
        }),
      };
    });
  };

  const maxPerVisitByPayorId = useMemo(() => {
    return insurancesToUse?.reduce((acc, cur) => {
      acc[cur.payorId] = cur.maxPerVisit;
      return acc;
    }, {} as STRING_NUMBER_HASH);
  }, [insurancesToUse]);

  const perPayorArray = useMemo(() => {
    const rAsAny = responsibilities as any,
      memo = Object.entries(responsibilities?.payorResponsibilities ?? {}).map(
        ([key, value]) => {
          console.log(`key: ${key}, value: ${value}`);
          return {
            patientAmount: rAsAny?.patientResponsibilities?.[key],
            payorAmount: value?.amount,
            name: value?.name,
            payorId: value?.payorId,
          };
        },
      );
    return memo;
  }, [responsibilities]);

  return (
    <div
      data-component="encounter-billing-view"
      className="border overflow-hidden rounded-md border-gray-400 border-spacing-4 p-4"
    >
      {/* <pre>
        {ChiroUpJSON.pretty({
          responsibilities,
          patientResponsibility,
          perPayorArray,
        })}
      </pre> */}
      {!!insurancesToUse?.length && !!services.length && (
        <>
          {perPayorArray.map((item, idx) => (
            <div key={`ppa-${idx}`} className="flex flex-col mt-4">
              <div
                className={classNames(
                  isBillingStarted ? 'text-accent-600' : 'text-primary-500',
                  'font-sans font-bold pb-2',
                )}
              >
                {item.name}
              </div>
              <div className="pl-4 w-full flex flex-row justify-between">
                <div>
                  <span>Payor total</span>
                  <span>
                    {!!maxPerVisitByPayorId[item.payorId] && (
                      <TrivialTooltip
                        iconClassName="h-3 w-3 text-gray-400 inline-block ml-1 mr-1 align-text-top"
                        text={`This payor allows a maximum charge of $${createDecimal(
                          maxPerVisitByPayorId[item.payorId] ?? 0,
                        ).toFixed(
                          2,
                        )}, encompassing both patient and payor contributions.`}
                      />
                    )}
                  </span>
                </div>
                <Currency value={item.payorAmount} />
              </div>
              <div className="pl-4 w-full flex flex-row justify-between">
                <span>Patient responsibility</span>
                <Currency value={item?.patientAmount} />
              </div>
            </div>
          ))}
          <div className="ml-4 flex justify-between mb-2 dark:bg-darkGray-500">
            <span>Contractual adjustment</span>
            <Currency
              value={createDecimal(total)
                .minus(responsibilities.finalPatientResponsibility)
                .minus(balanceAllocatedToPatient.plus(payorResponsibilityTotal))
                .toFixed(2)}
              negativeClassName="text-red-500"
            />
          </div>
        </>
      )}

      <div className="pb-4 font-bold">{treatmentName ?? ''}</div>
      <div className="mt-4 flex justify-between mb-2 dark:bg-darkGray-500">
        <span>Patient responsibility</span>
        <Currency value={responsibilities?.finalPatientResponsibility} />
      </div>
      <div className="flex justify-between mb-2">
        <span>Subtotal</span>
        <Currency value={subtotal} />
      </div>
      <div className="flex justify-between flex-row mb-2">
        <div>Tax</div>

        <div className="flex flex-row">
          {balance > 0 && changeSalesTax && showTaxButton && (
            <div
              onClick={() => changeSalesTax()}
              className="cursor-pointer text-primary-500 hover:text-primary-400 text-xs mr-8 whitespace-nowrap"
            >
              {applyTax ? 'Remove Tax' : 'Apply Tax'}
            </div>
          )}
          <Currency value={editing ? tax : savedTax} />
        </div>
      </div>
      <div className="flex justify-between mb-2">
        <span>Total</span>
        <Currency value={total} />
      </div>
      {!!balance && (
        <div
          className="border-black flex justify-between mb-2 font-sans font-bold mt-4 pt-2"
          style={{ borderTopWidth: 'thin' }}
        >
          <span>Balance</span>
          <Currency value={balance} />
        </div>
      )}
    </div>
  );
};

export default EncounterBillingView;
