import { Button, ButtonColors, Loading } from '@chiroup/components';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import { NUMBER_ANY_HASH } from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { PatientPayment } from '@chiroup/core/types/PatientPayment.type';
import { PatientTransaction } from '@chiroup/core/types/PatientTransaction.type';
import classNames from 'classnames';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  ActionsOnClick,
  LockButtonStateType,
  Phase,
  phases,
} from './ConsolidatedTransactionModal';
import LockButtonContent from './LockButtonContext';
import TransactionEditorTabContent from './TransactionEditorTabContent';
import TransactionPaymentModal from './TransactionPaymentModal';

type AdHocProps = {
  actionsOnClick: (
    action: ActionsOnClick,
    transaction: PatientTransaction | null | undefined,
  ) => void;
  context: NUMBER_ANY_HASH;
  currentPhase: Phase;
  data: PatientTransaction | null;
  isFetching: boolean;
  isReadOnly: boolean;
  isSaving: boolean;
  lockButtonStateByProviderId?: { [key: string]: LockButtonStateType };
  parentProperties: any;
  row: PatientTransaction | null;
  setCurrentPhase: Dispatch<SetStateAction<Phase>>;
  setLocalRow: Dispatch<SetStateAction<PatientTransaction | null>>;
  trace?: boolean;
};

const SingleAdHoc: React.FC<AdHocProps> = ({
  actionsOnClick,
  currentPhase,
  isFetching,
  isReadOnly = false,
  isSaving,
  lockButtonStateByProviderId = {},
  parentProperties = {},
  row,
  setCurrentPhase,
  setLocalRow,
  trace = false,
}) => {
  /**
   * Stuff from the parent...
   */
  const {
    BUTTON,
    actionButtonsComponent,
    activeSubtype,
    activeTabName,
    addPendingItems,
    balances,
    balanceAllocatedToPatient,
    findThePackageCredit,
    openPaymentModal,
    patientPackages,
    patientResponsibility,
    payorResponsibilityTotal,
    payment,
    refetchBalance,
    responsibilities,
    save,
    selectedPaymentCard,
    setShowSupplementsModal,
    setActiveSubtype,
    // setDiagnosesOrder,
    setOpenPaymentModal,
    setSelectedPaymentCard,
    triggerAppendItems,
    hasAccess,
    setIsPurchasePackageModal,
  } = parentProperties;

  /**
   * u s e S t a t e
   */
  const [traceOpen, setTraceOpen] = useState<boolean>(!trace); // Initially closed.

  /**
   * R e t u r n
   */
  return (
    <div data-id="ad-hoc-transaction-view">
      <div className="p-4">
        {isFetching && !isSaving ? (
          <div className="flex justify-center p-4">
            <Loading color="text-gray-400" />
          </div>
        ) : (
          <>
            {/* ...quiet... */}
            <TransactionEditorTabContent
              absoluteServiceItems={row?.services}
              actionButtonsComponent={actionButtonsComponent}
              activeSubtype={activeSubtype}
              activeTabName={activeTabName}
              addPendingItems={addPendingItems}
              balanceAllocatedToPatient={balanceAllocatedToPatient}
              consolidatedView={true}
              editing={currentPhase.name === 'Edit'}
              findThePackageCredit={findThePackageCredit}
              insurancesToUse={[]}
              isLoading={isFetching}
              leftSideComponent={
                <div
                  data-id="add-extra-items-component"
                  className="pt-4 flex flex-row flex-wrap space-x-2 justify-start"
                >
                  <Button
                    {...BUTTON.supplementsAndSupplies}
                    onClick={() => {
                      setShowSupplementsModal(true);
                    }}
                  />
                  {hasAccess(FeatureFlags.packages) && (
                    <Button
                      {...BUTTON.addPackage}
                      onClick={() => {
                        setIsPurchasePackageModal(true);
                      }}
                    />
                  )}
                </div>
              }
              instanceComponents={{
                lockButton: lockButtonStateByProviderId?.[
                  row?.provider?.id as string
                ] ? (
                  lockButtonStateByProviderId?.[row?.provider?.id as string]
                    .available ? (
                    <LockButtonContent
                      state={
                        lockButtonStateByProviderId?.[
                          row?.provider?.id as string
                        ]
                      }
                      transaction={row}
                      readonly={isReadOnly}
                      isBillingStarted={row?.isBillingStarted}
                      onClick={(e: any) => {
                        actionsOnClick(ActionsOnClick.Lock, row);
                      }}
                    />
                  ) : null
                ) : null,
                unMergeButton: null,
              }}
              noCodesMessage="No codes are associated with this purchase."
              patientPackages={patientPackages}
              patientResponsibility={patientResponsibility}
              payorResponsibilityTotal={payorResponsibilityTotal}
              readonly={isReadOnly}
              responsibilities={responsibilities}
              saveServicesCallback={() => {
                setCurrentPhase(phases[currentPhase.next]);
              }}
              setActiveSubtype={setActiveSubtype}
              // setDiagnosesOrder={setDiagnosesOrder}
              setWorkingTransaction={setLocalRow}
              triggerAppendItems={triggerAppendItems}
              workingTransaction={row}
              refetchBalance={refetchBalance}
            />{' '}
          </>
        )}
      </div>
      {row && (
        <TransactionPaymentModal
          payment={payment as PatientPayment}
          transaction={row}
          isOpen={openPaymentModal}
          close={() => setOpenPaymentModal(false)}
          save={save}
          patientId={row.patient?.id || ''}
          selectedPaymentCard={selectedPaymentCard}
          setSelectedPaymentCard={setSelectedPaymentCard}
          clear={() => {
            console.log('TODO: Whatever clear is supposed to do.');
          }}
          balances={balances}
          refetchList={() => {
            // Update the current transaction...
            // update the parent...
            console.log(
              '......TODO: refetchList on the transaction payment modal.....',
            );
          }}
        />
      )}

      {trace ? (
        <div className="mt-4">
          <Button
            onClick={() => setTraceOpen(!traceOpen)}
            text="Toggle #3"
            color={ButtonColors.plainWithBorder}
          />
          <div className={classNames('p-4', traceOpen ? '' : 'hidden')}>
            {/* <pre>{ChiroUpJSON.pretty(row)}</pre> */}
            <h1>row</h1>
            <pre>
              {ChiroUpJSON.pretty({
                id: row?.id,
                billingKey: row?.billingKey,
                provider: row?.provider,
                type: row?.type,
                activeTabName,
                merged: row?.merged,
              })}
            </pre>
            <hr />
            <h1>data</h1>
            <pre>
              {ChiroUpJSON.pretty({
                id: row?.id,
                billingKey: row?.billingKey,
                provider: row?.provider,
              })}
            </pre>
            <hr />
            <pre>{ChiroUpJSON.pretty({ items: row?.items })}</pre>
            <hr />
            <pre>{ChiroUpJSON.pretty(lockButtonStateByProviderId)}</pre>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SingleAdHoc;
