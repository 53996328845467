import {
  generateTimeOptions,
  Input,
  NotFound,
  Select,
} from '@chiroup/components';
import {
  ChiroUpAppointmentCommon,
  ChiroUpDayJsCommon,
} from '@chiroup/core/constants/stringConstants';
import { classNames } from '@chiroup/core/functions/classNames';
import { durationDisplay } from '@chiroup/core/functions/durationDisplay';
import { AppointmentForUI } from '@chiroup/core/types/Appointment.type';
import {
  Discipline,
  DisciplineTreatment,
} from '@chiroup/core/types/Discipline.type';
import { useForm } from '@chiroup/hooks';
import dayjs, { isDayjs } from 'dayjs';
import InputG from 'intergalactic/input';
import SelectG from 'intergalactic/select';
import qs from 'query-string';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { ScheduleContext } from '../../contexts/schedule.context';
import Button, { ButtonColors } from '../common/Button';
import ClinicianSelect from '../common/fields/selects/ClinicianSelect';

const validation = {
  patientId: { required: { message: 'Patient is required' } },
  clinicianId: { required: { message: 'Clinician is required' } },
  treatmentId: { required: { message: 'Treatment is required' } },
  timeOfDay: { required: { message: 'Start time is required' } },
  duration: { required: { message: 'Duration is required' } },
  daysOfWeek: { required: { message: 'Days of week is required' } },
  endRecurring: { required: { message: 'End recurring is required' } },
};

type Props = {
  checkAvailability: (val: any) => void;
  onClose: () => void;
  fetchingRecurringData: boolean;
  appointment?: AppointmentForUI | null;
  disabled?: boolean;
  editType?: string;
  setEditType?: (val: string) => void;
  showForm?: boolean;
  setShowForm?: (val: boolean) => void;
  timezone: string;
  passedDisciplines?: Discipline[];
};

const ScheduleRecurringAppointmentsForm: React.FC<Props> = ({
  checkAvailability,
  onClose,
  fetchingRecurringData,
  appointment,
  disabled = false,
  editType,
  setEditType,
  showForm,
  setShowForm,
  timezone,
  passedDisciplines,
}) => {
  const [isAppointmentPast, setIsAppointmentPast] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { disciplines, startTimeInterval } = useContext(ScheduleContext);
  const { search } = useLocation();
  const { value, onChange, errors, isSubmitting, patchValue } = useForm<
    Partial<AppointmentForUI>
  >({ daysOfWeek: [] }, validation);

  useEffect(() => {
    if (appointment?.recurringAppointmentId) {
      const time = dayjs(appointment.startTime).format('h:mm a');
      const dayOfWeek = dayjs(appointment.startTime).format('dddd');

      patchValue({
        patientId: appointment.patientId,
        treatmentId: appointment.treatmentId,
        clinicianId: appointment.clinicianId,
        timeOfDay: time,
        duration: appointment.duration,
        daysOfWeek: [dayOfWeek],
        endRecurring: appointment.endRecurring,
      });
    } else {
      const { treatment, clinician, duration, patient, startTime } = qs.parse(
        search,
      ) as any;
      const dayOfWeek = dayjs
        .unix(startTime / 1000)
        .tz(timezone)
        .format('dddd');

      patchValue({
        patientId: patient,
        treatmentId: treatment ? Number(treatment) : undefined,
        clinicianId: clinician,
        timeOfDay: ChiroUpDayJsCommon.display.timeFormat(
          startTime,
          'h:mm a',
          timezone,
        ),
        duration: duration ? Number(duration) : undefined,
        daysOfWeek: [dayOfWeek],
      });
    }
    setIsAppointmentPast(
      appointment?.startTime ? appointment.startTime < dayjs() : false,
    );
  }, [fetchingRecurringData, patchValue, search, timezone, appointment]);

  const treatmentHash = (passedDisciplines || disciplines)?.reduce(
    (arr: any, discipline) => {
      const options = discipline?.treatments?.map((treatment) => ({
        text: treatment.name,
        value: treatment.ID,
      }));

      const durations = discipline?.treatments?.map(
        (treatment) => treatment.treatmentDuration,
      );
      const treatments = discipline?.treatments?.reduce(
        (
          res: { [key: number]: DisciplineTreatment },
          treatment: DisciplineTreatment,
        ) => {
          return {
            ...res,
            [treatment.ID]: treatment,
          };
        },
        {},
      );
      return {
        options: [...arr.options, ...options],
        objs: { ...arr.objs, ...treatments },
        durations: [
          ...new Set([...arr.durations, ...durations, value.duration]),
        ].sort((a, b) => a - b),
      };
    },
    { options: [], objs: {}, durations: [] },
  );

  const displayName = useMemo(() => {
    const { patientName } = qs.parse(search) as any;
    return patientName || '';
  }, [search]);

  const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  const options = useMemo(() => {
    return generateTimeOptions('05:58', '18:00', startTimeInterval);
  }, [startTimeInterval]);

  const [customDuration, setCustomDuration] = useState(0);

  return (
    <div
      className={classNames(
        'p-4 border border-gray-300 rounded-lg',
        disabled
          ? 'cursor-not-allowed'
          : isAppointmentPast
            ? 'cursor-not-allowed'
            : 'cursor-pointer hover:bg-gray-100 hover:border-gray-300 dark:bg-darkGray-700 dark:hover:bg-darkGray-700',
        isAppointmentPast ? 'bg-orange-50' : 'bg-gray-50',
      )}
      title={isAppointmentPast ? ChiroUpAppointmentCommon.cannotModify : ''}
      onClick={() => {
        if (disabled || isAppointmentPast) return;
        if (!isEditing) setIsEditing(true);
      }}
    >
      {!showForm && isEditing && appointment?.recurringAppointmentId ? (
        <>
          <p className="text-sm text-gray-500 dark:text-gray-300">
            This is a recurring appointment. Do you want to edit only this
            appointment or do you want to edit all future appointments?
          </p>
          <div className="m-2">
            <label>
              <input
                type="radio"
                value="editOnlyThisAppointment"
                checked={editType === 'editOnlyThisAppointment'}
                onChange={() =>
                  setEditType && setEditType('editOnlyThisAppointment')
                }
                className="form-radio h-4 w-4 text-sm text-primary-600 transition duration-150 ease-in-out m-2"
              />
              <span className="text-sm text-gray-500 dark:text-gray-300">
                Edit only this appointment
              </span>
            </label>
            <br />
            <label>
              <input
                type="radio"
                value="editAllFutureRecurring"
                checked={editType === 'editAllFutureRecurring'}
                onChange={() => setEditType?.('editAllFutureRecurring')}
                className="form-radio h-4 w-4 text-primary-600 transition duration-150 ease-in-out m-2"
              />
              <span className="text-sm text-gray-500 dark:text-gray-300">
                Edit this appointment and all future recurring appointments
              </span>
            </label>
          </div>
          <div className="flex flex-row items-center justify-end gap-2">
            <Button
              text="Cancel"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setShowForm?.(false);
                setEditType?.('');
                setIsEditing(false);
              }}
            />
            <Button
              text="Next"
              disabled={!editType?.length}
              type="submit"
              onClick={() => {
                setShowForm?.(true);
              }}
            />
          </div>
        </>
      ) : isEditing || !appointment?.recurringAppointmentId ? (
        <form>
          {!appointment?.recurringAppointmentId && (
            <span className="text-xl flex flex-row gap-2 font-bold text-gray-900 dark:text-gray-300 sm:text-2xl ">
              {displayName}
            </span>
          )}
          <ClinicianSelect
            name="clinicianId"
            value={value.clinicianId}
            onChange={onChange('clinicianId')}
            label="Clinician *"
            errors={errors.fieldErrors?.clinicianId}
            limit={1}
            disabled={appointment?.encounterSigned}
            tooltip={
              appointment?.encounterSigned
                ? 'Provider cannot be changed after the note is signed'
                : ''
            }
          />
          <Select
            name="treatmentId"
            label="Treatment *"
            value={value.treatmentId}
            onChange={onChange('treatmentId')}
            options={treatmentHash.options}
            limit={1}
            errors={errors.fieldErrors?.treatmentId}
          />

          <div>
            <p className="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2 dark:text-darkGray-200">
              Time *
            </p>
            <SelectG
              interaction="focus"
              size={'l'}
              onChange={(val: any) => {
                onChange('timeOfDay')(val);
              }}
              value={value.timeOfDay}
            >
              <SelectG.Trigger tag={InputG}>
                {() => (
                  <InputG.Value
                    value={value.timeOfDay}
                    onChange={(val: any) => {
                      onChange('timeOfDay')(val);
                    }}
                    id="release-time-picker"
                  />
                )}
              </SelectG.Trigger>
              <SelectG.Menu>
                {options.map((option) => (
                  <SelectG.Option value={option.text} key={option.value}>
                    {option.text}
                  </SelectG.Option>
                ))}
              </SelectG.Menu>
            </SelectG>
          </div>

          <div className="flex">
            {customDuration === 0 ? (
              <Select
                name="duration"
                label="Duration *"
                value={value.duration}
                onChange={onChange('duration')}
                options={
                  treatmentHash?.durations.map((duration: number) => ({
                    text: duration + ' minutes',
                    value: duration,
                  })) || []
                }
                limit={1}
                errors={errors.fieldErrors?.duration}
                className="w-1/2"
                hint="Custom duration"
                hintOnClick={() => setCustomDuration(1)}
              />
            ) : (
              <Input
                name="duration"
                label="Duration *"
                value={value.duration}
                onChange={onChange('duration')}
                errors={errors.fieldErrors?.duration}
                className="w-1/2"
                type="number"
                hint="in minutes"
                hintOnClick={() => setCustomDuration(0)}
                min="0"
              />
            )}
          </div>

          <div className="mt-2 text-left">
            <div className="text-sm leading-5 text-gray-500 dark:text-gray-400">
              <fieldset>
                <legend className="block text-sm font-medium leading-5 text-gray-900  sm:mt-px sm:pt-2 dark:text-gray-300">
                  Recurring days *
                </legend>
                <div className="mt-4 flex flex-row flex-wrap">
                  {daysOfWeek.map((day, dayIdx) => (
                    <span key={dayIdx} className="mr-4">
                      <span className="min-w-0 flex text-sm ">
                        <label
                          htmlFor={`day-${day}`}
                          className="font-medium text-gray-700 select-none mb-4 dark:text-gray-300"
                        >
                          {day}
                        </label>
                        <span className="ml-3 flex items-center h-5">
                          <input
                            name={`day-${day}`}
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            value={day}
                            checked={
                              value.daysOfWeek && value.daysOfWeek.includes(day)
                            }
                            onChange={() => {
                              if (
                                value.daysOfWeek &&
                                value.daysOfWeek.includes(day)
                              ) {
                                patchValue({
                                  daysOfWeek: value.daysOfWeek.filter(
                                    (d) => d !== day,
                                  ),
                                });
                              } else {
                                patchValue({
                                  daysOfWeek: [
                                    ...(value.daysOfWeek || []),
                                    day,
                                  ],
                                });
                              }
                            }}
                          />
                        </span>
                      </span>
                    </span>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="date"
              className="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2 dark:text-darkGray-200"
            >
              End recurring date
            </label>
            <div id="date">
              <Datepicker
                value={{
                  startDate: value.endRecurring,
                  endDate: value.endRecurring,
                }}
                onChange={(val) => {
                  const start = dayjs(val?.startDate).valueOf();
                  const now = dayjs().valueOf();
                  const yearFromNow = dayjs().add(1, 'year').valueOf();
                  const yearFromNowDate = dayjs().add(1, 'year').toDate();
                  const valStartTimeStamp = dayjs(val?.startDate).valueOf();
                  if (val && start > now) {
                    if (valStartTimeStamp > yearFromNow) {
                      onChange('endRecurring')(yearFromNowDate);
                    } else {
                      onChange('endRecurring')(val.startDate);
                    }
                  }
                }}
                asSingle={true}
                useRange={false}
                placeholder={'MM/DD/YYYY'}
                displayFormat={'MM/DD/YYYY'}
                primaryColor={'green'}
                inputClassName="border border-gray-300 shadow-sm rounded-md mt-1 focus:outline-none focus:ring-none focus:ring-primary-500 focus:border-transparent pl-2"
              />
            </div>
          </div>

          <div className="flex-shrink-0 pl-4 border-t border-gray-300 dark:border-darkGray-600 py-5 dark:bg-darkGray-700">
            <div className="space-x-3 flex justify-end">
              <Button
                text="Cancel"
                onClick={() => {
                  setShowForm ? setShowForm(false) : onClose();
                  setEditType?.('');
                  setIsEditing(false);
                }}
                color={ButtonColors.plain}
              />
              <Button
                text="Check availability"
                disabled={
                  !value.endRecurring ||
                  !value.duration ||
                  !value.timeOfDay ||
                  !value.treatmentId
                }
                loading={isSubmitting}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  console.log({ value });
                  checkAvailability(value);
                }}
              />
            </div>
          </div>
        </form>
      ) : appointment ? (
        <div className="pb-5 pt-5 sm:pt-0">
          <dl className="space-y-8 sm:space-y-6">
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Clinician
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                <p>{appointment.displayValues.clinicianName}</p>
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Treatment
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {appointment.displayValues.treatmentName}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Date
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {appointment?.startTime && isDayjs(appointment?.startTime)
                  ? appointment.startTime.format(
                      ChiroUpDayJsCommon.format.datetime,
                    )
                  : appointment?.startTime
                    ? dayjs(appointment.startTime).format(
                        ChiroUpDayJsCommon.format.datetime,
                      )
                    : ''}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Duration
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {durationDisplay(appointment.duration)}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Notes
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {appointment.notes}
              </dd>
            </div>
          </dl>
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default ScheduleRecurringAppointmentsForm;
