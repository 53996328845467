/**
 * Tips for those who might read documentation....
 *
 * 1) This is a mass of things calling other things across a lot
 *    of components. Some of the callbacks/functions that are
 *    passed call setLocalData which is in the main component.
 *    So, when a sub-component calls it, we often get a React
 *    error. The way we've gotten around that is to use a
 *    setTimeout() with a 0 delay. This puts the call at the
 *    end of the Javascript execution queue and allow React to
 *    shut the hell up.
 *
 * 2) A "reload" of the specific transactions displayed in a
 *    loop by this component, can be done by using the
 *    queryClient.setQueryData() function. The individual
 *    transaction components watch their data (useEffect) and
 *    when the data is at variance with the local row, the local
 *    row is updated from the queryClient data. It looks like
 *    magic so seemed worth a mention.
 */
import { FaceFrownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../../../common/Modal';
import { useConsolidatedTransaction } from '../hooks/useConsolidatedTransaction';

import {
  AlertBlock,
  Button,
  ButtonColors,
  Checkbox,
  ConfirmModal,
  Loading,
  MakeBrowserWait,
  ManagedActionItems,
} from '@chiroup/components';
import {
  NUMBER_ANY_HASH,
  STRING_ANY_HASH,
  STRING_BOOLEAN_HASH,
} from '@chiroup/core/constants/globals';
import {
  ChiroUpDayJsCommon,
  ChiroUpTransactionCommon,
} from '@chiroup/core/constants/stringConstants';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { createPatientName } from '@chiroup/core/functions/createPatientName';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import {
  PatientOnTransactionType,
  PatientTransaction,
  PatientTransactionClass,
  ProviderOnTransactionType,
  TransactionPurchaseSubtypeEnum,
  TransactionTypeEnum,
  isaServiceItem,
} from '@chiroup/core/types/PatientTransaction.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { v4 } from 'uuid';
import { MeContext } from '../../../../../contexts/me.context';

import classNames from 'classnames';
import { useQueryClient } from 'react-query';
import { ManagedActionItemsContext } from '../../../../../contexts/managedActionItems.context';
import { PatientContext } from '../../../../../contexts/patient.context';
import ButtonGroup, {
  ButtonGroupType,
} from '../../../../common/buttons/ButtonGroup';
import ConsolidatedPaymentModal from './ConsolidatedPaymentModal';
import {
  MergeTransactionsOptions,
  mergeTransactionsStandalone,
} from './functions/mergeTransactions.standalone';
import { LocationCard } from './LocationCard';
import SingleTransaction from './SingleTransaction';
import { TabSummary } from './TabSummary';

export const ClassNames = {
  bg: 'bg-gray-200',
};

export const BASE_TAB = 'Detail';
export type Phase = { name: string; header: string; next: number };

export const phases: Phase[] = [
  {
    name: 'New',
    header: '',
    next: 2,
  },
  {
    name: 'Edit',
    header: '',
    next: 2,
  },
  {
    name: 'View',
    header: '',
    next: 1,
  },
];

export type InstanceComponents = {
  lockButton?: ReactNode;
  unMergeButton?: ReactNode;
};

export enum ActionsOnClick {
  Lock = 1,
  UnMerge = 2,
}

type LifeCycle = {
  isDirty?: () => boolean; // You tell me if it is dirty.
  isRestActive?: () => boolean; // You tell me if a REST call is active.
  save?: (cb?: () => void) => void; // You do the save and do what you like.
  cancel?: () => void; // The cancel is hit BEFORE ANYTHING else.
  close?: () => void; // Close after something.
};

export type EncounterContext = {
  assessmentCodes: any | null | undefined;
  clinicId: number;
  importDiagnosesAlways: boolean | null | undefined;
  horizontal?: boolean | null | undefined;
  omitTitle: boolean;
  persistDiagnosesList: boolean | null | undefined;
  patientId: string;
  plan: any | null | undefined;
  saveVisit: any | null | undefined;
  isSavingVisit?: boolean | null | undefined;
  setVisitForm: any | null | undefined;
  visitForm: any | null | undefined;
};

type Props = {
  encounterContext?: EncounterContext | null | undefined;
  initialContext?: PatientTransaction[] | null | undefined;
  id?: string | null;
  isOpen?: boolean;
  onCloseView?: ({
    data,
    dirty,
  }: {
    data?: PatientTransaction[] | null | undefined;
    dirty?: boolean;
  }) => void;
  mode?: 'modal' | 'route';
  setInitialContext?: React.Dispatch<
    React.SetStateAction<PatientTransaction[] | null | undefined>
  >;
  trace?: boolean;
  lifeCycle?: LifeCycle | null | undefined;
};

export type LockButtonStateType = {
  available: boolean;
  locked: boolean;
};

// const mustSaveAfterMerge = {
//   id: 'must-save-after-merge',
//   title: [
//     'Click save to commit the changes.',
//     'Click cancel to revert the merge.',
//     'Once saved, reverting the merge is a manual process.',
//   ].join(' '),
//   persistent: false,
// };

// TODO: Add "onDirty" function as a parameter so we can have the modal reload
//     the data if it is dirty (for lists of transactions).
const ConsolidatedTransactionModal: React.FC<Props> = ({
  encounterContext,
  id,
  initialContext,
  isOpen = true,
  onCloseView = null,
  mode = 'modal',
  setInitialContext,
  trace = false,
  lifeCycle,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { me, hasRole, selectedLocationFull } = useContext(MeContext);
  const { patient } = useContext(PatientContext);
  const [isRestActive, setIsRestActive] = useState<boolean | null>(null);
  /**
   * u s e S t a t e
   */
  const [backgroundDirty, setBackgroundDirty] = useState<boolean | null>(false);
  /**
   * This is hacky. Don't do this.
   *
   * The problem is that it is showing us how what we have is artchitected poorly.
   * We need to refactor the SingleTransaction component to use context of the transaction
   * group that we have here. Different day. We're tired.
   */
  const [getSingleTransaction] = useState<STRING_ANY_HASH>({});
  const [mergeButtonTooltip, setMergeButtonTooltip] = useState<string>('');
  const [singleProviderOption, setSingleProviderOption] =
    useState<ProviderOnTransactionType | null>(null);
  const [countofProviders, setCountOfProviders] = useState<number>(0);
  const [confirmMergeOpen, setConfirmMergeOpen] = useState<boolean>(false);
  const [mergeOptions, setMergeOptions] = useState<MergeTransactionsOptions>({
    units: true,
    treatments: true,
    providers: {},
  });
  const [localData, setLocalData] = useState<PatientTransaction[] | null>(null);
  const [payAvailability, setPayAvailability] = useState<STRING_BOOLEAN_HASH>(
    {},
  );

  const [showPatientPaymentModal, setShowPatientPaymentModal] =
    useState<boolean>(false);

  const editingNewData = useMemo(() => {
    return localData?.[0]?.billingKey && localData?.[0]?.id === -1;
  }, [localData]);
  const [currentPhase, setCurrentPhase] = useState<Phase>(phases[2]);
  // const [lifeCycleRestActive, setLifeCycleRestActive] = useState(false);

  // useEffect(() => {
  //   console.log('how many runs....');
  // }, []);

  const topOfPageRef = useRef<HTMLDivElement>(null);
  const [slotContext, setSlotContext] = useState<NUMBER_ANY_HASH>({
    [-1]: { ref: topOfPageRef, role: 'top' },
  });

  /**
   * u s e M e m o
   */

  const isAdHoc = useMemo(() => {
      return localData?.[0]?.type === TransactionTypeEnum.AdHoc;
    }, [localData]),
    isNotAdHoc = useMemo(() => {
      return !isAdHoc;
    }, [isAdHoc]);

  const isEncounterMode = useMemo(() => {
      return !!encounterContext;
    }, [encounterContext]),
    isNotEncounterMode = useMemo(() => {
      return !isEncounterMode;
    }, [isEncounterMode]);

  const isEditingNew = useMemo(() => {
    // console.log({ ThisIsTheIsEditingNewId: id });
    if (id?.indexOf('new') === 0) return true;
    return false;
  }, [id]);

  const isBillingStarted = useMemo(() => {
    return localData?.some((row) => row.isBillingStarted);
  }, [localData]);

  const textPrimary = useMemo(() => {
      return isBillingStarted ? 'text-accent-500' : 'text-primary-500';
    }, [isBillingStarted]),
    hoverTextPrimary = useMemo(() => {
      return isBillingStarted ? 'text-accent-600' : 'text-primary-600';
    }, [isBillingStarted]);

  const locations = useMemo(() => {
    return me?.selectedClinic?.locations ?? [];
  }, [me?.selectedClinic?.locations]);

  const userTz = useMemo(() => {
    return selectedLocationFull.timezone;
  }, [selectedLocationFull]);

  const itemId = useMemo(() => {
    let res: string | null | undefined = null;

    // console.log({ IncomingId: id });
    if (id) {
      if (!editingNewData) res = id;
      if (res?.indexOf('new') === 0) {
        res = null;
      }
    }
    if (mode === 'route' && location && location.pathname) {
      const pcs = location.pathname.split('/');
      res = pcs[pcs.length - 1];
    }
    return res;
  }, [editingNewData, id, location, mode]);

  const childrenByType = useMemo(() => {
    return (
      localData?.reduce((acc: STRING_BOOLEAN_HASH, row: PatientTransaction) => {
        acc[String(row.type)] = true;
        return acc;
      }, {}) ?? {}
    );
  }, [localData]);

  const mixedTypes = useMemo(() => {
    return Object.keys(childrenByType).length > 1;
  }, [childrenByType]);

  const providerOptions = useMemo(() => {
    if (!localData || !localData?.length) return {};
    const provs: { [key: string]: ProviderOnTransactionType } = {},
      skipProviderById: { [key: string]: boolean } = {};

    for (const row of localData ?? []) {
      const pid = row.provider?.id;
      if (pid && !row?.provider?.usingPrimary && !provs[pid] && row.provider) {
        provs[pid] = row.provider;
      }
      // Ignore providers with locked or merged transactions.
      if (row.merged || row?.items?.some((item) => item.locked)) {
        if (pid) skipProviderById[pid] = true;
      }
    }
    // REMOVE all the providers that are already merged...

    const deletedProvs: { [key: string]: ProviderOnTransactionType } = {};

    Object.keys(skipProviderById).forEach((key) => {
      deletedProvs[key] = ChiroUpJSON.clone(provs[key]);
      delete provs[key];
    });

    // REMOVE all the providers who have locked services.

    const keys = Object.keys(provs);
    if (keys.length === 1) {
      setSingleProviderOption(provs[keys[0]]);
    } else {
      setSingleProviderOption(null);
      if (keys.length === 0) {
        setMergeButtonTooltip('No providers found.');
      }
    }

    setMergeOptions((p) => {
      const n = ChiroUpJSON.clone(p);
      for (const prov of Object.values(provs ?? {})) {
        n.providers[prov.id] = true;
      }
      return n;
    });
    const len = Object.keys(provs ?? []).length;
    setCountOfProviders(len);

    if (countofProviders < 2) {
      return {};
    }

    return provs;
  }, [countofProviders, localData]);

  const mergeContext = useMemo(() => {
    const notMerged: { [key: string]: PatientTransaction } = {},
      mergedInto: { [key: string]: PatientTransaction[] } = {};
    for (const row of localData ?? []) {
      if (row.merged) {
        mergedInto[row.merged] = mergedInto[row.merged] ?? [];
        mergedInto[row.merged].push(row);
      } else {
        notMerged[row.billingKey] = row;
      }
    }

    // If there are merge diffs, then it is not merged.
    const maybeMerge = mergeTransactionsStandalone({
      transactions: localData ?? [],
      options: mergeOptions,
    });
    const maybeDiffs = ChiroUpJSON.compare({
      obj1: { value: localData },
      obj2: { value: maybeMerge.transactions },
    });
    // console.log({ maybeDiffs });
    const isMerged = Object.keys(maybeDiffs).length === 0;
    if (maybeMerge?.messages?.length) {
      setMergeButtonTooltip(maybeMerge.messages.map((m) => m.text).join(' '));
    } else if (isMerged) {
      setMergeButtonTooltip(`The transactions are merged.`);
    } else {
      setMergeButtonTooltip('');
    }

    return {
      not: notMerged,
      into: mergedInto,
      isMerged,
    };
  }, [localData, mergeOptions]);

  /**
   * H o o k s
   */
  const {
    items: managedActionItems,
    add: addManagedActionItem,
    clear: clearManagedActionItems,
  } = useContext(ManagedActionItemsContext);

  const {
    data,
    isError,
    failed,
    isFetching,
    isSaving,
    onCloseCleanup,
    refetch,
    snapshot,
    save: saveConsolidatedTransaction,
    policies,
    // patient: patientFromHook,
  } = useConsolidatedTransaction({
    billingKey: itemId,
  });

  const isAnyRestRunning = useMemo(() => {
    return (
      isRestActive ||
      isFetching ||
      isSaving ||
      isError ||
      !!lifeCycle?.isRestActive?.()
    );
  }, [isError, isFetching, isRestActive, isSaving, lifeCycle]);

  useEffect(() => {
    if (data && data.length) {
      setLocalData(ChiroUpJSON.clone(data));
    }
  }, [data]);

  /**
   * For new transactions, we need to set the UI data structures without
   * making a REST call as it isn't there yet!
   */
  useEffect(() => {
    if (
      initialContext &&
      Array.isArray(initialContext) &&
      initialContext.length &&
      typeof setInitialContext === 'function'
    ) {
      setLocalData(() => ChiroUpJSON.clone(initialContext));
      setInitialContext(null);
      setCurrentPhase(phases[1]);
    } else if (isEncounterMode && encounterContext) {
      setCurrentPhase(phases[1]);
    }
    // console.log({ InitialContext: initialContext });
  }, [encounterContext, initialContext, isEncounterMode, setInitialContext]);

  const queryClient = useQueryClient();

  const buttonGroupButtons = useMemo(() => {
    const { pathname, search } = location,
      onAppointmentScreen =
        pathname.indexOf('/schedule') === 0 && search.indexOf('?open=') === 0;
    const buttons: ButtonGroupType[] = [];
    if (!localData || isEncounterMode || !localData.length) return buttons;

    const transaction = localData[0],
      btn = {
        label: 'Appointment',
        to: `/schedule?open=${transaction.billingKey}`,
      } as ButtonGroupType;

    if (onAppointmentScreen) {
      delete btn.to;
      btn.onClick = () => {
        onCloseView?.({ data, dirty: !!backgroundDirty });
      };
    }

    if (transaction.hasAppointment) {
      buttons.unshift(btn);
    }

    return buttons;
  }, [
    backgroundDirty,
    data,
    isEncounterMode,
    localData,
    location,
    onCloseView,
  ]);

  const close = () => {
    setTimeout(() => {
      onCloseCleanup();
      setLocalData(null);
      setCurrentPhase(phases[2]);
      clearManagedActionItems();
    }, 750);
    if (mode === 'route') navigate(-1);
    onCloseView?.({ data, dirty: !!backgroundDirty });
  };

  /**
   * M e m o   A f t e r   H o o k s
   */
  const lockButtonStateByProviderId = useMemo(() => {
    if (!localData || !localData?.length) return {};
    const resp: { [key: string]: LockButtonStateType } = {};
    for (const row of localData) {
      if (row?.provider?.id && !resp[row.provider.id]) {
        resp[row.provider.id] = {
          available:
            row?.services?.length === 0
              ? false
              : localData
                  .filter((ld) => ld?.provider?.id === row?.provider?.id)
                  .every(
                    (transaction) =>
                      transaction?.services?.every(
                        (service) => (service?.diagnoses?.length ?? 0) > 0,
                      ),
                  ),
          locked:
            !!row?.services?.length &&
            !!row?.services?.every((service) => !!service?.locked),
        };
      }
    }
    return resp;
  }, [localData]);

  const isBalanceTransfer = useMemo(() => {
    if (!localData || !localData?.length) return false;
    const rowThatIsBalanceTransfer = localData?.find(
      (row: PatientTransaction) => {
        return (
          row?.type === TransactionTypeEnum.AdHoc &&
          String(row?.subtype) ===
            TransactionPurchaseSubtypeEnum.BalanceTransfer
        );
      },
    );
    return !!rowThatIsBalanceTransfer;
  }, [localData]);

  const isReadOnly = useMemo(() => {
    if (isBalanceTransfer) {
      return true;
    }
    return currentPhase.name !== 'Edit' && currentPhase.name !== 'New';
  }, [currentPhase.name, isBalanceTransfer]);

  const isNotReadOnly = useMemo(() => {
    return !isReadOnly;
  }, [isReadOnly]);

  const buttonColor = useMemo(() => {
    if (!localData || !localData?.length) return ButtonColors.primary;
    return localData?.[0]?.isBillingStarted
      ? ButtonColors.accent
      : ButtonColors.primary;
  }, [localData]);

  const trivialTooltip = useMemo(() => {
    if (!localData || !localData?.length) return { text: '' };
    return {
      text: localData?.[0]?.isBillingStarted
        ? ChiroUpTransactionCommon.billingStarted
        : '',
    };
  }, [localData]);

  const tabSummary = useMemo(() => {
    if (!localData || !localData?.length) return null;
    const workingTransaction = localData?.[0] as any;
    return (
      <TabSummary
        value={workingTransaction}
        omitClassName="rounded-lg hover:border-gray-400"
      />
    );
  }, [localData]);

  const mainTitle = useMemo(() => {
    if (!localData?.[0]?.patient) return undefined;
    const $i = localData?.[0] as any,
      resp = [
        ChiroUpDayJsCommon.display.datetimeWithTz(
          $i.transactionDate as number,
          $i.tz,
          userTz,
          ChiroUpDayJsCommon.format.date,
        ),
      ];
    return resp.join(' ');
  }, [localData, userTz]);

  /**
   * F u n c t i o n s
   */
  const newTransaction = (opts: any, location?: ClinicLocation) => {
    const newx = typeof opts === 'string' && opts === 'ad-hoc';
    const idToUse = newx ? v4() : opts.id;
    let newObject: PatientTransaction | null = null;
    /**
     * Use cases:
     * 1) New transaction without an appointment (ad-hoc).
     * 2) New transaction with an appointment.
     */
    if (newx) {
      newObject = PatientTransactionClass.newRecord({
        billingKey: idToUse,
        patient: patient
          ? ({
              id: patient?.ID,
              fname: patient.fname,
              lname: patient.lname,
              phone: patient.phone,
              email: patient.email,
              displayName: createPatientName(patient),
            } as PatientOnTransactionType)
          : undefined,
        clinicId: me.selectedClinic?.ID || -1,
        locationId: location?.ID || -1,
        tz: selectedLocationFull?.timezone,
        type: TransactionTypeEnum.AdHoc,
        created: {
          id: me?.ID || '',
          ts: Date.now(),
          displayName: me?.name || '',
        },
      });
      if (newObject) {
        setLocalData([newObject]);
        setCurrentPhase(phases[0]);
      }

      queryClient.setQueryData(['transaction', idToUse], newObject);
    } else {
      console.log('TODO: new transaction with appointment');
    }

    // onCloseView?.({ data: newObject ? [newObject] : null, dirty: true });
  };

  const actionsOnClick = useCallback(
    (
      action: ActionsOnClick,
      transaction: PatientTransaction | null | undefined,
    ) => {
      if (!transaction) return;
      const newo = ChiroUpJSON.clone(localData) as PatientTransaction[],
        providerId = transaction.provider?.id as string,
        isTransactionLocked = lockButtonStateByProviderId[providerId]?.locked;

      if (action === ActionsOnClick.Lock) {
        for (const row of newo ?? []) {
          if (row?.provider?.id === providerId) {
            for (const service of row?.services ?? []) {
              service.locked = !isTransactionLocked;
            }
            for (const item of (row?.items ?? []).filter((item) =>
              isaServiceItem(item),
            )) {
              item.locked = !isTransactionLocked;
            }
          }
        }
      } else if (action === ActionsOnClick.UnMerge) {
        if (lockButtonStateByProviderId?.[providerId]?.locked) {
          return;
        }
        for (const row of newo ?? []) {
          if (!row) continue;
          if (row.merged === transaction.billingKey) {
            row.merged = null;
          }
        }
      } else {
        alert(`Unknown action '${action}'!`);
        return;
      }

      for (const t of newo) {
        queryClient.setQueryData(['transaction', t.billingKey], t);
      }

      setLocalData(() => {
        return newo;
      });
    },
    [localData, lockButtonStateByProviderId, queryClient],
  );

  const mergeTransactions = useCallback(
    ({
      beforeCallback,
      afterCallback,
    }: {
      beforeCallback?: () => void;
      afterCallback?: () => void;
    }) => {
      beforeCallback?.();
      const newData = mergeTransactionsStandalone({
        transactions: localData ?? [],
        options: mergeOptions,
        queryClient,
      });

      // TODO: Maybe find a way around this React hack.
      // This blinks. But it is the easiest way to make React
      // play nice. Otherwise, it simply re-uses what it has
      // and that is not what we want.
      setTimeout(() => {
        setLocalData(() => {
          return [];
        });
        setTimeout(() => {
          setLocalData(() => {
            return newData.transactions;
          });
        }, 0);
      }, 0);

      setSlotContext(() => {
        return {
          [-1]: { ref: topOfPageRef, role: 'top' },
        };
      });

      afterCallback?.();
    },
    [localData, mergeOptions, queryClient],
  );

  /**
   * R e t u r n
   */
  if (mixedTypes) {
    return (
      <div>
        <div className="flex flex-row justify-center items-center h-96">
          <div className="flex flex-col items-center">
            <FaceFrownIcon className="h-12 w-12 text-gray-400" />
            <div className="text-gray-400 text-lg">
              Mixed transaction types are not supported.
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (failed) {
    return (
      <div>
        <div className="flex flex-row justify-center items-center h-96">
          <div className="flex flex-col items-center">
            <FaceFrownIcon className="h-12 w-12 text-gray-400" />
            <div className="text-gray-400 text-lg">
              The transaction could not be loaded.
            </div>
          </div>
        </div>
      </div>
    );
  }

  if ((localData?.[0]?.id ?? -1) === -1 && !localData?.[0]?.billingKey) {
    return (
      <Modal
        isOpen={isOpen}
        close={close}
        isFullScreen={true}
        addClasses={`bg-transparent`}
        omitClasses="bg-white"
      >
        <div
          className="rounded-lg overflow-hidden bg-white relative"
          ref={topOfPageRef}
        >
          {tabSummary}
          <div
            className="h-6 w-6 cursor-pointer font-black absolute text-gray-600"
            style={{ top: '2rem', right: '2.5rem' }}
            onClick={close}
          >
            <XMarkIcon />
          </div>
          <Loading
            flag={isFetching}
            style={`standard-gray`}
            addClassName="bg-gray-50"
          />

          <div className="flex flex-col mt-4 sm:grid-cols-2 xl:grid-cols-3 w-full px-4 pb-4">
            <div
              className="col-span-1 dark:border-darkGray-800 dark:bg-darkGray-700 dark:hover:border-darkGray-500"
              key={`ad-hoc-appt`}
            >
              {!isAnyRestRunning ? (
                locations.length > 0 ? (
                  <>
                    <div className="font-bold font-sans text-md text-gray-600">
                      Supplement or Supply Purchase by Location
                    </div>

                    {!!me?.selectedClinic?.locations?.length && (
                      <div className="flex p-4">
                        {me.selectedClinic.locations.map((location) => (
                          <div
                            className="flex "
                            key={`location-${location.ID}`}
                          >
                            <LocationCard
                              location={location}
                              onClick={(location: ClinicLocation) => {
                                // console.log(
                                //   `new adhoc transaction for ${location}`,
                                // );
                                newTransaction('ad-hoc', location);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    <cite>Locations are not set up.</cite>
                  </div>
                )
              ) : null}
            </div>
          </div>
        </div>
        <MakeBrowserWait isWaiting={isAnyRestRunning} />
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      isFullScreen={true}
      addClasses={`bg-transparent`}
      omitClasses="bg-white"
    >
      <div
        className="rounded-lg overflow-hidden bg-white relative"
        ref={topOfPageRef}
      >
        {tabSummary}
        <div
          className="h-6 w-6 cursor-pointer font-black absolute text-gray-600"
          style={{ top: '2rem', right: '2.5rem' }}
          onClick={close}
        >
          <XMarkIcon />
        </div>
        <Loading
          flag={isFetching}
          style={`standard-gray`}
          addClassName="bg-gray-50"
        />
        {trace ? (
          <pre data-id="debug-is-here" className="bg-yellow-200 p-8">
            {ChiroUpJSON.pretty({
              isAnyRestRunning,
              isFetching,
              isSaving,
              isError,
              payAvailability,
              isEditingNew,
              // transactionId,
              backgroundDirty,
              types: localData?.map((row) => ({
                type: row?.type,
                subtype: row?.subtype,
                billingKey: row?.billingKey,
              })),
            })}
          </pre>
        ) : null}
        <div
          data-id="consolidated-button-bar"
          className={classNames(
            isFetching || isError ? 'hidden' : '',
            ClassNames.bg,
            'flex flex-row justify-between pl-4 pt-4 pr-12 w-full',
          )}
          // Yes, I hate myself. Needed an easy button. Don't judge too harshly, big story. [BWM]
          style={
            isNotAdHoc && isNotEncounterMode
              ? { top: '3.5rem', right: '0' }
              : {}
          }
        >
          <div className="flex flex-row font-bold text-lg text-gray-600">
            {isNotAdHoc && isNotEncounterMode ? (
              <div className="w-16">&nbsp;</div>
            ) : null}
            <div className="pl-3 pt-2">{mainTitle}</div>
          </div>
          <ButtonGroup
            buttons={buttonGroupButtons}
            disabled={isFetching || isSaving || !localData?.length}
            isEmptyOkay={true}
          />
          <div>
            {currentPhase.name === 'View' &&
              !isBalanceTransfer &&
              !!localData &&
              !isFetching &&
              !isSaving &&
              !isError &&
              hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) && (
                <div className="flex justify-end h-8 space-x-4 pr-1">
                  <Button
                    text="Pay"
                    onClick={() => {
                      setShowPatientPaymentModal(true);
                    }}
                    color={buttonColor}
                    disabled={
                      isAnyRestRunning ||
                      Object.keys(payAvailability).filter(
                        (k) => payAvailability[k],
                      ).length === 0
                    }
                  />
                  <Button
                    text="Edit"
                    onClick={() => {
                      setCurrentPhase(phases[currentPhase.next]);
                    }}
                    trivialTooltip={trivialTooltip}
                    color={buttonColor}
                  />
                </div>
              )}
            {isNotReadOnly && (
              <div className="flex flex-row justify-end space-x-2 h-8 pr-2">
                <Button
                  text="Cancel"
                  onClick={() => {
                    if (editingNewData || isEncounterMode) {
                      close();
                      return;
                    }
                    if (isSaving) return;
                    setCurrentPhase(phases[currentPhase.next]);
                    clearManagedActionItems();
                    const rollbackObject = snapshot
                        ? ChiroUpJSON.parse(snapshot, null)
                        : null,
                      items = rollbackObject?.items ?? [];
                    setSlotContext(() => {
                      return {
                        [-1]: { ref: topOfPageRef, role: 'top' },
                      };
                    });

                    // Reset the cached transaction data since we may have been
                    // updating it.
                    for (const o of items) {
                      queryClient.setQueryData(['transaction', o.billingKey], {
                        ...o,
                        // This is so that ad hoc transactions update (go to one, remove an item, click cancel - does it come back?)
                        // Just close your eyes and forget you ever saw it.
                        updatedAt: new Date().getTime(),
                      });
                    }
                  }}
                  color={ButtonColors.plain}
                />
                {isEncounterMode || isAdHoc ? null : (localData ?? []).length <=
                  1 ? null : (
                  <Button
                    text="Merge"
                    onClick={(e: any) => {
                      e?.preventDefault?.();
                      e?.stopPropagation?.();
                      setConfirmMergeOpen(true);
                    }}
                    color={buttonColor}
                    disabled={mergeContext.isMerged || isAnyRestRunning}
                    tooltip={mergeButtonTooltip}
                  />
                )}

                <Button
                  text="Save"
                  loading={isSaving}
                  disabled={
                    !localData ||
                    isFetching ||
                    isSaving ||
                    !!managedActionItems.length
                  }
                  color={buttonColor}
                  trivialTooltip={trivialTooltip}
                  onClick={async (e: any) => {
                    if (managedActionItems.length) {
                      return;
                    }

                    const allLocalRows = Object.values(
                        getSingleTransaction,
                      ).map((fn) => fn?.()),
                      diff = ChiroUpJSON.compare({
                        obj1: { value: data ?? {} },
                        obj2: {
                          value: allLocalRows,
                        },
                        options: {
                          compareLeafAs: {
                            amount: 'number',
                            locked: 'boolean',
                          },
                          strict: true,
                        },
                      }),
                      localIsClean = Object.keys(diff).length === 0;
                    let lifeCycleIsDirty = false;

                    const woops = {
                      id: 'no-changes',
                      title: 'No changes detected.',
                      persistent: true,
                    };

                    // Is there anything dirty from the lifeCycle perspective?
                    if (lifeCycle && lifeCycle.isDirty) {
                      lifeCycleIsDirty = lifeCycle.isDirty();
                      if (lifeCycleIsDirty) {
                        console.log(
                          '......there are dirty things according to the lifecycle.',
                        );
                      }
                      if (lifeCycleIsDirty) {
                        if (lifeCycle?.save) {
                          try {
                            lifeCycle?.save?.();
                          } catch (e) {
                            console.error(e);
                            addManagedActionItem({
                              id: 'life-cycle-save-error',
                              title:
                                'There was an error in the lifecycle save.',
                            });
                            return;
                          }
                        } else {
                          addManagedActionItem({
                            id: 'no-life-cycle-save',
                            title: 'There is a lifecycle, but no save method.',
                          });
                          return;
                        }
                        if (localIsClean) return;
                      }
                    }

                    if (localIsClean && !lifeCycleIsDirty) {
                      addManagedActionItem(woops);
                      return;
                    }

                    const res: any | null | undefined =
                      await saveConsolidatedTransaction({
                        clinicId: me?.selectedClinic?.ID,
                        payload: allLocalRows,
                      });
                    if (!res) {
                      addManagedActionItem({
                        id: 'save-failed',
                        title:
                          'Save failed. Check the console for details errors.',
                        persistent: true,
                      });
                    } else if (res.statusCode !== 200) {
                      addManagedActionItem({
                        id: 'save-failed',
                        title: res.message,
                        persistent: true,
                      });
                    } else {
                      // Leave it in edit mode if we're in encounter mode.
                      if (!isEncounterMode) {
                        setCurrentPhase(phases[currentPhase.next]);
                      }

                      setTimeout(() => {
                        setBackgroundDirty(true);
                        setLocalData(() => {
                          return res.items;
                        });
                      }, 0);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <ManagedActionItems
          containerClassName={classNames(ClassNames.bg, 'pr-12 pt-4')}
          innerClassName="rounded-lg text-sm border border-gray-200 bg-orange-50 p-4"
          style={{
            paddingLeft: isAdHoc || isEncounterMode ? '1.5rem' : '5.6rem',
          }}
        />
        <div
          className={classNames('flex flex-row space-x-4 pb-8', ClassNames.bg)}
        >
          <div className="pl-6 pr-12 py-6 flex flex-col space-y-6 grow">
            {localData && Array.isArray(localData) && localData.length
              ? localData.map((row: PatientTransaction, idx) => (
                  <div key={`txn.${idx}`} className="bg-white">
                    <SingleTransaction
                      actionsOnClick={actionsOnClick}
                      close={close}
                      context={slotContext}
                      currentPhase={currentPhase}
                      encounterContext={encounterContext}
                      getSingleTransaction={getSingleTransaction}
                      hoverTextPrimary={hoverTextPrimary}
                      isBalanceTransfer={isBalanceTransfer}
                      isFetching={isAnyRestRunning}
                      isNotReadOnly={isNotReadOnly}
                      isReadOnly={isReadOnly}
                      isSaving={isSaving}
                      lockButtonStateByProviderId={lockButtonStateByProviderId}
                      mergeContext={mergeContext}
                      ord={idx}
                      policies={policies}
                      refetch={refetch}
                      row={row}
                      setBackgroundDirty={setBackgroundDirty}
                      setContext={setSlotContext}
                      setCurrentPhase={setCurrentPhase}
                      setPayAvailability={setPayAvailability}
                      textPrimary={textPrimary}
                      trace={trace}
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
        {isError ? (
          <div className="w-full flex justify-center p-8">
            <div className="h-24 w-24 text-red-500">
              <FaceFrownIcon />
            </div>
          </div>
        ) : null}
      </div>
      {showPatientPaymentModal && patient ? (
        <ConsolidatedPaymentModal
          isOpen={true}
          passedTransactions={localData || []}
          isRouted={false}
          callbacks={{
            onClose: () => {
              setShowPatientPaymentModal(false);
            },
            onSaving: () => {
              setIsRestActive(true);
            },
            finally: () => {
              setIsRestActive(false);
            },
          }}
          disableCreateCredit={true}
        />
      ) : null}
      {confirmMergeOpen ? (
        <ConfirmModal
          isOpen={true}
          confirm={(e: any) => {
            e?.preventDefault?.();
            e?.stopPropagation?.();
            mergeTransactions({
              beforeCallback: () => {
                setConfirmMergeOpen(false);
              },
            });
          }}
          close={() => setConfirmMergeOpen(false)}
          confirmText="Continue"
          focusOnCancel={true}
          confirmDisabled={Object.values(mergeOptions?.providers ?? {}).every(
            (value) => value === false,
          )}
        >
          <div className="mt-4 font-sans font-light text-sm flex flex-col space-y-2">
            {/* <pre>{ChiroUpJSON.pretty({ mergeOptions, providerOptions })}</pre> */}
            <p>
              This combines items from multiple transactions into single
              transactions for billing purposes. The options below control the
              process. Use with caution, once merged,{' '}
              <em>reversing the process completely is a manual operation</em>.
            </p>
            {/* <pre>
              {ChiroUpJSON.pretty({
                singleProviderOption,
                isNull: singleProviderOption === null,
                undef: singleProviderOption === undefined,
                countofProviders,
              })}
            </pre> */}
            {countofProviders > 1 ? (
              <p>
                By default, the transactions for all rendering providers will be
                affected. By unchecking a provider, they will be ignored.
              </p>
            ) : singleProviderOption ? (
              <p>
                All <cite>{singleProviderOption.displayName}</cite> transactions
                will be affected.
              </p>
            ) : (
              <AlertBlock
                issues={[
                  {
                    text: 'No providers found.',
                  },
                ]}
              />
            )}
            <p>
              When <cite>merge units</cite> is checked, the number of units for
              a given service code on the merged transaction will be the sum of
              the units on the individual transactions instead of the merged
              transaction having multiple items for the same service code.
            </p>
            <p>
              When <cite>merge treatments</cite> is checked, all treatments for
              a particular provider will be put under a single transaction.
            </p>
          </div>

          <div className="font-sans font-medium text-md mt-4 text-primary-500 mb-2">
            Options
          </div>
          <div className="flex flex-col space-y-2">
            <ProvidersToMerge
              providers={providerOptions}
              options={mergeOptions}
              onClick={(provider) => {
                setMergeOptions((p) => {
                  if (!p) return p;
                  const key = provider?.id ?? '';
                  if (typeof p?.providers?.[key] !== 'boolean') {
                    p.providers = p.providers ?? {};
                    p.providers[key] = true;
                  } else {
                    p = {
                      ...p,
                      providers: {
                        ...p.providers,
                        [key]: !p.providers[key],
                      },
                    };
                  }
                  return p;
                });
              }}
            />
            <Checkbox
              value={mergeOptions.units}
              onChange={() => {
                setMergeOptions((p) => {
                  return {
                    ...p,
                    units: !p.units,
                  };
                });
              }}
              label="Merge units"
            />
            <Checkbox
              value={mergeOptions.treatments}
              onChange={() => {
                setMergeOptions((p) => {
                  return {
                    ...p,
                    treatments: !p.treatments,
                  };
                });
              }}
              label="Merge treatments"
            />
          </div>
        </ConfirmModal>
      ) : null}
    </Modal>
  );
};

export default ConsolidatedTransactionModal;

type ProvidersToMerge = {
  providers?: {
    [key: string]: ProviderOnTransactionType & { selected?: boolean };
  };
  options: MergeTransactionsOptions;
  onClick: (provider?: ProviderOnTransactionType) => void;
  containerClassName?: string;
};

const ProvidersToMerge: React.FC<ProvidersToMerge> = ({
  providers,
  onClick,
  options = {},
  containerClassName = '',
}: ProvidersToMerge) => {
  const aProviders = useMemo(() => {
    if (!providers) return [];
    return Object.values(providers);
  }, [providers]);

  if (!aProviders.length) return null;

  return (
    <>
      {aProviders.map((provider) => {
        return (
          <Checkbox
            key={provider.id}
            value={options?.providers?.[provider.id]}
            onChange={() => {
              onClick(provider);
            }}
            label={`Merge for ${provider.displayName}`}
          />
        );
      })}
    </>
  );
};
