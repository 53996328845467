import patientBillingService from '../../../../../services/patientBilling.service';
import { MeContext } from '../../../../../contexts/me.context';
import { useContext, useEffect, useState } from 'react';
import {
  QueryFunctionContext,
  SetDataOptions,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import {
  ConsolidatedTransactionGetResponse,
  PatientTransaction,
} from '@chiroup/core/types/PatientTransaction.type';
import { Insurance } from '@chiroup/core/types/PatientInsurance.type';
import { Patient } from '@chiroup/core/types/Patient.type';
// import useServices from '../../visits/notes/services/useServices';

const getQuery = function (clinicId: number, queryClient: any) {
  return async (context: QueryFunctionContext) => {
    const billingKey = context.queryKey[1] as string;
    if (!billingKey) return;
    if (billingKey === 'new') return {} as any;
    const res = (await patientBillingService.getConsolidatedTransaction(
      clinicId,
      billingKey,
    )) as unknown as ConsolidatedTransactionGetResponse;
    // const { items, policies, patient } = res;
    // // We want to set the transaction cache so they are not loaded.
    // for (const item of items) {
    //   // console.log({ getQuery_settingCacheFor: item.billingKey });
    //   const patientId = item?.patient?.id,
    //     bk = item?.billingKey,
    //     providerId = item?.provider?.id;
    //   for (const insurance of item?.insurances ?? []) {
    //     queryClient?.setQueryData(
    //       ['insuranceList', patientId, bk, providerId],
    //       insurance,
    //       { staleTime: 5000 },
    //     );
    //   }
    // }
    return res;
  };
};

export const useConsolidatedTransaction = function ({
  billingKey,
  onFetchFail,
}: {
  billingKey?: string | null;
  onFetchFail?: (e: any) => void;
}) {
  const queryClient = useQueryClient();
  const QUERY_KEY_ARRAY = ['consolidated-transaction', billingKey];
  const { me } = useContext(MeContext);
  const { createToast } = useContext(ToastContext);
  const [snapshot, setSnapshot] = useState<string>();
  const [data, setData] = useState<PatientTransaction[]>([]);
  const [policies, setPolicies] = useState<Insurance[]>([]);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [failed, setFailed] = useState(false);
  const {
    status,
    data: fullQueryResponse,
    isFetching,
    refetch,
    isError,
  } = useQuery(
    QUERY_KEY_ARRAY,
    getQuery(me?.selectedClinic?.ID || -1, queryClient),
    {
      onError: (error: any) => {
        createToast({
          title: `Request Failed`,
          description: `${
            error?.response?.data?.message ??
            error?.response?.data?.error ??
            error?.message ??
            'Unknown error.'
          }`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
        onFetchFail?.(error);
        setIsSaving(false);
        // console.log({ isFetching });
        queryClient.setQueryData(QUERY_KEY_ARRAY, undefined);
        setFailed(true);
      },
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5000,
      keepPreviousData: true,
    },
  );

  const onCloseCleanup = () => {
    // queryClient.invalidateQueries(QUERY_KEY_ARRAY);
    setSnapshot(undefined);
  };

  const save = async ({
    clinicId,
    payload,
  }: {
    clinicId: number | null | undefined;
    payload: PatientTransaction[] | null | undefined;
  }) => {
    setIsSaving(true);
    try {
      const res = await patientBillingService.saveConsolidatedTransaction({
        clinicId,
        payload,
      });
      // console.log({ ResultFromSaveOnUseConsolidatedTransaction: res });
      if (res && res.items && res.issues) {
        queryClient.setQueryData(QUERY_KEY_ARRAY, res.items);
        for (const item of res.items) {
          queryClient.setQueryData(
            ['transaction', item.billingKey],
            item as PatientTransaction,
            { staleTime: 5000 } as SetDataOptions,
          );
        }
      }
      setSnapshot(res?.items ? JSON.stringify(res.items) : undefined);
      return res;
    } catch (e: any) {
      createToast({
        title: `Failed to save transaction`,
        description: `${
          e?.response?.data?.message ??
          e?.response?.data?.error ??
          e?.message ??
          'Unknown error.'
        }`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  // When the data changes, we need to take a new snapshot AND
  // update the queryClient with the new data to prevent an
  // unnecessary REST call.
  useEffect(() => {
    if (fullQueryResponse) {
      // console.log({ data });
      if (!snapshot) setSnapshot(JSON.stringify(fullQueryResponse));
      const items = fullQueryResponse?.items ?? [],
        policies = fullQueryResponse?.policies ?? [],
        patient = fullQueryResponse?.patient ?? {};

      for (const o of items) {
        queryClient.setQueryData(['transaction', o.billingKey], o);
      }
      setData(() => items);
      setPolicies(() => policies);
      setPatient(() => patient);
    }
  }, [fullQueryResponse, queryClient, snapshot]);

  return {
    data,
    failed,
    isError,
    isFetching,
    isSaving,
    items: data,
    onCloseCleanup,
    patient,
    policies,
    refetch,
    save,
    setSnapshot,
    snapshot,
    status,
  };
};
