import { useQuery } from 'react-query';
import patientBillingService from '../services/patientBilling.service';
import { MeContext } from '../contexts/me.context';
import { useContext } from 'react';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import { PatientPackage } from '@chiroup/core/types/Packages.type';

type Props = {
  patientId?: string;
  locationId?: number;
};
const useApplicablePatientPackageList = ({ patientId, locationId }: Props) => {
  const { me, hasAccess } = useContext(MeContext);
  const { data, isFetching, refetch } = useQuery<{
    data: {
      packages: PatientPackage[];
      servicesCovered: { [key: number]: number[] };
      treatmentsCovered: { [key: number]: number[] };
    };
  }>(
    ['patientPackageList', patientId, locationId],
    () =>
      patientBillingService.getApplicablePatientPackageList({
        patientId,
        locationId,
        clinicId: me?.selectedClinic?.ID,
        hasAccess: hasAccess(FeatureFlags.packages),
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!patientId,
    },
  );

  return { isFetching, data, refetch };
};

export default useApplicablePatientPackageList;
