import { LoadingPage } from '@chiroup/components';
import InvoiceHeader from './Invoice/InvoiceHeader';
import InvoiceBreakdown from './Invoice/InvoiceBreakdown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useInvoice from './Invoice/hooks/useInvoice';
import Modal from '../../../../../components/common/Modal';
import InvoiceTotals from './Invoice/InvoiceTotals';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import {
  EnvelopeIcon,
  PrinterIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import IconButton from '../../../../../components/common/IconButton';
import { useReactToPrint } from 'react-to-print';
import InvoiceClinicHeader from './Invoice/InvoiceClincHeader';
import InvoiceNotes from './Invoice/InvoiceNotes';
import patientBillingService from '../../../../../services/patientBilling.service';
import InvoicePaymentHistory from './Invoice/InvoicePaymentHistory';
import patientService from '../../../../../services/patient.service';
import TransactionPatientInvoicePrintView from './TransactionPatientInvoicePrintView';
import { InvoiceUseEnum } from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import { Patient } from '@chiroup/core/types/Patient.type';
import { PatientInvoice } from '@chiroup/core/types/PatientInvoice.type';

type Props = {
  refetch?: () => void;
  use?: InvoiceUseEnum;
  navigateOverride?: string;
  skipUseQuery?: boolean;
  invoiceId?: string | number;
  callbacks?: {
    onClose?: () => void;
  };
};

const TransactionPatientInvoice = ({
  refetch: passedRefetch = () => {},
  use,
  navigateOverride,
  skipUseQuery,
  invoiceId: passedInvoiceId,
  callbacks,
}: Props) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [invoiceData, setInvoiceData] = useState<PatientInvoice>();
  const [isPrinting, setIsPrinting] = useState(false);
  const [isEmailing, setIsEmailing] = useState(false);
  const [patient, setPatient] = useState<Patient | null>(null);

  const { invoiceId: pathInvoiceId } = useParams() as { invoiceId: string };

  const { createToast } = useContext(ToastContext);

  const invoiceId = useMemo(() => {
    return Number(passedInvoiceId ?? pathInvoiceId);
  }, [passedInvoiceId, pathInvoiceId]);

  const {
    data,
    refetch: invoiceRefetch,
    isFetching,
    save,
    isSaving,
  } = useInvoice({
    invoiceId,
    use,
  });

  const refetch = useMemo(() => {
    return passedRefetch ?? invoiceRefetch;
  }, [invoiceRefetch, passedRefetch]);

  const componentRef = useRef(null);

  useEffect(() => {
    let isComponentMounted = true;
    const createTransactionActivity = async () => {
      try {
        if (!isComponentMounted) return;
        await patientBillingService.createTransactionActivity({
          billingKey: data?.billingKey ?? '',
          clinicId: data?.clinicId ?? -1,
        });

        setInvoiceData((prev) => {
          const updatedInvoiceData = prev ? { ...prev, printed: true } : prev;
          return updatedInvoiceData;
        });
        const invoiceDataToSave = ChiroUpJSON.clone(invoiceData);
        invoiceDataToSave.printed = true;
        save(invoiceDataToSave, data?.patientId ?? '', true);
      } catch (error) {
        console.error('Error creating transaction activity:', error);
      }
    };

    if (isPrinting) {
      createTransactionActivity();
    }
    return () => {
      isComponentMounted = false;
    };
  }, [isPrinting, invoiceData, data, save]);

  const close = () => {
    if (callbacks?.onClose) {
      callbacks.onClose();
      return;
    }
    if (navigateOverride) {
      navigate(navigateOverride);
      return;
    }
    if (search === '?invoice=true') {
      //this means we came from reporting
      navigate(pathname.substring(0, pathname.lastIndexOf('/')));
      return;
    }
    const newPath = pathname.substring(0, pathname.lastIndexOf('/')) + search;
    navigate(newPath);
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      if (data && isMounted) {
        setInvoiceData(data?.invoiceData as PatientInvoice);
        if (data.patientId) {
          const patientId = data.patientId,
            dataAsAny = data as any;
          const patient =
            dataAsAny?._meta?.patientRecord ??
            (await patientService.findOne(patientId, data.clinicId));
          setPatient(patient);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [data, setInvoiceData]);

  const onSuccess = (message = 'Successfully updated invoice!') => {
    createToast({
      title: 'Success!',
      description: message,
      type: ToastTypes.Success,
      duration: 5000,
    });
  };

  const onFail = (message = 'Failed to update invoice!') => {
    createToast({
      title: 'Error!',
      description: message,
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };

  const saveInvoice = async () => {
    if (!invoiceData) return;
    try {
      const res = await save(invoiceData, data?.patientId ?? '');
      setInvoiceData(res.invoiceData);
      onSuccess();
    } catch (e) {
      console.error(e);
      onFail();
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: async () => {
      setIsPrinting(true);
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 0);
      });
    },
    pageStyle: () => {
      return `
      @media print 
      {
          @page {
            size: 50mm 150mm;
            margin: 0;
          }
          html, body {
              width: 210mm;
              height: 297mm;
              font-size: 11px;
              background: #FFF;
              overflow: visible;
          }
          body {
              padding-top: 15mm;
          }
      }
      `;
    },
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });

  const handleEmail = async () => {
    setIsEmailing(true);
    try {
      setInvoiceData((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          printed: true,
        };
      });
      const invoiceToSend = ChiroUpJSON.clone(invoiceData);
      invoiceToSend.printed = true;
      await save(invoiceToSend, data?.patientId ?? '', true);
      await patientBillingService.emailPatientInvoice({
        invoiceId: +invoiceId,
        clinicId: data?.clinicId ?? -1,
      });
      onSuccess('Emailed patient invoice!');
    } catch (e) {
      onFail('Failed to email patient invoice!');
      console.error(e);
    } finally {
      refetch();
      setIsEmailing(false);
    }
  };

  const userSettingsBilling = useMemo(() => {
    if (!data) return null;
    return (data as any)?._meta?.userSettingsBilling;
  }, [data]);

  return (
    <Modal isOpen close={close} isFullScreen>
      {isFetching || !patient ? (
        <LoadingPage />
      ) : data && invoiceData ? (
        <>
          {/* <pre>{ChiroUpJSON.pretty(invoiceData)}</pre> */}
          <div className="flex flex-row justify-between">
            <div className="ml-8">
              {data?.dispatched && (
                <IconButton
                  className="h-6 w-6 text-primary-600 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300 print:hidden flex align-right"
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                      />
                    </svg>
                  }
                  tooltip="Invoice has been dispatched"
                  onClick={close}
                  disabled={true}
                />
              )}
            </div>
            <div className="flex items-center justify-end space-x-2 ">
              <IconButton
                className="h-6 w-6 text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300 print:hidden mb-1"
                icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    pointerEvents="bounding-box"
                    className={
                      'w-full h-full text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300'
                    }
                  >
                    <path
                      d="M6.75 19.25H17.25C18.3546 19.25 19.25 18.3546 19.25 17.25V9.82843C19.25 9.29799 19.0393 8.78929 18.6642 8.41421L15.5858 5.33579C15.2107 4.96071 14.702 4.75 14.1716 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                    ></path>
                    <path
                      d="M8.75 19V15.75C8.75 15.1977 9.19772 14.75 9.75 14.75H14.25C14.8023 14.75 15.25 15.1977 15.25 15.75V19"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M8.75 5V8.25"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                }
                onClick={saveInvoice}
                loading={isSaving}
                disabled={isSaving || isEmailing || isPrinting || isFetching}
              />

              <IconButton
                className="h-6 w-6 text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300 print:hidden"
                icon={<PrinterIcon />}
                onClick={handlePrint}
                // tooltip="Print"
                disabled={isSaving || isEmailing || isPrinting || isFetching}
              />
              <IconButton
                className="h-6 w-6 text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300 print:hidden"
                icon={<EnvelopeIcon />}
                // tooltip="Print"
                onClick={handleEmail}
                loading={isEmailing}
                disabled={isSaving || isEmailing || isPrinting || isFetching}
              />
              <IconButton
                className="h-6 w-6 text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300 print:hidden"
                icon={<XMarkIcon />}
                // tooltip="Print"
                onClick={close}
                disabled={isSaving || isEmailing || isPrinting || isFetching}
              />
            </div>
          </div>

          <InvoiceClinicHeader location={invoiceData.location} />
          <InvoiceHeader
            id={data?.number ? `${data.number}` : 'unknown'}
            patient={patient}
            selectedLocation={invoiceData.location as ClinicLocation}
            issuedDate={(invoiceData.issuedDate as string) ?? ''}
            status={invoiceData.status}
            setInvoiceData={setInvoiceData}
          />
          <InvoiceBreakdown
            items={invoiceData.invoiceBreakdown?.items ?? []}
            lineItems={invoiceData.invoiceBreakdown?.lineItems ?? []}
            payors={invoiceData?.payors ?? {}}
            purchaseDate={invoiceData.invoiceBreakdown?.purchaseDate ?? ''}
            provider={invoiceData.invoiceBreakdown?.renderingProvider ?? ''}
            setInvoiceData={setInvoiceData}
            providerId={invoiceData.invoiceBreakdown?.renderingProviderId ?? ''}
            license={invoiceData?.invoiceBreakdown?.providerLicense ?? null}
            patientResponsibility={invoiceData?.patientResponsibility ?? 0}
            userSettingsBilling={userSettingsBilling}
            skipUseQuery={skipUseQuery}
          />
          <InvoiceTotals
            subtotal={invoiceData?.subTotal || 0}
            tax={invoiceData?.taxTotal || 0}
            total={invoiceData?.total || 0}
            balance={invoiceData?.balance || 0}
            notes={invoiceData?.notes || undefined}
          />
          <InvoicePaymentHistory payments={invoiceData?.paymentHistory} />
          <InvoiceNotes
            invoiceData={invoiceData}
            setInvoiceData={setInvoiceData}
          />
          <div className={'hidden'}>
            <TransactionPatientInvoicePrintView
              ref={componentRef}
              data={data}
              invoiceData={invoiceData}
              patient={patient}
            />
          </div>
        </>
      ) : null}
    </Modal>
  );
};

export default TransactionPatientInvoice;
