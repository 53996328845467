/**
 * TODO: What is the SEMANTICS from an invoice status perspective of
 * an individual payor line item that is backed out leaving others
 * alone? Partially pregnant as it were.
 *
 */
import {
  BusinessRuleSolution,
  Button,
  ButtonColors,
  Currency,
  HashCardDisplay,
  HashCardDisplayColors,
  icons,
  Loading,
  MakeBrowserWait,
  OpenClosedStates,
  Select,
  TimestampDisplay,
  Toggle,
  trivialDownload,
  TrivialTooltip,
} from '@chiroup/components';
import {
  BusinessRuleItemType,
  calculatePatientAge,
  STRING_ANY_HASH,
  STRING_BOOLEAN_HASH,
  STRING_NUMBER_HASH,
  STRING_STRING_HASH,
} from '@chiroup/core/constants/globals';
import {
  ChiroUpBaseCommon,
  ChiroUpDayJsCommon,
} from '@chiroup/core/constants/stringConstants';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { classNames } from '@chiroup/core/functions/classNames';
import { clog } from '@chiroup/core/functions/clog';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import { formatCurrency } from '@chiroup/core/functions/format';
import { hasProperty } from '@chiroup/core/functions/hasProperty';
import {
  ClaimResponseEraStatus,
  ClaimResponseEraType,
  ClaimServiceItemType,
} from '@chiroup/core/types/Era.type';
import {
  AdjustmentActionType,
  AllInvoiceStatusOptions,
  AllowedInvoiceStatusTransitions,
  IntegrationInvoice,
  InvoiceGenerationEdiXref,
  InvoiceStatusData,
  InvoiceStatusDataItemType,
  InvoiceStatusEnum,
  InvoiceStatusTips,
  isaInvoiceAdjustableStatus,
  PayorPriorityDisplay,
} from '@chiroup/core/types/Invoice.type';
import {
  primaryInsuredNameDisplay,
  primaryInsuredTypeDisplay,
  PrimaryInsuredTypes,
} from '@chiroup/core/types/PatientInsurance.type';
import {
  PAYMENT_TYPE_DISPLAY,
  PayorPaymentTypesHash,
} from '@chiroup/core/types/PatientPayment.type';
import {
  isaServiceItem,
  PatientTransaction,
  PatientTransactionItemType,
  PatientTransactionRowType,
  TransactionItemTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { PayorLineItemType } from '@chiroup/core/types/Payor.type';
import {
  ArrowDownOnSquareIcon,
  ArrowPathIcon,
  ArrowPathRoundedSquareIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUturnUpIcon,
  CheckCircleIcon,
  CheckIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  CogIcon,
  DocumentMinusIcon,
  DocumentPlusIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import React, {
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import businessRulesService from '../../services/businessRules.service';
import { integrationService } from '../../services/integration.service';
import patientBillingService from '../../services/patientBilling.service';
import IconButton from '../common/IconButton';
import Modal from '../common/Modal';
import useIntegrationInvoice from './hooks/useIntegrationInvoice';
import { metaByInvoiceStatus } from './metaByInvoiceStatus';

type Props = {
  invoiceId: number;
  state: OpenClosedStates;
  close?: (isDirty?: boolean) => void;
  clinicId: number;
  trace?: boolean;
  containerCallback: (_: IntegrationInvoice | null | undefined) => void;
  updateInvoiceCallback?: (_: {
    invoice: IntegrationInvoice | null | undefined;
    refetch?: boolean;
  }) => void;
  readonly?: boolean;
  onModalStateChange?: (args?: any) => void;
};

const ClassNames = {
  adjustmentSeps: 'px-4 py-2 text-gray-400 font-semibold bg-gray-50',
  noPayments:
    'mb-2 rounded-none px-8 pb-2 text-gray-400 italic border-gray-400',
  cre: {
    numbersValue: 'flex justify-end',
    firstNumberValue: 'flex',
    numbersLabel: 'text-xs flex justify-end text-gray-400',
    firstNumberLabel: 'text-xs flex text-gray-400',
    fieldDiv: 'py-1 flex flex-row justify-between',
    firstIdentLabel: 'text-xs flex text-gray-400 justify-start',
    firstIdentValue: 'flex justify-start',
    identValue: 'flex justify-end',
    identLabel: 'text-xs flex text-gray-400 justify-end',
    lastLabel: 'text-xs flex text-gray-400 justify-end',
    lastValue: 'flex justify-end',
    firstLastLabel: 'text-xs flex text-gray-400 justify-start',
    firstLastValue: 'flex justify-start',
  },
  callback:
    'flex justify-end font-semibold text-primary-400 hover:text-primary-600 h-8 w-8 w-full pt-1',
  table: {
    firstTh: 'font-normal text-xs',
    th: 'font-normal text-xs text-right',
    padTh: 'font-normal text-xs text-right px-2',
    leftTh: 'font-normal text-xs text-left',
    firstCell: '',
    cell: 'text-right',
    leftCell: 'text-left',
    iFirstTh: 'font-normal text-xs',
  },
  subHeadingNote: 'font-normal text-xs',
  subHeadingNoteSep: 'px-4 pt-2 text-black font-normal',
  circle:
    'min-w-6 flex items-center justify-center w-6 h-6 rounded-full text-primary-500 border border-primary-500',
  trash:
    'flex justify-end grow min-w-6 text-primary-400 cursor-pointer hover:text-primary-600',
  noServicesFound: 'text-gray-400 italic',
  td: {
    xySize: 'h-8 w-8',
  },
};

const CommonInvoiceModal: React.FC<Props> = ({
  invoiceId,
  state,
  trace = false,
  close = (isDirty?: boolean) => {
    if (trace) clog(`Close not implemented: ${isDirty}.`);
  },
  updateInvoiceCallback = (_) => {
    if (trace) {
      clog('CommonInvoiceModal :: updateInvoiceCallback not implemented.');
    }
  },
  readonly = false,
}) => {
  const { pathname } = useLocation();
  if (!invoiceId || invoiceId <= 0) {
    const parts = pathname.split('/');
    invoiceId = Number(parts[parts.length - 1]);
  }
  const { me } = useContext(MeContext);
  const clinicId = me?.selectedClinic?.ID ?? -1;
  const {
    adjust,
    data: rawInvoice,
    toggleElectronicBilling,
    isRestActive: toggleRestActive,
    setInvoiceData,
    isFetching,
    restFailed,
    setRestFailed,
  } = useIntegrationInvoice({
    clinicId,
    id: invoiceId,
  });
  const invoice = useMemo(() => {
    if (!rawInvoice) {
      return {} as IntegrationInvoice;
    }
    return rawInvoice;
  }, [rawInvoice]);

  const [adjustPayload, setAdjustPayload] = useState<any>(null);
  const [adjustmentActions, setAdjustmentActions] = useState<
    AdjustmentActionType[]
  >([]);

  const adjustResponses = useMemo(() => {
    if (!adjustPayload?.adjust?.responses) return [];
    return adjustPayload?.adjust?.responses ?? [];
  }, [adjustPayload]);

  const adjustPayments = useMemo(() => {
    // return [];
    if (!adjustPayload?.adjust?.payments) return [];
    return adjustPayload?.adjust.payments;
  }, [adjustPayload]);

  const adjustEras = useMemo(() => {
    return adjustPayload?.adjust?.eras ?? [];
  }, [adjustPayload]);

  const adjustItems = useMemo(() => {
    return adjustPayload?.adjust?.items ?? [];
  }, [adjustPayload]);

  const actionContext = useMemo(() => {
    const actionsByKey: STRING_NUMBER_HASH = {},
      actionObjectsByKey: STRING_ANY_HASH = {},
      unpostByCode: { [key: string]: AdjustmentActionType[] } = {},
      postByCode: { [key: string]: AdjustmentActionType[] } = {},
      responsesWithoutServices: STRING_ANY_HASH = {},
      creIdsWithoutServices: STRING_ANY_HASH = {};

    let countOfResponsesWithoutServices = 0;

    const erasById = adjustEras.reduce(
      (acc: AdjustmentEraTypeHash, era: AdjustmentEraType) => {
        acc[String(era.id)] = era;
        return acc;
      },
      {} as AdjustmentEraTypeHash,
    );

    const insuranceById: {
        [key: string]: {
          name?: string;
          code?: string;
          payorId?: number;
          invoice?: boolean;
          pli?: boolean;
          cre?: boolean;
          payment?: boolean;
        };
      } = {},
      key = invoice?.payorId ?? -1;
    if (key > 0) {
      insuranceById[key] = {
        name: invoice?.legalName,
        code: '',
        payorId: key,
        invoice: true,
      };
    }

    invoice?.payorLineItems?.forEach((item) => {
      const key = item.payorId ?? -1;
      if (key > 0) {
        if (!insuranceById[key]) {
          insuranceById[key] = {
            name: '- na -',
            code: '- na -',
            payorId: key,
            pli: true,
          };
        } else {
          insuranceById[key].pli = true;
        }
      }
    });

    adjustmentActions.forEach((item, ord: number) => {
      const key = [item.action, item.type, item.pk].join('.');
      actionsByKey[key] = ord + 1;
      actionObjectsByKey[key] = item;

      if (item.action === 'unpost') {
        const code = item?.row?.adjudicatedProcedureCode ?? '-na-';
        if (!unpostByCode[code]) {
          unpostByCode[code] = [];
        }
        unpostByCode[code].push(item);
      } else if (item.action === 'post') {
        const code = item?.row?.adjudicatedServiceCode ?? '-na-';
        if (!postByCode[code]) {
          postByCode[code] = [];
        }
        postByCode[code].push(item);
      }
    });

    adjustResponses?.forEach((item: any, ord: number) => {
      if (!item?.serviceItems?.length) {
        responsesWithoutServices[item.eraId] = true;
        countOfResponsesWithoutServices++;
        if (item?.id) {
          creIdsWithoutServices[item?.id] = item;
        }
      }
      const key = item?.payorId ?? -1;
      if (key > 0) {
        if (!insuranceById[key]) {
          insuranceById[key] = {
            name: item.payorName,
            code: item.payorCode,
            payorId: key,
            cre: true,
          };
        } else {
          insuranceById[key].code = item?.payorCode || insuranceById[key].code;
          insuranceById[key].cre = true;
        }
      }
    });
    adjustPayments?.forEach((item: any, ord: number) => {
      const key = item?.payorId ?? -1;
      if (key > 0) {
        if (!insuranceById[key]) {
          insuranceById[key] = {
            name: item.payorName,
            code: item.payorCode,
            payorId: key,
            payment: true,
          };
        } else {
          insuranceById[key].code = item?.payorCode || insuranceById[key].code;
          insuranceById[key].payment = true;
        }
      }
    });

    const payorsByCode: STRING_STRING_HASH = Object.keys(insuranceById).reduce(
      (acc, key) => {
        const item = insuranceById[key],
          code = item?.code ?? '-1';
        if (code) {
          acc[code] = item.name ?? '- na -';
        }
        return acc;
      },
      {} as STRING_STRING_HASH,
    );

    return {
      actionObjectsByKey,
      actionsByKey,
      adjustItems,
      adjustmentActions,
      countOfResponsesWithoutServices,
      creIdsWithoutServices,
      erasById,
      insuranceById,
      payorsByCode,
      postByCode,
      responsesWithoutServices,
      unpostByCode,
    };
  }, [
    adjustEras,
    adjustItems,
    adjustPayments,
    adjustResponses,
    adjustmentActions,
    invoice?.legalName,
    invoice?.payorId,
    invoice?.payorLineItems,
  ]);

  const actionsByKey = useMemo(() => {
    return actionContext?.actionsByKey ?? {};
  }, [actionContext?.actionsByKey]);

  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [adjustBilledServices, setAdjustBilledServices] = useState(false);
  const [newStatus, setNewStatus] = useState<InvoiceStatusEnum | null>(null);
  const { selectedLocationFull } = useContext(MeContext);
  const navigate = useNavigate();

  const shrinkButtons = useMemo(() => {
    return (
      invoice.status === InvoiceStatusEnum.Submitted &&
      invoice.electronicBilling === false
    );
  }, [invoice]);

  //  In case we want to do something before closing.
  const componentClose = (isDirty?: boolean) => {
    close(isDirty);
  };

  const eraHistory = useMemo(() => {
    if (!invoice) return null;
    if (!invoice.statusData) return null;
    if (!invoice.statusData.items) return null;
    let hits = 0;
    const history: STRING_ANY_HASH = {};
    // Reverse order so oldest are on the top if they
    const omg = ChiroUpJSON.clone(
      invoice?.statusData?.items,
    ).reverse() as InvoiceStatusDataItemType[];
    omg.forEach((item) => {
      const ts = Number(item.ts);
      if (item.payorLineItems) {
        hits++;
        item.payorLineItems.forEach((lineItem) => {
          const eraId = [lineItem.eraId ?? 'No ERA', hits].join('.');
          if (!history[eraId]) {
            history[eraId] = [];
          }
          history[eraId].push({ ...lineItem, ts });
        });
      }
    });
    return history;
  }, [invoice]);

  const metaContext = useMemo(() => {
    if (!invoice) return {};
    const res = {
      [invoice.id]: {
        delayReason: invoice.delayReason,
        generation: invoice.generation,
        claimControlNumber: invoice.claimControlNumber,
      },
    };
    return res;
  }, [invoice]);

  useEffect(() => {
    if (!invoice) return;
    setNewStatus(invoice.status as InvoiceStatusEnum);
  }, [invoice]);

  const validTransitions = useMemo(() => {
    if (!invoice) return [];
    return AllInvoiceStatusOptions.filter((option) => {
      return (
        AllowedInvoiceStatusTransitions[invoice.status as InvoiceStatusEnum]?.[
          option.value
        ] || option.value === invoice.status
      );
    });
  }, [invoice]);

  const userTz = useMemo(() => {
    return selectedLocationFull.timezone;
  }, [selectedLocationFull]);

  const disableToggle = useMemo(() => {
    if (!invoice) return false;
    return (
      invoice.status === InvoiceStatusEnum.Submitted ||
      invoice.status === InvoiceStatusEnum.Received ||
      invoice.status === InvoiceStatusEnum.Reprocessed ||
      invoice.status === InvoiceStatusEnum.Partial ||
      invoice.status === InvoiceStatusEnum.Paid
    );
  }, [invoice]);

  const [resolvedSomething, setResolvedSomething] = useState(false);
  const { createToast } = useContext(ToastContext);
  const [isRestActive, setIsRestActive] = useState<{
    resolve?: boolean;
    save?: boolean;
    test?: boolean;
    submit?: boolean;
    download?: boolean;
    downloadComplete?: boolean;
    downloadFields?: boolean;
    updateStatus?: boolean;
    saveResolved?: boolean;
    any?: boolean;
  }>({});
  // const [downloadContent, setDownloadContent] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  // 'saved' is used to decide if the buttons have been pushed once already.
  // The modal goes unresponsive and needs to be closed in re-opened at that
  // point. Might not be the desired behavior, but that was the idea.
  const [saved, setSaved] = useState<boolean>(false);

  const anyRestActive = useMemo(() => {
    return (
      isRestActive.resolve ||
      isRestActive.save ||
      isRestActive.test ||
      isRestActive.submit ||
      isRestActive.download ||
      isRestActive.downloadComplete ||
      isRestActive.downloadFields ||
      isRestActive.updateStatus ||
      isRestActive.saveResolved ||
      isRestActive.any ||
      toggleRestActive
    );
  }, [
    isRestActive.any,
    isRestActive.download,
    isRestActive.downloadComplete,
    isRestActive.downloadFields,
    isRestActive.resolve,
    isRestActive.save,
    isRestActive.saveResolved,
    isRestActive.submit,
    isRestActive.test,
    isRestActive.updateStatus,
    toggleRestActive,
  ]);

  const noStatus = useMemo(() => {
    return (
      !invoice ||
      !invoice.statusData ||
      !invoice.statusData.items ||
      !invoice.statusData.items[0] ||
      !invoice.statusData.items[0].final
    );
  }, [invoice]);

  const latestInvoiceMessages = useMemo(() => {
    if (!invoice) return null;
    const statusData =
        typeof invoice?.statusData === 'string'
          ? ChiroUpJSON.parse(invoice?.statusData)
          : invoice?.statusData,
      items = statusData?.items ?? [],
      latest = items[0] ?? null,
      obj = {
        issues: latest?.issues ?? [],
        notes: latest?.notes ?? [],
      };
    // console.log({ latest });
    return obj;
  }, [invoice]);

  const hasIssues = useMemo(() => {
    return (
      latestInvoiceMessages &&
      latestInvoiceMessages.issues &&
      latestInvoiceMessages.issues.length !== 0
    );
  }, [latestInvoiceMessages]);

  const issues = useMemo(() => {
    return (latestInvoiceMessages?.issues || []) as BusinessRuleItemType[];
  }, [latestInvoiceMessages]);

  const notes = useMemo(() => {
    return (latestInvoiceMessages?.notes || []) as BusinessRuleItemType[];
  }, [latestInvoiceMessages]);

  const submitDisabled = useMemo(() => {
    if (!hasIssues) return false;
    return !resolvedSomething || hasIssues || saved || isRestActive.submit;
  }, [hasIssues, saved, isRestActive, resolvedSomething]);

  const _save = async (e?: any, passedInvoice?: IntegrationInvoice) => {
    const title = `Save Resolved Issues`;
    if (e && e.preventDefault) e.preventDefault();
    if (e && e.stopPropagation) e.stopPropagation();
    setIsRestActive((p) => ({ ...p, saveResolved: true }));

    try {
      const results = await patientBillingService.setInvoiceStatus({
          clinicId,
          invoiceId: invoice.id,
          issues: {
            [invoice.id]:
              passedInvoice?.statusData?.items?.[0]?.issues ?? issues ?? [],
          },
        }),
        result =
          results && results?.invoices?.[0] ? results?.invoices?.[0] : null,
        saveIssues = results?.issues ?? [],
        saveNotes = results?.notes ?? [];

      if (result) {
        setSaved(false); // Okay, we SAVED it, but we still want the buttons to be available.
        setIsDirty(false);
        setResolvedSomething(false);
        if (saveIssues && saveIssues.length) {
          createToast({
            title,
            description: `${
              saveIssues?.length
                ? saveIssues.map((i: BusinessRuleItemType) => i.text).join(' ')
                : 'No issues.'
            }`,
            type: ToastTypes.Info,
            duration: 5000,
          });
        } else {
          setInvoiceData(result);
          updateInvoiceCallback({ invoice: result });
        }
        if (saveNotes && saveNotes.length) {
          createToast({
            title,
            description: `${
              saveNotes?.length
                ? saveNotes.map((i: BusinessRuleItemType) => i.text).join(' ')
                : 'No notes.'
            }`,
            type: ToastTypes.Info,
            duration: 5000,
          });
        }
      } else {
        createToast({
          title,
          description: `No result returned.`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }
    } catch (e: any) {
      createToast({
        title: `Save Resolved Issues`,
        description: `${e.message ?? e.response?.data?.message}`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
      console.error({ e });
    }
    setIsRestActive((p) => ({ ...p, saveResolved: false }));
  };

  const saveActiveIndicator = useMemo(() => {
    const saveResolvedEnabled =
      (resolvedSomething &&
        !saved &&
        !isRestActive.resolve &&
        !isRestActive.download &&
        !isRestActive.downloadComplete &&
        !isRestActive.downloadFields &&
        !isRestActive.test &&
        !isRestActive.submit) ||
      isDirty;
    if (!saveResolvedEnabled || readonly) return null;
    return (
      <div className="absolute top-1 right-4">
        {isRestActive.saveResolved ? (
          <Loading style={`tiny-inline`} color="text-tray-600 mt-1" />
        ) : null}
      </div>
    );
  }, [
    isDirty,
    isRestActive.download,
    isRestActive.downloadComplete,
    isRestActive.downloadFields,
    isRestActive.resolve,
    isRestActive.saveResolved,
    isRestActive.submit,
    isRestActive.test,
    readonly,
    resolvedSomething,
    saved,
  ]);

  const [autoSaveHandler, setAutoSaveHandler] = useState<NodeJS.Timeout | null>(
    null,
  );

  const resolveCallback = ({
    resolveId,
  }: {
    resolveId: number | null;
    location: any;
  }) => {
    if (typeof resolveId !== 'number') return;
    if (!issues) return;
    // console.log({ issues });
    if (!issues[resolveId]) return;
    // console.log({ resolveId });
    const clone = ChiroUpJSON.clone(invoice);
    clone.statusData.items[0].issues.splice(resolveId, 1);
    setInvoiceData(() => clone);
    setTimeout(() => {
      setSaved(false);
    }, 100);

    if (autoSaveHandler) {
      clearTimeout(autoSaveHandler);
    }
    setAutoSaveHandler(
      setTimeout(() => {
        _save(null, clone);
        setAutoSaveHandler(null);
      }, 1250), // debouce 1.25 seconds
    );

    setResolvedSomething(true);
    setIsDirty(true);
  };

  const age = useMemo(() => {
    if (!invoice || !invoice.transactionDate) return null;
    const now = new Date().getTime();
    const delta = (now - (invoice?.transactionDate ?? 0)) / 1000 / 60 / 60 / 24;
    return Math.floor(delta);
  }, [invoice]);

  const statusDataItem = useMemo(() => {
    if (invoice && invoice.id !== invoiceId) return null;
    return typeof invoice?.statusData === 'object'
      ? invoice?.statusData?.items?.[0]
      : null;
  }, [invoice, invoiceId]);

  const submitInvoice = useCallback(
    async (invoice: IntegrationInvoice, testOnly?: boolean) => {
      /**
       * Known state please...
       */
      testOnly = !!testOnly;
      const isNotTestOnly = !testOnly,
        statusWas = invoice.status;

      setSaved(false);
      let result = null;
      let res = {
        count: 0,
        issues: [] as BusinessRuleItemType[],
      };

      if (
        invoice.status === InvoiceStatusEnum.Submitted &&
        invoice.electronicBilling === false &&
        invoice.generation !== InvoiceGenerationEdiXref.Original
      ) {
        // console.log(invoice, metaContext);
      } else {
        res = businessRulesService.invoiceStatusTransition({
          invoice: { ...invoice, ...metaContext?.[invoice.id] },
          targetStatus: InvoiceStatusEnum.Submitted,
          // trace: true,
        });
      }

      if (res.count) {
        setSaved(true);
        const clone = ChiroUpJSON.clone(invoice);
        if (clone?.statusData?.items?.[0]?.issues) {
          clone.statusData.items[0].issues = res.issues;
        } else {
          clone.statusData = clone.statusData ?? {};
          clone.statusData.items = res.issues;
        }

        updateInvoiceCallback({
          invoice: clone,
          refetch: statusWas && clone.status && statusWas !== clone.status,
        });
        setInvoiceData(clone);
        return;
      }

      if (testOnly) {
        setIsRestActive((p) => ({ ...p, test: true }));
      } else {
        setIsRestActive((p) => ({ ...p, submit: true }));
      }

      try {
        result = (await patientBillingService.get837P({
          clinicId,
          payload: {
            context: {
              invoiceIds: [invoice.id],
              update: isNotTestOnly,
              targetStatus: InvoiceStatusEnum.Submitted,
              meta: { ...metaContext },
            },
          },
        })) as any;
        /**
         * Look for overall issues.
         */
        let allIssues = [];
        if (result?.meta?.issues?.length) {
          createToast({
            title: `Submit Invoice`,
            description: `${
              result?.meta?.issues?.length
                ? result?.meta?.issues
                    .map((i: BusinessRuleItemType) => i.text)
                    .join(' ')
                : 'No issues.'
            }`,
            type: ToastTypes.Info,
            duration: 5000,
          });
          allIssues = result?.meta?.issues ?? [];
        }

        /**
         * Invoice-specific issues.
         */
        if (result?.processed?.[invoice.id].issues?.length) {
          allIssues = allIssues.concat(result?.processed?.[invoice.id].issues);
        }

        if (allIssues.length === 0) {
          createToast({
            title: testOnly ? `Test` : `Submit`,
            description: `Invoice successfully processed.`,
            type: ToastTypes.Success,
            duration: 5000,
          });
          if (
            invoice.electronicBilling &&
            !testOnly &&
            !invoice?.integrationActive
          ) {
            trivialDownload({ content: result?.data ?? '' });
          }
        }

        setSaved(testOnly ? false : true);
      } catch (e: any) {
        console.error({ e });
        createToast({
          title: testOnly ? `Test` : `Submit`,
          description: `${e.message ?? e.response?.data?.me}`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }

      if (result?.invoice) {
        updateInvoiceCallback({
          invoice: result?.invoice,
          refetch: statusWas !== result.invoice.status,
        });
        setInvoiceData(result.invoice);
      }

      if (testOnly) {
        setIsRestActive((p) => ({ ...p, test: false }));
      } else {
        setIsRestActive((p) => ({ ...p, submit: false }));
      }
      setResolvedSomething(false);
      setIsDirty(false);
    },
    [
      metaContext,
      clinicId,
      updateInvoiceCallback,
      setInvoiceData,
      setSaved,
      createToast,
    ],
  );

  const downloadCMS1500 = useCallback(
    async (invoice: IntegrationInvoice, complete: boolean = true) => {
      if (isRestActive.download) return;
      setIsRestActive((p) => ({ ...p, download: true }));
      try {
        const res = (await patientBillingService.downloadCMS1500({
          clinicId,
          invoiceId: invoice.id,
          complete,
        })) as any;
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = (reader.result as string)
            ?.replace(/"/g, '')
            ?.replace(/^data:application\/pdf;base64,/, '');

          try {
            const binaryString = atob(base64String);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            trivialDownload({
              content: bytes,
              name: `CMS1500_${invoice.number}.pdf`,
              mimeType: 'application/pdf',
            });
          } catch (error) {
            console.error('Error decoding Base64:', error);
          }
        };
        // reader.readAsDataURL(res);
        reader[
          import.meta.env.VITE_ACTUAL_STAGE === 'local'
            ? 'readAsDataURL'
            : 'readAsText'
        ](res);
      } catch (e: any) {
        createToast({
          title: `Download`,
          description: `${e.message ?? e.response?.data?.message}`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
        console.error({ e });
      }
      setIsRestActive((p) => ({ ...p, download: false }));
      setResolvedSomething(false);
      setIsDirty(false);
    },
    [
      clinicId,
      createToast,
      isRestActive.download,
      setResolvedSomething,
      setIsDirty,
    ],
  );

  const resolve = useCallback(
    async (invoice: IntegrationInvoice) => {
      if (isRestActive.resolve || saved) return;
      const toastTitle = 'Resolve';
      setIsRestActive((p) => ({ ...p, resolve: true }));
      try {
        const result = await businessRulesService.resolveInvoiceIssues({
          clinicId,
          invoice, // The invoice should JUST have any unresolved issues or no issues.
          promote: true, // Move to unsubmitted if no errors.
          trace,
        });
        if (trace) clog({ resolveInvoiceIssues_result: { result } });

        /**
         * We get back and updated invoice with any potential issues that
         * were found.
         */
        const statusData = result.statusData as InvoiceStatusData,
          items = statusData?.items ?? [],
          latest = items[0] ?? null,
          issues = latest?.issues ?? [];

        if (issues.length) {
          createToast({
            title: toastTitle,
            description: `${
              issues?.length
                ? issues.map((i: BusinessRuleItemType) => i.text).join(' ')
                : 'No issues.'
            }`,
            type: ToastTypes.Info,
            duration: 5000,
          });
        } else {
          createToast({
            title: toastTitle,
            description: `Invoice successfully processed.`,
            type: ToastTypes.Success,
            duration: 5000,
          });
        }

        updateInvoiceCallback({ invoice: result });
        setInvoiceData(result);
        setSaved(false);
      } catch (e: any) {
        console.error({ e });
        createToast({
          title: `Save`,
          description: `${e.message ?? e.response?.data?.message}`.replace(
            /(^"|"$)/g,
            '',
          ),
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }
      setIsRestActive((p) => ({ ...p, resolve: false }));
      setIsDirty(true);
    },
    [
      clinicId,
      createToast,
      isRestActive.resolve,
      saved,
      setInvoiceData,
      trace,
      updateInvoiceCallback,
    ],
  );

  const test = useCallback(
    async ({
      invoice,
      targetStatus = InvoiceStatusEnum.Unsubmitted,
    }: {
      invoice: IntegrationInvoice;
      targetStatus?: InvoiceStatusEnum;
    }) => {
      if (isRestActive.test || saved) return;
      setIsRestActive((p) => ({ ...p, test: true }));
      let result: any | null = null;
      const statusWas = invoice.status;

      try {
        result = await patientBillingService.get837P({
          clinicId,
          payload: {
            context: {
              invoiceIds: [invoice.id],
              update: false,
              targetStatus,
              meta: { ...metaContext },
            },
          },
        });
        // result = await businessRulesService.apply({
        //   clinicId,
        //   body: {
        //     rule: 'invoice.0001',
        //     context: {
        //       invoice,
        //       targetStatus,
        //       meta: { ...metaContext },
        //     },
        //     update: invoice.status !== targetStatus,
        //   },
        // });

        /**
         * Look for overall issues.
         */
        // let allIssues = [];
        if (result?.meta?.issues?.length) {
          createToast({
            title: `Submit Invoice`,
            description: `${
              result?.meta?.issues?.length
                ? result?.meta?.issues
                    .map((i: BusinessRuleItemType) => i.text)
                    .join(' ')
                : 'No issues.'
            }`,
            type: ToastTypes.Info,
            duration: 5000,
          });
          // allIssues = result?.meta?.issues ?? [];
        }

        /**
         * Invoice-specific issues.
         */
        // if (result?.processed?.[invoice.id].issues?.length) {
        //   allIssues = allIssues.concat(result?.processed?.[invoice.id].issues);
        // }

        if (trace) clog({ resolveInvoiceIssues_result: { result } });
        if (result?.invoice) {
          updateInvoiceCallback({
            invoice: result?.invoice,
            refetch: statusWas !== result.invoice.status,
          });
          setInvoiceData(result.invoice);
        }
        setSaved(true);
      } catch (e: any) {
        createToast({
          title: `${invoice.status} to ${targetStatus}`,
          description: `${e.message ?? e.response?.data?.message}`.replace(
            /(^"|"$)/g,
            '',
          ),
          type: ToastTypes.Fail,
          duration: 5000,
        });
        console.error({ e });
      }
      setIsRestActive((p) => ({ ...p, test: false }));
      setResolvedSomething(false);
      setIsDirty(false);
    },
    [
      clinicId,
      createToast,
      isRestActive.test,
      metaContext,
      saved,
      setInvoiceData,
      trace,
      updateInvoiceCallback,
    ],
  );

  const buttonsByInvoiceStatus = useCallback(
    (invoice: IntegrationInvoice) => {
      if (readonly) return null;
      const status = invoice?.status,
        statusData =
          typeof invoice?.statusData === 'string'
            ? ChiroUpJSON.parse(invoice?.statusData)
            : invoice?.statusData,
        items = statusData?.items ?? [],
        latest = items[0] ?? null,
        issues = latest?.issues ?? [],
        noIssues = latest ? latest?.issues?.length === 0 : true, // Issues must be explicitly set.
        updateStatusButton =
          validTransitions.length > 1 ? ( // Its current status and one other.
            <Button
              className={classNames(shrinkButtons ? 'h-10' : '')}
              text={shrinkButtons ? '' : 'Status'}
              onClick={() => {
                setUpdatingStatus(true);
              }}
              icon={<CogIcon />}
              tooltipIconClassName="text-gray-600 w-3 h-3 ml-2"
              color={ButtonColors.plainWithBorder}
              loading={isRestActive.updateStatus}
              disabled={updatingStatus}
              tooltip="Update the invoice status."
            />
          ) : null;
      switch (status) {
        case InvoiceStatusEnum.Pending:
        case InvoiceStatusEnum.Rejected:
          if (noIssues) {
            return (
              <div className="flex flex-row justify-end space-x-4">
                {updateStatusButton}
                <Button
                  text="Test"
                  onClick={() => {
                    test({ invoice });
                  }}
                  loading={isRestActive.test}
                  disabled={isRestActive.test || saved || isRestActive.submit}
                  tooltip={[
                    'This will run the business rules',
                    'and move the invoice to unsubmitted if there are no issues.',
                  ].join(' ')}
                  color={ButtonColors.primary}
                />
                <Button
                  text="Submit"
                  onClick={() => {
                    submitInvoice(invoice);
                  }}
                  icon={<CloudArrowUpIcon className="h-6 w-6" />}
                  loading={isRestActive.submit}
                  disabled={isRestActive.submit || saved || isRestActive.save}
                  tooltip={[
                    'This will run the business rules and submit the invoice if there',
                    'are no issues.',
                  ].join(' ')}
                  color={ButtonColors.primary}
                />
              </div>
            );
          }
          return (
            <div className="flex flex-row justify-end space-x-4">
              {updateStatusButton}
              <Button
                text={!hasIssues ? 'Resolve' : 'Save'}
                onClick={() => {
                  resolve(invoice);
                }}
                loading={isRestActive.resolve}
                disabled={
                  !resolvedSomething ||
                  isRestActive.resolve ||
                  saved ||
                  isRestActive.submit
                }
                tooltip={[
                  'This will resolve the issues and try to move the invoice to unsubmitted.',
                ].join(' ')}
                color={ButtonColors.primary}
              />
              <Button
                text="Submit"
                onClick={() => {
                  submitInvoice(invoice);
                }}
                icon={<CloudArrowUpIcon className="h-6 w-6" />}
                loading={isRestActive.submit}
                disabled={
                  issues.length !== 0 ||
                  isRestActive.submit ||
                  saved ||
                  isRestActive.resolve ||
                  isRestActive.test
                }
                tooltip={[
                  'This will run the business rules and submit the invoice if there',
                  'are no issues.',
                ].join(' ')}
                color={ButtonColors.primary}
              />
            </div>
          );
        case InvoiceStatusEnum.Unsubmitted: {
          return (
            <div className="w-full flex flex-row justify-end space-x-4">
              {updateStatusButton}
              <Button
                text="Test"
                onClick={() => {
                  submitInvoice(invoice, true);
                }}
                disabled={submitDisabled}
                loading={isRestActive.test}
                color={ButtonColors.primary}
              />
              <Button
                text={invoice.electronicBilling ? 'Submit' : 'Generate'}
                icon={<CloudArrowUpIcon className="h-6 w-6" />}
                onClick={() => {
                  submitInvoice(invoice);
                }}
                disabled={submitDisabled}
                loading={isRestActive.submit}
                color={ButtonColors.primary}
              />
            </div>
          );
        }
        case InvoiceStatusEnum.Received:
        case InvoiceStatusEnum.Reprocessed:
        case InvoiceStatusEnum.Partial: {
          return (
            <div className="flex flex-row justify-end space-x-4">
              {updateStatusButton}
              <Button
                text="ERA"
                tooltip="Manual ERA data entry."
                icon={<PencilIcon />}
                onClick={(e) => {
                  e.currentTarget.blur();
                  navigate(`/billing/invoices/${invoice.id}`);
                }}
              />
            </div>
          );
        }
        case InvoiceStatusEnum.Submitted: {
          return (
            <>
              {updateStatusButton}
              <Button
                text="ERA"
                tooltip="Manual ERA data entry."
                icon={<PencilIcon />}
                onClick={(e) => {
                  e.currentTarget.blur();
                  navigate(`/billing/invoices/${invoice.id}`);
                }}
              />
              {invoice?.generation !== InvoiceGenerationEdiXref.Original &&
              invoice?.generation !== InvoiceGenerationEdiXref.Replacement ? (
                <Button
                  text="Submit"
                  icon={<CloudArrowUpIcon className="h-6 w-6" />}
                  onClick={() => {
                    submitInvoice(invoice);
                  }}
                  disabled={submitDisabled}
                  loading={isRestActive.submit}
                  color={ButtonColors.primary}
                />
              ) : invoice?.electronicBilling !== false ? (
                <Button
                  text="Download"
                  icon={<CloudArrowDownIcon className="h-6 w-6" />}
                  onClick={async () => {
                    if (!invoice) return;
                    if (!invoice.exchangeId) {
                      createToast({
                        title: `Download`,
                        description: `No exchangeId found.`,
                        type: ToastTypes.Fail,
                        duration: 5000,
                      });
                      setSaved(true);
                      return;
                    }
                    setIsRestActive((p) => ({ ...p, download: true }));
                    try {
                      const res = (await integrationService.download({
                        clinicId: invoice.clinicId,
                        exchangeId: invoice.exchangeId,
                      })) as any;
                      if (res && res.body && res.body.length > 0) {
                        trivialDownload({ content: res.body });
                      }
                    } catch (e: any) {
                      setSaved(true); // Yes, I know. We're not saving...so sue me.
                      createToast({
                        title: `Download`,
                        description: `${
                          e.message ?? e.response?.data?.message
                        }`,
                        type: ToastTypes.Fail,
                        duration: 5000,
                      });
                      console.error({ hereIsTheE: e });
                    }
                    setIsRestActive((p) => ({ ...p, download: false }));
                    // setIsDirty(true);
                  }}
                  disabled={isRestActive.download}
                  loading={isRestActive.download}
                  color={ButtonColors.primary}
                />
              ) : (
                <div
                  className={classNames(
                    shrinkButtons ? 'space-x-2' : 'space-x-3',
                  )}
                >
                  <Button
                    text="Complete"
                    icon={<CloudArrowDownIcon className="h-6 w-6" />}
                    tooltip="Download 1500 form with fields filled in."
                    onClick={async () => {
                      if (!invoice) return;
                      if (!invoice.exchangeId) {
                        createToast({
                          title: `Download`,
                          description: `No exchangeId found.`,
                          type: ToastTypes.Fail,
                          duration: 5000,
                        });
                        setSaved(true);
                        return;
                      }
                      setIsRestActive((p) => ({
                        ...p,
                        downloadComplete: true,
                      }));
                      try {
                        await downloadCMS1500(invoice);
                        // trivialDownload({ content: res });
                      } catch (e: any) {
                        setSaved(true); // Yes, I know. We're not saving...so sue me.
                        createToast({
                          title: `Download`,
                          description: `${
                            e.message ?? e.response?.data?.message
                          }`,
                          type: ToastTypes.Fail,
                          duration: 5000,
                        });
                        console.error({ hereIsTheE: e });
                      }
                      setIsRestActive((p) => ({
                        ...p,
                        downloadComplete: false,
                      }));
                      // setIsDirty(true);
                    }}
                    disabled={
                      isRestActive.downloadComplete ||
                      isRestActive.download ||
                      isRestActive.downloadFields
                    }
                    loading={isRestActive.downloadComplete}
                    color={ButtonColors.primary}
                  />
                  <Button
                    text="Fields"
                    icon={<CloudArrowDownIcon className="h-6 w-6" />}
                    tooltip="Download a blank page with the fields in the correct positions."
                    onClick={async () => {
                      if (!invoice) return;
                      if (!invoice.exchangeId) {
                        createToast({
                          title: `Download`,
                          description: `No exchangeId found.`,
                          type: ToastTypes.Fail,
                          duration: 5000,
                        });
                        setSaved(true);
                        return;
                      }
                      setIsRestActive((p) => ({ ...p, downloadFields: true }));
                      try {
                        await downloadCMS1500(invoice, false);
                        // trivialDownload({ content: res });
                      } catch (e: any) {
                        setSaved(true); // Yes, I know. We're not saving...so sue me.
                        createToast({
                          title: `Download`,
                          description: `${
                            e.message ?? e.response?.data?.message
                          }`,
                          type: ToastTypes.Fail,
                          duration: 5000,
                        });
                        console.error({ hereIsTheE: e });
                      }
                      setIsRestActive((p) => ({ ...p, downloadFields: false }));
                      // setIsDirty(true);
                    }}
                    disabled={
                      isRestActive.downloadComplete ||
                      isRestActive.download ||
                      isRestActive.downloadFields
                    }
                    loading={isRestActive.downloadFields}
                    color={ButtonColors.primary}
                  />
                </div>
              )}
            </>
          );
        }
        case InvoiceStatusEnum.Waiting:
          /**
           * An invoice without a status or a priority has not been looked
           * at by the business rules. Probably an initial invoice. This
           * should only happen when priority is not zero. The user has no
           * operations to perform if it is not the primary.
           */
          if (noStatus || invoice.priority === 0) {
            return (
              <>
                {updateStatusButton}
                <Button
                  text="Test"
                  onClick={() => {
                    test({
                      invoice,
                      targetStatus: InvoiceStatusEnum.Unsubmitted,
                    });
                  }}
                  loading={isRestActive.test}
                  icon={<CloudArrowUpIcon className="h-6 w-6" />}
                  disabled={isRestActive.test || saved || isRestActive.submit}
                  color={ButtonColors.primary}
                  tooltip="This will run the business rules and move the invoice to unsubmitted if there are no issues."
                />
              </>
            );
          }
          return null;
        default:
          if (trace) clog(`No button for status=${status}.`);
          return updateStatusButton;
      }
    },
    [
      hasIssues,
      isRestActive.resolve,
      isRestActive.submit,
      isRestActive.test,
      noStatus,
      readonly,
      submitDisabled,
      validTransitions,
      createToast,
      downloadCMS1500,
      isRestActive.download,
      isRestActive.downloadComplete,
      isRestActive.downloadFields,
      isRestActive.save,
      isRestActive.updateStatus,
      navigate,
      resolve,
      resolvedSomething,
      saved,
      shrinkButtons,
      submitInvoice,
      test,
      trace,
      updatingStatus,
    ],
  );

  const adjustClaim = useCallback(
    async (e: any) => {
      if (isRestActive.any) return;
      if (adjustBilledServices === false) {
        setIsRestActive((p) => ({ ...p, any: true }));
        try {
          const res = await integrationService.invoiceById({
            clinicId,
            id: invoiceId,
            use: 'adjust',
          });
          setAdjustPayload(res);
          setAdjustBilledServices(!adjustBilledServices);
        } catch (e: any) {
          console.error({ e });
        } finally {
          setIsRestActive((p) => ({ ...p, any: false }));
        }
      } else {
        setAdjustBilledServices(!adjustBilledServices);
        setAdjustmentActions([]);
      }
    },
    [adjustBilledServices, clinicId, invoiceId, isRestActive.any],
  );

  const submitAdjustments = useCallback(async () => {
    const res = await adjust({
      clinicId,
      id: invoiceId,
      actions: adjustmentActions,
    });
    // console.log({ res });
    if (res && !res?.issues?.length) {
      setAdjustmentActions([]);
      setAdjustBilledServices(false);
      updateInvoiceCallback?.({ invoice: res?.invoice });
    }
  }, [adjust, adjustmentActions, clinicId, invoiceId, updateInvoiceCallback]);

  const actionCallback = useCallback(
    (args: AdjustmentActionType) => {
      const { action, type, row, pk } = args,
        key = [action, type, pk].join('.'),
        newActions: AdjustmentActionType[] = ChiroUpJSON.clone(
          adjustmentActions,
        ).filter(
          (o: AdjustmentActionType) =>
            !(o.action === 'rollback' && o.type === 'initial'),
        );
      let currentClickActions = [args];

      if (action === 'trash') {
        const oldobj = newActions.splice(Number(pk), 1),
          oldpk = [oldobj[0].action, oldobj[0].type, oldobj[0].pk].join('.');
        // console.log({ oldobj });
        currentClickActions = [];

        const rollbackItems = newActions.filter(
            (a: any) => a.action === 'rollback' && a.type === 'payment',
          ),
          rollbackPaymensById = rollbackItems.reduce((acc: any, a: any) => {
            const id = a?.row?.patientTransactionId;
            if (!acc[id]) {
              acc[id] = [];
            }
            acc[id].push(a);
            return acc;
          }, {});

        for (const [id, itemArray] of Object.entries(rollbackPaymensById)) {
          const items = itemArray as unknown as PatientTransactionItemType[],
            sum = items
              .reduce((acc: any, a: any) => {
                const amount = createDecimal(a?.row?.amount ?? 0);
                return acc.plus(amount);
              }, createDecimal(0))
              .toNumber(),
            newkey = ['rollback', 'initial', id].join('.');
          if (newkey === oldpk) continue;
          if (sum > 0) {
            currentClickActions.push({
              action: 'rollback',
              type: 'initial',
              row: {
                items,
                total: sum,
              },
              pk: String(id),
            });
          }
        }
        setAdjustmentActions(() => {
          return [...newActions, ...currentClickActions];
        });
        return;
      }
      if (actionsByKey[key]) {
        // console.log({ alreadyDefined: { action, type, row } });

        return;
      }
      if (action === 'remove-item' && type === 'transaction') {
        // TODO: May want a treatment rule.
        const amount = Number(
          (createDecimal(row?.amount) ?? 0)
            .times(row?.units ?? 0)
            .toNumber()
            .toFixed(2),
        );
        const key = ['user', 'remove-item', 'transaction', 'item-gt-zero'].join(
          '.',
        );
        if (!actionsByKey[key] && amount > 0) {
          currentClickActions.push({
            action: 'user',
            type: 'remove-item',
            row: { ...row, total: amount },
            pk: [row?.id, 'item-gt-zero'].join('.'),
          });
        }
      } else if (action === 'era-to-cre') {
        const likeMeKey = [
            args?.row?.adjudicatedServiceCode,
            args?.row?.eraId,
            args?.row?.claimResponseId,
          ].join('.'),
          actionsLikeMe = newActions.filter((a) => {
            const key = [
              a?.row?.adjudicatedServiceCode,
              a?.row?.eraId,
              a?.row?.claimResponseId,
            ].join('.');
            return key === likeMeKey;
          });
        if (actionsLikeMe.length > 1) {
          const userKey = ['user', 'era-to-cre', likeMeKey].join('.');
          if (!actionsByKey[userKey]) {
            currentClickActions.push({
              action: 'user',
              type: 'era-to-cre',
              row,
              pk: [args?.row?.eraId, args?.row?.claimResponseId].join('.'),
            });
          }
        }
      } else if (action === 'unpost') {
        const remitAmount = createDecimal(row.remitAmount),
          payment = adjustPayments.find(
            (p: any) => String(p.referenceNumber) === String(row.eraId),
          ),
          paymentLine = payment?.items?.find(
            (pti: PatientTransactionItemType) => {
              const amount = createDecimal(pti.amount ?? 0);
              return remitAmount.equals(amount) && pti?.type === 'Debit';
            },
          );
        if (paymentLine) {
          const key = [
            'rollback',
            'payment',
            payment.referenceNumber,
            paymentLine.id,
          ].join('.');
          if (!actionsByKey[key] && remitAmount.greaterThan(0)) {
            currentClickActions.push({
              action: 'rollback',
              type: 'payment',
              row: { ...paymentLine, eraId: payment.referenceNumber },
              pk: [payment.referenceNumber, paymentLine.id].join('.'),
            });
          }
        }
        //const hasPost = actionContext?.postByCode[row?.adjudicatedServiceCode];
        // console.log({ hasPost });
      } else if (action === 'post') {
        // When we post, the pk has the eraId AND the item's ordinal position.
        const [findKey] = (args?.pk ?? '').split('.'),
          remitAmount = createDecimal(row.remitAmount);
        const payment = adjustPayments.find(
            (p: PatientTransactionRowType) => findKey === p?.referenceNumber,
          ),
          paymentLine = payment?.items?.find(
            (pti: PatientTransactionItemType) => {
              const amount = createDecimal(pti.amount ?? 0);
              return remitAmount.equals(amount) && pti?.type === 'Debit';
            },
          );
        if (paymentLine) {
          const key = [
            'user',
            'payment',
            payment.referenceNumber,
            paymentLine.id,
          ].join('.');
          if (!actionsByKey[key] && remitAmount.greaterThan(0)) {
            currentClickActions.push({
              action: 'user',
              type: 'payment',
              row: { ...paymentLine, eraId: payment.referenceNumber },
              pk: [payment.referenceNumber, paymentLine.id].join('.'),
            });
          }
        }
        const codesInServices = row?.serviceItems?.reduce(
            (acc: any, itm: any) => {
              acc[itm.adjudicatedServiceCode] = true;
              return acc;
            },
            {},
          ),
          codesInServicesLength = Object.keys(codesInServices ?? {}).length,
          unpostItems =
            actionContext?.unpostByCode[row?.adjudicatedServiceCode];
        if (!unpostItems?.length) {
          // Find the PLI that has the same service

          if (codesInServicesLength) {
            const found: STRING_BOOLEAN_HASH = {};
            Object.keys(actionContext?.unpostByCode ?? {}).forEach((code) => {
              if (codesInServices[code]) {
                found[code] = true;
              }
            });
            Object.keys(found).forEach((code) => {
              currentClickActions.push({
                action: 'user',
                type: 'reconsider-reverse',
                row: { ...row, adjudicatedServiceCode: code },
                pk: [row.eraId, row.id].join('.'),
              });
            });
            // console.log({ ThisIsRealBad: { unpostItems } });
          }
        } else if (codesInServicesLength) {
          const found: STRING_BOOLEAN_HASH = {};
          Object.keys(actionContext?.unpostByCode ?? {}).forEach((code) => {
            if (codesInServices[code]) {
              found[code] = true;
            }
          });
          Object.keys(found).forEach((code) => {
            currentClickActions.push({
              action: 'user',
              type: 'reconsider-reverse',
              row: { ...row, adjudicatedServiceCode: code },
              pk: [row.eraId, row.id].join('.'),
            });
          });
        }

        currentClickActions.unshift({
          ...args,
          action: 'apply',
          type: 'pli',
        });
        currentClickActions.unshift({
          ...args,
          action: 'user',
          type: 'apply-era-to-pli',
        });
        const servicePosts =
          actionContext?.postByCode?.[row?.adjudicatedServiceCode];
        if (servicePosts?.length) {
          const key = [
            'user',
            'multi-post',
            row?.adjudicatedServiceCode ?? row?.submittedProcedureCode,
          ].join('.');
          const uniqueEraIds = new Set(
            servicePosts.map((p: any) => p?.row?.eraId),
          );
          const message = [
            'Please verify this is correct:',
            `There are multiple postings for service ${row?.adjudicatedServiceCode}`,
            `on ERA${uniqueEraIds.size === 1 ? '' : 's'} ${Array.from(
              uniqueEraIds,
            ).join(', ')}`,
          ].join(' ');

          if (!actionsByKey[key]) {
            currentClickActions.push({
              action: 'user',
              type: 'multi-post',
              row: { ...row, message },
              pk: row?.adjudicatedServiceCode,
            });
          } else {
            const myService = newActions.find(
              (a) => a.pk === row?.adjudicatedServiceCode,
            );
            if (myService) {
              myService.row.message = message;
            }
          }
        }
      }
      // Put in an action for rolling back the initial payment
      // for the amount of any items.
      const rollbackItems = newActions.filter(
          (a) => a.action === 'rollback' && a.type === 'payment',
        ),
        rollbackPaymensById = rollbackItems.reduce((acc: any, a: any) => {
          const id = a?.row?.patientTransactionId;
          if (!acc[id]) {
            acc[id] = [];
          }
          acc[id].push(a);
          return acc;
        }, {});

      for (const [id, itemArray] of Object.entries(rollbackPaymensById)) {
        const items = itemArray as unknown as PatientTransactionItemType[],
          sum = items
            .reduce((acc: any, a: any) => {
              const amount = createDecimal(a?.row?.amount ?? 0);
              return acc.plus(amount);
            }, createDecimal(0))
            .toNumber();
        if (sum > 0) {
          currentClickActions.push({
            action: 'rollback',
            type: 'initial',
            row: {
              items,
              total: sum,
            },
            pk: String(id),
          });
        }
      }

      // console.log({ rollbackItems });

      setAdjustmentActions(() => [...newActions, ...currentClickActions]);

      // console.log({ actionCallback: args });
    },
    [
      actionContext?.postByCode,
      actionContext?.unpostByCode,
      actionsByKey,
      adjustPayments,
      adjustmentActions,
    ],
  );

  const showStaticBilledServices = useMemo(() => {
    return (
      invoice?.payorLineItems &&
      invoice?.payorLineItems?.length !== 0 &&
      invoice.id === invoiceId
    );
  }, [invoice.id, invoice?.payorLineItems, invoiceId]);

  if (state === OpenClosedStates.Closed) return null;
  if (!invoice) {
    if (trace) clog('The invoice is empty.');
    // return null;
  }

  const moreDetailClassName = 'text-xs text-gray-600';

  return (
    <Modal
      isOpen={state === OpenClosedStates.Open ? true : false}
      omitClasses="sm:max-w-lg"
      addClasses="sm:max-w-xl"
      className="inline-block align-bottom bg-white dark:bg-darkGray-900 rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full print:hidden sm:max-w-lg"
    >
      {/* <pre>{ChiroUpJSON.pretty(validTransitions)}</pre> */}
      <div className="flex flex-row w-full mb-4 rounded-lg overflow-hidden rounded-b-none">
        <div className="grow bg-primary-500 text-white font-bold flex flex-row space-x-4 justify-center text-lg pt-6 p-4">
          {invoice.id === invoiceId ? (
            <>
              <div>
                {invoice.uiLegalName ?? <cite>- Payor not Available -</cite>}
              </div>
              <Loading
                flag={toggleRestActive || (isFetching && !restFailed)}
                style={`tiny-inline-white`}
              />
            </>
          ) : (
            <div className="italic text-sm">
              {restFailed ? 'Error' : 'Loading...'}
            </div>
          )}
        </div>

        <div className="bg-primary-500 text-white p-4">
          <button
            onClick={() => {
              componentClose(isDirty);
              if (isDirty) {
                updateInvoiceCallback({ invoice: null, refetch: isDirty });
              }
              setTimeout(() => {
                setSaved(false);
                setResolvedSomething(false);
                setRestFailed(false);
              }, 750);
            }}
          >
            <XCircleIcon className={ClassNames.td.xySize} />
          </button>
        </div>
      </div>
      <div className="px-6">
        {invoice.id === invoiceId ? (
          <HashCardDisplay
            header={<div>Invoice Detail</div>}
            headerComponentRight={
              <div className="flex flex-row space-x-4">
                <div className="text-center w-2/3 py-1 px-3 text-xs font-medium rounded-full bg-primary-50 text-primary-700 ring-1 ring-inset ring-primary-600/50">
                  {PayorPriorityDisplay[invoice.priority ?? 0]}
                </div>
                <div className="flex flex-row">
                  <div className="whitespace-nowrap text-center py-1 px-4 text-xs font-medium rounded-full bg-primary-50 text-primary-700 ring-1 ring-inset ring-primary-600/50">
                    {invoice.status}
                  </div>
                  <TrivialTooltip text={InvoiceStatusTips[invoice.status]} />
                </div>
              </div>
            }
            containerClassName="w-full mt-8"
            noHover={true}
            skipNullValues={true}
            grid={{ cols: 3, labelSpan: 1, valueSpan: 2 }}
            addClassName={'shadow-lg'}
            hash={{
              Patient: (
                <div className="flex flex-col w-full">
                  <div className="flex">
                    {invoice?.patient?.displayName ?? <cite>-na-</cite>}
                  </div>
                  <div className={classNames('flex', moreDetailClassName)}>
                    {invoice?.patient?.dateOfBirth
                      ? '(' +
                        ChiroUpDayJsCommon.display.date(
                          invoice.patient.dateOfBirth,
                        ) +
                        ' age ' +
                        calculatePatientAge({
                          dob: invoice.patient.dateOfBirth,
                        }) +
                        ')'
                      : null}
                  </div>
                </div>
              ),
              'Primary Insured': (
                <div className="flex flex-col">
                  <div>
                    {primaryInsuredTypeDisplay(invoice?.primaryInsured?.type)}
                  </div>
                  {invoice?.primaryInsured?.type ===
                  PrimaryInsuredTypes.Patient ? null : (
                    <div className={classNames('flex', moreDetailClassName)}>
                      {primaryInsuredNameDisplay(invoice?.primaryInsured)}
                    </div>
                  )}
                  <div className={classNames('flex', moreDetailClassName)}>
                    Member Id:{' '}
                    {invoice?.primaryInsured?.memberId ?? <cite>-na-</cite>}
                  </div>
                </div>
              ),
              'Transaction Date':
                rawInvoice?.billingKey && rawInvoice?.patientId ? (
                  <Link
                    to={`/patients/${rawInvoice?.patientId}/billing/${rawInvoice?.billingKey}`}
                    className="text-primary-600 hover:text-primary-700"
                  >
                    <div className="flex flex-row space-x-2">
                      <div>
                        <TimestampDisplay
                          ts={invoice.transactionDate}
                          itemTz={invoice.tz}
                          userTz={userTz}
                          asDateOnly={true}
                        />
                      </div>
                      {age ? (
                        <div className="flex flex-row">
                          ({age})
                          <TrivialTooltip text="(nnn) - The number of days since the transaction date." />
                        </div>
                      ) : null}
                    </div>
                  </Link>
                ) : (
                  <div className="flex flex-row space-x-2">
                    <div>
                      <TimestampDisplay
                        ts={invoice.transactionDate}
                        itemTz={invoice.tz}
                        userTz={userTz}
                      />
                    </div>
                    {age ? (
                      <div className="flex flex-row">
                        ({age})
                        <TrivialTooltip text="(nnn) - The number of days since the transaction date." />
                      </div>
                    ) : null}
                  </div>
                ),
              Number: (
                <div>
                  #{invoice.number}
                  <TrivialTooltip
                    text={`Control #${invoice.controlNumber}`}
                    type="info"
                  />
                </div>
              ),
              'EDI Batch': invoice.interchangeControlNumber ? (
                <div
                  className="flex flex-row hover:text-primary-500"
                  title="Opens the exchange where this claim was submitted in a new tab."
                >
                  <Link
                    to={`/billing/exchanges?id=${invoice.exchangeId}`}
                    target="_blank"
                    className="flex flex-row space-x-0.5"
                  >
                    <div>{invoice.interchangeControlNumber}</div>
                    <div className="cursor-pointer pt-0.5">
                      <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                    </div>
                  </Link>
                </div>
              ) : null,
              [`ERA${(invoice?.eraIds ?? []).length > 1 ? 's' : ''}`]: (
                invoice?.eraIds ?? []
              ).length ? (
                <div className="flex flex-row space-x-3">
                  {invoice?.eraIds?.map((eraId, i) => (
                    <Link
                      key={`era-${i}`}
                      to={`/billing/eras/${eraId}`}
                      target="_blank"
                      className="flex flex-row space-x-0.5 hover:text-primary-500 "
                      title={`Opens ERA ${eraId} in a new tab.`}
                    >
                      <div>{eraId}</div>
                      <div className="cursor-pointer pt-0.5">
                        <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                      </div>
                    </Link>
                  ))}
                </div>
              ) : null,
              Created: (
                <TimestampDisplay
                  ts={invoice.createdAt}
                  itemTz={invoice?.tz}
                  userTz={userTz}
                />
              ),
              Updated: invoice?.statusAt ? (
                <div>
                  {/* <pre>
                    {invoice?.statusAt} <br />
                    {new Date(invoice?.statusAt).toISOString()}
                    <br />
                    {new Date(invoice?.statusAt).toString()}
                  </pre> */}
                  <TimestampDisplay
                    ts={invoice.statusAt}
                    itemTz={invoice?.tz}
                    userTz={userTz}
                    errorValue={null}
                  />
                </div>
              ) : null,
              'Billed Amount':
                invoice?.payorLineItems && invoice.id === invoiceId
                  ? null
                  : invoice.billedAmount,
              Balance:
                invoice?.payorLineItems && invoice.id === invoiceId
                  ? null
                  : invoice.balance,
              // This looks screwy, but it puts in things that must be filled in
              // depending on the invoice state and the age of the transaction.
              ...metaByInvoiceStatus({
                invoice,
                readonly,
                callback: (args: any) => {
                  updateInvoiceCallback(args);
                  const invoice = args?.invoice || null;
                  if (invoice) setInvoiceData(invoice);
                },
              }),
              'Electronic Billing': (
                <div
                  className="flex flex-row space-x-4"
                  title={`${
                    invoice.electronicBilling
                      ? 'Electronic-billing automation.'
                      : 'Paper-billing automation.'
                  }`}
                >
                  <Toggle
                    value={!!invoice.electronicBilling}
                    disabled={disableToggle || anyRestActive}
                    onChange={async (_: any) => {
                      await toggleElectronicBilling();
                      setIsDirty(true);
                    }}
                  />
                  {invoice.electronicBilling ? null : (
                    <div className="w-full flex justify-end">
                      <span className="rounded-lg bg-yellow-300 pt-1 px-2 mt-1 text-[10px] leading-[10px] font-light text-gray-600 ml-2 whitespace-nowrap h-5">
                        Paper Billing
                      </span>
                    </div>
                  )}
                </div>
              ),
              'Billing Profile': (
                <div className="flex flex-col w-full">
                  <div className="">
                    {invoice?.billingProfile?.name ?? <cite>-na-</cite>}
                  </div>
                  <div className={classNames(moreDetailClassName)}>
                    {invoice?.billingProfile?.npi
                      ? `(NPI #: ${invoice.billingProfile.npi})`
                      : null}
                  </div>
                </div>
              ),
              Provider: (
                <div className="flex flex-col w-full">
                  <div className="">
                    {invoice?.provider?.name ?? <cite>-na-</cite>}
                  </div>
                  <div className={classNames(moreDetailClassName)}>
                    {invoice?.provider?.npi
                      ? `(NPI #: ${invoice.provider.npi})`
                      : null}
                  </div>
                </div>
              ),
              'Place of Service': invoice?.pos ? invoice.pos : null,
            }}
            tips={{
              Generation: [
                'Replacement: Required when the payor paid',
                'incorrectly or an element of data on the claim was either not previously',
                'sent or needs to be corrected, for example rendering provider, units,',
                'amounts, or billing codes.',
                'Void: Required to eliminate the previously submitted claim when a claim',
                'is submitted in error or should not have been submitted. The entire claim',
                'must match the original with the exception of the claim frequency code,',
                'condition code, payer assigned claim number, and the patient control number.',
                'Voiding claims result in the recoupment of any payments made by the Payor',
                'and should only be used when all other measures have been taken.',
              ].join(' '),
            }}
          />
        ) : (
          <>
            <Loading style={`standard-gray`} flag={isFetching && !restFailed} />
            {restFailed ? (
              <div className="pb-4 italic">The invoice failed to load.</div>
            ) : null}
          </>
        )}
        {statusDataItem ? (
          <div className="relative">
            <HashCardDisplay
              containerClassName="w-full mt-8"
              header={`Last Status Update`}
              noHover={true}
              addClassName={'shadow-lg'}
              headerComponentRight={saveActiveIndicator}
              grid={{ cols: 3, labelSpan: 1, valueSpan: 2 }}
              hash={{
                Timestamp: (
                  <TimestampDisplay
                    ts={statusDataItem.ts}
                    itemTz={invoice?.tz}
                    userTz={userTz}
                  />
                ),
                Previous: statusDataItem.previous ?? <cite>-na-</cite>,
                Final: statusDataItem.final ?? <cite>-na-</cite>,
                Issues: issues.length ? issues : null, // Null omits these.
                Notes: notes.length ? notes : null, // Null omits the line.
              }}
              components={{
                Issues: (
                  <div>
                    {issues.length
                      ? issues.map((item, i) => {
                          return (
                            <div
                              key={`brs-${i}`}
                              className={classNames(
                                'px-4 pb-1',
                                HashCardDisplayColors[i % 2],
                                i === 0 ? 'pt-1' : '',
                              )}
                            >
                              <BusinessRuleSolution
                                result={item}
                                workTextErrors={true}
                                resolveId={i}
                                resolveCallback={resolveCallback}
                                trace={trace}
                                isRestActive={anyRestActive}
                                data={{
                                  billingKey: invoice.billingKey,
                                  clinicId,
                                }}
                              />
                            </div>
                          );
                        })
                      : null}
                  </div>
                ),
                Notes: (
                  <div>
                    {notes.length
                      ? notes.map((item, i) => {
                          return (
                            <div
                              key={`brs-${i}`}
                              className={classNames(
                                'px-4 pb-1',
                                HashCardDisplayColors[i % 2],
                                i === 0 ? 'pt-1' : '',
                              )}
                            >
                              <BusinessRuleSolution
                                result={item}
                                workTextErrors={true}
                                resolveId={i}
                                trace={trace}
                                isaNote={true}
                              />
                            </div>
                          );
                        })
                      : null}
                  </div>
                ),
              }}
            />
          </div>
        ) : null}
        {showStaticBilledServices ? (
          <div className="mt-8">
            <HashCardDisplay
              header={<div>Billed Services</div>}
              headerComponentRight={
                adjustBilledServices ? null : isaInvoiceAdjustableStatus(
                    invoice,
                  ) ? (
                  <div
                    className="text-primary-500 hover:text-primary-600 cursor-pointer"
                    onClick={(e: any) => {
                      e?.stopPropagation();
                      e?.preventDefault();
                      adjustClaim(e);
                    }}
                  >
                    <CogIcon className="w-5 h-5" />
                  </div>
                ) : null
              }
              components={{
                ...invoice?.payorLineItems?.reduce((acc, pli, idx) => {
                  acc[`${pli.payorId}.${pli.controlNumber}`] = (
                    <div
                      className={classNames(
                        HashCardDisplayColors[idx % 2],
                        'grid grid-cols-4',
                      )}
                    >
                      <div className="px-4 pt-2">
                        <span className=" text-gray-400">Service</span>
                        <TrivialTooltip
                          type="info"
                          text={`(${pli.status})${
                            pli.description ? ' - ' + pli.description : ''
                          }`}
                        />
                      </div>
                      <div className="px-4 pt-2 opacity-50 flex justify-end">
                        Units
                      </div>
                      <div className="px-4 pt-2 opacity-50 flex justify-end">
                        Billed
                      </div>
                      <div className="px-4 pt-2 opacity-50 flex justify-end">
                        Balance
                      </div>
                      <div className="px-4 pb-2">
                        {pli.adjudicatedProcedureCode ||
                          pli.submittedProcedureCode}
                        <TrivialTooltip
                          type="info"
                          text={pli.controlNumber}
                          tipClassName="w-64"
                        />
                      </div>
                      <div className="px-4 pb-2 flex justify-end">
                        {pli.submittedUnits}
                      </div>
                      <div className="px-4 pb-2 flex justify-end">
                        <Currency value={pli.billedAmount} />
                      </div>
                      <div className="px-4 pb-2 flex justify-end">
                        <Currency
                          value={
                            (pli.billedAmount ?? 0) -
                            (pli.remitAmount ?? 0) -
                            (pli.adjustmentTotal ?? 0)
                          }
                        />
                      </div>
                      {pli.remitDate && (
                        <div className="border-t border-gray-900/5 px-6 py-6 col-span-4">
                          <div className="underline">Payment</div>

                          <div className="flex w-full mb-5">
                            <div className="flex justify-between text-gray-500 text-sm w-full">
                              <div className="flex flex-row space-x-2">
                                <TimestampDisplay
                                  ts={pli.remitDate}
                                  itemTz={invoice?.tz}
                                  format={ChiroUpDayJsCommon.format.date}
                                  userTz={userTz}
                                />{' '}
                                {pli.postedDate ? (
                                  <>
                                    <span>posted</span>
                                    <TimestampDisplay
                                      ts={pli.postedDate}
                                      itemTz={invoice?.tz}
                                      userTz={userTz}
                                      format={ChiroUpDayJsCommon.format.date}
                                    />
                                  </>
                                ) : null}
                              </div>
                              <div>{formatCurrency(pli.remitAmount)}</div>
                            </div>
                          </div>

                          <div className="underline">Adjustments</div>
                          {pli.adjustments?.map((adj: any, index: number) => (
                            <div
                              key={`pli-${pli.id}-adj-${adj.groupCode}-${index}`}
                            >
                              <div
                                key={adj.groupCode}
                                className="flex flex-row text-sm"
                              >
                                <div className="flex flex-col">
                                  <div>{adj.groupDescription}</div>
                                </div>
                              </div>

                              {adj.items.map((item: any, itemIndex: number) => (
                                <div key={`adj-${itemIndex}`}>
                                  <div className="flex w-full"></div>
                                  <div
                                    key={`pli-${pli.id}-adj-${adj.groupCode}-item-${itemIndex}`}
                                    className="flex justify-between text-gray-500 text-sm w-full mb-2"
                                  >
                                    <div>{item.description}</div>
                                    <div className="self-end">
                                      {formatCurrency(item.amount)}
                                    </div>
                                  </div>

                                  <div className="w-full text-xs">
                                    {item.remarks &&
                                      item.remarks.length > 0 && (
                                        <div className="flex flex-row">
                                          <div className="flex flex-col">
                                            <div className="text-gray-500">
                                              {item.remarks.map(
                                                (
                                                  remark: any,
                                                  remarkIndex: number,
                                                ) => (
                                                  <div
                                                    key={`pli-${pli.id}-adj-${adj.groupCode}-item-${itemIndex}-remark-${remark.code}-${remarkIndex}`}
                                                    className="flex flex-row mt-2 text-sm"
                                                  >
                                                    <div className="flex flex-col">
                                                      <div className="text-gray-500">
                                                        {remark.description}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ),
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                          {!pli.adjustments ||
                            (pli.adjustments?.length === 0 && (
                              <div className="flex w-full">
                                <div className="flex justify-between text-gray-500 text-sm w-full">
                                  <div>No adjustments were made.</div>
                                </div>
                              </div>
                            ))}
                          {(pli?.remarks?.length ?? 0) !== 0 && (
                            <div className="mt-6">Additional Remarks</div>
                          )}
                          {pli.remarks?.map(
                            (remark: any, remarkIndex: number) => (
                              <div
                                key={`pli-${pli.id}-remark-${remark.code}-${remarkIndex}`}
                                className="flex flex-row mt-2 text-sm"
                              >
                                <div className="flex flex-col">
                                  <div className="text-gray-500">
                                    {remark.description}
                                  </div>
                                </div>
                              </div>
                            ),
                          )}
                          {/* {!pli.remarks ||
                            (pli.remarks?.length === 0 && (
                              <div className="flex w-full">
                                <div className="flex justify-between text-gray-500 text-sm w-full">
                                  <div>No remarks</div>
                                </div>
                              </div>
                            ))} */}

                          {(pli?.internalNotes?.length ?? 0) > 0 && (
                            <div className="mt-6">Internal Notes</div>
                          )}
                          {pli.internalNotes?.map(
                            (note: any, noteIndex: number) => (
                              <div
                                key={`pli-${pli.id}-remark-${note.createdAt}-${noteIndex}`}
                                className="flex flex-row mt-2 text-sm"
                              >
                                <div className="flex flex-col">
                                  <div className="text-gray-500">
                                    {note.message}
                                  </div>
                                </div>
                              </div>
                            ),
                          )}
                          {/* {!pli.internalNotes ||
                            (pli.internalNotes?.length === 0 && (
                              <div className="flex w-full">
                                <div className="flex justify-between text-gray-500 text-sm w-full">
                                  <div>No notes</div>
                                </div>
                              </div>
                            ))} */}
                        </div>
                      )}
                    </div>
                  );
                  return acc;
                }, {} as STRING_ANY_HASH),
                Balance: (
                  <div
                    className={classNames(
                      'bg-gray-100 border-t-2 border-gray-200',
                      'grid grid-cols-4',
                    )}
                  >
                    <div>&nbsp;</div>
                    <div className="px-4 py-2 flex justify-end">&nbsp;</div>
                    <div className="px-4 py-2 flex justify-end">
                      <Currency value={invoice.billedAmount} />
                    </div>
                    <div className="px-4 py-2 flex justify-end">
                      <Currency value={invoice.balance} />
                    </div>
                  </div>
                ),
              }}
              containerClassName="w-full mt-8"
              noHover={true}
              skipNullValues={true}
              addClassName={'shadow-lg'}
            />
          </div>
        ) : null}
        {adjustBilledServices ? (
          <HashCardDisplay
            header={<div>Adjust Billed Services</div>}
            headerClassName="overflow-hidden border-b-2 border-transparent flex flex-row bg-primary-500 text-white px-4 py-2 font-semibold"
            headerComponentRight={
              <div
                className="text-white hover:text-black cursor-pointer"
                onClick={(e: any) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  adjustClaim(e);
                }}
              >
                <XMarkIcon className="w-5 h-5" />
              </div>
            }
            containerClassName="w-full mt-8"
            noHover={true}
            skipNullValues={true}
            addClassName={'shadow-lg'}
            components={{
              // debug: (
              //   <pre>
              //     {ChiroUpJSON.pretty({
              //       actionsByKey,
              //       // postByCode: actionContext?.postByCode,
              //       unpostByCode: actionContext?.unpostByCode,
              //     })}
              //   </pre>
              // ),
              h2: (
                <div className={ClassNames.adjustmentSeps}>
                  Current Services
                </div>
              ),
              current: (
                <CurrentCard
                  rows={invoice.payorLineItems ?? []}
                  callback={actionCallback}
                  actionsByKey={actionsByKey}
                  invoice={invoice}
                  context={actionContext}
                />
              ),
              items: (
                <TransactionItemsCard
                  context={actionContext}
                  actionsByKey={actionsByKey}
                  callback={actionCallback}
                />
              ),
              h2_1: (
                <div
                  className={classNames(
                    ClassNames.adjustmentSeps,
                    'border-t border-b border-gray-400',
                  )}
                >
                  Insurance
                </div>
              ),
              insurance: (
                <div className="px-4 mt-4">
                  <table className="w-full mb-4">
                    <thead>
                      <tr className="text-sx text-gray-400">
                        <th className={ClassNames.table.iFirstTh}>Name</th>
                        <th className={ClassNames.table.th}>Code</th>
                        <th className={ClassNames.table.th}>Invoice</th>
                        <th className={ClassNames.table.th}>ERA</th>
                        <th className={ClassNames.table.th}>Payment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(actionContext?.insuranceById ?? {}).map(
                        (item: any, idx: number) => {
                          return (
                            <tr key={`insurance-${idx}`}>
                              <td
                                className={classNames(
                                  ClassNames.table.firstCell,
                                )}
                              >
                                {item.name}
                              </td>
                              <td className={ClassNames.table.cell}>
                                {item?.code}
                              </td>
                              <td
                                className={classNames(
                                  'w-16',
                                  ClassNames.table.cell,
                                )}
                              >
                                <DisplayBoolean value={item?.invoice} />
                              </td>
                              <td
                                className={classNames(
                                  'w-16',
                                  ClassNames.table.cell,
                                )}
                              >
                                <DisplayBoolean value={item?.cre} />
                              </td>
                              <td
                                className={classNames(
                                  'w-16',
                                  ClassNames.table.cell,
                                )}
                              >
                                <DisplayBoolean value={item?.payment} />
                              </td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </table>
                </div>
              ),
              h2_2: (
                <div
                  className={classNames(
                    ClassNames.adjustmentSeps,
                    'border-t border-gray-400',
                  )}
                >
                  ERA Claim Response{adjustResponses?.length === 1 ? '' : 's'}{' '}
                  <span className={ClassNames.subHeadingNote}>
                    (Most-recent First)
                  </span>
                </div>
              ),
              responses: adjustResponses.map((resp: any, i: number) => {
                return (
                  <ClaimResponseEraCard
                    key={`resp-${i}`}
                    row={resp}
                    // payorLineItems={invoice?.payorLineItems ?? []}
                    color={HashCardDisplayColors[i % 2]}
                    ord={i}
                    callback={actionCallback}
                    actionsByKey={actionsByKey}
                  />
                );
              }),
              sep: (
                <div
                  className={classNames(
                    ClassNames.adjustmentSeps,
                    'border-t border-gray-400 rounded-none',
                  )}
                >
                  Posted Payment{adjustPayments?.length === 1 ? '' : 's'}
                </div>
              ),
              payments: adjustPayments.length ? (
                adjustPayments.map((pay: any, i: number) => {
                  return (
                    <PaymentCard
                      key={`pay-${i}`}
                      row={pay}
                      color={HashCardDisplayColors[i % 2]}
                      callback={actionCallback}
                      actionsByKey={actionsByKey}
                    />
                  );
                })
              ) : (
                <div
                  className={classNames(ClassNames.noPayments, 'pt-4 border-t')}
                >
                  No posted payor payments.
                </div>
              ),
              eras: (
                <EraCard
                  context={actionContext}
                  actionsByKey={actionsByKey}
                  callback={actionCallback}
                />
              ),
              sep2: (
                <>
                  <div
                    className={classNames(
                      ClassNames.adjustmentSeps,
                      'border-t border-gray-400 rounded-none',
                      adjustmentActions?.length < 2 ? 'border-b mb-3' : '',
                    )}
                  >
                    Actions{' '}
                  </div>
                  {adjustmentActions?.length > 1 ? (
                    <div
                      className={classNames(
                        ClassNames.subHeadingNoteSep,
                        'border-t border-gray-400 rounded-none',
                      )}
                    >
                      Order of operations is significant!
                    </div>
                  ) : null}
                </>
              ),
              actions: (
                <div className="">
                  {adjustmentActions?.length ? (
                    adjustmentActions.map((action: any, i: number) => {
                      return (
                        <ActionCard
                          key={`action-${i}`}
                          row={action}
                          ord={i}
                          color={HashCardDisplayColors[i % 2]}
                          callback={actionCallback}
                        />
                      );
                    })
                  ) : (
                    <div className={classNames(ClassNames.noPayments)}>
                      No actions specified.
                    </div>
                  )}
                </div>
              ),
              submit: adjustmentActions?.length ? (
                <div className="flex justify-end mt-4 pr-2 pb-4">
                  <Button
                    className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded"
                    onClick={() => {
                      submitAdjustments();
                    }}
                    disabled={toggleRestActive}
                    loading={toggleRestActive}
                    text="Commit"
                  />
                </div>
              ) : null,
            }}
          />
        ) : null}
        <EraHistory history={eraHistory} itemTz={invoice.tz} userTz={userTz} />
      </div>
      {invoice.id === invoiceId ? (
        <>
          {updatingStatus && (
            <div className="w-100 p-6 grid grid-cols-7">
              <div className="flex flex-grow w-full col-span-6">
                <Select
                  label="New Status"
                  name="newStatus"
                  value={newStatus}
                  options={validTransitions}
                  onChange={(val: any) => {
                    if (!val) return;
                    setNewStatus(val);
                  }}
                  limit={1}
                  className={`w-full`}
                />
              </div>
              <div className="flex space-x-2 justify-start mt-9 ml-4">
                <IconButton
                  className="h-5 w-5 hover:text-primary-500 text-gray-600 pt-0.5"
                  icon={<XCircleIcon className="h-6 w-6" />}
                  tooltip="Cancel"
                  onClick={() => {
                    setUpdatingStatus(false);
                  }}
                  disabled={isRestActive.updateStatus}
                />
                <IconButton
                  onClick={async () => {
                    if (!newStatus) return;
                    setSaved(false);
                    setUpdatingStatus(false);
                    setIsRestActive((p) => ({ ...p, updateStatus: true }));
                    const clone = ChiroUpJSON.clone(invoice);
                    clone.status = newStatus;
                    const title = `Update Status`;

                    try {
                      const results =
                          await patientBillingService.setInvoiceStatus({
                            clinicId,
                            invoiceId: invoice.id,
                            status: newStatus,
                            persistIssues: true,
                          }),
                        result =
                          results &&
                          results?.invoices &&
                          results?.invoices?.[0],
                        saveIssues = results?.issues ?? [],
                        saveNotes = results?.notes ?? [];

                      setSaved(true);
                      if (saveIssues && saveIssues.length) {
                        createToast({
                          title,
                          description: `${
                            saveIssues?.length
                              ? saveIssues
                                  .map((i: BusinessRuleItemType) => i.text)
                                  .join(' ')
                              : 'No issues.'
                          }`,
                          type: ToastTypes.Info,
                          duration: 5000,
                        });
                      } else {
                        setInvoiceData(result);
                        updateInvoiceCallback({
                          invoice: result,
                          refetch: true,
                        });
                      }
                      if (saveNotes && saveNotes.length) {
                        createToast({
                          title,
                          description: `${
                            saveIssues?.length
                              ? saveIssues
                                  .map((i: BusinessRuleItemType) => i.text)
                                  .join(' ')
                              : 'No notes.'
                          }`,
                          type: ToastTypes.Info,
                          duration: 5000,
                        });
                      }
                    } catch (e: any) {
                      createToast({
                        title,
                        description: `${
                          e.message ?? e.response?.data?.message
                        }`,
                        type: ToastTypes.Fail,
                        duration: 5000,
                      });
                      console.error({ e });
                    }
                    setSaved(false);
                    setIsRestActive((p) => ({ ...p, updateStatus: false }));
                  }}
                  disabled={
                    isRestActive.updateStatus ||
                    newStatus === null ||
                    newStatus === invoice.status
                  }
                  loading={isRestActive.updateStatus}
                  className="h-5 w-5 hover:text-primary-500 text-gray-600"
                  icon={icons.saveDisk}
                  tooltip="Save"
                />
              </div>
            </div>
          )}
          <div
            className={classNames(
              'flex justify-end grow p-6',
              shrinkButtons ? 'space-x-2' : 'space-x-4',
            )}
          >
            {buttonsByInvoiceStatus(invoice)}
          </div>
        </>
      ) : null}
      {trace ? (
        <div>
          <pre>
            {ChiroUpJSON.pretty({
              hasIssues,
              saved,
              latestInvoiceMessages,
              invoiceId,
              eraHistory,
            })}
          </pre>
        </div>
      ) : null}
      <MakeBrowserWait isWaiting={anyRestActive} />
    </Modal>
  );
};

type AdjustmentEraTypeHash = {
  [key: string]: AdjustmentEraType;
};

type AdjustmentEraType = { id: number; services: ClaimServiceItemType[] };

type ClaimResponseEraCardProps = {
  row: ClaimResponseEraType & {
    payorName: string;
    payorCode: string;
    adjustmentAmount: number;
    remitDate: number;
  };
  color?: string;
  ord: number;
  callback: (args: AdjustmentActionType) => void;
  actionsByKey: STRING_NUMBER_HASH;
  context?: STRING_ANY_HASH;
};

const ClaimResponseEraCard: React.FC<ClaimResponseEraCardProps> = ({
  row,
  color = 'bg-white',
  ord = 0,
  callback,
  actionsByKey,
}) => {
  const services = useMemo(() => {
    if (row && row.serviceItems) {
      return row.serviceItems.map((item: any) => {
        return {
          ...item,
          remitAmount: Number(item?.remitAmount ?? 0),
          adjustmentTotal: item?.adjustments
            ?.reduce((topAcc: Decimal, adj: any, index: number) => {
              const sum = adj.items.reduce((acc: Decimal, item: any) => {
                return acc.add(createDecimal(item.amount ?? 0));
              }, createDecimal(0));
              return topAcc.add(sum);
            }, createDecimal(0))
            .toNumber(),
        };
      });
    }
  }, [row]);

  const numbersFromServices = useMemo(() => {
    const response = {
      billed: createDecimal(0),
      payment: createDecimal(0),
      adjustments: createDecimal(0),
      balance: createDecimal(0),
    };
    if (services?.length) {
      services.forEach((item: any) => {
        response.billed = response.billed.add(
          createDecimal(item.billedAmount ?? 0),
        );
        response.payment = response.payment.add(
          createDecimal(item.remitAmount ?? 0),
        );
        response.adjustments = response.adjustments.add(
          createDecimal(item.adjustmentTotal ?? 0),
        );
        response.balance = response.balance.add(
          createDecimal(item.billedAmount ?? 0)
            .sub(createDecimal(item.remitAmount ?? 0))
            .sub(createDecimal(item.adjustmentTotal ?? 0)),
        );
      });
    }
    return {
      billed: response.billed.toNumber(),
      payment: response.payment.toNumber(),
      adjustments: response.adjustments.toNumber(),
      balance: response.balance.toNumber(),
    };
  }, [services]);

  const grandAdjustmentTotal = useMemo(() => {
    if (services) {
      return services
        .reduce((topAcc: Decimal, item: any) => {
          return topAcc.add(createDecimal(item.adjustmentTotal));
        }, createDecimal(0))
        .toNumber();
    }
    return 0;
  }, [services]);

  const noServicesMessage = useMemo(() => {
    if (row?.fullClaimResponse) {
      const resp = row.fullClaimResponse || {};
      return (
        <div>
          <div className="grid grid-cols-4 w-full pb-4">
            <div
              className={classNames(
                ClassNames.noServicesFound,
                'col-span-4 py-4',
              )}
            >
              No services were found.
            </div>
            <div className={ClassNames.cre.firstIdentLabel}>Control Number</div>
            <div className={ClassNames.cre.identLabel}>Charge</div>
            <div className={ClassNames.cre.identLabel}>Payment</div>
            <div className={ClassNames.cre.lastLabel}>Claim Received</div>
            <div className={ClassNames.cre.firstIdentValue}>
              {resp?.patientControlNumber ?? '-na-'}
            </div>
            <div className={ClassNames.cre.identValue}>
              <Currency
                value={resp?.totalCharge ?? 0}
                negativeClassName="text-red-500"
              />
            </div>
            <div className={ClassNames.cre.identValue}>
              <Currency
                value={resp?.paymentAmount ?? 0}
                negativeClassName="text-red-500"
              />
            </div>
            <div className={ClassNames.cre.identValue}>
              {resp?.claimReceivedDate ?? '-na-'}
            </div>
            <div
              className={classNames(
                ClassNames.cre.firstIdentLabel,
                'col-span-2 mt-2',
              )}
            >
              Status
            </div>
            <div
              className={classNames(
                ClassNames.cre.lastLabel,
                'col-span-2 mt-2',
              )}
            >
              Payer Control Number
            </div>
            <div className="col-span-2">
              {resp?.statusCode ?? '-na-'} - {resp?.statusDescription}
            </div>
            <div className={classNames(ClassNames.cre.lastValue, 'col-span-2')}>
              {resp?.payerClaimControlNumber ?? '-na-'}
            </div>
          </div>
        </div>
      );
    }
    return <div className="text-gray-400 italic">No services were found.</div>;
  }, [row.fullClaimResponse]);

  const statusToPostKey = ['status-to-post', 'cre', row?.id].join('.');

  const myAction: AdjustmentActionType = {
      action: 'post',
      type: 'cre',
      row,
      pk: [row?.eraId || 'current', row.id].join('.'),
    },
    myKey = [myAction.action, myAction.type, myAction.pk].join('.');
  // const statusKey = ['set-post-status', 'cre', row.eraId, row.id].join('.');

  return (
    <div className="">
      <HashCardDisplay
        // hash={row}
        noHover={true}
        border=""
        containerClassName={`w-full ${color}`}
        bodyClassName="text-gray-800 overflow-hidden border-t border-gray-400 rounded-none"
        components={{
          eraId: (
            <div className="grid grid-cols-4 w-full px-4 pb-2 mt-2">
              <div className={ClassNames.cre.firstIdentLabel}>
                ERA Line Item No.
              </div>
              <div className={ClassNames.cre.identLabel}>ERA</div>
              <div className={ClassNames.cre.identLabel}>Control Number</div>
              <div className={ClassNames.cre.lastLabel}>Payor</div>
              <div className={ClassNames.cre.firstIdentValue}>{row.id}</div>
              <div className={ClassNames.cre.identValue}>{row.eraId}</div>
              <div className={ClassNames.cre.identValue}>
                {row.controlNumber}
              </div>
              <div
                className={classNames(
                  ClassNames.cre.lastValue,
                  'flex-row space-x-1',
                )}
              >
                <TrivialTooltip text={row.payorName} type={`info`} />
                <div>{row.payorCode}</div>
              </div>
            </div>
          ),
          numbers: (
            <div className="grid grid-cols-4 w-full px-4">
              <div className={ClassNames.cre.firstNumberLabel}>Billed</div>
              <div className={ClassNames.cre.numbersLabel}>Payment</div>
              <div className={ClassNames.cre.numbersLabel}>Adjustments</div>
              <div className={ClassNames.cre.numbersLabel}>Balance</div>
              <div className={ClassNames.cre.firstNumberValue}>
                <Currency
                  value={numbersFromServices.billed}
                  negativeClassName="text-red-500"
                />
              </div>
              <div className={ClassNames.cre.numbersValue}>
                <Currency
                  value={numbersFromServices.payment}
                  negativeClassName="text-red-500"
                />
              </div>
              <div className={ClassNames.cre.numbersValue}>
                <Currency
                  value={grandAdjustmentTotal}
                  negativeClassName="text-red-500"
                />
              </div>
              <div className={ClassNames.cre.numbersValue}>
                <Currency
                  value={numbersFromServices.balance}
                  negativeClassName="text-red-500"
                />
              </div>
            </div>
          ),
          last: (
            <div className="grid grid-cols-3 w-full px-4 pt-2">
              <div className={ClassNames.cre.firstLastLabel}>Status</div>
              <div className={classNames(ClassNames.cre.lastLabel)}>
                Remit Date
              </div>
              <div
                className={classNames(
                  'flex-row space-x-1',
                  ClassNames.cre.lastLabel,
                )}
              >
                <TrivialTooltip
                  text={dayjs(row?.postedAt).format(
                    ChiroUpDayJsCommon.format.isoAt,
                  )}
                />
                <div>Posted</div>
              </div>
              <div
                className={classNames(
                  ClassNames.cre.firstLastValue,
                  'flex flex-row space-x-2',
                )}
              >
                <div>{row.status}</div>
                {actionsByKey[myKey] ? (
                  <div
                    className={classNames(ClassNames.circle, 'cursor-pointer')}
                    onClick={() => {
                      // console.log({ trash: ord });
                      callback({
                        action: 'trash',
                        type: 'item',
                        pk: (ord - 1).toString(),
                        row,
                      });
                    }}
                  >
                    {actionsByKey[myKey]}
                  </div>
                ) : row.status !== 'Posted' && services?.length ? (
                  <div
                    className={`text-primary-400 hover:text-primary-600 cursor-pointer`}
                    title={`Post this ERA line item to the claim ${myAction.pk}.`}
                    onClick={() => {
                      // console.log('oink', myAction);
                      callback({
                        ...myAction,
                        row,
                        pk: [myAction.pk].join('.'),
                      });
                    }}
                  >
                    <DocumentPlusIcon className="w-5 h-5" />
                  </div>
                ) : null}
                {(row?.status === ClaimResponseEraStatus.Posted ||
                  row?.status === ClaimResponseEraStatus.Applied) && (
                  <div
                    className={`text-primary-400 hover:text-primary-600 cursor-pointer`}
                    title="Re-apply adjudication info to claim."
                    onClick={() => {
                      callback({
                        action: 'apply-adj',
                        type: 'cre',
                        pk: [row.eraId, row.id].join('.'),
                        row,
                      });
                    }}
                  >
                    <ArrowUturnUpIcon className="w-5 h-5" />
                  </div>
                )}
                {row?.status !== ClaimResponseEraStatus.Posted &&
                services?.length ? (
                  <div
                    className="flex text-primary-400 hover:text-primary-600 -mt-0.5 cursor-pointer"
                    title="Set the status to post without adjusting anything else."
                    onClick={() => {
                      // console.log('oink', myAction);
                      if (actionsByKey[statusToPostKey]) {
                        callback({
                          action: 'trash',
                          type: 'item',
                          pk: (ord - 1).toString(),
                          row,
                        });
                        return;
                      }
                      callback({
                        action: 'status-to-post',
                        type: 'cre',
                        row,
                        pk: String(row.id),
                      });
                    }}
                  >
                    {actionsByKey[statusToPostKey] ? (
                      <div className={ClassNames.circle}>
                        {actionsByKey[statusToPostKey]}
                      </div>
                    ) : (
                      <ArrowDownOnSquareIcon className="w-5 h-5" />
                    )}
                  </div>
                ) : null}
              </div>
              <div className={ClassNames.cre.lastValue}>
                <TimestampDisplay
                  ts={row.remitDate}
                  format={ChiroUpDayJsCommon.format.isoDate}
                />
              </div>
              <div className={ClassNames.cre.lastValue}>
                <TimestampDisplay
                  ts={row.postedAt}
                  format={ChiroUpDayJsCommon.format.isoDate}
                  // format={ChiroUpDayJsCommon.format.isoAt}
                />
              </div>
            </div>
          ),
          services: (
            <div className="px-4">
              {services?.length ? (
                <table className="w-full my-4">
                  <thead>
                    <tr className="text-sx text-gray-400">
                      <th className={ClassNames.table.firstTh}>Service</th>
                      <th className={ClassNames.table.th}>Units</th>
                      <th className={ClassNames.table.th}>Billed</th>
                      <th className={ClassNames.table.th}>Paid</th>
                      <th className={ClassNames.table.th}>Adj's</th>
                      <th className={classNames(ClassNames.table.th)}>
                        Balance
                      </th>
                      {row?.status === 'Posted' ? null : <th>&nbsp;</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {services?.map(
                      (item: ClaimServiceItemType, idx: number) => {
                        const adjTotal = item?.adjustments?.reduce(
                          (topAcc: Decimal, adj: any) => {
                            const sum = adj.items.reduce(
                              (acc: Decimal, item: any) => {
                                return acc.add(createDecimal(item.amount ?? 0));
                              },
                              createDecimal(0),
                            );
                            return topAcc.add(sum);
                          },
                          createDecimal(0),
                        );
                        const rowKey = [myKey, idx].join('.');
                        return (
                          <tr key={`cre-service-${ord}-${idx}`}>
                            <td
                              className={classNames(ClassNames.table.firstCell)}
                            >
                              {item.adjudicatedServiceCode}
                            </td>
                            <td className={ClassNames.table.cell}>
                              {item.adjudicatedServiceUnits}
                            </td>
                            <td className={ClassNames.table.cell}>
                              <Currency
                                value={item.billedAmount}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={ClassNames.table.cell}>
                              <Currency
                                value={item.remitAmount}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={ClassNames.table.cell}>
                              <Currency
                                value={adjTotal?.toNumber()}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={classNames(ClassNames.table.cell)}>
                              <Currency
                                value={createDecimal(item.billedAmount)
                                  .minus(item.remitAmount ?? 0)
                                  .minus(adjTotal ?? 0)
                                  .toNumber()}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            {row?.status === 'Posted' ? null : (
                              <td className={ClassNames.callback}>
                                <div className="flex justify-end">
                                  {actionsByKey[rowKey] ? (
                                    <div className={ClassNames.circle}>
                                      {actionsByKey[rowKey]}
                                    </div>
                                  ) : actionsByKey[myKey] ? (
                                    <div
                                      className="cursor-not-allowed text-black w-5"
                                      title="This line-item will be applied when the claim is posted."
                                    >
                                      <CheckIcon className="w-5 h-5" />
                                    </div>
                                  ) : (
                                    <div
                                      title="Post this ERA line item to the claim."
                                      onClick={() => {
                                        // console.log('oink-02', myAction);
                                        callback({
                                          ...myAction,
                                          row: {
                                            ...item,
                                            status: row?.status,
                                            eraId: row?.eraId,
                                            id: row?.id,
                                          },
                                          pk: [myAction.pk, String(idx)].join(
                                            '.',
                                          ),
                                        });
                                      }}
                                    >
                                      <DocumentPlusIcon className="w-5 h-5" />
                                    </div>
                                  )}
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </table>
              ) : (
                noServicesMessage
              )}
            </div>
          ),
        }}
      />
      {/* <pre className={color}>{ChiroUpJSON.pretty(row)}</pre> */}
    </div>
  );
};

type PaymentCardProps = {
  row: PatientTransaction & {
    payorName: string;
    payorCode: string;
    debitSum: number;
    creditSum: number;
  };
  color?: string;
  callback: (args: AdjustmentActionType) => void;
  actionsByKey: STRING_NUMBER_HASH;
};

const PaymentCard: React.FC<PaymentCardProps> = ({
  row,
  color = 'bg-white',
  callback,
  actionsByKey,
}) => {
  const summaryItems = useMemo(() => {
    if (row?.items) {
      return row.items.map((item) => {
        return {
          ...item,
          isAmountNumber: item?.amount !== undefined && item?.amount !== null,
        };
      });
    }
    return [];
  }, [row]);
  const myAction: AdjustmentActionType = {
      action: 'rollback',
      type: 'payment',
      row,
      pk: String(row.referenceNumber),
    },
    myKey = [myAction.action, myAction.type, row.referenceNumber].join('.');

  const myInitialPayment = useMemo(() => {
    return summaryItems.find(
      (item) =>
        item?.type === TransactionItemTypeEnum.Credit &&
        (item?.description ?? '')
          .toLocaleLowerCase()
          .indexOf('initial payment') === 0,
    )?.id;
  }, [summaryItems]);

  const myInitialPaymentKey = useMemo(() => {
    return ['rollback', 'initial', row?.id].join('.');
  }, [row?.id]);

  return (
    <div>
      <HashCardDisplay
        // hash={row}
        noHover={true}
        border=""
        containerClassName={`w-full ${color}`}
        bodyClassName="text-gray-800 overflow-hidden border-t border-gray-400 rounded-none"
        components={{
          // debug: <pre>{ChiroUpJSON.pretty(myInitialPayment)}</pre>,
          eraId: (
            <div className="grid grid-cols-4 w-full px-4 pb-2 mt-2">
              <div className={ClassNames.cre.firstIdentLabel}>Date</div>
              <div className={ClassNames.cre.identLabel}>ERA</div>
              <div
                className={classNames(
                  ClassNames.cre.identLabel,
                  'flex-row space-x-1',
                )}
              >
                <TrivialTooltip
                  text={
                    ((PAYMENT_TYPE_DISPLAY as STRING_STRING_HASH)[
                      row.subtype ?? ''
                    ] ?? '- unknown subtype -') as string
                  }
                  type={`info`}
                />
                <div>Type</div>
              </div>
              <div className={ClassNames.cre.lastLabel}>Payor</div>
              <div className={ClassNames.cre.firstIdentValue}>
                <TimestampDisplay
                  ts={row.transactionDate}
                  format={ChiroUpDayJsCommon.format.isoDate}
                />
              </div>
              <div className={ClassNames.cre.identValue}>
                {row.referenceNumber ?? '-'}
              </div>
              <div className={ClassNames.cre.identValue}>{row.subtype}</div>
              <div
                className={classNames(
                  ClassNames.cre.lastValue,
                  'flex-row space-x-1',
                )}
              >
                <TrivialTooltip text={row.payorName} type={`info`} />
                <div>
                  {row.payorCode ??
                    (row?.referenceNumber &&
                    PayorPaymentTypesHash[row?.subtype ?? '']
                      ? '-'
                      : 'Patient')}
                </div>
              </div>
            </div>
          ),
          numbers: (
            <div className="grid grid-cols-3 w-full px-4">
              <div className={ClassNames.cre.firstNumberLabel}>Debits</div>
              <div className={ClassNames.cre.numbersLabel}>Credits</div>
              <div className={ClassNames.cre.numbersLabel}>Balance</div>
              <div className={ClassNames.cre.firstNumberValue}>
                <Currency
                  value={row.debitSum}
                  negativeClassName="text-red-500"
                />
              </div>
              <div className={ClassNames.cre.numbersValue}>
                <Currency
                  value={row.creditSum}
                  negativeClassName="text-red-500"
                />
              </div>
              <div className={ClassNames.cre.numbersValue}>
                <Currency
                  value={row.creditSum - row.debitSum}
                  negativeClassName="text-red-500"
                />
              </div>
            </div>
          ),
          summary: (
            <div className="px-4">
              <table className="w-full my-4">
                <thead>
                  <tr className="text-sx text-gray-400">
                    <th className={classNames(ClassNames.table.firstTh)}>
                      Units
                    </th>
                    <th className={ClassNames.table.leftTh}>Description</th>
                    <th>&nbsp;</th>
                    <th className={ClassNames.table.th}>Amount</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {summaryItems?.map((item, idx: number) => {
                    const rowKey = [myKey, item?.id].join('.');
                    let myIndicator: any = null;
                    if (item?.id === myInitialPayment) {
                      myIndicator = actionsByKey[myInitialPaymentKey] ? (
                        <div
                          className={classNames(
                            ClassNames.circle,
                            'cursor-not-allowed',
                          )}
                        >
                          {actionsByKey[myInitialPaymentKey]}
                        </div>
                      ) : (
                        <div className="cursor-not-allowed">&nbsp;</div>
                      );
                    }
                    let description = (item?.description ?? '-').replace(
                      /\.$/,
                      '',
                    );
                    if (description.length > 43) {
                      description = description.substring(0, 40) + '...';
                    }
                    return (
                      <tr key={`payment-item-${idx}`}>
                        <td
                          className={classNames(
                            ClassNames.table.leftCell,
                            'pr-2',
                          )}
                        >
                          {item.units}
                        </td>
                        <td className={classNames(ClassNames.table.leftCell)}>
                          {description}
                        </td>
                        <td>
                          {item?.type === TransactionItemTypeEnum.Credit
                            ? '+'
                            : '-'}
                        </td>
                        <td className={classNames(ClassNames.table.cell)}>
                          <Currency
                            value={item.amount}
                            negativeClassName="text-red-500"
                          />
                        </td>
                        <td className={ClassNames.callback}>
                          <div className="flex justify-end">
                            {/* {rowKey} */}
                            {actionsByKey[rowKey] ? (
                              <div
                                className={ClassNames.circle}
                                onClick={() => {
                                  const ord = actionsByKey[rowKey] - 1;
                                  callback({
                                    action: 'trash',
                                    type: 'item',
                                    pk: ord.toString(),
                                    row: {},
                                  });
                                }}
                              >
                                {actionsByKey[rowKey]}
                              </div>
                            ) : myIndicator ? (
                              myIndicator
                            ) : item?.isAmountNumber ? (
                              <div
                                title="Rollback this payment."
                                className="cursor-pointer"
                                onClick={() => {
                                  callback({
                                    ...myAction,
                                    row: {
                                      ...item,
                                      eraId: row.referenceNumber,
                                    },
                                    pk: [row.referenceNumber, item.id].join(
                                      '.',
                                    ),
                                  });
                                }}
                              >
                                <ArrowPathRoundedSquareIcon className="h-5 w-5" />
                              </div>
                            ) : (
                              <TrivialTooltip
                                text="The database field value is not a number, so zero is displayed."
                                type="info"
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ),
        }}
      />
      {/* <pre>{ChiroUpJSON.pretty(row)}</pre> */}
    </div>
  );
};

type EraHistoryProps = {
  history: STRING_ANY_HASH | null;
  containerClassName?: string;
  itemTz?: string;
  userTz?: string;
};
export const EraHistory: React.FC<EraHistoryProps> = ({
  containerClassName = '',
  history,
  itemTz = ChiroUpDayJsCommon.defaultTimezone,
  userTz = ChiroUpDayJsCommon.defaultTimezone,
}) => {
  // Order is descending. The ones on the top will be
  // the most recent (primary key is always increasing).
  // If there ends up to be ANY that don't have an ERA
  // id [which should not happen, BTW], they will be
  // at the bottom.
  const eraOrder = useMemo(() => {
    if (!history) return [];
    const res = Object.keys(history).sort((a, b) => {
      return Number(a) > Number(b) ? -1 : 1;
    });
    return res;
  }, [history]);

  const colors = ['bg-transparent', 'bg-primary-50'];
  if (!history) return null;

  return (
    <div className={containerClassName}>
      {eraOrder.map((key) => {
        const plis = history[key] as PayorLineItemType[],
          eraId = plis?.[0]?.eraId; // All PLIs will have the same ERA id.
        if (!plis || plis.length === 0) return null;
        return (
          <div key={key} className="border-b border-gray-200">
            <HashCardDisplay
              header={
                <Link
                  to={`/billing/eras/${eraId}`}
                  target="_blank"
                  title="Opens this ERA in a new tab."
                  className="flex flex-row space-x-2 hover:font-bold"
                >
                  <div>ERA #{key}</div>
                  {isNaN(Number(key)) ? null : (
                    <div className="cursor-pointer pt-0.5">
                      <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                    </div>
                  )}
                </Link>
              }
              headerComponentRight={
                <div className="flex flex-row space-x-2">
                  <div>Reprocessed</div>{' '}
                  <TimestampDisplay
                    ts={(plis?.[0] as any).ts}
                    format={ChiroUpDayJsCommon.format.isoAt}
                  />
                </div>
              }
              headerClassName="overflow-hidden border-b-2 border-gray-400 flex flex-row bg-gray-400 text-white px-4 py-2 font-bold"
              bodyClassName="bg-gray-50 text-gray-800 overflow-hidden"
              hashCardDisplayColors={colors}
              components={{
                ...plis.reduce((acc, pli, idx) => {
                  acc[`${pli.payorId}.${pli.controlNumber}`] = (
                    <div
                      className={classNames(
                        colors[idx % 2],
                        'grid grid-cols-4',
                      )}
                    >
                      <div className="px-4 pt-2">
                        <span className=" text-gray-400">Service</span>
                        <TrivialTooltip
                          type="info"
                          text={`(${pli.status})${
                            pli.description ? ' - ' + pli.description : ''
                          }`}
                        />
                      </div>
                      <div className="px-4 pt-2 opacity-50 flex justify-end">
                        Units
                      </div>
                      <div className="px-4 pt-2 opacity-50 flex justify-end">
                        Billed
                      </div>
                      <div className="px-4 pt-2 opacity-50 flex justify-end">
                        Balance
                      </div>
                      <div className="px-4 pb-2 whitespace-nowrap">
                        {pli.submittedProcedureCode}
                        <TrivialTooltip
                          type="info"
                          text={pli.controlNumber}
                          tipClassName="w-64"
                        />
                      </div>
                      <div className="px-4 pb-2 flex justify-end">
                        {pli.submittedUnits}
                      </div>
                      <div className="px-4 pb-2 flex justify-end">
                        <Currency value={pli.billedAmount} />
                      </div>
                      <div className="px-4 pb-2 flex justify-end">
                        <Currency
                          value={
                            (pli.billedAmount ?? 0) -
                            (pli.remitAmount ?? 0) -
                            Math.abs(pli.adjustmentTotal ?? 0)
                          }
                        />
                      </div>
                      {pli.remitDate && (
                        <div className="border-t border-gray-900/5 px-6 py-6 col-span-4">
                          <div className="underline">Payment</div>

                          <div className="flex w-full mb-5">
                            <div className="flex justify-between text-gray-500 text-sm w-full">
                              <div className="flex flex-row space-x-2">
                                <TimestampDisplay
                                  ts={pli.remitDate}
                                  itemTz={itemTz}
                                  format={ChiroUpDayJsCommon.format.date}
                                  userTz={userTz}
                                />{' '}
                                {pli.postedDate ? (
                                  <>
                                    <span>posted</span>
                                    <TimestampDisplay
                                      ts={pli.postedDate}
                                      itemTz={itemTz}
                                      userTz={userTz}
                                      format={ChiroUpDayJsCommon.format.date}
                                    />
                                  </>
                                ) : null}
                              </div>
                              <div>{formatCurrency(pli.remitAmount)}</div>
                            </div>
                          </div>

                          <div className="underline">Adjustments</div>
                          {pli.adjustments?.map((adj: any, index: number) => (
                            <div
                              key={`pli-${pli.id}-adj-${adj.groupCode}-${index}`}
                            >
                              <div
                                key={adj.groupCode}
                                className="flex flex-row text-sm"
                              >
                                <div className="flex flex-col">
                                  <div>{adj.groupDescription}</div>
                                </div>
                              </div>

                              {adj.items.map((item: any, itemIndex: number) => (
                                <div key={`adj-${itemIndex}`}>
                                  <div className="flex w-full"></div>
                                  <div
                                    key={`pli-${pli.id}-adj-${adj.groupCode}-item-${itemIndex}`}
                                    className="flex justify-between text-gray-500 text-sm w-full mb-2"
                                  >
                                    <div>{item.description}</div>
                                    <div className="self-end">
                                      {formatCurrency(item.amount)}
                                    </div>
                                  </div>

                                  <div className="w-full text-xs">
                                    {item.remarks &&
                                      item.remarks.length > 0 && (
                                        <div className="flex flex-row">
                                          <div className="flex flex-col">
                                            <div className="text-gray-500">
                                              {item.remarks.map(
                                                (
                                                  remark: any,
                                                  remarkIndex: number,
                                                ) => (
                                                  <div
                                                    key={`pli-${pli.id}-adj-${adj.groupCode}-item-${itemIndex}-remark-${remark.code}-${remarkIndex}`}
                                                    className="flex flex-row mt-2 text-sm"
                                                  >
                                                    <div className="flex flex-col">
                                                      <div className="text-gray-500">
                                                        {remark.description}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ),
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                          {!pli.adjustments ||
                            (pli.adjustments?.length === 0 && (
                              <div className="flex w-full">
                                <div className="flex justify-between text-gray-500 text-sm w-full">
                                  <div>No adjustments were made.</div>
                                </div>
                              </div>
                            ))}
                          {(pli?.remarks?.length ?? 0) !== 0 && (
                            <div className="mt-6">Additional Remarks</div>
                          )}
                          {pli.remarks?.map(
                            (remark: any, remarkIndex: number) => (
                              <div
                                key={`pli-${pli.id}-remark-${remark.code}-${remarkIndex}`}
                                className="flex flex-row mt-2 text-sm"
                              >
                                <div className="flex flex-col">
                                  <div className="text-gray-500">
                                    {remark.description}
                                  </div>
                                </div>
                              </div>
                            ),
                          )}

                          {(pli?.internalNotes?.length ?? 0) > 0 && (
                            <div className="mt-6">Internal Notes</div>
                          )}
                          {pli.internalNotes?.map(
                            (note: any, noteIndex: number) => (
                              <div
                                key={`pli-${pli.id}-remark-${note.createdAt}-${noteIndex}`}
                                className="flex flex-row mt-2 text-sm"
                              >
                                <div className="flex flex-col">
                                  <div className="text-gray-500">
                                    {note.message}
                                  </div>
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                      )}
                    </div>
                  );
                  return acc;
                }, {} as STRING_ANY_HASH),
              }}
              containerClassName="w-full mt-8"
              noHover={true}
              skipNullValues={true}
            />
          </div>
        );
      })}
    </div>
  );
};

type AdjustBilledServicesProps = {
  hash?: STRING_ANY_HASH | null | undefined;
  valueClassNames?: STRING_STRING_HASH | null | undefined;
  labelClassNames?: STRING_STRING_HASH | null | undefined;
  header?: React.ReactNode | null;
  containerClassName?: string;
  trace?: boolean;
  headerComponentRight?: React.ReactNode;
  disabled?: boolean;
  skipNullValues?: boolean;
  components?: { [key: string]: React.ReactNode };
  tips?: { [key: string]: React.ReactNode };
  noHover?: boolean;
  addClassName?: string;
  grid?: { cols: number; labelSpan: number; valueSpan: number };
  square?: boolean;
  border?: string;
  topBorder?: string;
  headerClassName?: string;
  bodyClassName?: string;
  hashCardDisplayColors?: string[];
};

export const AdjustBilledServices: React.FC<AdjustBilledServicesProps> = ({
  hash,
  header = null,
  valueClassNames = {},
  labelClassNames = {},
  containerClassName = 'w-full',
  trace = false,
  headerComponentRight = null,
  disabled = false,
  skipNullValues = false,
  components = {},
  tips = {},
  noHover = false,
  addClassName = '',
  grid = { cols: 2, labelSpan: 1, valueSpan: 1 },
  square = false,
  border = 'border-gray-400',
  topBorder = '',
  headerClassName = 'overflow-hidden border-b-2 flex flex-row bg-gray-100 text-gray-800 px-4 py-2 font-bold',
  bodyClassName = 'bg-white text-gray-800 overflow-hidden',
  hashCardDisplayColors,
}) => {
  hash = hash || components;
  const keyPrefix = useId();
  const hashKeys = useMemo(() => (hash ? Object.keys(hash) : []), [hash]);

  /**
   * What the heck is this? For aesthetic reason, we want the bottom of the
   * LAST row in the card to be rounded. The problem is, if you don't show
   * a value due to the skipNullValues flag, then the detection of when the
   * last row is being rendered is off. So, we create a delta of null values
   * to make sure we round the correct bottom...so to speak.
   */
  const keyDelta = useMemo(() => {
    let res = 0;
    if (hash && skipNullValues) {
      res = Object.keys(hash).reduce((acc, key) => {
        if (!hasProperty(components, key)) return acc;
        return acc + 1;
      }, 0);
    }
    return -1 * res;
  }, [hash, skipNullValues, components]);

  const colors = useMemo(() => {
    if (hashCardDisplayColors) return hashCardDisplayColors;
    // console.log('hashcard display colors is not defined');
    return HashCardDisplayColors;
  }, [hashCardDisplayColors]);

  if (!hash) return null;

  if (!hash) return null;

  let visibleLines = 0;

  return (
    <div className={containerClassName}>
      <div
        className={classNames(
          'flex flex-col text-sm border ',
          border,
          topBorder,
          square ? '' : 'rounded-md',
          disabled
            ? ''
            : noHover
              ? ''
              : 'hover:ring-2 hover:ring-primary-500 shadow-xl hover:shadow-sm',
          addClassName,
        )}
      >
        {header ? (
          <div
            className={classNames(
              square ? '' : 'rounded-md rounded-b-none',
              headerClassName,
            )}
          >
            <div className="flex grow">{header}</div>
            {headerComponentRight}
          </div>
        ) : null}
        <div className={classNames(square ? '' : 'rounded-md', bodyClassName)}>
          {Object.entries(hash).map(([key, value], idx) => {
            if (value === null && skipNullValues) {
              return <div key={`${keyPrefix}-${idx}`}></div>;
            }
            /**
             * It is possible for a component value to be null, so this allows
             * for that use case.
             */
            if (hasProperty(components, key)) {
              return <div key={`${keyPrefix}-${idx}`}>{components[key]}</div>;
            }
            visibleLines++;
            const tip = tips[key] ?? null;
            return (
              <div
                className={classNames(
                  `grid grid-cols-${grid.cols}`,
                  colors[visibleLines % 2],
                  idx === hashKeys.length - 1 + keyDelta
                    ? square
                      ? ''
                      : 'rounded-b-md'
                    : '',
                )}
                key={`${keyPrefix}-${idx}`}
              >
                <div
                  className={classNames(
                    labelClassNames?.[key] ??
                      labelClassNames?._ ??
                      'px-4 py-1 text-sm flex flex-row',
                    grid.labelSpan !== 1 ? `col-span-${grid.labelSpan}` : '',
                  )}
                >
                  <div className="italic">{key}</div>
                  <div>
                    {tip ? (
                      typeof tip === 'string' ? (
                        <TrivialTooltip
                          text={tip}
                          tipClassName="font-normal w-72"
                        />
                      ) : (
                        <TrivialTooltip component={tip} />
                      )
                    ) : null}
                  </div>
                </div>
                <div
                  className={
                    valueClassNames?.[key] ??
                    valueClassNames?._default ??
                    `px-4 py-1 text-sm col-span-${grid.valueSpan}`
                  }
                >
                  {typeof value === 'object'
                    ? Array.isArray(value)
                      ? value.map((v, key) => (
                          <div key={`${keyPrefix}-${key}`}>{v}</div>
                        ))
                      : React.isValidElement(value)
                        ? value
                        : ChiroUpJSON.stringify(value)
                    : typeof value === 'boolean'
                      ? value
                        ? 'true'
                        : 'false'
                      : value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {trace ? (
        <pre>{ChiroUpJSON.pretty({ hash, header, keyDelta })}</pre>
      ) : null}
    </div>
  );
};

const ActionDescriptions: STRING_ANY_HASH = {
  'apply-adj': (obj: AdjustmentActionType) => {
    // console.log({ applyAdj: obj });
    return (
      [
        `Apply adjudication info from ERA #${obj?.row?.eraId}`,
        `to the claim item #${obj?.row?.id}`,
      ].join(' ') + '.'
    );
  },
  'status-to-post': (obj: AdjustmentActionType) => {
    // console.log({ statusToPost: obj });
    return (
      [
        `Set the status of claim item #${obj?.pk} on ERA #${obj?.row?.eraId}`,
        `to "Posted" without performing other actions on the response`,
      ].join(' ') + '.'
    );
  },
  'remove-item': (obj: AdjustmentActionType) => {
    // console.log({ removeItem: obj });
    const amount = createDecimal(obj?.row?.amount ?? 0)
        .times(obj?.row?.units ?? 1)
        .toNumber()
        .toFixed(2),
      units = obj?.row?.units ?? 1;
    return (
      [
        `Remove ${(obj?.row?.type ?? '- bad -').toLowerCase()} item #${
          (obj?.row?.ordinalId ?? 0) + 1
        }`,
        `"${obj?.row?.description}"`,
        `for`,
        ChiroUpBaseCommon.format.asMoney(Number(amount) ?? 0),
        `(${units} unit${
          units === 1 ? '' : 's'
        } @ ${ChiroUpBaseCommon.format.asMoney(
          Number(obj?.row?.amount ?? 0),
        )})`.replace(/ /g, '\u00A0'),
      ].join(' ') + '.'
    );
  },
  'era-to-cre': (obj: AdjustmentActionType) => {
    // console.log({ eraToCre: obj });
    return (
      [
        `Apply line-item #${(obj?.row?.ordinalId ?? 0) + 1} for service`,
        obj?.row?.adjudicatedServiceCode,
        `from ERA #${obj?.row?.eraId}`,
        `to the claim id #${obj?.row?.claimResponseEraId}`,
      ].join(' ') + '.'
    );
  },
  'set-post-status': (obj: AdjustmentActionType) => {
    // console.log({ setPostStatus: obj });
    return (
      [
        `Set the status of ERA #${obj?.row?.eraId}`,
        `response #${obj?.row?.id} to posted`,
      ].join(' ') + '.'
    );
  },
  user: (obj: AdjustmentActionType) => {
    // console.log({ user: obj });
    if (obj.type === 'payment') {
      return (
        [
          `Before submitting, double-check if any payment line items`,
          `from ERA #${obj?.row?.eraId}`,
          `for`,
          ChiroUpBaseCommon.format.asMoney(obj?.row?.amount ?? 0),
          `need to be rolled back. Add them as actions if necessary`,
        ].join(' ') + '.'
      );
    } else if (obj.type === 'multi-post') {
      return [obj?.row?.message].join(' ') + '.';
    } else if (obj.type === 'era-to-cre') {
      return (
        [
          `Please verify that the service ${obj?.row?.adjudicatedServiceCode}`,
          `should be applied to item #${obj?.row?.claimResponseEraId} from`,
          `ERA #${obj?.row?.eraId}`,
          'multiple times',
        ].join(' ') + '.'
      );
    } else if (obj.type === 'remove-item') {
      return (
        [
          `Note: Removing ${(
            obj?.row?.type ?? '- bad -'
          ).toLowerCase()} item #${(obj?.row?.ordinalId ?? 0) + 1}`,
          `"${obj?.row?.description}"`,
          `for ${ChiroUpBaseCommon.format.asMoney(obj?.row?.total ?? 0)}`,
          'DOES NOT affect any payments. It just removes the line items',
          'from this transaction/purchase.',
          'Use with extreme caution',
        ].join(' ') + '.'
      );
    } else if (obj.type === 'reconsider-reverse') {
      const code = obj?.row?.adjudicatedServiceCode,
        eraId = obj?.row?.eraId;
      return (
        [
          `The action reversing the posting of ${code} may not be necessary.`,
          `Posting ERA #${eraId} will replace`,
        ].join(' ') + '.'
      );
    } else if (obj.type === 'apply-era-to-pli') {
      // console.log({ applyEraToPli: obj });
      const itemId = obj?.row?.id;
      return (
        [
          `If there is a history below for applying line-item #${itemId}`,
          'or it is the current line items,',
          'do not perform the "apply" action below as it has already been done.',
          'Applying it again will result in additional history entries',
        ].join(' ') + '.'
      );
    }
    return ChiroUpJSON.pretty(obj);
  },
  unpost: (obj: AdjustmentActionType) => {
    // console.log({ unpost: obj });
    const billedAmount = ChiroUpBaseCommon.format.asMoney(
        obj?.row?.billedAmount ?? 0,
      ),
      remitAmount = ChiroUpBaseCommon.format.asMoney(
        obj?.row?.remitAmount ?? 0,
      ),
      adjustmentTotal = ChiroUpBaseCommon.format.asMoney(
        obj?.row?.adjustmentTotal ?? 0,
      ),
      eraId = obj?.row?.eraId;
    return (
      [
        `Reverse posting`,
        `of ${remitAmount}`,
        `(${billedAmount} - ${adjustmentTotal})`,
        eraId ? `for ERA #${obj?.row?.eraId}` : 'for',
        `code ${obj?.row?.adjudicatedProcedureCode}`.replace(/ /g, '\u00A0'),
      ].join(' ') + '.'
    );
  },
  post: (obj: AdjustmentActionType) => {
    let [, ord] = (obj?.pk ?? '').split('.');
    let code = obj?.row?.adjudicatedServiceCode,
      prefix = 'Apply';

    if (!code) {
      code = `all services`;
      prefix = 'Post';
      ord = String(Number(ord) - 1);
    } else {
      code = `service ${code}`;
    }

    return (
      [
        prefix,
        `ERA #${obj?.row?.eraId}`,
        'line-item',
        `#${Number(ord) + 1}`,
        'for',
        code,
      ].join(' ') + '.'
    );
  },
  apply: (obj: AdjustmentActionType) => {
    let [, ord] = (obj?.pk ?? '').split('.');
    let code = obj?.row?.adjudicatedServiceCode;

    if (!code) {
      code = `all services`;
      ord = String(Number(ord) - 1);
    } else {
      code = `service ${code}`;
    }

    return (
      [
        'Apply',
        `ERA #${obj?.row?.eraId}`,
        'line-item',
        `#${Number(ord) + 1}`,
        'for',
        code,
      ].join(' ') + '.'
    );
  },
  rollback: (obj: AdjustmentActionType) => {
    // console.log({ rollback: obj });
    if (obj?.type === 'initial') {
      return (
        [
          `Rollback initial payment amount by`,
          `${ChiroUpBaseCommon.format.asMoney(obj?.row?.total ?? 0)}`,
        ].join(' ') + '.'
      );
    }
    return (
      [
        `Reverse the ${(obj?.row?.type ?? '').toLocaleLowerCase()}`,
        obj?.row?.eraId
          ? `payment from ERA #${obj?.row?.eraId}`
          : `patient payment`,
        'for',
        ChiroUpBaseCommon.format.asMoney(obj?.row?.amount ?? 0),
      ].join(' ') + '.'
    );
  },
};

type ActionCardProps = {
  row: any;
  ord: number;
  color?: string;
  callback: (args: AdjustmentActionType) => void;
  iconClasses?: string;
  userIconClasses?: string;
};

const ActionCard: React.FC<ActionCardProps> = ({
  row,
  color = 'bg-white',
  ord = 0,
  callback,
  iconClasses = 'w-5 h-5 mr-0.5',
  userIconClasses = 'w-6 h-6',
}) => {
  const icon = useMemo(() => {
    if (row.action === 'user') {
      return <CheckCircleIcon className={userIconClasses} />;
    }
    return <TrashIcon className={iconClasses} />;
  }, [iconClasses, row.action, userIconClasses]);

  const fn = useMemo(() => {
    return (
      ActionDescriptions[row.action] ||
      ((row: any) => {
        return ChiroUpJSON.pretty(row);
      })
    );
  }, [row]);

  const hasViableAction = useMemo(() => {
    return !(row.action === 'rollback' && row.type === 'initial');
  }, [row]);

  return (
    <div>
      <HashCardDisplay
        // hash={row}
        noHover={true}
        border=""
        containerClassName={`w-full`}
        bodyClassName="text-gray-800 overflow-hidden rounded-none"
        components={{
          action: (
            <div className="flex flex-row space-x-2 px-4 py-2 w-full">
              <div className={ClassNames.circle}>{ord + 1}</div>
              <div>{fn(row)}</div>
              {hasViableAction ? (
                <div
                  className={classNames(ClassNames.trash, 'cursor-pointer')}
                  onClick={() => {
                    // console.log({ remove: ord });
                    callback({
                      action: 'trash',
                      type: 'item',
                      pk: ord.toString(),
                      row,
                    });
                  }}
                >
                  {icon}
                </div>
              ) : null}
            </div>
          ),
          // type: <div>{row.type}</div>,
          // description: <div>{ActionDescriptions[row.action](row)}</div>,
          // row: <pre>{ChiroUpJSON.pretty(row)}</pre>,
        }}
      />
    </div>
  );
};

type CurrentCardProps = {
  rows: PayorLineItemType[];
  invoice: IntegrationInvoice;
  actionsByKey: STRING_NUMBER_HASH;
  callback: (args: AdjustmentActionType) => void;
  context: STRING_ANY_HASH;
};

const CurrentCard: React.FC<CurrentCardProps> = ({
  rows,
  invoice,
  actionsByKey,
  callback,
  context,
}) => {
  const eraId = useMemo(() => {
    return rows?.[0]?.eraId;
  }, [rows]);

  const myAction: AdjustmentActionType = useMemo(() => {
    return {
      action: 'unpost',
      type: 'item',
      pk: String(eraId ?? 'current'),
      row: {},
    };
  }, [eraId]);

  const myKey = useMemo(() => {
    return [myAction.action, myAction.type, myAction.pk].join('.');
  }, [myAction]);

  const isNotAdjudicated = useMemo(() => {
    return (rows?.[0]?.adjudicatedProcedureCode ?? ' ') === ' ';
  }, [rows]);

  const handledService = useMemo(() => {
    const handled: STRING_BOOLEAN_HASH = {},
      items = context?.adjustmentActions ?? [];
    items.forEach((item: AdjustmentActionType, idx: number) => {
      if (item && item?.row && item?.row?.serviceItems) {
        item.row.serviceItems.forEach((svc: any) => {
          const code = svc?.adjudicatedServiceCode ?? svc?.submittedServiceCode,
            svcKey = ['post', 'cre', code].join('.');
          handled[svcKey] = true;
        });
      } else if (item && item?.row && item?.row?.adjudicatedServiceCode) {
        const code = item?.row?.adjudicatedServiceCode,
          svcKey = ['post', 'cre', code].join('.');
        handled[svcKey] = true;
      }
    });
    // console.log({ handled });
    return handled;
  }, [context]);

  return (
    <div>
      <HashCardDisplay
        noHover={true}
        border=""
        containerClassName={`w-full`}
        bodyClassName="text-gray-800 overflow-hidden border-t border-gray-400 rounded-none"
        components={{
          services: (
            <div className="px-4">
              <table className="w-full my-4">
                <thead>
                  <tr className="text-sx text-gray-400">
                    <th className={ClassNames.table.firstTh}>Service</th>
                    <th className={ClassNames.table.th}>Units</th>
                    <th className={ClassNames.table.th}>Billed</th>
                    <th className={ClassNames.table.th}>Paid</th>
                    <th className={ClassNames.table.th}>Adj's</th>
                    <th className={classNames(ClassNames.table.th)}>Balance</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {rows?.map((item: PayorLineItemType, idx: number) => {
                    const adjTotal = item?.adjustments?.reduce(
                      (topAcc: Decimal, adj: any) => {
                        const sum = adj.items.reduce(
                          (acc: Decimal, item: any) => {
                            return acc.add(createDecimal(item.amount ?? 0));
                          },
                          createDecimal(0),
                        );
                        return topAcc.add(sum);
                      },
                      createDecimal(0),
                    );
                    const rowKey = [myKey, item.id].join('.');
                    const serviceKey = [
                      'post',
                      'cre',
                      item.adjudicatedProcedureCode ??
                        item?.submittedProcedureCode,
                    ].join('.');

                    return (
                      <tr key={`pli-${idx}`}>
                        <td className={classNames(ClassNames.table.firstCell)}>
                          {item.adjudicatedProcedureCode ??
                            item?.submittedProcedureCode}
                          {/* {serviceKey} */}
                        </td>
                        <td className={ClassNames.table.cell}>
                          {item.adjudicatedUnits ?? item?.submittedUnits}
                        </td>
                        <td className={ClassNames.table.cell}>
                          <Currency value={item.billedAmount} />
                        </td>
                        <td className={ClassNames.table.cell}>
                          <Currency value={item.remitAmount} />
                        </td>
                        <td className={ClassNames.table.cell}>
                          <Currency value={adjTotal?.toNumber()} />
                        </td>
                        <td className={classNames(ClassNames.table.cell)}>
                          <Currency
                            value={createDecimal(item.billedAmount ?? 0)
                              .minus(item.remitAmount ?? 0)
                              .minus(adjTotal ?? 0)
                              .toNumber()}
                          />
                        </td>
                        {handledService[serviceKey] ? (
                          <td className={ClassNames.td.xySize}>
                            <div
                              className="flex justify-end h-5"
                              title="The adjudication of this service will be replaced by the selected actions."
                            >
                              <CheckIcon className="w-5 h-5" />
                            </div>
                          </td>
                        ) : (
                          <td className={ClassNames.callback}>
                            <div className="flex justify-end">
                              {actionsByKey[rowKey] ? (
                                <div
                                  className={ClassNames.circle}
                                  onClick={() => {
                                    const ord = actionsByKey[rowKey] - 1;
                                    callback({
                                      action: 'trash',
                                      type: 'item',
                                      pk: ord.toString(),
                                      row: {},
                                    });
                                  }}
                                >
                                  {actionsByKey[rowKey]}
                                </div>
                              ) : isNotAdjudicated ? (
                                <TrivialTooltip
                                  text="This service has not been adjudicated."
                                  type="info"
                                />
                              ) : (
                                <div
                                  title="Unpost this ERA line item."
                                  onClick={() => {
                                    callback({
                                      ...myAction,
                                      row: { ...item, eraId, pk: rowKey },
                                      pk: [
                                        item.eraId ?? 'current',
                                        item.id,
                                      ].join('.'),
                                    });
                                  }}
                                >
                                  <DocumentMinusIcon className="w-5 h-5" />
                                </div>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ),
        }}
      />
    </div>
  );
};
type DisplayBooleanProps = {
  value: boolean;
};

const DisplayBoolean: React.FC<DisplayBooleanProps> = ({ value }) => {
  return (
    <div className="flex justify-end">
      {value ? <CheckIcon className="h-5 w-54 text-black" /> : null}
    </div>
  );
};

type EraCardProps = {
  context: any;
  actionsByKey: STRING_NUMBER_HASH;
  callback: (args: AdjustmentActionType) => void;
};

const EraCard: React.FC<EraCardProps> = ({
  context,
  actionsByKey,
  callback,
}) => {
  const [selectedClaimId, setSelectedClaimId] = useState<string | null>(null);
  const myAction: AdjustmentActionType = {
    action: 'era-to-cre',
    type: 'era',
    pk: 'era',
    row: {},
  };
  const myKey = [myAction.action, myAction.type].join('.');

  const showEras = useMemo(() => {
    const keys = Object.keys(context?.responsesWithoutServices ?? {});
    if (keys.length) {
      return keys.map((key) => {
        return context?.erasById?.[key] || {};
      });
    }
    return [];
  }, [context]);

  const claimIds = useMemo(() => {
    return ['-1'].concat(Object.keys(context?.creIdsWithoutServices ?? {}));
  }, [context]);

  if (!showEras.length) return null;
  return (
    <div>
      <HashCardDisplay
        // hash={row}
        noHover={true}
        border=""
        containerClassName={`w-full`}
        bodyClassName="text-gray-800 overflow-hidden border-gray-400 rounded-none"
        components={{
          sep: (
            <div
              className={classNames(
                ClassNames.adjustmentSeps,
                'border-t border-gray-400 rounded-none',
              )}
            >
              ERA{showEras?.length === 1 ? '' : 's'} for Claim Response
              {context.countOfResponsesWithoutServices === 1 ? '' : 's'} Without
              Services
            </div>
          ),
          // debug: <div>{ChiroUpJSON.pretty(showEras)}</div>,
          eras: showEras.map((era: AdjustmentEraType, idx: number) => {
            return (
              <div key={`era-${idx}`} className="px-4 border-t pt-4">
                <div className="grid grid-cols-6">
                  <div className={ClassNames.cre.firstIdentLabel}>ERA #</div>
                  <div
                    className={classNames(
                      ClassNames.cre.identLabel,
                      'col-span-5',
                    )}
                  >
                    Apply to Claim
                  </div>
                  <div>{era.id}</div>
                  <div
                    className={classNames(
                      ClassNames.cre.lastValue,
                      'col-span-5 flex flex-row space-x-2',
                    )}
                  >
                    {claimIds.length > 0
                      ? claimIds.map((id, idx) => {
                          if (id === '-1') {
                            return selectedClaimId === null ? null : (
                              <div
                                key={`era-card-id-${idx + 1}`}
                                onClick={() => {
                                  setSelectedClaimId(null);
                                }}
                              >
                                <ArrowPathIcon className="h-4 w-4 text-primary-400 hover:text-primary-600 cursor-pointer" />
                              </div>
                            );
                          }
                          return (
                            <div
                              key={`era-card-id-${idx + 1}`}
                              className={classNames(
                                selectedClaimId && id !== selectedClaimId
                                  ? 'text-gray-400 cursor-not-allowed'
                                  : 'text-primary-400',
                                selectedClaimId === id
                                  ? 'font-bold text-primary-600'
                                  : 'cursor-pointer',
                              )}
                              onClick={() => {
                                if (selectedClaimId) return;
                                setSelectedClaimId(id);
                              }}
                            >
                              {id}
                            </div>
                          );
                        })
                      : 'none'}
                  </div>
                </div>
                <table className="w-full my-4">
                  <thead>
                    <tr className="text-sx text-gray-400">
                      <th className={ClassNames.table.firstTh}>Service</th>
                      <th className={ClassNames.table.th}>Billed</th>
                      <th className={ClassNames.table.th}>Paid</th>
                      <th className={ClassNames.table.th}>Adj's</th>
                      <th className={classNames(ClassNames.table.th)}>
                        Balance
                      </th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {era?.services?.map(
                      (item: ClaimServiceItemType, ord: number) => {
                        const adjTotal = item?.adjustments?.reduce(
                          (topAcc: Decimal, adj: any) => {
                            const sum = adj.items.reduce(
                              (acc: Decimal, item: any) => {
                                return acc.add(createDecimal(item.amount ?? 0));
                              },
                              createDecimal(0),
                            );
                            return topAcc.add(sum);
                          },
                          createDecimal(0),
                        );
                        const rowKey = [
                          myKey,
                          era?.id,
                          selectedClaimId,
                          ord,
                        ].join('.');

                        return (
                          <tr key={`cre-service-${idx}-${ord}`}>
                            <td
                              className={classNames(ClassNames.table.firstCell)}
                            >
                              {item.adjudicatedServiceCode}
                            </td>

                            <td className={ClassNames.table.cell}>
                              <Currency
                                value={item.billedAmount}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={ClassNames.table.cell}>
                              <Currency
                                value={item.remitAmount}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={ClassNames.table.cell}>
                              <Currency
                                value={adjTotal?.toNumber()}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={classNames(ClassNames.table.cell)}>
                              <Currency
                                value={createDecimal(item.billedAmount)
                                  .minus(item.remitAmount ?? 0)
                                  .minus(adjTotal ?? 0)
                                  .toNumber()}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={ClassNames.callback}>
                              <div className="flex w-full justify-end">
                                {actionsByKey[rowKey] ? (
                                  <div className={ClassNames.circle}>
                                    {actionsByKey[rowKey]}
                                  </div>
                                ) : selectedClaimId ? (
                                  <div
                                    title="Post this ERA line item to the claim."
                                    onClick={() => {
                                      callback({
                                        ...myAction,
                                        row: {
                                          ...item,
                                          claimResponseEraId: selectedClaimId,
                                          eraId: era?.id,
                                          ordinalId: ord,
                                        },
                                        pk: [
                                          era?.id,
                                          selectedClaimId,
                                          ord,
                                        ].join('.'),
                                      });
                                    }}
                                  >
                                    <DocumentPlusIcon className="w-5 h-5" />
                                  </div>
                                ) : (
                                  <div className="w-5">&nbsp;</div>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </table>
              </div>
            );
          }),
        }}
      />
    </div>
  );
};

type TransactionItemsCardProps = {
  context: any;
  actionsByKey: STRING_NUMBER_HASH;
  callback: (args: AdjustmentActionType) => void;
};

const TransactionItemsCard: React.FC<TransactionItemsCardProps> = ({
  context,
  actionsByKey,
  callback,
}) => {
  const myAction: AdjustmentActionType = {
    action: 'remove-item',
    type: 'transaction',
    pk: '',
    row: {},
  };
  const myKey = [myAction.action, myAction.type].join('.');

  const showItems = useMemo(() => {
    return (context?.adjustItems ?? []).filter(
      (i: PatientTransactionItemType) => !isaServiceItem(i),
    );
  }, [context]);

  if (!showItems.length) return null;
  return (
    <div>
      <HashCardDisplay
        // hash={row}
        noHover={true}
        border=""
        containerClassName={`w-full`}
        bodyClassName="text-gray-800 overflow-hidden border-gray-400 rounded-none"
        components={{
          sep: (
            <div
              className={classNames(
                ClassNames.adjustmentSeps,
                'border-t border-gray-400 rounded-none',
              )}
            >
              Non-service Transaction Item{showItems?.length === 1 ? '' : 's'}
            </div>
          ),
          // debug: <div>{ChiroUpJSON.pretty(showItems)}</div>,
          services: (
            <div className="px-4 border-t border-gray-400">
              <table className="w-full my-4">
                <thead>
                  <tr className="text-sx text-gray-400">
                    <th className={ClassNames.table.firstTh}>&nbsp;</th>
                    <th className={ClassNames.table.firstTh}>Description</th>
                    <th className={ClassNames.table.th}>Units</th>
                    <th className={ClassNames.table.th}>Each</th>
                    <th className={ClassNames.table.th}>Amount</th>
                    <th className="w-2">&nbsp;</th>
                    <th className="w-4">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {showItems?.map(
                    (item: PatientTransactionItemType, idx: number) => {
                      const rowKey = [myKey, item?.id].join('.');
                      return (
                        <tr key={`cre-service-${idx}`}>
                          <td
                            className={classNames(ClassNames.table.firstCell)}
                          >
                            {item?.type === 'Debit'
                              ? '-'
                              : item?.type === 'Credit'
                                ? '+'
                                : '-'}
                          </td>
                          <td className={ClassNames.table.firstCell}>
                            {item.description ?? '-'}
                          </td>
                          {/* <td className={ClassNames.table.cell}>
                            {item.subtype ?? '-'}
                          </td> */}
                          <td className={ClassNames.table.cell}>
                            {item.units ?? '-'}
                          </td>
                          <td className={ClassNames.table.cell}>
                            <Currency
                              value={item?.amount ?? 0}
                              negativeClassName="text-red-500"
                            />
                          </td>

                          <td
                            className={classNames(
                              ClassNames.table.cell,
                              'text-right',
                            )}
                          >
                            <Currency
                              value={createDecimal(item?.amount ?? 0)
                                .times(item?.units ?? 1)
                                .toNumber()}
                              negativeClassName="text-red-500"
                            />
                          </td>
                          <td className="w-2">
                            <TrivialTooltip
                              text={`Posted ${dayjs(item?.createdAt).format(
                                ChiroUpDayJsCommon.format.isoAt,
                              )}`}
                              type="info"
                            />
                          </td>
                          <td className={classNames(ClassNames.callback)}>
                            <div className="flex justify-end">
                              {actionsByKey[rowKey] ? (
                                <div
                                  className={ClassNames.circle}
                                  onClick={() => {
                                    callback({
                                      action: 'trash',
                                      type: 'item',
                                      pk: String(actionsByKey[rowKey] - 1),
                                      row: item,
                                    });
                                  }}
                                >
                                  {actionsByKey[rowKey]}
                                </div>
                              ) : (
                                <div
                                  title="Delete this line item."
                                  onClick={() => {
                                    callback({
                                      ...myAction,
                                      row: {
                                        ...item,
                                        ordinalId: idx,
                                      },
                                      pk: String(item?.id),
                                    });
                                  }}
                                >
                                  <TrashIcon className="w-5 h-5" />
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default CommonInvoiceModal;
