import {
  Checkbox,
  DateTime,
  getNearest,
  getNearestTime,
  Input,
  Select,
  SelectOption,
} from '@chiroup/components';
import {
  NUMBER_ANY_HASH,
  SELECT_OPTION_TYPE,
} from '@chiroup/core/constants/globals';
import { ChiroUpDayJsCommon } from '@chiroup/core/constants/stringConstants';
import {
  Appointment,
  AppointmentStatuses,
} from '@chiroup/core/types/Appointment.type';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SelectedLocationFullType } from '../../contexts/me.context';
import ButtonGroup, { ButtonGroupType } from '../common/buttons/ButtonGroup';
import IconButton from '../common/IconButton';
import { useNavigate } from 'react-router-dom';

type Props = {
  ord: number;
  slot: Appointment;
  readonly?: boolean;
  options: { [key: string]: SELECT_OPTION_TYPE[] };
  location?: SelectedLocationFullType;
  context: NUMBER_ANY_HASH;
  disableButtonGroup?: boolean;
  setContext: Dispatch<SetStateAction<NUMBER_ANY_HASH>>;
  deleteMySlot: (ord: number, options: any) => void;
  updateMySlot: ({
    ord,
    key,
    value,
  }: {
    ord: number;
    key: string;
    value: any;
  }) => void;
  addSlotButton?: JSX.Element;
  patientId?: string;
  firstProviderSlotIndex?: { [key: string]: number };
  trackOptions: SelectOption[];
  onClose: () => void;
  setValue: Dispatch<SetStateAction<any>>;
  timezone: string;
};

const ScheduleAppointmentFormSlot: React.FC<Props> = ({
  ord,
  slot,
  readonly,
  options,
  location,
  context,
  disableButtonGroup = false,
  setContext,
  updateMySlot,
  addSlotButton,
  firstProviderSlotIndex,
  trackOptions,
  deleteMySlot,
  onClose,
  setValue,
  timezone,
}: Props) => {
  /* const trace = { ord, slot, readonly, location } */

  const [enableCustomDuration, setEnableCustomDuration] = useState(false),
    [localErrors, setLocalErrors] = useState('');

  // console.log({ slot });
  const startTimeDayJs = useMemo(() => {
    return dayjs(slot.startTime);
  }, [slot?.startTime]);
  const myRef = useRef(null);
  const navigate = useNavigate();

  const getButtons = useMemo(() => {
    const status = slot?.status;
    if (status !== AppointmentStatuses.CheckedIn) {
      return [];
    }
    const buttons: ButtonGroupType[] = [];

    buttons.push({
      label: 'Encounter',
      to: `/patients/${slot?.patientId}/encounters/${slot?.id}`,
    });

    const defaultQueryParams = [
      `patient=${slot?.patientId}`,
      `patientName=${encodeURIComponent(
        slot?.displayValues?.patientName ?? '- no name -',
      )}`,
      `clinician=${slot?.clinicianId}`,
      `treatment=${slot?.treatmentId}`,
      `open=add`,
    ];

    let appointmentLabel = 'Next Appointment';
    let nextEncounterDate: dayjs.Dayjs;
    if (
      slot?.displayValues?.clinicianName?.length &&
      slot?.displayValues?.nextRequestedEncounterDate
    ) {
      nextEncounterDate = dayjs(slot.displayValues.nextRequestedEncounterDate);
      defaultQueryParams.unshift(
        `endDate=${nextEncounterDate.format('YYYY-MM-DD')}`,
      );
      defaultQueryParams.unshift(
        `startDate=${nextEncounterDate.format('YYYY-MM-DD')}`,
      );

      appointmentLabel = `Schedule appointment on ${nextEncounterDate.format(
        'M/DD/YYYY',
      )}`;
    }
    const scheduleUrl = `/schedule?${defaultQueryParams.join('&')}`;
    buttons.push({
      label: appointmentLabel,
      onClick: () => {
        onClose();
        setValue({
          patientId: slot.patientId,
          treatmentId: Number(slot.treatmentId),
          clinicianId: slot.clinicianId,
          startTime: dayjs.tz(
            nextEncounterDate
              ? Number(nextEncounterDate)
              : nextEncounterDate
                ? getNearestTime(nextEncounterDate)
                : getNearest(timezone),
            timezone,
          ),
          duration: Number(slot.duration),
          slots: [slot],
        });

        navigate(scheduleUrl);
      },
    });
    return buttons;
  }, [onClose, setValue, navigate, timezone, slot]);

  useEffect(() => {
    setContext((prev) => ({
      ...prev,
      [ord]: {
        ...prev[ord],
        ref: myRef,
      },
    }));
  }, [ord, setContext]);
  const nav = useMemo(() => {
    if (!context) return [];
    return Object.entries(context)
      .map(([key, value], idx) =>
        Number(key) === ord ? (
          <li
            key={`nav-${ord}-${idx}`}
            className="font-sans font-black text-2xl flex justify-center cursor-auto pr-1"
          >
            {Number(key) + 1}
          </li>
        ) : (
          <li
            key={`nav-${ord}-${idx}`}
            className="flex w-3 h-3 font-sans text-xs font-semibold border text-gray-400 border-gray-400 p-3 rounded-full justify-center items-center hover:text-white hover:bg-primary-500 cursor-pointer"
            onClick={(e: any) => {
              console.log('onClick slot', key);
              e.stopPropagation();
              e.preventDefault();
              console.log({ context });
              context?.[Number(key)]?.ref?.current?.scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            {Number(key) + 1}
          </li>
        ),
      )
      .concat(
        addSlotButton ? (
          <li
            key={`add-slot-btn`}
            style={{ marginLeft: '-3px' }}
            className="pt-2"
          >
            {addSlotButton}
          </li>
        ) : (
          <li key={`add-slot-btn`}></li>
        ),
      );
  }, [addSlotButton, context, ord]);
  const readonlyInputClassName = 'bg-inherit border-gray-100';

  if (readonly) {
    return (
      <div id={`stop-${ord + 1}`} className="mt-4" ref={myRef}>
        <div className="flex flex-row space-x-4 mt-8">
          <div className="flex w-8 justify-end">
            <ul className="space-y-1">{nav.map((item) => item)}</ul>
          </div>
          <div
            className={classNames(
              readonlyInputClassName,
              'flex flex-col space-y-2 w-full border border-gray-300 px-4 pt-5 pb-5 relative rounded-lg shadow-inner',
            )}
          >
            <div
              className="w-24 z-0 bg-transparent rounded-lg absolute top-6 border border-gray-300 left-3"
              style={{ height: '5.5rem' }}
            >
              {' '}
            </div>
            <div>
              <Input
                name="clinicianId"
                label="Clinician"
                value={
                  (
                    options?.clinicians?.find(
                      (c) => c.value === slot.clinicianId,
                    ) ??
                    ({
                      value: -1,
                      text: `- ${slot?.clinicianId ?? 'primary'} -`,
                    } as any)
                  ).text
                }
                inputClassName={readonlyInputClassName}
                disabled={true}
                inline={true}
                inlineInputWidth="w-1/2"
                inlineLabelWidth="z-20 relative pl-2 w-1/2"
              />
            </div>
            <div>
              <Input
                name="roomId"
                label="Room"
                value={
                  (
                    options?.rooms?.find(
                      (c) => String(c.value) === String(slot.roomId),
                    ) ??
                    ({
                      value: -1,
                      text: `- none -`,
                    } as any)
                  ).text
                }
                inputClassName={readonlyInputClassName}
                disabled={true}
                inline={true}
                inlineInputWidth="w-1/2"
                inlineLabelWidth="pl-2 w-1/2"
              />
            </div>
            {firstProviderSlotIndex &&
              firstProviderSlotIndex[slot.clinicianId] === ord && (
                <div>
                  <Input
                    name="trackId"
                    label="Track"
                    value={
                      slot?.createTrackCCSSubmission
                        ? 'Created on CCS submission'
                        : (
                            trackOptions?.find(
                              (c: any) =>
                                String(c.value) === String(slot.trackId),
                            ) ??
                            ({
                              value: -1,
                              text: `- none -`,
                            } as any)
                          ).text
                    }
                    inputClassName={readonlyInputClassName}
                    disabled={true}
                    inline={true}
                    inlineInputWidth="w-1/2"
                    inlineLabelWidth="pl-2 w-1/2"
                  />
                </div>
              )}

            <Input
              name="treatmentId"
              label="Treatment"
              value={
                (
                  options?.treatments?.find(
                    (c) => Number(c.value) === slot.treatmentId,
                  ) ?? ({ value: -1, text: `- ${slot.treatmentId} -` } as any)
                ).text
              }
              inputClassName={readonlyInputClassName}
              disabled={true}
              inline={true}
              inlineInputWidth="w-1/2"
              inlineLabelWidth="pl-2 w-1/2"
            />
            <Input // Display the start time
              name="startTime"
              label="Start and Duration"
              value={`${startTimeDayJs
                .tz(location?.timezone)
                .format(ChiroUpDayJsCommon.format.time)} for ${
                slot.duration
              } minutes`}
              inputClassName={readonlyInputClassName}
              disabled={true}
              inline={true}
              inlineInputWidth="w-1/2"
              inlineLabelWidth="pl-2 w-1/2"
            />
            {slot.clinicianId && (
              <ButtonGroup
                buttons={getButtons}
                disabled={disableButtonGroup}
                isEmptyOkay={true}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id={`stop-${ord + 1}`} className="pt-3 relative" ref={myRef}>
      <div className="flex flex-row space-x-4">
        <div className="flex w-10 justify-center">
          <ul className="space-y-1">{nav.map((item) => item)}</ul>
        </div>
        <div className="flex flex-col space-y-2 w-full bg-white border py-4 px-6 relative rounded-lg shadow-inner">
          <div
            className="w-24 z-0 bg-gray-50 rounded-lg absolute top-6 border border-gray-200 left-3"
            style={{ height: '5.5rem' }}
          >
            {' '}
          </div>
          <Select
            name="clinicianId"
            label="Clinician"
            value={slot.clinicianId}
            onChange={(val) => {
              updateMySlot({ ord, key: 'clinicianId', value: val });
            }}
            options={options.clinicians}
            limit={1}
            disabled={readonly}
            tooltip={
              readonly
                ? 'Provider cannot be changed after the note is signed'
                : ''
            }
            inlineSelect={true}
            inlineLabelWidth="w-1/4 z-20 relative"
            inlineInputWidth="w-3/4"
          />
          <Select
            name="roomId"
            className="col-span-2"
            label="Room"
            value={slot.roomId}
            onChange={(val: any) => {
              updateMySlot({ ord, key: 'roomId', value: val });
            }}
            options={options.rooms}
            disabled={readonly}
            limit={1}
            inlineSelect={true}
            inlineLabelWidth="w-1/4 z-20 relative"
            inlineInputWidth="w-3/4"
          />
          {firstProviderSlotIndex &&
            firstProviderSlotIndex[slot.clinicianId] === ord &&
            !!trackOptions?.length && (
              <>
                <Select
                  name="trackId"
                  label="Track"
                  value={slot.trackId}
                  onChange={(val) => {
                    updateMySlot({ ord, key: 'trackId', value: val });
                  }}
                  options={trackOptions}
                  limit={1}
                  disabled={
                    readonly ||
                    !trackOptions.length ||
                    slot?.createTrackCCSSubmission
                  }
                  tooltip={
                    readonly
                      ? 'Tracks cannot be changed after the note is signed'
                      : ''
                  }
                  inlineSelect={true}
                  inlineLabelWidth="w-1/4 z-20 relative"
                  inlineInputWidth="w-3/4"
                />

                <Checkbox
                  name="createTrackCCSSubmission"
                  label="Create track on CCS submission"
                  value={!!slot?.createTrackCCSSubmission}
                  onChange={(val) => {
                    updateMySlot({
                      ord,
                      key: 'createTrackCCSSubmission',
                      value: val,
                    });
                  }}
                />
              </>
            )}
          <Select
            name="treatmentId"
            label="Treatment"
            value={slot.treatmentId}
            onChange={(val) => {
              updateMySlot({ ord, key: 'treatmentId', value: val });
            }}
            options={options.treatments}
            limit={1}
            inlineSelect={true}
            inlineLabelWidth="w-1/4"
            inlineInputWidth="w-3/4"
          />

          <DateTime
            label="Date *"
            timeLabel="Time"
            value={startTimeDayJs}
            onChange={(val) => {
              // console.log('onChange dateTime', val.toDate());
              updateMySlot({ ord, key: 'startTime', value: val?.valueOf() });
            }}
            timezone={location?.timezone ?? ChiroUpDayJsCommon.defaultTimezone}
            localErrors={localErrors}
            setLocalErrors={setLocalErrors}
            // startTimeInterval={startTimeInterval}
            omitDate={true}
            timeContainerClassName="flex flex-row w-full"
            labelClassName="mb-1 pt-2.5 text-sm font-medium leading-5 text-gray-900 sm:mt-px dark:text-darkGray-200 flex flex-row space-x-2 w-1/4"
            inputContainerClassName="w-3/4"
          />
          {!enableCustomDuration ? (
            <Select
              name="duration"
              label="Duration"
              value={slot.duration}
              onChange={(val) => {
                // console.log('onChange durationSelect', val);
                updateMySlot({ ord, key: 'duration', value: val });
              }}
              options={options.durations}
              limit={1}
              className="min-w-1/3 w-full"
              labelClassName="mb-1 block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2 dark:text-darkGray-200"
              inlineSelect={true}
              inlineLabelWidth="w-1/4"
              inlineInputWidth="w-3/4"
              hint="In minutes."
            />
          ) : (
            <Input
              name="duration"
              label="Duration"
              hint="In minutes."
              value={slot.duration}
              onChange={(val) => {
                updateMySlot({ ord, key: 'duration', value: val });
                // console.log('onChange durationInput', val);
              }}
              className="w-full flex flex-row"
              //   labelClassName="whitespace-nowrap"
              type="number"
              min="0"
              inline={true}
              inlineLabelWidth="w-1/4"
              inlineInputWidth="w-3/4"
            />
          )}
          <CustomDurationButton
            value={enableCustomDuration}
            toggle={setEnableCustomDuration}
          />
          {/* The length is the # of time slots plus the button to add a new slot.  */}
          {nav.length !== 2 ? (
            <div className="flex justify-end cursor-pointer text-primary-500 absolute -top-8 right-0">
              <IconButton
                onClick={(e) => {
                  e?.stopPropagation?.();
                  deleteMySlot(ord, options);
                }}
                tooltip={`Delete.`}
                className="text-primary-500 w-5 h-5"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                }
              />
            </div>
          ) : null}
        </div>
      </div>
      {/* <pre>{ChiroUpJSON.pretty({ options: options.clinicians })}</pre> */}
    </div>
  );
};

type ToggleSubComponentProps = {
  value: boolean;
  toggle: Dispatch<SetStateAction<boolean>>;
};
const CustomDurationButton: React.FC<ToggleSubComponentProps> = ({
  value,
  toggle,
}) => {
  return (
    <div
      className="absolute bottom-4"
      onClick={() => {
        toggle((prev) => !prev);
      }}
    >
      <button className="px-2 shadow-md no-underline rounded-full bg-primary-500 text-white font-sans text-xs border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none">
        {!value ? `Custom` : `Preset`}
      </button>
    </div>
  );
};

export default ScheduleAppointmentFormSlot;
