import React from 'react';
import CodeEncounterDiagnoses from './CodeEncounterDiagnoses';
import { DndServiceDiagnosisType } from './CodeEncounterDiagnosisServices';
import { PatientTransactionItemDiagnosisType } from '@chiroup/core/types/PatientTransaction.type';

type Props = {
  omitDiagnosisHeader?: boolean;
  listofDiagnoses?: DndServiceDiagnosisType[];
  addDiagnosisToAllServices?: (
    e: React.MouseEvent<HTMLDivElement>,
    diagnosis: PatientTransactionItemDiagnosisType,
  ) => void;
  isBillingStarted?: boolean;
  readonly?: boolean;
  locked?: boolean;
  importOnlyMode?: boolean;
  componentClassName?: string;
  labelClassName?: string;
};

const CodeEncounterDiagnosisServicesList: React.FC<Props> = ({
  omitDiagnosisHeader,
  listofDiagnoses,
  readonly,
  addDiagnosisToAllServices,
  isBillingStarted,
  locked,
  importOnlyMode = false,
  componentClassName = 'w-full',
  labelClassName = [
    'mb-2.5 relative block text-lg',
    isBillingStarted ? 'text-accent-600' : 'text-primary-600',
    'font-medium leading-5 sm:mt-px sm:pt-2 mt-4',
  ].join(' '),
}) => {
  if (importOnlyMode) return null;

  return (
    <div className={componentClassName}>
      {!omitDiagnosisHeader && <div className={labelClassName}>Diagnoses</div>}
      {listofDiagnoses && listofDiagnoses.length > 0 ? (
        <div className="rounded-md bg-white dark:bg-darkGray-800 border border-gray-300 dark:border-darkGray-600 mb-3">
          <CodeEncounterDiagnoses
            diagnoses={listofDiagnoses}
            onAddAllClicked={addDiagnosisToAllServices}
            readonly={locked || readonly}
            isBillingStarted={isBillingStarted}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CodeEncounterDiagnosisServicesList;

/**
 *         <div className="text-sm text-gray-400 ml-2">
          <cite>
            No diagnoses have been specified.{' '}
            <ol className="ml-2">
              <li>1. Add one or more services.</li>
              <li>2. Add one or more diagnoses to a service.</li>
              <li>
                3. Drag a diagnosis to service <cite>or</cite> copy the
                diagnosis to all services.
              </li>
              <li>
                4. Drag a diagnosis to sort in priority order (primary,
                secondary,...).
              </li>
              <li>5. Drag services to sort.</li>
            </ol>
          </cite>
        </div>
 */
