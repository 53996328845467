import { Checkbox, Currency, Input } from '@chiroup/components';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import {
  PatientInvoiceLineItem,
  PatientInvoice,
} from '@chiroup/core/types/PatientInvoice.type';

type InvoiceTableRowProps = {
  item: PatientInvoiceLineItem;
  setInvoiceData: React.Dispatch<
    React.SetStateAction<PatientInvoice | undefined>
  >;
  index: number;
};

const getAmount = (amount: number, units: number, credit = false) => {
  return createDecimal(amount || 0)
    .times(credit ? (units || 1) * -1 : units || 1)
    .toNumber()
    .toFixed(2);
};

const InvoiceLineItemTableRow: React.FC<InvoiceTableRowProps> = ({
  item,
  setInvoiceData,
  index,
}) => {
  const updateInvoiceData = (key: keyof PatientInvoiceLineItem, value: any) => {
    setInvoiceData((prev) => {
      if (!prev) return undefined; // or return a default PatientInvoice object

      const newItems = prev.invoiceBreakdown?.lineItems?.map((lineItem, idx) =>
        idx === index ? { ...lineItem, [key]: value } : lineItem,
      );

      return {
        ...prev,
        invoiceBreakdown: {
          ...prev.invoiceBreakdown,
          lineItems: newItems,
        },
        issuedDate: prev.issuedDate,
      };
    });
  };

  return (
    <>
      <tr className="text-xs odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
        <td className="whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
          <Input
            name="description"
            value={item.description}
            onChange={(e) => updateInvoiceData('description', e)}
          />
        </td>
        <td>
          <Input
            name="type"
            value={item.detail}
            onChange={(e) => updateInvoiceData('detail', e)}
          />
        </td>
        {(typeof item?.amount === 'number' ||
          typeof item?.amount === 'string') && (
          <td className="whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 text-right">
            <Currency
              value={getAmount(item.amount, item.units ?? 1, item.credit)}
            />
          </td>
        )}
      </tr>
      <Checkbox
        label="Hidden"
        className="p-2"
        value={item.hidden}
        onChange={(e) => updateInvoiceData('hidden', e)}
      />
    </>
  );
};

export default InvoiceLineItemTableRow;
