import {
  PatientTransaction,
  TransactionTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { LockButtonStateType } from './ConsolidatedTransactionModal';

type LockButtonProps = {
  isBillingStarted: boolean | undefined;
  state: LockButtonStateType | null | undefined;
  transaction: PatientTransaction | null | undefined;
  readonly: boolean;
  onClick?: (e: any) => void;
  componentClassName?: string;
  iconContainerClassName?: string;
};
const LockButtonContent: React.FC<LockButtonProps> = ({
  isBillingStarted,
  state,
  transaction,
  readonly,
  onClick = (e: any) => {
    e?.stopPropagation?.();
    e?.preventDefault?.();
    console.log('TODO: lock/unlock');
  },
  componentClassName = [
    'flex-row',
    'flex',
    'font-bold',
    'font-sans',
    'justify-end',
    'mb-4',
    'pl-1',
    'p-1',
    'rounded-md',
    'space-x-1',
    'text-sm',
  ].join(' '),
  iconContainerClassName = 'flex items-center space-x-1',
}: LockButtonProps) => {
  const iconClassName = 'h-4 w-4';
  if (!state) return null;
  if (
    transaction?.type !== TransactionTypeEnum.Encounter &&
    transaction?.type !== TransactionTypeEnum.Hybrid
  ) {
    return null;
  }

  return (
    <div
      className={classNames(
        componentClassName,
        readonly
          ? 'text-gray-400'
          : isBillingStarted
            ? 'text-accent-400 cursor-pointer hover:text-accent-600'
            : 'text-primary-400 cursor-pointer hover:text-primary-600',
      )}
      onClick={readonly ? undefined : onClick}
    >
      {' '}
      {readonly && state.locked ? (
        <div className={iconContainerClassName}>
          <LockClosedIcon className={iconClassName} />
          <span>Locked</span>
        </div>
      ) : readonly && !state.locked ? (
        <div className={iconContainerClassName}>
          <LockOpenIcon className={iconClassName} />
          <span>Unlocked</span>
        </div>
      ) : !readonly && state.locked ? (
        <div className={iconContainerClassName}>
          <LockOpenIcon className={iconClassName} />
          <span>Unlock</span>
        </div>
      ) : !readonly && !state.locked ? (
        <div className={iconContainerClassName}>
          <LockClosedIcon className={iconClassName} />
          <span>Lock</span>
        </div>
      ) : (
        '... not sure how this happened ...'
      )}
    </div>
  );
};

export default LockButtonContent;
