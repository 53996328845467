import { MeContext } from '../../../../../contexts/me.context';
import { useContext } from 'react';
import useInsuranceCard from '../hooks/useInsuranceCard';
import downloadService from '../../../../../services/download.service';
import { Loading } from '@chiroup/components';
import Modal from '../../../../../components/common/Modal';
import { Patient } from '@chiroup/core/types/Patient.type';
import { Insurance } from '@chiroup/core/types/PatientInsurance.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { ValueOf } from '@chiroup/core/types/ValueOf.type';
import classNames from 'classnames';

type Props = {
  patient: Partial<Patient>;
  value: Partial<Insurance>;
  onChange: (
    key: keyof Partial<Insurance>,
  ) => (val: ValueOf<Insurance>) => void;
};

const PatientInsuranceCard: React.FC<Props> = ({
  patient,
  value,
  onChange,
}) => {
  const { me, hasRole } = useContext(MeContext);
  const {
    fileOpened,
    loadingFile,
    uploadInsuranceCard,
    openFile,
    setFileOpened,
  } = useInsuranceCard(patient, value, onChange);
  return (
    <>
      <div className="mt-6 sm:col-span-2">
        <dt className="text-sm font-medium leading-5 text-gray-500 dark:text-darkGray-400">
          Card on File
        </dt>
        <dd className="mt-1 text-sm leading-5 text-gray-900 dark:text-darkGray-100">
          <ul className="border border-gray-300 rounded-md dark:border-darkGray-600">
            <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm leading-5 border-t border-gray-300 dark:border-darkGray-600">
              <div className="flex items-center flex-1 w-0">
                <svg
                  className={`flex-shrink-0 h-5 w-5 ${
                    value.insuranceFront ? 'text-primary-500' : 'text-gray-400'
                  }`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="flex-1 w-0 ml-2 truncate">Front</span>
              </div>
              <div className="flex-shrink-0 ml-4">
                {value.insuranceFront ? (
                  <>
                    {hasRole([
                      UserRoles.Admin,
                      UserRoles.Biller,
                      UserRoles.Staff,
                    ]) && (
                      <span className="mr-2 font-medium transition duration-150 ease-in-out cursor-pointer text-primary-600 hover:text-primary-400">
                        <label
                          htmlFor="replaceInsuranceCard"
                          className="cursor-pointer"
                        >
                          Replace{' '}
                          <input
                            id="replaceInsuranceCard"
                            type="file"
                            onChange={(e) => uploadInsuranceCard(e, 'front')}
                            className="hidden"
                          />
                        </label>
                      </span>
                    )}
                    <span
                      onClick={() => {
                        openFile(value.insuranceFront);
                      }}
                      className="mr-4 font-medium transition duration-150 ease-in-out cursor-pointer text-primary-600 hover:text-primary-400"
                    >
                      Open
                    </span>
                    <span
                      onClick={() =>
                        downloadService.download(
                          'insurance-card',
                          me.selectedClinic?.ID,
                          value.insuranceFront,
                          patient.lname,
                          patient.fname,
                        )
                      }
                      className="font-medium transition duration-150 ease-in-out cursor-pointer text-primary-600 hover:text-primary-400"
                    >
                      Download
                    </span>
                  </>
                ) : hasRole([
                    UserRoles.Admin,
                    UserRoles.Biller,
                    UserRoles.Staff,
                  ]) ? (
                  <span className="font-medium transition duration-150 ease-in-out cursor-pointer text-primary-600 hover:text-primary-400">
                    <label
                      htmlFor="uploadInsuranceCard"
                      className="cursor-pointer"
                    >
                      Upload{' '}
                      <p className="mt-1 text-xs text-gray-500">
                        PNG, JPG, GIF, PDF up to 10MB
                      </p>
                      <input
                        id="uploadInsuranceCard"
                        type="file"
                        onChange={(e) => uploadInsuranceCard(e, 'front')}
                        className="hidden"
                      />
                    </label>
                  </span>
                ) : (
                  'Image not available'
                )}
              </div>
            </li>
            <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm leading-5 border-t border-gray-300 dark:border-darkGray-600">
              <div className="flex items-center flex-1 w-0">
                <svg
                  className={`flex-shrink-0 h-5 w-5 ${
                    value.insuranceBack ? 'text-primary-500' : 'text-gray-400'
                  }`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="flex-1 w-0 ml-2 truncate">Back</span>
              </div>
              <div className="flex-shrink-0 ml-4">
                {value.insuranceBack ? (
                  <>
                    {hasRole([
                      UserRoles.Admin,
                      UserRoles.Biller,
                      UserRoles.Staff,
                    ]) && (
                      <span className="mr-2 font-medium transition duration-150 ease-in-out cursor-pointer text-primary-600 hover:text-primary-400">
                        <label
                          htmlFor="replaceInsuranceCardBack"
                          className="cursor-pointer"
                        >
                          Replace{' '}
                          <input
                            id="replaceInsuranceCardBack"
                            type="file"
                            onChange={(e) => uploadInsuranceCard(e, 'back')}
                            className="hidden"
                          />
                        </label>
                      </span>
                    )}
                    <span
                      onClick={() => {
                        openFile(value.insuranceBack);
                      }}
                      className="mr-4 font-medium transition duration-150 ease-in-out cursor-pointer text-primary-600 hover:text-primary-400"
                    >
                      Open
                      <p className="mt-1 text-xs text-gray-500">
                        PNG, JPG, GIF, PDF up to 10MB
                      </p>
                    </span>
                    <span
                      onClick={() =>
                        downloadService.download(
                          'insurance-card',
                          me.selectedClinic?.ID,
                          value.insuranceBack,
                          patient.lname,
                          patient.fname,
                        )
                      }
                      className="font-medium transition duration-150 ease-in-out cursor-pointer text-primary-600 hover:text-primary-400"
                    >
                      Download
                    </span>
                  </>
                ) : hasRole([
                    UserRoles.Admin,
                    UserRoles.Biller,
                    UserRoles.Staff,
                  ]) ? (
                  <span className="font-medium transition duration-150 ease-in-out cursor-pointer text-primary-600 hover:text-primary-400">
                    <label
                      htmlFor="uploadInsuranceCardBack"
                      className="cursor-pointer"
                    >
                      Upload{' '}
                      <p className="mt-1 text-xs text-gray-500">
                        PNG, JPG, GIF, PDF up to 10MB
                      </p>
                      <input
                        id="uploadInsuranceCardBack"
                        type="file"
                        onChange={(e) => uploadInsuranceCard(e, 'back')}
                        className="hidden"
                      />
                    </label>
                  </span>
                ) : (
                  'Image not available'
                )}
              </div>
            </li>
          </ul>
        </dd>
      </div>
      <Modal
        isOpen={loadingFile || !!fileOpened}
        close={() => setFileOpened('')}
        addClasses={classNames(
          fileOpened?.includes('.pdf') ? 'min-h-[80vh] min-w-[50vw]' : '',
        )}
      >
        {loadingFile ? (
          <div className="flex flex-row justify-center w-full">
            <Loading className="w-5 h-5 text-gray-500" />
          </div>
        ) : fileOpened?.includes('.pdf') ? (
          <iframe
            src={fileOpened}
            className={classNames(
              'w-full h-full',
              fileOpened?.includes('.pdf') ? 'min-h-[75vh] min-w-[45vw]' : '',
            )}
            title="Patient file"
          />
        ) : (
          <img
            className="w-full h-auto"
            src={fileOpened || ''}
            alt=""
            crossOrigin="anonymous"
          />
        )}
      </Modal>
    </>
  );
};

export default PatientInsuranceCard;
