import {
  Button,
  ButtonColors,
  Checkbox,
  Input,
  InputMasked,
  Select,
} from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import { ArrowUpCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import {
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import Modal from '../../../../../components/common/Modal';
import useEpayCustomer from '../../../../../components/patient-billing/hooks/useEpayCustomer';
import { MeContext } from '../../../../../contexts/me.context';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import patientBillingService from '../../../../../services/patientBilling.service';
import useTerminalTransactionWatcher from '../hooks/useTerminalTransactionWatcher';
import CreditCardDeviceSelector from './CreditCardDeviceSelector';
import useBillingSettings from '../../../../settings/clinic/useBillingSettings';
import { useNavigate } from 'react-router-dom';
import { EPayStatus } from '@chiroup/core/enums/EPayStatus.enum';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import { titleCaseEnumToArrayOfOptions } from '@chiroup/core/functions/enumToArrayOfOptions';
import { formatCurrency } from '@chiroup/core/functions/format';
import {
  PaymentMethodType,
  CCPaymentFields,
} from '@chiroup/core/types/PatientBillling.type';
import {
  PatientPayment,
  PaymentType,
  PaymentTypePatient,
} from '@chiroup/core/types/PatientPayment.type';
import { PatientTransaction } from '@chiroup/core/types/PatientTransaction.type';
import { UserRoles } from '@chiroup/core/types/User.type';

export type PayRequest = {
  payRequestKey: string | null;
  complete: boolean;
  status: string | null;
  refnum?: string | null;
  error?: string | null;
  result?: string | null;
};

type Props = {
  payment: PatientPayment;
  transaction: PatientTransaction;
  isOpen: boolean;
  close: () => void;
  save: (val: Partial<PatientPayment>) => Promise<PatientPayment>;
  patientId: string;
  selectedPaymentCard: PaymentMethodType | null;
  setSelectedPaymentCard: (paymentMethod: PaymentMethodType | null) => void;
  credits?: boolean;
  clear: () => void;
  refetchList: () => void;
  balances:
    | {
        balance: number;
        creditBalance: number;
      }
    | undefined;
};

const TransactionPaymentModal: React.FC<Props> = ({
  payment,
  transaction,
  save,
  isOpen,
  close,
  patientId,
  selectedPaymentCard,
  setSelectedPaymentCard,
  clear,
  balances,
  refetchList,
}) => {
  const myId = useId();
  const navigate = useNavigate();
  const { me, hasRole } = useContext(MeContext);
  const { createToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { data: billingSettings } = useBillingSettings();
  const [applyCredits, setApplyCredits] = useState(false);
  const [ccError, setCCError] = useState<string | null>(null);
  const [waitingForTerminal, setWaitingForTerminal] = useState<boolean>(false);

  useEffect(() => {
    if (selectedPaymentCard) {
      setCCError(null);
    }
  }, [selectedPaymentCard]);

  const { terminalTransaction, setTerminalTransaction } =
    useTerminalTransactionWatcher();

  const processingFee = useMemo(() => {
    return (
      me?.selectedClinic?.settings?.find((s) => s.setting === 'General')
        ?.jsonValue?.ccFee / 100
    );
  }, [me?.selectedClinic?.settings]);

  const {
    value,
    onChange,
    errors,
    registerSubmit,
    isSubmitting,
    isDirty,
    patchValue,
  } = useForm<PatientPayment & CCPaymentFields>(
    {
      type: PaymentType.Cash,
      paymentDate: dayjs().format('MM/DD/YYYY'),
      transactionPayments: [],
      patientId,
    },
    {
      paymentDate: {
        required: {
          message: 'Payment date is required.',
        },
      },
      type: {
        function: {
          value: (value: Partial<PatientPayment>) => {
            const amount = Number(value.amount || 0);
            if (!!amount && !value.type) {
              return 'Payment Type is required.';
            }
            if (
              value.type === PaymentType.CreditCard &&
              value.deviceKey === 'manual'
            ) {
              const pickedSavedCard = !!value?.selectedPaymentCard?.key;
              if (pickedSavedCard) {
                return false;
              }
              if (!value?.epayPublicKey) {
                return 'Please select a payment method.';
              }
              // const ccNum = document.getElementById(
              //   'payjs-cnum',
              // ) as HTMLInputElement;
              // if (!ccNum?.value) {
              //   return 'Card number is required.';
              // }
              // const ccExp = document.getElementById(
              //   'payjs-exp',
              // ) as HTMLInputElement;
              // if (!ccExp?.value) {
              //   return 'Expiration date is required.';
              // }
              // const ccCvv = document.getElementById(
              //   'payjs-cvv',
              // ) as HTMLInputElement;
              // if (!ccCvv?.value) {
              //   return 'CVV is required.';
              // }
            }
            return false;
          },
        },
      },
      referenceNumber: {
        function: {
          value: (value: Partial<PatientPayment>) => {
            if (
              value?.type === 'check' &&
              value?.referenceNumber &&
              !value?.referenceNumber.toString().length
            ) {
              return 'Reference number is required.';
            }
            return false;
          },
        },
      },
      amount: {
        function: {
          value: (value: Partial<PatientPayment>) => {
            const creditAmount = createDecimal(value.creditAmount || 0).toDP(2);
            const amount = createDecimal(value.amount || 0).toDP(2);
            if (creditAmount && creditAmount.greaterThan(0)) {
              // If we're applying credits, a payment amount isn't required.
              return false;
            }
            if (!amount || amount.lessThanOrEqualTo(0)) {
              return 'Amount is required.';
            }
            if (amount && amount.lessThanOrEqualTo(0)) {
              return 'Amount must be greater than 0.';
            }
            return false;
          },
        },
      },
      creditAmount: {
        function: {
          value: (value: Partial<PatientPayment>) => {
            const creditAmount = createDecimal(value.creditAmount || 0).toDP(2);
            const amount = createDecimal(value.amount || 0).toDP(2);
            const patientBalance = createDecimal(
              Number(transaction?.patientBalance) || 0,
            ).toDP(2);

            if (!value.creditAmount || creditAmount.lessThanOrEqualTo(0)) {
              return false;
            }
            if (creditAmount && creditAmount.lessThanOrEqualTo(0)) {
              return 'Credit amount must be greater than 0.';
            }
            if (creditAmount.greaterThan(balances?.creditBalance || 0)) {
              return 'Credit amount cannot exceed available credit.';
            }
            if (creditAmount.add(amount).greaterThan(patientBalance)) {
              return 'Credit amount cannot exceed total due.';
            }
            return false;
          },
        },
      },
      fname: {
        function: {
          value: (value: Partial<PatientPayment & CCPaymentFields>) => {
            if (value.deviceKey) return false;
            if (value.type === PaymentType.CreditCard && !value.fname) {
              return 'First name is required.';
            }
            return false;
          },
        },
      },
      lname: {
        function: {
          value: (value: Partial<PatientPayment & CCPaymentFields>) => {
            if (value.deviceKey) return false;
            if (value.type === PaymentType.CreditCard && !value.lname) {
              return 'Last name is required.';
            }
            return false;
          },
        },
      },
      postalCode: {
        function: {
          value: (value: Partial<PatientPayment & CCPaymentFields>) => {
            if (value.deviceKey) return false;
            if (value.type === PaymentType.CreditCard && !value.postalCode) {
              return 'Postal code is required.';
            }
            return false;
          },
        },
      },
      streetAddress: {
        function: {
          value: (value: Partial<PatientPayment & CCPaymentFields>) => {
            if (value.deviceKey) return false;
            if (value.type === PaymentType.CreditCard && !value.streetAddress) {
              return 'Street address is required.';
            }
            return false;
          },
        },
      },
    },
  );

  const {
    epayCustomer,
    isFetching: isFetchingEpayCustomer,
    refetch: refetchEpayCustomer,
  } = useEpayCustomer({ patientId, merchantId: value?.merchantId });

  const hideCreditTransfer = useMemo(() => {
    return billingSettings?.enableCreditTransfers !== true;
  }, [billingSettings]);

  const filteredPaymentTypes = useMemo(() => {
    return titleCaseEnumToArrayOfOptions(PaymentTypePatient).filter((o) => {
      if (hideCreditTransfer) {
        return o.value !== PaymentType.CreditTransfer;
      }
      return true;
    });
  }, [hideCreditTransfer]);

  useEffect(() => {
    patchValue({
      selectedPaymentCard,
    });
  }, [selectedPaymentCard, patchValue]);

  useEffect(() => {
    if (
      !!value.amount &&
      (value.type === PaymentType.CreditCard ||
        value.type === PaymentType.CreditCardExternal) &&
      processingFee > 0
    ) {
      patchValue({
        processingFee: Number(
          createDecimal(processingFee)
            .times(createDecimal(Number(value.amount)))
            .toFixed(2),
        ),
      });
    } else {
      patchValue({
        processingFee: 0,
      });
    }
  }, [processingFee, patchValue, value.amount, value.type]);

  useEffect(() => {
    if (epayCustomer?.custid) {
      patchValue({
        fname: epayCustomer?.first_name || undefined,
        lname: epayCustomer?.last_name || undefined,
        postalCode: epayCustomer?.postalcode || undefined,
        streetAddress: epayCustomer?.street || undefined,
      });
    }
  }, [epayCustomer, patchValue]);

  const reset = useCallback(() => {
    setWaitingForTerminal(false);
    setTerminalTransaction(null);
  }, [setTerminalTransaction]);

  const closeModal = useCallback(
    (force = false) => {
      if ((isSubmitting || waitingForTerminal) && !force) {
        return;
      }
      reset();
      patchValue({
        amount: 0,
        description: '',
        type: PaymentType.Cash,
        referenceNumber: undefined,
        fname: undefined,
        lname: undefined,
        postalCode: undefined,
        streetAddress: undefined,
        saveCard: false,
        deviceKey: undefined,
        creditAmount: undefined,
        processingFee: 0,
      });
      close();
    },
    [close, isSubmitting, patchValue, reset, waitingForTerminal],
  );

  const confirmSubmit = useCallback(
    async ({
      val,
      refnum,
      batchKey,
    }: {
      val: Partial<PatientPayment>;
      refnum?: string;
      batchKey?: string;
    }) => {
      /**
       * Okay, since this modal has two halves, we  need to grab the
       * pieces from the SECOND half and append them to the value so
       * the form can submit...I think.
       */

      const res = await save({
        ...val,
        transactionPayments: [
          {
            transactionId: transaction.id,
            paymentAmount:
              Number(val?.amount || 0) + Number(val?.creditAmount || 0),
            billingKey: transaction.billingKey,
            //TODO: What goes in here?
            summary: {
              items: [],
            },
          },
        ],
      });

      if (res?.id) {
        closeModal(true);
      }
      refetchList();
      return res;
    },
    [save, transaction.id, transaction.billingKey, refetchList, closeModal],
  );

  const submit = async (val: Partial<PatientPayment>) => {
    if (!val.amount && !val.creditAmount) return;

    if (val.deviceKey && val.deviceKey !== 'manual') {
      await sendNewPayRequest(val);
      return val;
    } else {
      return confirmSubmit({ val });
    }
  };

  const sendNewPayRequest = async (val: Partial<PatientPayment>) => {
    if (!val.deviceKey) return;
    const { id } = await patientBillingService.createNewPayRequest({
      amount: val?.amount || 0,
      clinicId: me?.selectedClinic?.ID || -1,
      patientId,
      deviceKey: val.deviceKey,
      payment: {
        ...val,
        locationId: me?.selectedLocation,
        transactionPayments: [
          {
            transactionId: transaction.id,
            paymentAmount: val?.amount || 0,
            billingKey: transaction.billingKey,
          },
        ],
      },
    });
    if (id) {
      setWaitingForTerminal(true);
      setTerminalTransaction({
        // payRequestKey: res.key,
        // complete: false,
        id,
        status: EPayStatus.Pending,
      });
    }
    return id;
  };

  const handleTypeChange = async (type: PaymentType) => {
    const isCheck = type === PaymentType.Check;
    // const isCredit = type === PaymentType.Credit;

    const hasReferenceNumber = !!value?.referenceNumber?.toString().length;

    const newValue = {
      ...value,
      type,
    };

    if (isCheck && hasReferenceNumber) {
      newValue.alertOverrode = false;
      newValue.referenceNumber = undefined;
    }

    patchValue(newValue);
    setTerminalTransaction(null);
  };

  const onSuccess = async (closeAndFetch = true, force?: boolean) => {
    if (closeAndFetch) {
      refetchList();
      closeModal(force);
      clear();
    }
  };

  const onFail = (error: any) => {
    const isDuplicateRefNum =
      error?.response?.data?.fieldErrors?.referenceNumber?.code ===
      'DuplicateReferenceNumber';
    if (isDuplicateRefNum) {
      patchValue({ alertOverrode: true });
    }
    const message = error?.response?.data?.message || error?.message;
    const errorCode = error?.response?.data?.code;
    createToast({
      title: 'Error!',
      description: message || `There was an error saving the payment.`,
      type: ToastTypes.Fail,
      duration: 5000,
    });
    if (errorCode === 'CREATE_PAYMENT_ERROR') {
      closeModal();
      navigate(`/patients/${patientId}/billing/payments`);
    }
  };

  const onError = useCallback((message: string | null) => {
    setCCError(message);
  }, []);

  const onChangeAmount = (val: string) => {
    let amountToSet: any = val;
    if (Number(val) < 0) {
      amountToSet = 0;
    }
    patchValue({ amount: amountToSet });
  };

  return (
    <Modal
      addClasses="max-w-4xl w-full"
      omitClasses="sm:max-w-lg"
      isOpen={isOpen}
      close={() => closeModal(false)}
      key={myId}
    >
      <form
        onSubmit={registerSubmit(submit, {
          onSuccess: () => {
            value.deviceKey ? onSuccess(false) : onSuccess();
          },
          onFail,
        })}
      >
        <div className="absolute top-0 w-full left-0 flex items-center justify-end gap-x-6 bg-primary-600 rounded-t-md px-6 py-2">
          <div className="text-white">
            Available Credit: {formatCurrency(balances?.creditBalance || 0)}
          </div>
        </div>
        <div className="mt-8">
          <div className="mt-3 text-center sm:mt-5 flex flex-col gap-6">
            <div className="flex flex-col">
              <h3
                className="text-lg font-medium leading-6 text-gray-900 dark:text-darkGray-100"
                id="modal-headline"
              >
                Add Payment
              </h3>
            </div>
          </div>
          <div>
            <div className="flex flex-col sm:flex-row sm:gap-2 w-full">
              <InputMasked
                name="paymentDate"
                label="Transaction date *"
                value={value.paymentDate}
                onChange={onChange('paymentDate')}
                errors={errors.fieldErrors?.paymentDate}
                className="w-1/2"
                patternFormat="##/##/####"
                placeholder="MM/DD/YYYY"
                disabled={isSubmitting || waitingForTerminal}
                autoFocus
              />
              <div className="w-1/2 flex flex-col">
                <InputMasked
                  className="w-full"
                  label={`Payment amount *`}
                  name="amount"
                  placeholder="0.00"
                  value={value.amount}
                  numericOptions={{
                    decimalScale: 2,
                    fixedDecimalScale: true,
                    allowNegative: false,

                    // prefix: '$',
                  }}
                  onChange={(val) => onChangeAmount(val)}
                  onBlur={(val) => {
                    if (Number(val || 0) <= 0) {
                      patchValue({
                        type: null,
                      });
                    }
                  }}
                  errors={errors?.fieldErrors?.amount}
                  disabled={waitingForTerminal || isSubmitting}
                />
                <div className="text-sm text-gray-400 w-1/2 flex flex-row">
                  {!!value.amount &&
                  value?.amount >
                    (Number(transaction?.patientBalance) ||
                      transaction?.amount ||
                      0) ? (
                    <div className="whitespace-nowrap">
                      Cannot pay more than the total due.
                    </div>
                  ) : (
                    <div className="flex flex-row">
                      <div>
                        Patient balance:&nbsp;
                        {formatCurrency(
                          Number(transaction?.patientBalance) || 0,
                        )}
                      </div>
                    </div>
                  )}
                  {Number(value.amount).toFixed(2) !==
                  (Number(transaction?.patientBalance) || 0).toFixed(2) ? (
                    <div
                      title="Click to use the full balance as the amount."
                      onClick={() => {
                        onChangeAmount(
                          (Number(transaction?.patientBalance) || 0).toFixed(2),
                        );
                        patchValue({
                          type: null,
                        });
                      }}
                    >
                      <ArrowUpCircleIcon className="h-4 w-4 text-primary-500 mt-0.5 ml-0.5" />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {Number(value.amount || 0) > 0 && (
              <Select
                name="type"
                label="Payment type *"
                options={filteredPaymentTypes}
                onChange={handleTypeChange}
                value={value.type}
                limit={1}
                errors={errors.fieldErrors?.type}
                disabled={waitingForTerminal}
              />
            )}

            {value.type === PaymentType.Check && (
              <Input
                name="referenceNumber"
                label="Reference number"
                value={value?.referenceNumber || ''}
                onChange={(val: number) => {
                  patchValue({
                    referenceNumber: val,
                    alertOverrode: false,
                  });
                }}
                errors={errors.fieldErrors?.referenceNumber}
                disabled={isSubmitting}
                type="number"
              />
            )}
            <Input
              name="description"
              label="Description"
              value={value?.description || ''}
              onChange={onChange('description')}
              errors={errors.fieldErrors?.description}
              disabled={isSubmitting || waitingForTerminal}
            />
            {!!balances?.creditBalance && (
              <>
                <Checkbox
                  label="Apply credit"
                  name="applyCredit"
                  className="pt-4"
                  value={applyCredits}
                  onChange={(e) => {
                    setApplyCredits(e);
                    patchValue({ creditAmount: null });
                  }}
                  disabled={
                    isSubmitting || waitingForTerminal || payment?.isRefunded
                  }
                />
                {!!applyCredits && (
                  <InputMasked
                    className="w-full"
                    label="Credit Amount"
                    name="creditAmount"
                    placeholder="0.00"
                    value={value.creditAmount}
                    onChange={(val) => {
                      onChange('creditAmount')(val);
                    }}
                    errors={errors?.fieldErrors?.creditAmount}
                    numericOptions={{
                      decimalScale: 2,
                      fixedDecimalScale: true,
                      allowNegative: false,
                      // prefix: '$',
                    }}
                    disabled={isSubmitting}
                  />
                )}
              </>
            )}
            {value.type === PaymentType.CreditCardExternal && (
              <div className="mt-6">
                {processingFee > 0 && (
                  <p className="text-sm font-light leading-6 text-gray-900 dark:text-darkGray-100">
                    {`A processing fee of ${formatCurrency(
                      Number(
                        createDecimal(processingFee)
                          .times(createDecimal(Number(value.amount)))
                          .toFixed(2),
                      ),
                    )} will be applied to this transaction.`}
                  </p>
                )}
              </div>
            )}
            {value.type === PaymentType.CreditCard && (
              <div className="mt-6">
                {processingFee > 0 && (
                  <p className="text-sm font-light leading-6 text-gray-900 dark:text-darkGray-100">
                    {`A processing fee of ${formatCurrency(
                      Number(
                        createDecimal(processingFee)
                          .times(createDecimal(Number(value.amount)))
                          .toFixed(2),
                      ),
                    )} will be applied to this transaction.`}
                  </p>
                )}
                <CreditCardDeviceSelector
                  value={value}
                  onChange={onChange}
                  disabled={
                    !!(
                      isSubmitting ||
                      waitingForTerminal ||
                      payment?.isRefunded
                    )
                  }
                  patchValue={patchValue}
                  errors={errors}
                  isLoading={isFetchingEpayCustomer}
                  isSubmitting={isSubmitting}
                  waitingForTerminal={waitingForTerminal}
                  payment={payment}
                  selectedPaymentCard={selectedPaymentCard}
                  epayCustomer={epayCustomer}
                  setSelectedPaymentCard={setSelectedPaymentCard}
                  setTerminalTransaction={setTerminalTransaction}
                  onError={onError}
                  setWaitingForTerminal={setWaitingForTerminal}
                  terminalTransaction={terminalTransaction}
                  onSuccess={onSuccess}
                  refetchEpayCustomer={refetchEpayCustomer}
                />
              </div>
            )}
            {!!ccError && (
              <div className="rounded-md bg-red-50 p-4 mt-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">Error</h3>
                    <div className="mt-2 text-sm text-red-700">
                      <ul className="list-disc space-y-1 pl-5">
                        <li>{ccError}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={'mt-6 flex flex-row gap-2 justify-between'}>
          <Button
            text="Close"
            color={ButtonColors.plain}
            onClick={() => closeModal(false)}
            className="border border-gray-300 dark:border-darkGray-600"
            disabled={isSubmitting || waitingForTerminal}
          />
          {hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) && (
            <Button
              text="Create"
              type="submit"
              loading={isSubmitting || waitingForTerminal}
              disabled={
                !isDirty ||
                isSubmitting ||
                waitingForTerminal ||
                (!value.amount && !value.creditAmount)
              }
            />
          )}
        </div>
      </form>
    </Modal>
  );
};

export default TransactionPaymentModal;
