export enum MagicAction {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Updated = 'updated',
  Noop = 'noop', // No operations performed.
}

export type MagicActionType = {
  message: string;
  type?: MagicAction;
};

export const MagicToastTitle = 'ChiroUp Bot';
