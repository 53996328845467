import { classNames } from '@chiroup/core/functions/classNames';
import { PatientTransactionItemType } from '@chiroup/core/types/PatientTransaction.type';
import dayjs from 'dayjs';
import Datepicker from 'react-tailwindcss-datepicker';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

type Props = {
  item?: PatientTransactionItemType;
  onChange: (val: any) => void;
  readonly: boolean;
};
export const PackageDatePicker: React.FC<Props> = ({
  item,
  onChange,
  readonly,
}) => {
  const onDateChange = (
    item: PatientTransactionItemType,
    e: {
      startDate: string;
      endDate: string;
    },
  ) => {
    const packageItem = item;
    packageItem.packageDate = e.startDate;
    onChange(item);
  };

  return (
    item && (
      <div
        className={classNames(
          'flex text-sm font-medium items-center mb-4 space-x-2',
        )}
      >
        <label>Package start date:</label>
        <div className="border border-gray-300 px-4 py-2 cursor-pointer rounded-md h-10">
          <Datepicker
            value={{
              startDate: dayjs.utc(item?.packageDate).format('YYYY-MM-DD'),

              endDate: dayjs.utc(item?.packageDate).format('YYYY-MM-DD'),
            }}
            onChange={(e: any) => onDateChange(item, e)}
            asSingle={true}
            useRange={false}
            popoverDirection="down"
            placeholder={'MM/DD/YYYY'}
            displayFormat={'MM/DD/YYYY'}
            primaryColor={'green'}
            containerClassName="z-30"
            toggleIcon={() => null}
            inputClassName={
              'rounded-none py-0 pl-0 pr-0 border-0 text-center flex items-center justify-center w-44 max-w-96 focus:ring-0 cursor-pointer bg-transparent font-normal text-sm'
            }
            disabled={readonly}
          />
        </div>
      </div>
    )
  );
};
export default PackageDatePicker;
