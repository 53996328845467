import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import patientBillingService from '../../../services/patientBilling.service';

const getQuery = function (clinicId: number) {
  return async (context: QueryFunctionContext) => {
    const patientId = context.queryKey[1] as string;
    const merchantId = context.queryKey[2] as number;
    if (!patientId || !merchantId) {
      return null;
    }
    return await patientBillingService.getEpayCustomer({
      clinicId,
      patientId,
      merchantId,
    });
  };
};

const useEpayCustomer = ({
  patientId,
  merchantId,
}: {
  patientId?: string;
  merchantId?: number;
}) => {
  const meContext = useContext(MeContext);

  const {
    data: epayCustomer,
    error,
    refetch,
    isFetching,
  } = useQuery(
    ['epayCustomer', patientId, merchantId],
    getQuery(meContext?.me?.selectedClinic?.ID as number),
    {
      enabled: !!patientId && !!merchantId,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  return {
    epayCustomer: epayCustomer ?? null,
    error,
    refetch,
    isFetching,
  };
};

export default useEpayCustomer;
