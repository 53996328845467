import Decimal from 'decimal.js';
import { calculateInsurancePayment } from './calculateInsurancePayment';
import { calculatePatientResponsibility } from './calculatePatientResponsibility';
import { calculatePayorResponsibilityPerServiceNew } from './calculatePayorResponsibilityPerServiceNew';
import { createDecimal } from './createDecimal';
import { STRING_NUMBER_HASH } from '../constants/globals';
import { AppointmentInsuranceType } from '../types/Appointment.type';
import {
  PatientTransaction,
  PatientTransactionItemType,
  TransactionItemTypeEnum,
  TransactionItemSubtypeEnum,
} from '../types/PatientTransaction.type';
import { ChiroUpJSON } from './ChiroUpJSON';
import { clog } from './clog';
import { handleDuplicateServices } from './handleDuplicateServices';

export type CalculationPayor = {
  payorId: number;
  billingPriority: number;
  serviceAllowedAmounts:
    | { code: string; amount: number }[]
    | Record<string, number>;
  coPay?: number;
  coInsurance?: number;
  deductible?: number;
  payorName: string;
  billedAmounts?: { [key: string]: number };
  maxPerVisit?: number | string | null;
};

export type Responsibilities = {
  patientResponsibilities: Record<number, string>;
  payorResponsibilities: Record<
    number,
    { amount: string; name: string; payorId: number }
  >;
  finalPatientResponsibility: string;
};

type PayorServiceDetail = {
  totalServices: Decimal;
  coPay: Decimal;
  coInsurance: Decimal;
  deductible: Decimal;
  maxPerVisit?: Decimal;
};

type PayorsResponsibilitiesPerService = {
  [payorId: string]: {
    code: string;
    amount: number;
  }[];
};

const insurancesToUse = (insurances: Partial<AppointmentInsuranceType>[]) => {
  return insurances
    ?.sort(
      (a, b) => (a.billingPriority as number) - (b.billingPriority as number),
    )
    ?.map((insurance) => {
      const {
        payorID,
        insuranceName,
        billingPriority,
        deductible,
        coPay,
        coInsurance,
        serviceAllowedAmounts,
      } = insurance;
      const trivialAllowedAmount = Object.keys(
        serviceAllowedAmounts || {},
      )?.reduce((acc, cur) => {
        if (!serviceAllowedAmounts) return acc;
        return acc + Number(serviceAllowedAmounts[cur].allowedAmount);
      }, 0);
      const compactServiceAmounts = Object.keys(
        serviceAllowedAmounts ?? {},
      )?.reduce((acc, cur) => {
        if (!serviceAllowedAmounts) return acc;
        acc[cur] = Number(serviceAllowedAmounts[cur].allowedAmount);
        return acc;
      }, {} as STRING_NUMBER_HASH);
      return {
        payorId: payorID as number,
        payorName: insuranceName as string,
        billingPriority: billingPriority as number,
        deductible: Number(deductible) || 0,
        coPay: Number(coPay) || 0,
        coInsurance: Number(coInsurance) || 0,
        allowedAmount: trivialAllowedAmount,
        serviceAllowedAmounts: compactServiceAmounts,
        maxPerVisit: insurance.maxPerVisit,
      };
    });
};
export const calculateResponsibilitiesFromTransaction = ({
  transaction,
}: {
  transaction: PatientTransaction;
}) => {
  const insurances = insurancesToUse(
      transaction?.insurances as Partial<AppointmentInsuranceType>[],
    ),
    items = transaction.items,
    courtesyBilling = transaction?.courtesyBilling,
    superBill = transaction?.superBill;

  return calculateResponsibilities({
    insurances,
    items,
    courtesyBilling,
    superBill,
    allocateToPatient: transaction?.allocateToPatient,
    allocationAmount: transaction?.allocatedFromClaimsAmount,
  });
};

export const calculateResponsibilities = ({
  insurances,
  items,
  courtesyBilling = false,
  superBill = false,
  allocateToPatient = false,
  allocationAmount = 0,
  nonBillable = false,
}: {
  insurances: CalculationPayor[];
  items: PatientTransactionItemType[];
  courtesyBilling?: boolean;
  superBill?: boolean;
  allocateToPatient?: boolean;
  allocationAmount?: number;
  nonBillable?: boolean;
}): Responsibilities => {
  if (!items?.length) {
    return {
      patientResponsibilities: {},
      payorResponsibilities: {},
      finalPatientResponsibility: '0.00',
    };
  }

  const treatmentTotal = items.reduce((acc, item) => {
    if (item.subtype === TransactionItemSubtypeEnum.Treatment) {
      acc = acc.plus(
        createDecimal(item.amount ?? 0).times(createDecimal(item.units ?? 1)),
      );
    }
    return acc;
  }, createDecimal(0));

  /**
   * Use case: No insurances. Patient is responsible for it all.
   */
  if (!insurances?.length || superBill) {
    const total = items
      .reduce((acc, item) => {
        const amt = createDecimal(item.amount ?? 0).times(
          createDecimal(item.units ?? 0),
        );
        if (item.type === TransactionItemTypeEnum.Debit) {
          return acc.plus(amt);
        } else if (item.type === TransactionItemTypeEnum.Credit) {
          return acc.plus(amt.times(-1));
        } else if (item.type !== TransactionItemTypeEnum.Void) {
          console.warn(
            `Unknown item type on item: ${ChiroUpJSON.stringify(item)}`,
          );
        }
        return acc;
      }, createDecimal(0))
      .plus(treatmentTotal);

    return {
      patientResponsibilities: {
        '1': String(total),
      },
      payorResponsibilities: {},
      finalPatientResponsibility: String(total),
    };
  }

  const services = handleDuplicateServices(
    items?.filter(
      (i: PatientTransactionItemType) =>
        i.subtype === TransactionItemSubtypeEnum.Service,
    ),
  )?.deduped;

  const packageAppliedTotal = items?.reduce((acc, item) => {
    if (
      item.subtype === TransactionItemSubtypeEnum.Adjustment &&
      !!item?.packageId
    ) {
      return acc.plus(createDecimal(item?.amount || 0));
    }
    return acc;
  }, createDecimal(0));

  const patientService = items?.filter(
    (i) => i.subtype === TransactionItemSubtypeEnum.PatientService,
  );
  const payors = insurances?.map((payor: any) => ({
    payorId: payor.payorId,
    maxPerVisit: payor.maxPerVisit || null,
    billingPriority: payor.billingPriority,
    coPay: +(payor.coPay || 0),
    coInsurance: +(payor.coInsurance || 0),
    deductible: +(payor.deductible || 0),
    serviceAllowedAmounts: Object.keys(
      payor?.serviceAllowedAmounts ?? {},
    )?.reduce((acc: any, key) => {
      /**
       * This was killing the UI if we had a bad parameter passed.
       * Maybe it degrades a bit more gracefully this way.
       */
      try {
        acc[key] = createDecimal(
          payor?.serviceAllowedAmounts?.[key]?.allowedAmount ??
            payor?.serviceAllowedAmounts?.[key] ??
            0,
        )
          ?.times(
            services.find(
              (service: PatientTransactionItemType) => service.code === key,
            )?.units ?? 0,
          )
          ?.toDP(2)
          ?.toNumber();
      } catch (e) {
        console.error({
          payor,
          key,
          service: services.find(
            (service: PatientTransactionItemType) => service.code === key,
          ),
          error: e,
        });
      }
      return acc;
    }, {}),
    payorName: payor.payorName,
  }));

  const totalChargedByClinic = services?.reduce(
    (acc: { [key: string]: Decimal }, service: PatientTransactionItemType) => {
      acc[service.code as string] = createDecimal(
        acc[service.code as string] || 0,
      )
        .plus(createDecimal(service.amount || 0).times(service.units || 0))
        .toDP(2);
      return acc;
    },
    {} as { [key: string]: Decimal },
  );

  const sortedPayors = payors?.sort(
    (a, b) => a.billingPriority - b.billingPriority,
  );

  const highestAllowedAmountsPerService = findHighestAllowedAmounts(payors);

  let payorsResponsibilitiesPerService: PayorsResponsibilitiesPerService = {};
  let courtesyBillingAmount = '0.00';

  const valueToReturn = sortedPayors?.reduce(
    (acc: Responsibilities, payor) => {
      const payorCopy = JSON.parse(JSON.stringify(payor));
      const totalServices = calculateTotalServices(
        payorCopy.serviceAllowedAmounts as Record<string, number>,
      );
      const orderedServiceAmounts: {
        code: string;
        amount: number;
        payorId: number;
      }[] = orderServices(services, payorCopy);

      payorCopy.serviceAllowedAmounts = orderedServiceAmounts;
      const maxPerVisit = createDecimal(payorCopy.maxPerVisit ?? 0);

      const payorServiceDetail: PayorServiceDetail = {
        totalServices:
          !payorCopy.maxPerVisit || totalServices.lessThan(maxPerVisit)
            ? totalServices
            : maxPerVisit,
        coPay: createDecimal(payorCopy.coPay || 0),
        coInsurance: createDecimal(payorCopy.coInsurance || 0),
        deductible: createDecimal(payorCopy.deductible || 0),
      };

      let responsibilities: Responsibilities = {
        patientResponsibilities: {},
        payorResponsibilities: {},
        finalPatientResponsibility: '0.00',
      };
      if (payorCopy.billingPriority === 1) {
        payorsResponsibilitiesPerService = getPayorResponsibilityPerService({
          payorsResponsibilitiesPerService,
          payor: payorCopy,
          services,
        });

        const totalPayorResponsibility = Object.values(
          payorsResponsibilitiesPerService[payorCopy.billingPriority],
        )
          .reduce((total, service) => {
            return total.plus(service.amount);
          }, createDecimal(0))
          ?.toNumber()
          ?.toFixed(2);

        responsibilities = calculateForPrimaryPayor(
          payorServiceDetail,
          payorCopy,
        );
        responsibilities.payorResponsibilities[
          payorCopy.billingPriority
        ].amount = totalPayorResponsibility;
      } else {
        if (isMedicarePayor(payorCopy.payorName)) {
          const medicareResponsibilities = newMedicareCalculateResponsibilities(
            {
              highestAllowedAmountPerService: highestAllowedAmountsPerService,
              payorsResponsibilitiesPerService,
              payor: payorCopy,
              totalChargedByClinic,
              payorServiceDetail,
              services,
            },
          );

          const {
            patientResponsibilities,
            payorResponsibilities,
            finalPatientResponsibility,
          } = medicareResponsibilities;
          responsibilities.patientResponsibilities[payorCopy.billingPriority] =
            patientResponsibilities[payorCopy.billingPriority];
          responsibilities.payorResponsibilities[payorCopy.billingPriority] =
            payorResponsibilities[payorCopy.billingPriority];
          responsibilities.payorResponsibilities[payorCopy.billingPriority] = {
            ...responsibilities.payorResponsibilities[
              payorCopy.billingPriority
            ],
            payorId: payorCopy.payorId,
          };
          responsibilities.finalPatientResponsibility =
            finalPatientResponsibility;
        } else {
          const serviceAllowedAmounts = payorCopy.serviceAllowedAmounts;
          responsibilities = calculateForOtherPayor(
            payorServiceDetail,
            payorCopy,
            serviceAllowedAmounts,
            payorsResponsibilitiesPerService,
            sortedPayors,
            services,
          );
        }
      }

      if (courtesyBilling) {
        if (payorCopy.billingPriority === 1) {
          const amounts: number[] = Object.values(totalChargedByClinic).map(
            (amount) => Number(amount),
          );
          courtesyBillingAmount = amounts
            .reduce(
              (total: Decimal, amount: number) => {
                return total.plus(createDecimal(amount || 0));
              },
              createDecimal(0) as Decimal,
            )
            .toFixed(2);
        }

        // responsibilities.finalPatientResponsibility = '0.00';
        responsibilities.finalPatientResponsibility = courtesyBillingAmount;
        responsibilities.payorResponsibilities = Object.entries(
          responsibilities.payorResponsibilities,
        ).reduce(
          (acc, [key, value]) => {
            acc[key] = {
              amount: '0.00',
              name: value.name,
              payorId: payorCopy.payorId,
            };
            0;
            return acc;
          },
          {} as Record<
            string,
            {
              amount: string;
              name: string;
              payorId: number;
              maxPerVisit?: number;
            }
          >,
        );
      }

      if (allocateToPatient && !nonBillable) {
        responsibilities.finalPatientResponsibility = createDecimal(
          responsibilities.finalPatientResponsibility,
        )
          .plus(allocationAmount)
          .toDP(2)
          .toString();
      }

      return {
        patientResponsibilities: {
          ...acc.patientResponsibilities,
          ...responsibilities.patientResponsibilities,
        },
        payorResponsibilities: {
          ...acc.payorResponsibilities,
          ...responsibilities.payorResponsibilities,
        },
        finalPatientResponsibility: createDecimal(
          acc.finalPatientResponsibility,
        )
          .plus(createDecimal(responsibilities.finalPatientResponsibility))
          .plus(
            patientService?.reduce((acc, item) => {
              return acc.plus(
                createDecimal(item.amount || 0).times(item.units || 0),
              );
            }, createDecimal(0)),
          )
          .minus(packageAppliedTotal)
          .toFixed(2),
      };
    },
    {
      patientResponsibilities: {},
      payorResponsibilities: {},
      finalPatientResponsibility: treatmentTotal.toFixed(2),
    },
  );
  if (nonBillable) {
    if (+valueToReturn.finalPatientResponsibility <= 0) {
      valueToReturn.finalPatientResponsibility = Object.values(
        valueToReturn.payorResponsibilities,
      )
        .reduce((acc, payor) => {
          return acc.plus(createDecimal(payor.amount));
        }, createDecimal(0))
        .toFixed(2);
    }
    // PER EMILY: If the payor is non-billable then we do not need to worry about the visit max,
    // the entire amount should be allocatable to the patient.
    if (allocateToPatient) {
      valueToReturn.finalPatientResponsibility = createDecimal(
        valueToReturn.finalPatientResponsibility,
      )
        .plus(allocationAmount)
        .toDP(2)
        .toString();
    }

    valueToReturn.payorResponsibilities = Object.entries(
      valueToReturn.payorResponsibilities,
    ).reduce(
      (acc, [key, value]) => {
        acc[key] = {
          amount: '0.00',
          name: value.name,
          payorId: value.payorId,
        };
        return acc;
      },
      {} as Record<
        string,
        {
          amount: string;
          name: string;
          payorId: number;
          maxPerVisit?: number;
        }
      >,
    );
  }

  return valueToReturn;
};

const calculateTotalServices = (
  serviceAllowedAmounts: Record<string, number>,
): Decimal =>
  Object?.values(serviceAllowedAmounts)
    ?.reduce((total, amount) => {
      return total?.plus(createDecimal(amount || 0));
    }, createDecimal(0))
    ?.toDP(2);

const isMedicarePayor = (payorName: string): boolean =>
  payorName.toLowerCase().includes('medicare');

const calculateForPrimaryPayor = (
  { totalServices, coPay, coInsurance, deductible }: PayorServiceDetail,
  { payorId, payorName, billingPriority }: CalculationPayor,
): Responsibilities => {
  // Convert input values to numbers and calculate patient responsibility
  const patientResponsibility = calculatePatientResponsibility({
    totalServices: (totalServices || createDecimal(0))?.toNumber(),
    coPay: coPay?.toNumber(),
    coInsurance: coInsurance?.toNumber(),
    deductible: deductible?.toNumber(),
  });

  // Convert patient responsibility to string once for reuse
  const patientResponsibilityStr = patientResponsibility.toString();

  return {
    patientResponsibilities: {
      [billingPriority]: patientResponsibilityStr,
    },
    payorResponsibilities: {
      [billingPriority]: {
        amount: '0', //TODO: this is here because of the way the function used to work.  This doesnt appear to break anything, but it could be better
        name: payorName,
        payorId,
      },
    },
    finalPatientResponsibility: patientResponsibilityStr,
  };
};

type ServiceAmount = { code: string; amount: number };
type AdjustedServiceAmount = ServiceAmount; // Alias for clarity, but could be extended or modified in the future

const calculateAdjustedServiceAmounts = ({
  serviceAllowedAmounts,
  payorsResponsibilitiesPerService,
  payor,
  payors,
  services,
}: {
  serviceAllowedAmounts: ServiceAmount[];
  payorsResponsibilitiesPerService: PayorsResponsibilitiesPerService;
  payor: CalculationPayor;
  payors: CalculationPayor[];
  services: PatientTransactionItemType[];
}): Decimal => {
  // Sum the service amounts from payors with higher billing priority
  const summedServiceAmounts = new Map<string, Decimal>();
  payors
    .filter((p) => p.billingPriority < payor.billingPriority)
    .forEach((p) => {
      payorsResponsibilitiesPerService[p.billingPriority]?.forEach(
        (service) => {
          const currentAmount =
            summedServiceAmounts.get(service.code) || createDecimal(0);
          summedServiceAmounts.set(
            service.code,
            currentAmount.plus(service.amount),
          );
        },
      );
    });

  // Adjust the service amounts based on summed responsibilities
  const adjustedServiceAmounts: AdjustedServiceAmount[] =
    serviceAllowedAmounts.map((service) => {
      const totalResponsibility =
        summedServiceAmounts.get(service.code) || createDecimal(0);
      let adjustedAmount = createDecimal(service.amount)
        .minus(totalResponsibility)
        .toDP(2);
      if (adjustedAmount.lessThan(0)) adjustedAmount = createDecimal(0);

      return { code: service.code, amount: adjustedAmount?.toNumber() };
    });
  payorsResponsibilitiesPerService[payor.billingPriority] = orderServices(
    services,
    {
      ...payor,
      serviceAllowedAmounts: adjustedServiceAmounts,
    },
  );

  // Calculate the total adjusted amount
  return adjustedServiceAmounts
    .reduce((total, { amount }) => total.plus(amount), createDecimal(0))
    .toDP(2);
};

const calculateForOtherPayor = (
  input: PayorServiceDetail,
  payor: CalculationPayor,
  serviceAllowedAmounts: { code: string; amount: number }[],
  payorsResponsibilitiesPerService: PayorsResponsibilitiesPerService,
  payors: CalculationPayor[],
  services: PatientTransactionItemType[],
): Responsibilities => {
  const adjustedTotalServices = calculateAdjustedServiceAmounts({
    serviceAllowedAmounts,
    payorsResponsibilitiesPerService,
    payor,
    payors,
    services,
  });

  // Calculate patient responsibility based on the adjusted total services
  const patientResponsibilityAmount = calculatePatientResponsibility({
    totalServices: adjustedTotalServices?.toNumber(),
    coPay: input.coPay?.toNumber(),
    coInsurance: input.coInsurance?.toNumber(),
    deductible: input.deductible?.toNumber(),
  });
  const amountsToPassIntoFunction = Object.entries(
    payorsResponsibilitiesPerService[payor.billingPriority],
  )?.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (acc, [_, { code, amount }]) => {
      acc.push({ code, amount });
      return acc;
    },
    [] as { code: string; amount: number }[],
  );

  calculatePayorResponsibilityPerServiceNew({
    payor: { ...payor, serviceAllowedAmounts: amountsToPassIntoFunction },
  });

  const payorResponsibilityResult = calculateInsurancePayment({
    totalServices: adjustedTotalServices?.toNumber(),
    coPay: input.coPay?.toNumber(),
    coInsurance: input.coInsurance?.toNumber(),
    deductible: input.deductible?.toNumber(),
  }).insurerTotal;

  const payorResponsibilityAmount = Math.min(
    +payorResponsibilityResult,
    adjustedTotalServices?.toNumber(),
  );
  const adjustedPayorResponsibility = payorResponsibilityAmount?.toFixed(2);

  return {
    patientResponsibilities: {
      [payor.billingPriority]: patientResponsibilityAmount,
    },
    payorResponsibilities: {
      [payor.billingPriority]: {
        amount: adjustedPayorResponsibility,
        name: payor.payorName,
        payorId: payor.payorId,
      },
    },
    finalPatientResponsibility: patientResponsibilityAmount,
  };
};

const getPayorResponsibilityPerService = ({
  payorsResponsibilitiesPerService,
  payor,
  services,
}: {
  payorsResponsibilitiesPerService: PayorsResponsibilitiesPerService;
  payor: CalculationPayor;
  services: PatientTransactionItemType[];
}) => {
  payorsResponsibilitiesPerService[payor.payorId] =
    payorsResponsibilitiesPerService[payor.payorId] || [];

  if (payor.maxPerVisit) {
    let remainingMaxPerVisit = createDecimal(payor.maxPerVisit);

    payor.serviceAllowedAmounts = (
      payor.serviceAllowedAmounts as unknown as Array<{
        code: any;
        amount: string;
      }>
    )?.map((service) => {
      const serviceAmount = createDecimal(service.amount);
      const amountToUse = serviceAmount.lessThan(remainingMaxPerVisit)
        ? Number(service.amount)
        : remainingMaxPerVisit.greaterThan(0)
          ? Number(remainingMaxPerVisit.toFixed(2))
          : 0;

      remainingMaxPerVisit = remainingMaxPerVisit.minus(amountToUse);

      return {
        code: service.code,
        amount: amountToUse,
      };
    });
  }

  const payorResponsibilitiesPerService =
    calculatePayorResponsibilityPerServiceNew({ payor });

  payorsResponsibilitiesPerService[payor.billingPriority] = orderServices(
    services,
    {
      ...payor,
      serviceAllowedAmounts: payorResponsibilitiesPerService,
      payorId: payor.payorId,
    },
  );
  return payorsResponsibilitiesPerService;
};

//MEDICARE STUFF:
// As secondary payer, Medicare pays the lowest of the following amounts per service:
// 1.  The actual charge by the clinic minus the amount paid by the primary payer.
// 2.  The amount that Medicare would pay if the services were not covered by a primary payer.
// 3.  The higher of the Medicare fee schedule (without regard to any applicable Medicare deductible or coinsurance amounts)
//     or the primary payer's allowable charge (without regard to any deductible or co-insurance imposed by the policy or plan) minus the amount actually
//     paid by the primary payer

enum MedicareFormulas {
  ExcessCharge = 1,
  MedicareWouldPay = 2,
  HighestAllowedAmount = 3,
}

type ServiceDetails = {
  [serviceCode: string]: Decimal;
};

const newMedicareCalculateResponsibilities = ({
  highestAllowedAmountPerService,
  payorsResponsibilitiesPerService,
  payor,
  totalChargedByClinic,
  payorServiceDetail,
  services,
}: {
  highestAllowedAmountPerService: { [serviceCode: string]: number };
  payorsResponsibilitiesPerService: PayorsResponsibilitiesPerService;
  payor: CalculationPayor;
  totalChargedByClinic: { [key: string]: Decimal };
  payorServiceDetail: PayorServiceDetail;
  services: PatientTransactionItemType[];
}) => {
  // Calculate the total paid by service code
  const totalPaidByServiceCode: ServiceDetails = Object.values(
    payorsResponsibilitiesPerService,
  ).reduce(
    (totalPaid: { [key: string]: Decimal }, serviceDetails) => {
      serviceDetails.forEach((service) => {
        totalPaid[service.code] = totalPaid[service.code]
          ? totalPaid[service.code].plus(service.amount)
          : createDecimal(service.amount);
      });
      return totalPaid;
    },
    {} as { [key: string]: Decimal },
  );

  // Calculate the excess charge per service
  const excessChargePerService: ServiceDetails = Object.entries(
    totalChargedByClinic,
  ).reduce(
    (
      excessCharges: { [serviceCode: string]: Decimal },
      [serviceCode, totalCharged],
    ) => {
      const paidAmount =
        totalPaidByServiceCode[serviceCode] || createDecimal(0);
      const excessCharge = totalCharged.minus(paidAmount).greaterThan(0)
        ? totalCharged.minus(paidAmount)
        : createDecimal(0);

      excessCharges[serviceCode] = excessCharge;
      return excessCharges;
    },
    {} as { [serviceCode: string]: Decimal },
  );

  // Calculate responsibilities based on highestAllowedAmountPerService and amount already paid by service code
  const payorResponsibilities = getPayorResponsibilityPerService({
    payorsResponsibilitiesPerService: {},
    payor,
    services,
  });

  const medicareWouldPayPerService = Object.values(
    payorResponsibilities,
  )[0].reduce(
    (acc: { [serviceCode: string]: Decimal }, curr) => {
      acc[curr.code] = createDecimal(curr.amount);
      return acc;
    },
    {} as { [serviceCode: string]: Decimal },
  );

  const highestAllowedAmountPerServiceResponsibilities = Object.entries(
    highestAllowedAmountPerService,
  ).reduce(
    (
      highestAllowedAmounts: { [serviceCode: string]: Decimal },
      [serviceCode, amount],
    ) => {
      highestAllowedAmounts[serviceCode] = createDecimal(amount).minus(
        totalPaidByServiceCode[serviceCode] || 0,
      );
      return highestAllowedAmounts as { [serviceCode: string]: Decimal };
    },
    {},
  );

  const medicareResponsibilityPerService = findLeastAmountPerService(
    medicareWouldPayPerService,
    excessChargePerService,
    highestAllowedAmountPerServiceResponsibilities,
    payor.serviceAllowedAmounts as { code: string; amount: number }[],
  );

  payorsResponsibilitiesPerService[payor.payorId.toString()] = orderServices(
    services,
    {
      ...payor,
      serviceAllowedAmounts: medicareResponsibilityPerService,
    },
  );

  const totalMedicareResponsibility = Object.values(
    medicareResponsibilityPerService,
  ).reduce(
    (total, medicareResponsibilityPerService) =>
      total.plus(medicareResponsibilityPerService.amount),
    createDecimal(0),
  );

  const responsibilities = calculateForPrimaryPayor(
    { ...payorServiceDetail, totalServices: totalMedicareResponsibility },
    payor,
  );

  responsibilities.payorResponsibilities[payor.billingPriority].amount =
    totalMedicareResponsibility
      .minus(
        createDecimal(
          responsibilities.patientResponsibilities[payor.billingPriority],
        ),
      )
      .lessThan(createDecimal(0))
      ? createDecimal(0).toFixed(2)
      : totalMedicareResponsibility
          .minus(
            createDecimal(
              responsibilities.patientResponsibilities[payor.billingPriority],
            ),
          )
          .toFixed(2);

  return responsibilities;
};

const findHighestAllowedAmounts = (payors: CalculationPayor[]) => {
  const highestAllowedAmounts: { [serviceCode: string]: number } = {};

  payors?.forEach((payor) => {
    const serviceAllowedAmounts = payor.serviceAllowedAmounts as Record<
      string,
      number
    >;
    Object.keys(serviceAllowedAmounts)?.forEach((serviceCode) => {
      const amount = serviceAllowedAmounts[serviceCode];
      if (
        !highestAllowedAmounts[serviceCode] ||
        amount > highestAllowedAmounts[serviceCode]
      ) {
        highestAllowedAmounts[serviceCode] = amount;
      }
    });
  });

  return highestAllowedAmounts;
};

const findLeastAmountPerService = (
  medicareWouldPayPerService: { [serviceCode: string]: Decimal },
  excessChargePerService: { [serviceCode: string]: Decimal },
  highestAllowedAmountPerServiceResponsibilities: {
    [serviceCode: string]: Decimal;
  },
  payorServiceAmounts: { code: string; amount: number }[],
) => {
  const leastAmountPerServiceCode: {
    [serviceCode: string]: { amount: Decimal; source: number };
  } = {};

  const allServiceCodes = new Set([
    ...Object.keys(medicareWouldPayPerService),
    ...Object.keys(excessChargePerService),
    ...Object.keys(highestAllowedAmountPerServiceResponsibilities),
  ]);

  allServiceCodes.forEach((serviceCode) => {
    const medicareAmount = medicareWouldPayPerService[serviceCode]
      ? createDecimal(medicareWouldPayPerService[serviceCode])
      : createDecimal(Infinity);
    const excessAmount = excessChargePerService[serviceCode]
      ? createDecimal(excessChargePerService[serviceCode])
      : createDecimal(Infinity);
    const highestAllowedAmount = highestAllowedAmountPerServiceResponsibilities[
      serviceCode
    ]
      ? createDecimal(
          highestAllowedAmountPerServiceResponsibilities[serviceCode],
        )
      : createDecimal(Infinity);

    let leastAmount = createDecimal(Infinity);
    let source = 0;

    if (medicareAmount.lessThan(leastAmount)) {
      //When this formula is used we need to replace the calculated amount with the original amount
      leastAmount = createDecimal(
        payorServiceAmounts.find((p) => p.code === serviceCode)?.amount || 0,
      );
      source = MedicareFormulas.MedicareWouldPay;
    }
    if (excessAmount.lessThan(leastAmount)) {
      leastAmount = excessAmount;
      source = MedicareFormulas.ExcessCharge;
    }
    if (highestAllowedAmount.lessThan(leastAmount)) {
      leastAmount = highestAllowedAmount;
      source = MedicareFormulas.HighestAllowedAmount;
    }

    if (!leastAmount.equals(createDecimal(Infinity))) {
      leastAmountPerServiceCode[serviceCode] = { amount: leastAmount, source };
    }
  });

  return Object.entries(leastAmountPerServiceCode).map(
    ([serviceCode, { amount, source }]) => ({
      code: serviceCode,
      amount: amount.lessThan(0) ? 0 : amount?.toNumber(),
      source,
    }),
  );
};

const orderServices = (
  services: PatientTransactionItemType[],
  payor: CalculationPayor,
) => {
  const ordered: { code: string; amount: number; payorId: number }[] = [];
  if (!services || !services.length) {
    // clog(`Order services: Called with no services.  Returning empty array.`);
    return ordered;
  }
  services.forEach((service) => {
    const code = service.code;
    const payorId = payor.payorId;

    if (
      code &&
      payor.serviceAllowedAmounts &&
      !Array.isArray(payor.serviceAllowedAmounts)
    ) {
      const amount = (
        payor.serviceAllowedAmounts as Record<string, number | Decimal>
      )[code];
      if (typeof amount === 'number') {
        ordered.push({ code, amount, payorId });
      } else if (amount instanceof Decimal) {
        ordered.push({ code, amount: amount?.toNumber(), payorId });
      }
    } else if (service.amount && Array.isArray(payor.serviceAllowedAmounts)) {
      const service = (
        payor.serviceAllowedAmounts as {
          code: string;
          amount: number;
          payorId: number;
        }[]
      ).find((service) => service.code === code);
      if (service) {
        ordered.push(service);
      }
    }
  });
  return ordered;
};
