import { PatientTransaction } from '@chiroup/core/types/PatientTransaction.type';
import { atAutoPopulateModifier } from './autoPopulateMedicareModifier';
import { MagicAction, MagicActionType } from './commonMagic';
import { transactionOnBlurServiceAmount } from './transactionOnBlurServiceAmount';

export enum TransactionMagicEvent {
  onChangeService = 'onChangeService',
  onChangeInsurance = 'onChangeInsurance',
  onBlurServiceAmount = 'onBlurServiceAmount',
}

export type TransactionMagiceResponseType = {
  transaction: PatientTransaction;
  actions: MagicActionType[];
  touched: boolean;
};

const transactionSpells = {
  onChangeService: [atAutoPopulateModifier],
  onChangeInsurance: [atAutoPopulateModifier],
  onBlurServiceAmount: [transactionOnBlurServiceAmount],
};

export const transactionMagic = ({
  transaction,
  event,
  onEntry = null,
  onExit = null,
  trace = false,
}: {
  transaction: PatientTransaction | undefined | null;
  event: TransactionMagicEvent;
  onEntry?: ((transaction: PatientTransaction) => void) | null;
  onExit?: ((transaction: PatientTransaction) => void) | null;
  trace?: boolean;
}) => {
  if (!transaction) {
    return {
      actions: [
        {
          message: `No actions possible without a transaction.`,
          type: MagicAction.Error,
        },
      ],
      transaction: null,
    };
  }
  const actions: MagicActionType[] = [],
    magicResponses: {
      [key: string]: TransactionMagiceResponseType[];
    } = {};

  onEntry?.(transaction);
  if (trace) {
    console.log({ transaction, event });
  }

  if (transactionSpells[event]) {
    magicResponses[event] = magicResponses[event] || [];
    transactionSpells[event].forEach((magic) => {
      try {
        magicResponses[event].push(magic(transaction));
      } catch (e: any) {
        console.error(e);
        actions.push({
          message: `Error: ${e.message}`,
          type: MagicAction.Error,
        });
      }
    });
  } else {
    actions.push({
      message: 'No available spells for this event.',
      type: MagicAction.Warning,
    });
  }

  let touched = false;
  Object.keys(magicResponses).forEach((key) => {
    magicResponses[key].forEach((response) => {
      if (response.touched) {
        touched = true;
      }
      actions.push(...response.actions);
    });
  });
  onExit?.(transaction);
  return { actions, transaction, touched };
};
