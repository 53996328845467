import { InstanceKeys } from '@chiroup/core/constants/DatabaseFeatureCommon';
import { Packages } from '@chiroup/core/types/Packages.type';
import {
  PatientTransaction,
  PatientTransactionItemType,
  TransactionItemSubtypeEnum,
  toTransactionItemSubtype,
} from '@chiroup/core/types/PatientTransaction.type';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import Button, { ButtonColors } from '../../../../common/Button';
import LoadingPage from '../../../../common/LoadingPage';
import Modal from '../../../../common/Modal';
import Header from '../../../../layout/Header';
import useDatabase from '../../../../settings/database/useDatabase';
import { Input } from '@chiroup/components';

type PackageSupplementsModalProps = {
  patientId: string;
  transaction: PatientTransaction | null;
  addPendingItems: (items: PatientTransactionItemType[]) => void;
  isRouted?: boolean;
  onClose?: () => void;
  isOpen?: boolean;
};

const PurchasingPackages = ({
  patientId,
  transaction,
  addPendingItems,
  isRouted = true,
  isOpen = false,
  onClose,
}: PackageSupplementsModalProps) => {
  const navigate = useNavigate();
  const close = () => {
    if (!isRouted) {
      onClose?.();
      return;
    }
    navigate(`/patients/${patientId}/billing/${transaction?.billingKey}`);
  };
  const { data: packages, isFetching } = useDatabase<Packages>({
    instanceKey: InstanceKeys.packages,
    limit: 9999,
    showActivePackagesOnly: true,
  });

  const { createToast } = useContext(ToastContext);
  const [searchTerm, setSearchTerm] = useState('');
  const packagesIdHash: { [key: number]: boolean } = useMemo(() => {
    return transaction?.items?.reduce((obj: any, item) => {
      if (item.subtype === TransactionItemSubtypeEnum.Package) {
        obj[item?.id || ''] = true;
      }
      return obj;
    }, {});
  }, [transaction?.items]);

  const filteredPackages = useMemo(() => {
    if (!packages?.length) return [];
    const text = searchTerm.toLowerCase();
    return packages.filter(
      (packageItem) => packageItem?.name?.toLowerCase().includes(text),
    );
  }, [packages, searchTerm]);

  const handleAddPackages = async (id: any) => {
    if (!id || packagesIdHash?.[id]) return;
    const packageToPurchase = packages?.find((s) => s.ID === id);

    if (!packageToPurchase) {
      createToast({
        title: 'Not found!',
        description: <>The selected package was not available!</>,
        type: ToastTypes.Info,
        duration: 5000,
      });
      return;
    }

    const packageItem = toTransactionItemSubtype(
      TransactionItemSubtypeEnum.Package,
      {
        ...packageToPurchase,
        packageId: id,
        packageDate: transaction?.transactionDate,
      },
    );
    packageItem.id = v4();
    addPendingItems([packageItem]);
    return close();
  };
  return (
    <Modal isOpen={isRouted ? true : isOpen} close={close} addClasses="h-4/5">
      {isFetching ? (
        <LoadingPage />
      ) : (
        <>
          <Header title="Packages" />
          <div className="py-4">
            <Input
              type="text"
              name="filter"
              value={searchTerm}
              onChange={setSearchTerm}
              placeholder="Search for a package"
            />
          </div>
          <ul className="list-none gap-2 mt-4">
            {filteredPackages?.map((pkg) => {
              return (
                <li
                  key={pkg.ID}
                  className={`mb-2 flex shadow-sm rounded-md select-none cursor-pointer col-span-1 ${
                    packagesIdHash?.[pkg?.ID as number]
                      ? 'opacity-100'
                      : 'opacity-40'
                  } hover:opacity-100`}
                  onClick={() => handleAddPackages(pkg.ID)}
                >
                  <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-300 dark:border-darkGray-600 bg-white dark:bg-darkGray-800 rounded-r-md">
                    <div className="flex-1 px-4 py-2 text-sm">
                      <div className="flex gap-4 justify-between">
                        <div className="flex gap-4">
                          <span title={pkg.name}>{pkg.name}</span>
                          <span>${pkg.price}</span>
                        </div>
                        {packagesIdHash?.[pkg?.ID as number] && (
                          <CheckCircleIcon className="h-8 w-8 text-primary-500 mr-2" />
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <Button
            text="Close"
            color={ButtonColors.plainWithBorder}
            className="mt-4"
            onClick={() => {
              close();
            }}
          />
        </>
      )}
    </Modal>
  );
};

export default PurchasingPackages;
