import {
  PatientTransaction,
  TransactionTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { CogIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { LockButtonStateType } from './ConsolidatedTransactionModal';

type UnMergeButtonProps = {
  isBillingStarted: boolean | undefined;
  state: LockButtonStateType | null | undefined;
  transaction: PatientTransaction | null | undefined;
  readonly: boolean;
  onClick?: (e: any) => void;
  componentClassName?: string;
  iconContainerClassName?: string;
};
const UnMergeButtonContent: React.FC<UnMergeButtonProps> = ({
  isBillingStarted,
  state,
  transaction,
  readonly,
  onClick = (e: any) => {
    e?.stopPropagation?.();
    e?.preventDefault?.();
    console.log('TODO: unmerge');
  },
  componentClassName = [
    'flex-row',
    'flex',
    'font-bold',
    'font-sans',
    'justify-end',
    'mb-4',
    'pl-1',
    'p-1',
    'rounded-md',
    'space-x-1',
    'text-sm',
  ].join(' '),
  iconContainerClassName = 'flex items-center space-x-1',
}: UnMergeButtonProps) => {
  const iconClassName = 'h-4 w-4';
  // You can't unmerge the merged, you have to unmerge the mergee, as it were.
  if (
    transaction?.type !== TransactionTypeEnum.Encounter &&
    transaction?.type !== TransactionTypeEnum.Hybrid &&
    !transaction?.merged &&
    state?.locked
  ) {
    return null;
  }

  return (
    <div
      className={classNames(
        componentClassName,
        readonly || state?.locked
          ? 'text-gray-400 cursor-not-allowed'
          : isBillingStarted
            ? 'text-accent-400 cursor-pointer hover:text-accent-600'
            : 'text-primary-400 cursor-pointer hover:text-primary-600',
      )}
      onClick={readonly ? undefined : onClick}
    >
      {' '}
      {readonly || state?.locked ? (
        <div className={iconContainerClassName}>
          <CogIcon className={iconClassName} />
          <span>Merged</span>
        </div>
      ) : (
        <div className={iconContainerClassName}>
          <CogIcon className={iconClassName} />
          <span>Unmerge</span>
        </div>
      )}
    </div>
  );
};

export default UnMergeButtonContent;
