import { useContext } from 'react';
import {
  QueryFunctionContext,
  useInfiniteQuery,
  useQueryClient,
} from 'react-query';
import { MeContext } from '../../../../../contexts/me.context';
import patientBillingService from '../../../../../services/patientBilling.service';
import { PaymentFilters } from './usePayments';
import { PatientTransaction } from '@chiroup/core/types/PatientTransaction.type';

export type TransactionFilters = {
  location?: string;
  clinician?: string;
  start?: string;
  end?: string;
  outstanding?: boolean;
  outstandingPatientBalance?: boolean;
  showFutureTransactions?: string;
};

const getQuery = function (
  clinicId: number,
  limit?: number,
  passedTransactions?: PatientTransaction[],
) {
  return async (context: QueryFunctionContext) => {
    if (!clinicId) {
      return [];
    }
    if (passedTransactions?.length) {
      return {
        data: passedTransactions,
        skip: 0,
      };
    }
    const patient = context.queryKey[1] as string;
    const filters = context.queryKey[2] as TransactionFilters;

    return patientBillingService.listPatientTransaction(clinicId, {
      ...filters,
      skip: context.pageParam,
      limit,
      patient,
    });
  };
};

const useTransactions = function ({
  patientId,
  filters,
  passedTransactions = [],
}: {
  patientId?: string;
  filters: TransactionFilters;
  passedTransactions?: PatientTransaction[];
}) {
  const queryClient = useQueryClient();
  const { me } = useContext(MeContext);
  const {
    status,
    data,
    error,
    isFetching,
    refetch,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<{
    data: any[];
    skip: number;
  }>(
    ['transactions', patientId, filters],
    getQuery(me?.selectedClinic?.ID || -1, 20, passedTransactions),
    {
      getNextPageParam: (lastGroup) => lastGroup?.skip || undefined,
      refetchOnWindowFocus: false,
    },
  );

  const deletePaymentTransaction = async (
    billingKey: string,
    filters: PaymentFilters,
  ) => {
    await patientBillingService.deletePaymentTransaction(
      me?.selectedClinic?.ID || -1,
      billingKey,
    );
    queryClient.setQueryData(
      ['payments', patientId, filters, false],
      (prev: any) => {
        return {
          ...prev,
          pages: prev.pages.map((page: any) => {
            return {
              ...page,
              data: page.data.map((payment: any) => {
                if (payment.billingKey !== billingKey) return payment;
                payment.isVoided = true;
                return payment;
              }),
            };
          }),
        };
      },
    );
    clearPatientBillingBalances();
  };

  const refundPaymentTransaction = async (
    billingKey: string,
    filters: PaymentFilters,
  ) => {
    await patientBillingService.refundPaymentTransaction(
      me?.selectedClinic?.ID || -1,
      billingKey,
    );

    queryClient.setQueryData(
      ['payments', patientId, filters, false],
      (prev: any) => {
        return {
          ...prev,
          pages: prev.pages.map((page: any) => {
            return {
              ...page,
              data: page.data.map((payment: any) => {
                if (payment.billingKey === billingKey) {
                  return {
                    ...payment,
                    isRefunded: true,
                    balance: 0,
                  };
                }
                return payment;
              }),
            };
          }),
        };
      },
    );
    clearPatientBillingBalances();
  };

  const clearPatientBillingBalances = () => {
    queryClient.invalidateQueries({
      queryKey: ['patientsBillingBalances', patientId],
      exact: true,
    });
  };

  const clear = () => {
    queryClient.invalidateQueries({
      queryKey: ['transactions', patientId],
      exact: false,
    });
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    deletePaymentTransaction,
    clear,
    refundPaymentTransaction,
  };
};

export default useTransactions;
