import { useContext, useMemo, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import patientBillingService from '../../../../../services/patientBilling.service';
import { TransactionClaimsWriteOffInfo } from '@chiroup/core/types/PatientVisit.type';

const getClaimWriteOffInfo = ({
  clinicId,
  billingKey,
  isNew,
}: {
  isNew?: boolean;
  clinicId?: number;
  billingKey?: string;
}) => {
  if (!billingKey || clinicId === -1) {
    return async () => {
      return null;
    };
  }
  return async (context: QueryFunctionContext) => {
    if (billingKey === 'new') return null;
    if (isNew) return null;
    if (!clinicId || !billingKey) {
      return;
    }
    return patientBillingService.getClaimWriteOffInfo({
      clinicId,
      billingKey,
    }) as unknown as TransactionClaimsWriteOffInfo;
  };
};

const useClaimWriteOff = ({
  clinicId,
  billingKey,
  isErrorOkay = false,
  isNew,
}: {
  clinicId?: number;
  billingKey?: string;
  isErrorOkay?: boolean;
  isNew?: boolean;
}) => {
  const { createToast } = useContext(ToastContext);
  const [isRestActive, setIsRestActive] = useState(false);

  if (!clinicId || !billingKey) {
    if (!isErrorOkay) throw new Error('clinicId and billingKey are required.');
    clinicId = -1;
    billingKey = 'new';
  }

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['claimWriteOffInfo', billingKey],
    queryFn: getClaimWriteOffInfo({ clinicId, billingKey, isNew }),
    enabled: !!clinicId && !!billingKey && !isNew,
    refetchOnWindowFocus: false,
    staleTime: 5000,
    retry: 0,
    keepPreviousData: false,
    onError: (error: any) => {
      createToast({
        title: `Fetch Write-off Info`,
        description: `${
          error?.response?.data?.message ?? error?.message ?? 'Unknown error.'
        }`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    },
    onSettled: () => {
      setIsRestActive(false);
    },
  });

  const writeOffClaims = async ({
    clinicId,
    billingKey,
    amount,
  }: {
    clinicId: number;
    billingKey: string;
    amount: number;
  }) => {
    setIsRestActive(true);
    try {
      await patientBillingService.writeOffTransactionClaims({
        clinicId,
        billingKey,
        body: { amount },
      });

      refetch();
    } catch (error: any) {
      createToast({
        title: `Write-off Claim Response`,
        description: `${
          error?.response?.data?.message ?? error?.message ?? 'Unknown error.'
        }`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    }
    setIsRestActive(false);
  };

  return {
    data: data || null,
    isFetching,
    isRestActive,
    refetch,
    writeOffClaims,
  };
};

export default useClaimWriteOff;
