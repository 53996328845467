import {
  Button,
  ButtonColors,
  Checkbox,
  ConfirmModal,
  Input,
  InputMasked,
} from '@chiroup/components';
import {
  PaymentMethodType,
  PaymentMethodUpdateType,
} from '@chiroup/core/types/PatientBillling.type';
import { useForm } from '@chiroup/hooks';
import { TrashIcon } from '@heroicons/react/24/outline';
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/solid';
import React, { useContext, useState } from 'react';
import { MeContext } from '../../contexts/me.context';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import patientBillingService from '../../services/patientBilling.service';
import IconButton from '../common/IconButton';

type Props = {
  paymentMethod: PaymentMethodType;
  selected?: PaymentMethodType | null;
  setSelected?: (paymentMethod: PaymentMethodType | null) => void;
  disabled?: boolean;
  patientId: string;
  custKey: string;
  refetchEpayCustomer: () => void;
  merchantId?: number;
};

const maskCreditCard = (lastFourDigits: string, cardType: string) => {
  if (!lastFourDigits || lastFourDigits.length !== 4) {
    return;
  }

  // if (
  //   !['Visa', 'Mastercard', 'Discover', 'American Express'].includes(cardType)
  // ) {
  //   return;
  // }
  const maskedPart = 'xxxx xxxx xxxx ';
  return maskedPart + lastFourDigits;
};

const PaymentMethodItem: React.FC<Props> = ({
  paymentMethod,
  selected,
  setSelected,
  disabled = false,
  patientId,
  custKey,
  merchantId,
  refetchEpayCustomer,
}) => {
  const { createToast } = useContext(ToastContext);
  const { me } = useContext(MeContext);
  const { value, onChange, errors, isSubmitting, isDirty, registerSubmit } =
    useForm<PaymentMethodUpdateType>(
      {
        expires: paymentMethod.expires,
        method_name: paymentMethod.method_name,
        cardholder: paymentMethod.cardholder,
        avs_street: paymentMethod.avs_street,
        avs_postalcode: paymentMethod.avs_postalcode,
        key: paymentMethod.key,
        patientId,
        clinicId: me?.selectedClinic?.ID || -1,
        custKey,
      },
      {
        expires: {
          required: {
            message: 'Expiration date is required.',
          },
        },
        cardholder: {
          required: {
            message: 'Card holder name is required.',
          },
        },
        avs_street: {
          required: {
            message: 'Street address is required.',
          },
        },
        avs_postalcode: {
          required: {
            message: 'Zip code is required.',
          },
        },
      },
    );
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const onSubmit = async (value: Partial<PaymentMethodUpdateType>) => {
    value.merchantId = merchantId;
    await patientBillingService.updateCustomerPaymentMethod(value);
  };

  const onSuccess = (type: string) => {
    createToast({
      title: 'Success',
      description: `Payment method ${type} successfully`,
      type: ToastTypes.Success,
      duration: 5000,
    });
    refetchEpayCustomer();
    setIsEditing(false);
  };

  const onFail = (type: string) => {
    createToast({
      title: 'Failed',
      description: `Failed to ${type} payment method`,
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };

  const onDelete = async () => {
    try {
      setIsDeleting(true);
      value.merchantId = merchantId;
      await patientBillingService.deleteCustomerPaymentMethod(value);
      setConfirmDelete(false);
      setIsEditing(false);
      setIsDeleting(false);
      onSuccess('deleted');
    } catch (error) {
      setIsDeleting(false);
      onFail('delete');
    }
  };
  return (
    <div
      className={`p-4 rounded-md border border-gray-300 bg-white drop-shadow-md transition-all duration-400 w-full ${
        isEditing ? 'w-96 h-auto' : 'w-72 h-auto'
      }`}
    >
      <div className="flex items-center justify-between">
        <p className="text-md font-semibold flex-grow">
          {paymentMethod.card_type}
        </p>
        <div className="flex items-center space-x-2">
          {!isEditing ? (
            <IconButton
              icon={
                <PencilIcon className="text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300" />
              }
              className="h-4 w-4 text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300"
              onClick={() => setIsEditing(!isEditing)}
              tooltip="Edit"
              disabled={isSubmitting || disabled || isDeleting}
            />
          ) : (
            <IconButton
              icon={<XMarkIcon />}
              className="h-4 w-4 text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300"
              onClick={() => setIsEditing(!isEditing)}
              tooltip="Cancel"
              disabled={isSubmitting || disabled || isDeleting}
            />
          )}
        </div>
      </div>

      <p className="text-sm">{`Card Number: ${maskCreditCard(
        paymentMethod.ccnum4last,
        paymentMethod.card_type,
      )}`}</p>
      {isEditing ? (
        <div className="grid grid-cols-3 gap-2">
          <InputMasked
            label="Expiration"
            className="col-span-1"
            value={value.expires}
            onChange={onChange('expires')}
            placeholder="MM/YY"
            patternFormat="##/##"
            name="expirationDate"
            errors={errors?.fieldErrors?.expires}
            disabled={isSubmitting || disabled || isDeleting}
          />
          <Input
            label="Nickname"
            className="col-span-2"
            value={value.method_name}
            onChange={onChange('method_name')}
            name="method_name"
            errors={errors?.fieldErrors?.method_name}
            disabled={isSubmitting || disabled || isDeleting}
          />
        </div>
      ) : (
        <div>
          <p className="text-sm">{`Expiration: ${paymentMethod.expires}`}</p>
          <p className="text-sm">{`Nickname: ${paymentMethod.method_name}`}</p>
        </div>
      )}
      {isEditing && (
        <div className="grid grid-cols-5 gap-2">
          <Input
            label="Card Holder"
            name="card_holder"
            value={value.cardholder}
            onChange={onChange('cardholder')}
            className="col-span-5"
            errors={errors?.fieldErrors?.cardholder}
            disabled={isSubmitting || disabled || isDeleting}
          />
          <Input
            label="Street Address"
            name="avs_street"
            className="col-span-3"
            value={value.avs_street}
            onChange={onChange('avs_street')}
            disabled={isSubmitting || disabled || isDeleting}
            errors={errors?.fieldErrors?.avs_street}
          />
          <InputMasked
            label="Zip Code"
            name="avs_zip"
            className="col-span-2"
            value={value.avs_postalcode}
            onChange={onChange('avs_postalcode')}
            patternFormat="#####"
            disabled={isSubmitting || disabled || isDeleting}
            errors={errors?.fieldErrors?.avs_postalcode}
          />
        </div>
      )}
      {isEditing ? (
        <div className="flex justify-end mt-4 gap-4">
          <Button
            className="btn btn-primary"
            onClick={() => setConfirmDelete(true)}
            text="Delete"
            color={ButtonColors.red}
            loading={isDeleting}
            disabled={isSubmitting || disabled || isDeleting}
          />
          <Button
            className="btn btn-primary"
            onClick={registerSubmit(onSubmit, {
              onSuccess: () => onSuccess('updated'),
              onFail: () => onFail('update'),
            })}
            text="Update"
            loading={isSubmitting}
            disabled={isSubmitting || !isDirty || disabled}
          />
        </div>
      ) : setSelected ? (
        <Checkbox
          label="Use this card"
          className="mt-2"
          onChange={(e) => {
            setSelected(e ? paymentMethod : null);
          }}
          disabled={
            (selected && selected?.key !== paymentMethod.key) ||
            disabled ||
            false
          }
        />
      ) : null}
      <ConfirmModal
        isOpen={confirmDelete}
        close={() => setConfirmDelete(false)}
        icon={<TrashIcon className="h-6 w-6 text-red-600" />}
        confirm={onDelete}
        title="Delete Payment Method"
        description="Are you sure you want to delete this payment method?"
        confirmText="Delete"
        cancelText="Cancel"
        confirmDisabled={isSubmitting || disabled || isDeleting}
        loading={isDeleting}
        isDelete
      />
    </div>
  );
};

export default PaymentMethodItem;
