import {
  Button,
  ButtonColors,
  Checkbox,
  Input,
  InputMasked,
  LoadingPage,
  Select,
} from '@chiroup/components';
import { STRING_BOOLEAN_HASH } from '@chiroup/core/constants/globals';
import { EPayStatus } from '@chiroup/core/enums/EPayStatus.enum';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import { titleCaseEnumToArrayOfOptions } from '@chiroup/core/functions/enumToArrayOfOptions';
import { formatCurrency } from '@chiroup/core/functions/format';
import { CCPaymentFields } from '@chiroup/core/types/PatientBillling.type';
import {
  PatientPayment,
  PaymentType,
  PaymentTypePatient,
} from '@chiroup/core/types/PatientPayment.type';
import {
  PatientTransaction,
  PatientTransactionClass,
} from '@chiroup/core/types/PatientTransaction.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { useForm } from '@chiroup/hooks';
import { XCircleIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Modal from '../../../../../components/common/Modal';
import useEpayCustomer from '../../../../../components/patient-billing/hooks/useEpayCustomer';
import { MeContext } from '../../../../../contexts/me.context';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import patientBillingService from '../../../../../services/patientBilling.service';
import useBillingSettings from '../../../../settings/clinic/useBillingSettings';
import usePatientBillingBalances from '../../../hooks/usePatientBillingBalances';
import useTerminalTransactionWatcher from '../hooks/useTerminalTransactionWatcher';
import useTransactions from '../hooks/useTransactions';
import CreditCardDeviceSelector from './CreditCardDeviceSelector';
import PaymentTransactionItem from './PaymentTransactionItem';
import usePatientPayment from './usePatientPayment';

export type PayRequest = {
  id: number;
  status: string | null;
  error?: string | null;
};

type Props = {
  isOpen?: boolean;
  isRouted?: boolean;
  refetch?: () => void;
  credits?: boolean;
  clear?: () => void;
  passedTransactions?: PatientTransaction[];
  disableCreateCredit?: boolean;
  callbacks: {
    onSaving?: () => void;
    onSuccess?: () => void;
    onFail?: (error: any) => void;
    onClose?: (dirty?: boolean) => void;
    finally?: () => void;
  };
};

const ConsolidatedPaymentModal: React.FC<Props> = ({
  isOpen,
  passedTransactions = [],
  isRouted = true,
  disableCreateCredit = false,
  callbacks,
}) => {
  const patientId = useMemo(() => {
    return passedTransactions?.[0]?.patient?.id ?? '';
  }, [passedTransactions]);

  const {
    data: payment,
    save,
    selectedPaymentCard,
    setSelectedPaymentCard,
    clear,
  } = usePatientPayment({
    patientId,
    paymentId: 'new',
    // bulkResponse: Prevents extra REST calls by returning everything needed
    // in one glob. The hook updates via queryData to update the UI. [MAGIC]
    bulkResponse: true,
  });

  const { data: balances } = usePatientBillingBalances(patientId);
  const { data: billingSettings } = useBillingSettings();
  const { me, hasRole } = useContext(MeContext);
  const { createToast } = useContext(ToastContext);
  const openPaymentModal = useMemo(() => {
    if (isRouted) {
      return true;
    } else {
      return !!isOpen;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: transactions,
    // clear: clearTransactions,
    isFetching: isFetchingTransactions,
  } = useTransactions({
    patientId,
    filters: { outstandingPatientBalance: true },
    passedTransactions,
  });
  const [availableTransactions, setAvailableTransactions] = useState<any[]>([]);
  const [isEditingAppliedPayment, setIsEditingAppliedPayment] =
    useState<boolean>(false);

  const [collectingPayment, setCollectingPayment] = useState<boolean>(false);
  const [newChecked, setNewChecked] = useState<STRING_BOOLEAN_HASH>({});
  const [applyCredits, setApplyCredits] = useState(false);
  const [waitingForTerminal, setWaitingForTerminal] = useState<boolean>(false);
  const [allowContinue, setAllowContinue] = useState<boolean>(false);
  const [ccError, setCCError] = useState<string | null>(null);

  const { terminalTransaction, setTerminalTransaction } =
    useTerminalTransactionWatcher();

  useEffect(() => {
    if (selectedPaymentCard) {
      setCCError(null);
    }
  }, [selectedPaymentCard]);

  const isRefunded = useMemo(() => {
    return payment?.isRefunded;
  }, [payment]);

  const amountApplied = useMemo(() => {
    return availableTransactions.reduce(
      (a: number, item: any) => a + Number(item.paymentAmount || '0'),
      0,
    );
  }, [availableTransactions]);

  const processingFee = useMemo(() => {
    return (
      me?.selectedClinic?.settings?.find((s) => s.setting === 'General')
        ?.jsonValue?.ccFee / 100
    );
  }, [me?.selectedClinic?.settings]);

  const hideCreditTransfer = useMemo(() => {
    return billingSettings?.enableCreditTransfers !== true;
  }, [billingSettings]);

  const filteredPaymentTypes = useMemo(() => {
    return titleCaseEnumToArrayOfOptions(PaymentTypePatient).filter((o) => {
      if (hideCreditTransfer) {
        return o.value !== PaymentType.CreditTransfer;
      }
      return true;
    });
  }, [hideCreditTransfer]);

  const {
    value,
    onChange,
    errors,
    registerSubmit,
    isSubmitting,
    isDirty,
    patchValue,
  } = useForm<PatientPayment & CCPaymentFields>(
    payment?.id
      ? payment
      : {
          type: PaymentType.Cash,
          paymentDate: dayjs().format('MM/DD/YYYY'),
          transactionPayments: [],
          patientId,
        },
    {
      paymentDate: {
        required: {
          message: 'Payment date is required.',
        },
      },
      type: {
        function: {
          value: (value: Partial<PatientPayment>) => {
            const amount = Number(value.amount || 0);
            if (!!amount && !value.type) {
              return 'Payment Type is required.';
            }
            if (
              value.type === PaymentType.CreditCard &&
              value.deviceKey === 'manual'
            ) {
              const pickedSavedCard = !!value?.selectedPaymentCard?.key;
              if (pickedSavedCard) {
                return false;
              }
              if (!value?.epayPublicKey) {
                return 'Please select a payment method.';
              }
            }
            return false;
          },
        },
      },
      referenceNumber: {
        function: {
          value: (value: Partial<PatientPayment>) => {
            if (
              value?.type === 'check' &&
              value?.referenceNumber &&
              !value?.referenceNumber.toString().length
            ) {
              return 'Reference number is required.';
            }
            return false;
          },
        },
      },
      amount: {
        function: {
          value: (value: Partial<PatientPayment>) => {
            if (!collectingPayment) {
              return false;
            }
            const creditAmount = createDecimal(value.creditAmount || 0).toDP(2);
            const amount = createDecimal(value.amount || 0).toDP(2);
            if (creditAmount && creditAmount.greaterThan(0)) {
              // If we're applying credits, a payment amount isn't required.
              return false;
            }
            if (!amount || amount.lessThanOrEqualTo(0)) {
              return 'Amount is required.';
            }
            if (amount && amount.lessThanOrEqualTo(0)) {
              return 'Amount must be greater than 0.';
            }
            if (amount.lessThan(createDecimal(amountApplied).toDP(2))) {
              return "Amount can't be less than total due.";
            }
            return false;
          },
        },
      },
      creditAmount: {
        function: {
          value: (value: Partial<PatientPayment>) => {
            const creditAmount = createDecimal(value.creditAmount || 0).toDP(2);
            const amount = createDecimal(value.amount || 0).toDP(2);

            if (!value.creditAmount || creditAmount.lessThanOrEqualTo(0)) {
              return false;
            }
            if (creditAmount && creditAmount.lessThanOrEqualTo(0)) {
              return 'Credit amount must be greater than 0.';
            }
            if (creditAmount.greaterThan(balances?.creditBalance || 0)) {
              return 'Credit amount cannot exceed available credit.';
            }
            if (
              creditAmount
                .add(amount)
                .greaterThan(createDecimal(amountApplied).toDP(2))
            ) {
              return 'Credit amount cannot exceed total due.';
            }
            return false;
          },
        },
      },
      fname: {
        function: {
          value: (value: Partial<PatientPayment & CCPaymentFields>) => {
            if (value.deviceKey) return false;
            if (value.type === PaymentType.CreditCard && !value.fname) {
              return 'First name is required.';
            }
            return false;
          },
        },
      },
      lname: {
        function: {
          value: (value: Partial<PatientPayment & CCPaymentFields>) => {
            if (value.deviceKey) return false;
            if (value.type === PaymentType.CreditCard && !value.lname) {
              return 'Last name is required.';
            }
            return false;
          },
        },
      },
      postalCode: {
        function: {
          value: (value: Partial<PatientPayment & CCPaymentFields>) => {
            if (value.deviceKey) return false;
            if (value.type === PaymentType.CreditCard && !value.postalCode) {
              return 'Postal code is required.';
            }
            return false;
          },
        },
      },
      streetAddress: {
        function: {
          value: (value: Partial<PatientPayment & CCPaymentFields>) => {
            if (value.deviceKey) return false;
            if (value.type === PaymentType.CreditCard && !value.streetAddress) {
              return 'Street address is required.';
            }
            return false;
          },
        },
      },
    },
  );

  const {
    epayCustomer,
    refetch: refetchEpayCustomer,
    isFetching: isFetchingEpayCustomer,
  } = useEpayCustomer({ patientId, merchantId: value.merchantId });

  useEffect(() => {
    patchValue({
      selectedPaymentCard,
    });
  }, [selectedPaymentCard, patchValue]);

  useEffect(() => {
    if (
      !!value.amount &&
      (value.type === PaymentType.CreditCard ||
        value.type === PaymentType.CreditCardExternal) &&
      processingFee > 0
    ) {
      patchValue({
        processingFee: Number(
          createDecimal(processingFee)
            .times(createDecimal(Number(value.amount)))
            .toFixed(2),
        ),
      });
    } else {
      patchValue({
        processingFee: 0,
      });
    }
  }, [processingFee, patchValue, value.amount, value.type]);

  useEffect(() => {
    if (epayCustomer?.custid) {
      patchValue({
        fname: epayCustomer?.first_name || undefined,
        lname: epayCustomer?.last_name || undefined,
        postalCode: epayCustomer?.postalcode || undefined,
        streetAddress: epayCustomer?.street || undefined,
      });
    }
  }, [epayCustomer, patchValue]);

  useEffect(() => {
    if (
      isDirty &&
      !isEditingAppliedPayment &&
      !!value.transactionPayments?.length
    ) {
      setIsEditingAppliedPayment(true);
    }
  }, [isDirty, value.transactionPayments, isEditingAppliedPayment]);

  const remainder = useMemo(() => {
    const amounts = value?.amount
      ? value.amount -
        (value?.transactionPayments || []).reduce(
          (a, c) => (a = a + c.paymentAmount || 0),
          0,
        )
      : 0;
    const newAmounts = availableTransactions.reduce(
      (a: number, c: any, idx: number) => {
        if (!newChecked[idx]) return Number(a);
        if (c.paymentAmount) return Number(a) + Number(c.paymentAmount);
        if (c.balance || c.patientBalance) {
          return (
            Number(a) +
            (!Number.isNaN(c?.patientBalance)
              ? Number(c?.patientBalance)
              : Number(c.balance))
          );
        }
        return Number(a);
      },
      0,
    );
    return (Number(amounts) - Number(newAmounts)).toFixed(2);
  }, [value, availableTransactions, newChecked]);

  /**
   * Business Rule:
   *    The same transaction can have a piece of a payment or credit
   *    applied _multiple_ times. So, it can't be removed by the item's
   *    id, it has to be done by its ordinal position in the list just
   *    in case it appears multiple times.
   */
  const handleItemSelect = useCallback(
    (
      item: PatientTransaction | PatientTransactionClass,
      deselect = false,
      order = 0,
      available = false,
      amount = 0,
    ) => {
      if (available) {
        setNewChecked((prev) => ({ ...prev, [order]: amount > 0 }));
      }

      const clone = ChiroUpJSON.clone(value?.transactionPayments || []);
      const newAvailableItems = ChiroUpJSON.clone(availableTransactions);

      if (deselect && !available) {
        clone.splice(order, 1);
      } else {
        if (availableTransactions && availableTransactions[order]) {
          newAvailableItems[order].transactionId = item.id;
          // newAvailableItems[order].paymentAmount = item.balance || 0;
          // newAvailableItems[order].totalDue = Number(item.balance) || 0;
          newAvailableItems[order].billingKey = item.billingKey;
        }
      }

      const itemBalance = !Number.isNaN(Number(item?.patientBalance))
        ? Number(item?.patientBalance)
        : Number(item?.balance || 0);
      const creditAmount = Number(value?.amount || 0);
      const creditRemainder = Number(remainder) - itemBalance;
      if (deselect) {
        newAvailableItems[order].paymentAmount = 0;
      } else if (itemBalance > creditAmount) {
        newAvailableItems[order].paymentAmount =
          Number(value?.amount || '0') > 0 ? value?.amount : 0;
        setAvailableTransactions(newAvailableItems);
      } else if (creditRemainder < 0) {
        newAvailableItems[order].paymentAmount = Number(remainder);
        setAvailableTransactions(newAvailableItems);
      }

      if (!deselect) {
        newAvailableItems[order].paymentAmount = !Number.isNaN(
          newAvailableItems[order]?.patientBalance,
        )
          ? Number(newAvailableItems[order]?.patientBalance)
          : newAvailableItems[order].balance;
      }
      setAvailableTransactions(newAvailableItems);
      patchValue({ ...value, transactionPayments: clone });
    },
    [patchValue, availableTransactions, remainder, value],
  );

  const reset = useCallback(() => {
    setWaitingForTerminal(false);
    setTerminalTransaction(null);
  }, [setTerminalTransaction]);

  const closeModal = useCallback(
    (force = false) => {
      console.log({
        ConsolidatedPaymentModal: { isSubmitting, waitingForTerminal, force },
      });
      if ((isSubmitting || waitingForTerminal) && !force) {
        return;
      }
      reset();

      callbacks?.onClose?.();
    },
    [callbacks, isSubmitting, reset, waitingForTerminal],
  );

  const confirmSubmit = useCallback(
    async ({
      val,
      // refnum,
      // batchKey,
    }: {
      val: Partial<PatientPayment>;
      // refnum?: string;
      // batchKey?: string;
    }) => {
      /**
       * Okay, since this modal has two halves, we  need to grab the
       * pieces from the SECOND half and append them to the value so
       * the form can submit...I think.
       */
      const payload = JSON.parse(JSON.stringify(val));
      Object.keys(availableTransactions).forEach((key: string) => {
        if (newChecked[key]) {
          payload.transactionPayments.push(availableTransactions[Number(key)]);
        }
      });

      const res = await save({
        ...val,
        transactionPayments: payload.transactionPayments,
      });

      if (res?.id) {
        closeModal(true);
      }
      return res;
    },
    [availableTransactions, save, newChecked, closeModal],
  );

  const submit = async (val: Partial<PatientPayment>) => {
    if (!collectingPayment) {
      patchValue({ amount: amountApplied });
      setCollectingPayment(true);
      return {
        skipSuccess: true,
      };
    }
    if (!val.amount && !val.creditAmount) return;

    if (val.deviceKey && val.deviceKey !== 'manual') {
      const payload = JSON.parse(JSON.stringify(val));
      Object.keys(availableTransactions).forEach((key: string) => {
        if (newChecked[key]) {
          payload.transactionPayments.push(availableTransactions[Number(key)]);
        }
      });

      await sendNewPayRequest({
        ...val,
        transactionPayments: payload.transactionPayments,
        locationId: me?.selectedLocation,
      });
      return val;
    } else {
      return confirmSubmit({ val });
    }
  };

  const sendNewPayRequest = async (payment: any) => {
    if (!value.deviceKey) return;
    const { id } = await patientBillingService.createNewPayRequest({
      amount: value?.amount || 0,
      clinicId: me?.selectedClinic?.ID || -1,
      patientId,
      deviceKey: value.deviceKey,
      payment,
    });
    if (id) {
      setWaitingForTerminal(true);
      setTerminalTransaction({
        id,
        status: EPayStatus.Pending,
      });
    }
    return id;
  };

  const handleTypeChange = async (type: PaymentType) => {
    const isCheck = type === PaymentType.Check;
    // const isCredit = type === PaymentType.Credit;

    const hasReferenceNumber = !!value?.referenceNumber?.toString().length;

    const newValue = {
      ...value,
      type,
    };

    if (isCheck && hasReferenceNumber) {
      newValue.alertOverrode = false;
      newValue.referenceNumber = undefined;
    }

    patchValue(newValue);
    setTerminalTransaction(null);
  };

  const onSuccess = async (closeAndFetch = true, force?: boolean) => {
    if (closeAndFetch) {
      // clearTransactions();
      clear();
      setAvailableTransactions([]);
      closeModal(force);
      callbacks?.onSuccess?.();
      callbacks?.finally?.();
    }
  };

  const onFail = (error: any) => {
    const isEpayError = !!error?.response?.data?.fieldErrors?.ePay?.message;
    if (isEpayError) {
      onError(error.response.data.fieldErrors.ePay.message);
    } else {
      const isDuplicateRefNum =
        error?.response?.data?.fieldErrors?.referenceNumber?.code ===
        'DuplicateReferenceNumber';
      if (isDuplicateRefNum) {
        patchValue({ alertOverrode: true });
      }
      console.log({ error });
      const message = error?.response?.data?.message || error?.message;
      const errorCode = error?.response?.data?.code;

      createToast({
        title: 'Error!',
        description: message || `There was an error saving the payment.`,
        type: ToastTypes.Fail,
        duration: 5000,
      });

      if (errorCode === 'CREATE_PAYMENT_ERROR') {
        closeModal();
        callbacks?.onFail?.(error);
        callbacks?.finally?.();
      }
    }
  };

  const onError = useCallback((message: string | null) => {
    setCCError(message);
  }, []);

  useEffect(() => {
    if (isFetchingTransactions) return;
    const newbies = (transactions?.pages[0]?.data || [])?.map((transaction) => {
      return {
        ...transaction,
      };
    }) as PatientTransaction[];
    setAvailableTransactions(
      newbies.filter((t) => Number(t?.patientBalance || t?.balance || 0) > 0),
    );
  }, [isFetchingTransactions, transactions, transactions?.pages]);

  /**
   * This is weird and probably needs to be refactored. Basically,
   * there are two halves to the modal, the things that are there
   * and the NEW things that are being added. It looks like one
   * list but they are TWO arrays in this modal. So...the flag
   * 'available' is used to designate the ones that are new.
   */
  const updateSelectedItems = useCallback(
    (id: number, amount: number, order: number, available: boolean) => {
      const clone = ChiroUpJSON.clone(value?.transactionPayments || []);
      if (available) {
        const newItems = ChiroUpJSON.clone(availableTransactions);
        if (newItems[order]) {
          newItems[order].transactionId = id;
          newItems[order].paymentAmount = amount;
          newItems[order].billingKey = availableTransactions[order].billingKey;
        }
        setAvailableTransactions(newItems);
      } else {
        if (clone && clone[order]) {
          clone[order].paymentAmount = amount;
        }

        patchValue({ ...value, transactionPayments: clone });
      }
    },
    [patchValue, availableTransactions, value],
  );

  return (
    <Modal
      addClasses="max-w-4xl w-full"
      omitClasses="sm:max-w-lg"
      isOpen={openPaymentModal}
      close={() => closeModal(false)}
    >
      {/* <pre className="mt-12">{ChiroUpJSON.pretty(payment)}</pre> */}
      <form
        onSubmit={registerSubmit(submit as any, {
          onSuccess: () => {
            value.deviceKey ? onSuccess(false) : onSuccess();
          },
          onFail,
        })}
      >
        <div className="absolute top-0 w-full left-0 flex items-center justify-end gap-x-6 bg-primary-600 rounded-t-md px-6 py-2 h-10">
          {!!balances?.creditBalance && (
            <div className="text-white">
              Available Credit: {formatCurrency(balances?.creditBalance || 0)}
            </div>
          )}
        </div>
        <div className="mt-8">
          <div className="mt-3 text-center sm:mt-5 flex flex-col gap-6">
            <div className="flex flex-col">
              <h3
                className="text-lg font-medium leading-6 text-gray-900 dark:text-darkGray-100"
                id="modal-headline"
              >
                Add Payment
              </h3>
              {isRefunded && (
                <h3 className="mt-8 inline-flex items-center text-center px-2 py-1 text-md font-medium text-primary-700 rounded-md bg-primary-50 ring-1 ring-inset ring-primary-600/50">
                  <span>Payment has been refunded.</span>
                </h3>
              )}
            </div>
          </div>
          {!collectingPayment ? (
            <div className="flex flex-col text-left w-full">
              {isFetchingTransactions ? (
                <LoadingPage title="Loading Transactions" />
              ) : (
                <div className="mt-2">
                  {!disableCreateCredit && (
                    <Checkbox
                      label="Create credit"
                      name="createCredit"
                      className="pb-4"
                      value={allowContinue}
                      onChange={(e) => {
                        setAllowContinue(e);
                        setAvailableTransactions([]);
                      }}
                      disabled={
                        isSubmitting || waitingForTerminal || isRefunded
                      }
                    />
                  )}
                  {allowContinue ? null : availableTransactions?.length ===
                    0 ? (
                    <p className="text-md text-center mb-2 leading-5 text-gray-500 dark:text-darkGray-400">
                      No transactions available to apply payment.
                    </p>
                  ) : (
                    <>
                      <p className="text-md text-center mb-2 leading-5 text-gray-500 dark:text-darkGray-400">
                        Select transaction(s) to apply payment.
                      </p>

                      <ul className="divide-y divide-gray-100">
                        {availableTransactions?.map((transaction, index) => {
                          return (
                            <PaymentTransactionItem
                              order={index}
                              item={transaction}
                              key={index}
                              handleItemSelect={handleItemSelect}
                              updateSelectedItems={updateSelectedItems}
                              value={value}
                              isNew={true}
                              disabled={
                                isSubmitting || waitingForTerminal || isRefunded
                              }
                              available={true}
                              patientId={patientId}
                            />
                          );
                        })}
                      </ul>
                    </>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="text-center text-lg">
                Total Due: {formatCurrency(amountApplied)}
              </div>
              {isEditingAppliedPayment && (
                <p className="mt-8 inline-flex items-center px-2 py-1 text-xs font-medium text-red-700 rounded-md bg-red-50 ring-1 ring-inset ring-red-600/50">
                  <span>
                    Alert: Payment has been applied! Please note that any
                    modifications to this Payment could affect the associated
                    invoices and applied payments. Exercise caution while making
                    changes.
                  </span>
                </p>
              )}
              <div className="flex flex-col sm:flex-row sm:gap-2 w-full">
                <InputMasked
                  name="paymentDate"
                  label="Transaction date *"
                  value={value.paymentDate}
                  onChange={onChange('paymentDate')}
                  errors={errors.fieldErrors?.paymentDate}
                  className="w-full"
                  patternFormat="##/##/####"
                  placeholder="MM/DD/YYYY"
                  disabled={isSubmitting || waitingForTerminal || isRefunded}
                />
                <InputMasked
                  className="w-full"
                  label={`Payment amount *`}
                  name="amount"
                  placeholder="0.00"
                  value={value.amount}
                  onChange={onChange('amount')}
                  onBlur={(val) => {
                    if (Number(val || 0) <= 0) {
                      patchValue({
                        type: null,
                      });
                    }
                  }}
                  errors={errors?.fieldErrors?.amount}
                  numericOptions={{
                    decimalScale: 2,
                    fixedDecimalScale: true,
                    allowNegative: false,
                    // prefix: '$',
                  }}
                  disabled={waitingForTerminal || isSubmitting || isRefunded}
                  hint={
                    !!value.amount && value?.amount > amountApplied
                      ? `${
                          allowContinue ? '' : 'An additional'
                        } ${formatCurrency(
                          value?.amount - amountApplied,
                        )} will be
                    applied as a credit.`
                      : null
                  }
                />
              </div>

              {Number(value.amount || 0) > 0 && (
                <Select
                  name="type"
                  label="Payment type *"
                  options={filteredPaymentTypes}
                  onChange={handleTypeChange}
                  value={value.type}
                  limit={1}
                  errors={errors.fieldErrors?.type}
                  disabled={waitingForTerminal || isRefunded}
                />
              )}

              {value.type === PaymentType.Check && (
                <Input
                  name="referenceNumber"
                  label="Reference number"
                  value={value?.referenceNumber || ''}
                  onChange={(val: number) => {
                    patchValue({
                      referenceNumber: val,
                      alertOverrode: false,
                    });
                  }}
                  errors={errors.fieldErrors?.referenceNumber}
                  disabled={isSubmitting || isRefunded}
                  type="number"
                />
              )}
              <Input
                name="description"
                label="Description"
                value={value?.description || ''}
                onChange={onChange('description')}
                errors={errors.fieldErrors?.description}
                disabled={isSubmitting || waitingForTerminal || isRefunded}
              />
              {!!balances?.creditBalance && (
                <>
                  <Checkbox
                    label="Apply credit"
                    name="applyCredit"
                    className="pt-4"
                    value={applyCredits}
                    onChange={(e) => {
                      setApplyCredits(e);
                      patchValue({ creditAmount: null });
                    }}
                    disabled={isSubmitting || waitingForTerminal || isRefunded}
                  />
                  {!!applyCredits && (
                    <InputMasked
                      className="w-full"
                      label="Credit Amount"
                      name="creditAmount"
                      placeholder="0.00"
                      value={value.creditAmount}
                      onChange={(val) => {
                        onChange('creditAmount')(val);
                      }}
                      errors={errors?.fieldErrors?.creditAmount}
                      numericOptions={{
                        decimalScale: 2,
                        fixedDecimalScale: true,
                        allowNegative: false,
                        // prefix: '$',
                      }}
                      disabled={isSubmitting}
                    />
                  )}
                </>
              )}
              {value.type === PaymentType.CreditCardExternal && (
                <div className="mt-6">
                  {processingFee > 0 && (
                    <p className="text-sm font-light leading-6 text-gray-900 dark:text-darkGray-100">
                      {`A processing fee of ${formatCurrency(
                        Number(
                          createDecimal(processingFee)
                            .times(createDecimal(Number(value.amount)))
                            .toFixed(2),
                        ),
                      )} will be applied to this transaction.`}
                    </p>
                  )}
                </div>
              )}
              {value.type === PaymentType.CreditCard && (
                <div className="mt-6">
                  {processingFee > 0 && (
                    <p className="text-sm font-light leading-6 text-gray-900 dark:text-darkGray-100">
                      {`A processing fee of ${formatCurrency(
                        Number(
                          createDecimal(processingFee)
                            .times(createDecimal(Number(value.amount)))
                            .toFixed(2),
                        ),
                      )} will be applied to this transaction.`}
                    </p>
                  )}
                  <CreditCardDeviceSelector
                    value={value}
                    onChange={onChange}
                    disabled={
                      !!(isSubmitting || waitingForTerminal || isRefunded)
                    }
                    patchValue={patchValue}
                    errors={errors}
                    isLoading={isFetchingEpayCustomer}
                    isSubmitting={isSubmitting}
                    waitingForTerminal={waitingForTerminal}
                    payment={payment as PatientPayment}
                    refetchEpayCustomer={refetchEpayCustomer}
                    selectedPaymentCard={selectedPaymentCard}
                    epayCustomer={epayCustomer}
                    setSelectedPaymentCard={setSelectedPaymentCard}
                    setTerminalTransaction={setTerminalTransaction}
                    onError={onError}
                    setWaitingForTerminal={setWaitingForTerminal}
                    terminalTransaction={terminalTransaction}
                    onSuccess={onSuccess}
                  />
                </div>
              )}
              {!!ccError && (
                <div className="rounded-md bg-red-50 p-4 mt-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        Error
                      </h3>
                      <div className="mt-2 text-sm text-red-700">
                        <ul className="list-disc space-y-1 pl-5">
                          <li>{ccError}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={'mt-6 flex flex-row gap-2 justify-between'}>
          <div className="flex flex-row gap-2">
            <Button
              text="Close"
              onClick={() => closeModal(false)}
              color={ButtonColors.plainWithBorder}
              disabled={isSubmitting || waitingForTerminal}
            />
            {collectingPayment && (
              <Button
                text={`Back`}
                onClick={() => {
                  setCollectingPayment(false);
                  patchValue({
                    amount: undefined,
                    creditAmount: undefined,
                    type: undefined,
                  });
                }}
                color={ButtonColors.plainWithBorder}
              />
            )}
          </div>
          {!isRefunded &&
            hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) && (
              <Button
                text={
                  collectingPayment
                    ? 'Create'
                    : amountApplied > 0
                      ? `Collect ${formatCurrency(amountApplied)}`
                      : 'Collect'
                }
                type="submit"
                loading={isSubmitting || waitingForTerminal}
                disabled={
                  collectingPayment
                    ? isSubmitting ||
                      waitingForTerminal ||
                      (!value.amount && !value.creditAmount) ||
                      isRefunded
                    : !allowContinue && amountApplied <= 0
                }
              />
            )}
        </div>
      </form>
    </Modal>
  );
};

export default ConsolidatedPaymentModal;
