import {
  AlertBlock,
  Button,
  ButtonColors,
  Loading,
  MakeBrowserWait,
} from '@chiroup/components';
import {
  NUMBER_ANY_HASH,
  STRING_ANY_HASH,
  STRING_BOOLEAN_HASH,
} from '@chiroup/core/constants/globals';
import { ChiroUpTransactionCommon } from '@chiroup/core/constants/stringConstants';
import { balanceFromItems } from '@chiroup/core/functions/balanceFromItems';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import { findNewDiscount } from '@chiroup/core/functions/findNewDiscount';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import { Insurance } from '@chiroup/core/types/PatientInsurance.type';
import {
  isaPaymentItem,
  PatientTransaction,
  PatientTransactionItemType,
  toTransactionItemSubtype,
  TransactionAppendItemsType,
  TransactionItemSubtypeEnum,
  TransactionItemTypeEnum,
  TransactionTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { ClaimInfo } from '@chiroup/core/types/PatientVisit.type';
import {
  ArrowUpCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { v4 } from 'uuid';
import { MeContext } from '../../../../../contexts/me.context';
import { TransactionContext } from '../../../../../contexts/transaction.context';
import useApplicablePatientPackageList from '../../../../../hooks/useApplicablePatientPackageList';
import useResponsibilities from '../../../../../hooks/useResponsibilities';
import ButtonGroup, {
  ButtonGroupType,
} from '../../../../common/buttons/ButtonGroup';
import useDisciplineTreatments from '../../../../settings/clinic/useDisciplineTreatments';
import ClaimInfoForm from '../../visits/notes/ClaimInfoForm';
import CodeEncounterDiagnosisServices from '../../visits/notes/services/CodeEncounterDiagnosisServices';
import { useTransaction } from '../hooks/useTransaction';
import AllocateNonBillableToPatientModal from './AllocteNonBillableToPatientModal';
import ChoosePackageModal from './ChoosePackageModal';
import ConsolidatedPaymentModal from './ConsolidatedPaymentModal';
import {
  ActionsOnClick,
  BASE_TAB,
  ClassNames,
  EncounterContext,
  LockButtonStateType,
  Phase,
  phases,
} from './ConsolidatedTransactionModal';
import LockButtonContent from './LockButtonContext';
import PurchasingPackagesModal from './PurchasingPackagesModal';
import SingleAdHoc from './SingleAdHoc';
import SupplementsModal from './SupplementsModal';
import TransactionEditorTabContent from './TransactionEditorTabContent';
import TransactionEditorTabs from './TransactionEditorTabs';
import UnMergeButtonContent from './UnmergeContentButton';
import WriteOffClaimsModal from './WriteOffClaimsModal';
import WriteOffNonBillableBalanceModal from './WriteOffNonBillableBalanceModal';

type SingleProps = {
  actionsOnClick: (
    action: ActionsOnClick,
    transaction: PatientTransaction | null | undefined,
  ) => void;
  context: NUMBER_ANY_HASH;
  currentPhase: Phase;
  encounterContext?: EncounterContext | null | undefined;
  getSingleTransaction: STRING_ANY_HASH;
  hoverTextPrimary: string;
  isBalanceTransfer: boolean;
  isFetching: boolean;
  isNotReadOnly: boolean;
  isReadOnly: boolean;
  isSaving: boolean;
  lockButtonStateByProviderId?: { [key: string]: LockButtonStateType };
  mergeContext: any;
  ord: number;
  policies: Insurance[];
  refetch: () => void;
  row: PatientTransaction;
  setBackgroundDirty: Dispatch<SetStateAction<boolean | null>>;
  setContext: Dispatch<SetStateAction<NUMBER_ANY_HASH>>;
  setCurrentPhase: Dispatch<SetStateAction<Phase>>;
  setPayAvailability: Dispatch<SetStateAction<STRING_BOOLEAN_HASH>>;
  textPrimary: string;
  trace?: boolean;
  close?: () => void;
};
const SingleTransaction: React.FC<SingleProps> = ({
  actionsOnClick,
  context,
  currentPhase,
  encounterContext,
  getSingleTransaction,
  hoverTextPrimary,
  isBalanceTransfer,
  isFetching,
  isNotReadOnly,
  isReadOnly,
  isSaving,
  lockButtonStateByProviderId,
  mergeContext,
  ord,
  policies,
  setPayAvailability,
  refetch,
  row,
  setBackgroundDirty,
  setContext,
  setCurrentPhase,
  textPrimary,
  trace,
  close,
}) => {
  const uId = useId();
  /**
   * u s e S t a t e
   */
  const [isWriteOffClaimsModalOpen, setIsWriteOffClaimsModalOpen] =
    useState<boolean>(false);
  const [expandedClaimInfo, setExpandedClaimInfo] = useState<boolean>(false);
  const [traceOpen, setTraceOpen] = useState<boolean>(!trace); // Initially closed.
  const [showSupplementsModal, setShowSupplementsModal] =
    useState<boolean>(false);
  const [isPurchasePackageModal, setIsPurchasePackageModal] =
    useState<boolean>(false);
  const [isChoosePackageModal, setIsChoosePackageModal] =
    useState<boolean>(false);
  const [triggerAppendItems, setTriggerAppendItems] =
    useState<TransactionAppendItemsType | null>(null);
  const [localRow, setLocalRow] = useState<PatientTransaction | null>(row);

  const editingNewTransaction = useMemo(() => {
    return (localRow?.id ?? -1) === -1;
  }, [localRow]);

  const [activeSubtype, setActiveSubtype] = useState<string>('');
  const [isWritingOff, setIsWritingOff] = useState<boolean>(false);
  const [isAllocating, setIsAllocating] = useState<boolean>(false);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);

  const [showAllocacteNonBillableModal, setShowAllocateNonBillableModal] =
    useState<boolean>(false);
  const [fetchingAllocatableBalance] = useState<boolean>(false);
  const [showWriteOffNonBillableModal, setShowWriteOffNonBillableModal] =
    useState<boolean>(false);
  const [activeTabName, setActiveTabName] = useState<string>(BASE_TAB);

  // const [diagnosesOrder, setDiagnosesOrder] = useState<string[]>([]);

  const [incomingClaimInfo] = useState<ClaimInfo | null>(null);

  // const [isTransactionLocked, setIsTransactionLocked] =
  //   useState<boolean>(false);

  /**
   * H o o k s
   */
  const myRef = useRef(null);

  const { pathname } = useLocation();

  const { me, selectedLocationFull, /* hasRole, */ hasAccess } =
    useContext(MeContext);

  const { data: patientPackages } = useApplicablePatientPackageList({
    patientId: row?.patient?.id,
    locationId: row?.locationId,
  });

  const { data: disciplineTreatment } = useDisciplineTreatments(
    row?.treatmentId,
  );

  const {
    status,
    data,
    // isFetching,
    // refetch: refetchMyTransaction,
    insurances,
    payors,
    snapshot,
    locked,
    services,
    setServices,
    setSnapshot,
    setInsurances,
    setLocked,
    setPayors,
    setCourtesyBilling,
    setSuperBill,
    allowBillingPriorityChange,
    superBill,
    courtesyBilling,
    transactionReadonly,
    listofServices,
    setListofServices,
  } = useTransaction(row?.billingKey);

  const allocatableBalance = useMemo(() => {
    return (localRow as any)?.allocatableAmount;
  }, [localRow]);

  const isEncounterMode = useMemo(() => {
      return !!encounterContext;
    }, [encounterContext]),
    isNotEncounterMode = useMemo(() => {
      return !isEncounterMode;
    }, [isEncounterMode]);

  const nextEncounterDateDayjs = useMemo(() => {
    if (!row) return undefined;
    const localRow = row;
    return localRow?.nextRequestedEncounterDate
      ? dayjs(localRow?.nextRequestedEncounterDate * 1000)
      : undefined;
  }, [row]);

  const myUrl = useMemo(() => {
    return `/patients/${localRow?.patient?.id}/encounters/${localRow?.billingKey}`;
  }, [localRow?.billingKey, localRow?.patient?.id]);

  const myEncounter = useMemo(() => {
    // console.log({ myEncounter: { pathname, myUrl } });
    const matches = pathname.match(/\/encounters\/([^/]+)/),
      res = matches?.[1] ?? '-bogus-';
    return res === localRow?.billingKey;
  }, [localRow?.billingKey, pathname]);

  const buttonGroupButtons = useMemo(() => {
    const buttons: ButtonGroupType[] = [];
    if (!row || isEncounterMode) return buttons;

    const transaction = row;

    if (
      !!transaction?.provider?.displayName?.length &&
      !!transaction?.nextRequestedEncounterDate
    ) {
      buttons.push({
        label: nextEncounterDateDayjs
          ? 'Schedule appointment on ' +
            nextEncounterDateDayjs.format('M/DD/YYYY')
          : 'Next Appointment',
        to: [
          `/schedule?endDate=${nextEncounterDateDayjs?.format('YYYY-MM-DD')}`,
          `startDate=${nextEncounterDateDayjs?.format('YYYY-MM-DD')}`,
          `patient=${transaction?.patient?.id}`,
          `patientName=${transaction?.patient?.displayName}`,
          `clinician=${transaction?.appointmentClinicianId}`,
          `treatment=${transaction?.treatmentId}`,
          `open=add`,
        ].join('&'),
        onClick: () => close?.(),
      });
    } else {
      buttons.push({
        label: 'Next Appointment',
        to: [
          `/schedule?patient=${transaction?.patient?.id}`,
          `patientName=${transaction?.patient?.displayName}`,
          `clinician=${transaction?.appointmentClinicianId}`,
          `treatment=${transaction?.treatmentId}`,
          `open=add`,
        ].join('&'),
        onClick: () => close?.(),
      });
    }
    if (
      (!isEncounterMode && row.hasEncounter) ||
      (row.hasEncounter && !myEncounter)
    ) {
      if (!myEncounter) {
        buttons.push({
          label: 'Encounter',
          to: myUrl,
        });
      }
    }
    return buttons;
  }, [row, isEncounterMode, myEncounter, nextEncounterDateDayjs, close, myUrl]);

  /**
   * u s e E f f e c t
   */
  useEffect(() => {
    if (!(localRow?.merged && localRow?.items?.length === 0)) {
      setContext((prev) => ({
        ...prev,
        [ord]: {
          ...prev[ord],
          ref: myRef,
        },
      }));
    }
  }, [
    localRow?.items?.length,
    localRow?.merged,
    mergeContext.isMerged,
    ord,
    setContext,
  ]);

  useEffect(() => {
    console.log({ SettingLocalRowTo: data });
    setLocalRow(data);
  }, [data]);

  /**
   * u s e M e m o
   */

  getSingleTransaction[uId] = useMemo(() => {
    return () => {
      return localRow;
    };
  }, [localRow]);

  const isLocalReadOnly = useMemo(() => {
    return Boolean(isReadOnly || localRow?.merged);
  }, [isReadOnly, localRow?.merged]);

  // You can't unmerge if there are payments. [Seems like a sound decision.]
  const hasPayments = useMemo(() => {
    return localRow?.items?.filter((item) => isaPaymentItem(item)).length !== 0;
  }, [localRow?.items]);

  const visitForm = useMemo(() => {
    if (
      isNotEncounterMode ||
      !encounterContext ||
      !encounterContext.visitForm
    ) {
      return null;
    }
    return encounterContext.visitForm.id === localRow?.billingKey
      ? encounterContext.visitForm
      : null;
  }, [encounterContext, isNotEncounterMode, localRow?.billingKey]);

  const claimInfo = useMemo(() => {
    return visitForm?.encounterInfo?.claimInfo;
  }, [visitForm?.encounterInfo?.claimInfo]);

  // [2025-01-23.1305 by BWM] This can probably be deleted at some point
  // but didn't want to do so yet. The pattern is that saving the array
  // of transactions tells every sub-transaction to go get its data from
  // the query cache.
  //
  // Only once please.
  // useEffect(() => {
  //   if (claimInfo && incomingClaimInfo === null) {
  //     setIncomingClaimInfo(ChiroUpJSON.clone(claimInfo));
  //   }
  //   /**
  //    * Use cases:
  //    *
  //    * 1) After a save, the 'id' of the row will change so
  //    *    it needs to be reflected in the UI.
  //    * 2) If the # of items changes on the data, we need
  //    *    to reflect that in the UI as well.
  //    * 3) If this guy was unmerged, we need to load too.
  //    * 4) If locked is changed.
  //    */
  //   const rowLocked = localRow?.items?.some((item) => item.locked),
  //     dataLocked = data?.items?.some((item: PatientTransaction) => item.locked);
  //   if (
  //     localRow &&
  //     data &&
  //     localRow.billingKey === data.billingKey &&
  //     (localRow.id !== data.id ||
  //       localRow?.items?.length !== data?.items?.length ||
  //       localRow?.merged !== data?.merged ||
  //       rowLocked !== dataLocked)
  //   ) {
  //     setLocalRow(data);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const isAdHoc = useMemo(() => {
    return (
      data?.type === TransactionTypeEnum.AdHoc ||
      localRow?.type === TransactionTypeEnum.AdHoc
    );
  }, [data?.type, localRow?.type]);

  const isTransactionLocked = useMemo(() => {
    return lockButtonStateByProviderId?.[row?.provider?.id as string]?.locked;
  }, [lockButtonStateByProviderId, row?.provider?.id]);

  const nav = useMemo(() => {
    if (!context) return [];
    return Object.entries(context).map(([key, value], idx) => {
      const iKey = Number(key);
      if (iKey === ord) {
        return (
          <li
            key={`nav-${ord}-${idx}`}
            className="font-sans font-black text-2xl flex justify-center items-center cursor-auto pr-1"
            style={{ padding: '0 0.25rem' }}
          >
            {idx + 1}
          </li>
        );
      }
      if (iKey < 0 && ord === 0) return null; // No link to top on  first item.
      let display: ReactNode = null;
      if (iKey < 0) {
        display = <ArrowUpCircleIcon className="h-8 w-8" />;
      } else {
        display = (
          <div className="flex items-center justify-center h-4 w-4 border border-gray-400 p-3 rounded-full hover:text-white hover:bg-primary-500 align-middle">
            {idx + 1}
          </div>
        );
      }
      return (
        <li
          key={`nav-${ord}-${idx}`}
          className={classNames(
            'flex align-middle font-sans text-sm font-semibold text-gray-400 justify-center items-center cursor-pointer',
            iKey < 0 ? `${textPrimary} ${hoverTextPrimary}` : ' ',
          )}
          onClick={(e: any) => {
            // console.log('onClick slot', key);
            e.stopPropagation();
            e.preventDefault();
            context?.[iKey]?.ref?.current?.scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          {display}
        </li>
      );
    });
  }, [context, hoverTextPrimary, ord, textPrimary]);

  const discountAlreadyApplied = useMemo(() => {
    return localRow?.items?.some(
      (item) =>
        item?.subtype === TransactionItemSubtypeEnum.Adjustment ||
        item?.subtype === TransactionItemSubtypeEnum.Override,
    );
  }, [localRow?.items]);

  //returning an array of all the patient packages that can be used to apply to the service / treatment
  const canApplyPackage = useMemo(() => {
    let packageThatCanBeApplied: number[] = [];
    const alreadyPackageApplied = row?.items?.some((item) => item?.packageId);

    if (alreadyPackageApplied) return [];

    const itemsToUse = row?.insurances?.length
      ? row?.items?.filter((item) => !item?.insuranceBillable)
      : row?.items;

    const hasServices = itemsToUse?.filter(
      (item) =>
        item?.subtype === TransactionItemSubtypeEnum.Service ||
        item?.subtype === TransactionItemSubtypeEnum.PatientService,
    );

    //if there are no nonbillable services and patient has insurances, then no package can be applied
    if (!hasServices?.length && row?.insurances?.length) {
      return [];
    }

    if (hasServices?.length) {
      hasServices?.forEach((service) => {
        if (
          service?.code &&
          patientPackages?.data?.servicesCovered?.[service?.code as any]
        ) {
          packageThatCanBeApplied = packageThatCanBeApplied.concat(
            patientPackages?.data?.servicesCovered?.[service?.code as any],
          );
        }
      });
    } else if (
      row?.treatmentId &&
      patientPackages?.data?.treatmentsCovered?.[row?.treatmentId]
    ) {
      packageThatCanBeApplied = packageThatCanBeApplied.concat(
        patientPackages?.data?.treatmentsCovered?.[row?.treatmentId],
      );
    }

    return [...new Set(packageThatCanBeApplied)];
  }, [
    patientPackages?.data?.servicesCovered,
    patientPackages?.data?.treatmentsCovered,
    row?.insurances?.length,
    row?.items,
    row?.treatmentId,
  ]);

  const packageDisabled = useMemo(() => {
    return (
      currentPhase.name !== 'Edit' ||
      !row ||
      isFetching ||
      isSaving ||
      !canApplyPackage?.length
    );
  }, [row, currentPhase.name, isFetching, isSaving, canApplyPackage]);

  const patientBalance = useMemo(() => {
    return createDecimal(localRow?.patientBalance ?? 0);
  }, [localRow?.patientBalance]);

  const nonBillable = useMemo(() => {
    return localRow?.insurances?.some((i) => !i.billable);
  }, [localRow?.insurances]);

  const editingNew = useMemo(() => {
    return localRow?.billingKey === 'new';
  }, [localRow?.billingKey]);

  const isTransactionPaid = useMemo(() => {
    let resp = false;
    if (localRow?.type === TransactionTypeEnum.AdHoc) {
      const balance = localRow?.balance ?? 0;
      if (balance > 0) {
        resp = false;
      }
      resp = balance > 0;
    } else if (nonBillable) {
      if (Number(localRow?.patientBalance || 0) <= 0) {
        resp = true;
      }
      if (patientBalance.equals(0)) {
        resp = true;
      }
    } else {
      if (Number(localRow?.balance || 0) <= 0) {
        resp = true;
      }
    }
    // Can this be done in a useMemo??? Needed a timeout!!! [BWM: This sets a value in the container, so no.]
    setTimeout(() => {
      setPayAvailability?.((prev) => {
        const key = localRow?.billingKey;
        if (!key) return prev;
        return { ...prev, [key]: !resp };
      });
    }, 0);
    return resp;
  }, [
    localRow?.balance,
    localRow?.billingKey,
    localRow?.patientBalance,
    localRow?.type,
    nonBillable,
    patientBalance,
    setPayAvailability,
  ]);

  /**
   * H o o k s   a f t e r   u s e M e m o
   */
  const {
    patientResponsibility,
    insurancesToUse,
    responsibilities,
    payorResponsibilityTotal,
    balanceAllocatedToPatient,
  } = useResponsibilities({
    insurances,
    workingTransaction: localRow,
    courtesyBilling,
    superBill,
  });

  const isBalanceAllocatedToPatient = useMemo(() => {
    return !!balanceAllocatedToPatient;
  }, [balanceAllocatedToPatient]);

  /**
   * F u n c t i o n s
   */

  const findThePackageCredit = useCallback(
    ({
      packageId,
      items,
      insurances,
    }: {
      packageId?: number | null;
      items: PatientTransactionItemType[];
      insurances?: Partial<AppointmentInsuranceType>[];
    }) => {
      const packageToApply = patientPackages?.data?.packages?.find(
        (pkg) => pkg.ID === packageId,
      ) as any;

      const packageName = packageToApply?.packageName;

      //insurances could be an empty array so only want to sue working transaction insurances if insurances is undefined
      const insuranceArrToUse =
        insurances !== undefined ? insurances : row?.insurances;

      const itemsToUse = insuranceArrToUse?.length
        ? items?.filter((item) => !item?.insuranceBillable)
        : items;

      const hasServices = itemsToUse?.some(
        (item) =>
          item?.subtype === TransactionItemSubtypeEnum.Service ||
          item?.subtype === TransactionItemSubtypeEnum.PatientService,
      );

      let newCredit;

      if (!hasServices) {
        const costOfTreatment = items?.find(
          (item) =>
            item.type === TransactionItemTypeEnum.Debit &&
            item.subtype === TransactionItemSubtypeEnum.Treatment,
        )?.amount;

        newCredit = toTransactionItemSubtype(
          TransactionItemSubtypeEnum.Adjustment,
          {
            amount: costOfTreatment,
            description: `${packageName} - (package applied)`,
            packageId: packageId,
            adjustment: { structure: '$ Discount' },
          },
        );
        newCredit.id = v4();
      } else {
        const doesPackageCoverTreatment =
          patientPackages?.data?.treatmentsCovered?.[row?.treatmentId || -1];

        const codesAssociatedWithTreatment = doesPackageCoverTreatment
          ? disciplineTreatment?.referenceCodes?.map((code) => code.code)
          : [];
        const servicesThatCanBeCredited = [
          ...(codesAssociatedWithTreatment || []),
          ...(Object.keys(patientPackages?.data?.servicesCovered || {}) || []),
        ];

        const totalCoveredByPackage = itemsToUse?.reduce((acc, item) => {
          if (servicesThatCanBeCredited.includes(item.code as string)) {
            acc = acc.plus(
              createDecimal(item?.amount || 0).times(item?.units || 1),
            );
          }
          return acc;
        }, createDecimal(0));

        newCredit = toTransactionItemSubtype(
          TransactionItemSubtypeEnum.Adjustment,
          {
            amount: totalCoveredByPackage?.toFixed(2),
            description: `${packageName} - (package applied)`,
            packageId: packageId,
            adjustment: { structure: '$ Discount' },
          },
        );
        newCredit.id = v4();
      }
      return newCredit;
    },
    [
      patientPackages?.data?.packages,
      patientPackages?.data?.treatmentsCovered,
      patientPackages?.data?.servicesCovered,
      row?.insurances,
      row?.treatmentId,
      disciplineTreatment?.referenceCodes,
    ],
  );

  const genDupeKey = (item: PatientTransactionItemType) => {
    return `${item?.code}|${item?.type}|${item?.description}|${item?.amount}|${item?.subtype}`;
  };

  // const onChangeConsolidatedTransaction =
  //   (itemId: number) => (property: string | number) => (value: any) => {
  //     // console.log({ TransactionEditorTabContent: { itemId, property, value } });
  //     if (!localRow) return;
  //     const newobj = JSON.parse(JSON.stringify(localRow));
  //     newobj.items = newobj.items || [];
  //     const ord = newobj.items.findIndex(
  //       (item: PatientTransactionItemType) => item.id === itemId,
  //     );
  //     if (ord === -1) return;
  //     newobj.items[ord] = newobj.items[ord] || {};
  //     newobj.items[ord][property] = value;
  //     const percentDiscountApplied = newobj.items?.find(
  //       (item: PatientTransactionItemType) =>
  //         item.subtype === 'Adjustment' &&
  //         item?.adjustment?.structure === '% Discount',
  //     );
  //     if (percentDiscountApplied) {
  //       const newItemVals = findNewDiscount(newobj.items);
  //       newobj.items = newItemVals;
  //     }
  //     setLocalRow(newobj);

  //     // // Update the top-level datastructure.
  //     // setTimeout(() => {
  //     //   setConsolidatedData((prev) => {
  //     //     for (let i = 0; i < (prev ?? []).length; i++) {
  //     //       if (!prev?.[i]) continue;
  //     //       const o = prev[i];
  //     //       if (o.billingKey === newobj.billingKey) {
  //     //         prev[i].items = newobj.items;
  //     //       }
  //     //     }
  //     //     return prev;
  //     //   });
  //     // }, 0);
  //   };
  const addPendingItems = useCallback(
    (items: PatientTransactionItemType[]) => {
      if (!localRow) return;
      // console.log({ items });
      const newobj = ChiroUpJSON.clone(localRow);
      newobj.items = newobj.items || [];
      const nextItems = ChiroUpJSON.clone(newobj.items);

      /**
       * If we have dupes, just increase the units.
       *
       * 1) Generate a detection key for each item so we can track the # of units.
       * 2) Go through the new items and just update the units of any existing matches.
       *    Otherwise, just add the new item to the list.
       */
      const nextKeys = nextItems.reduce(
        (a: any, c: PatientTransactionItemType) => {
          const key = genDupeKey(c);
          if (!a[key]) {
            a[key] = c.units || 1;
            return a;
          }
          a[key] = a[key] + 1;
          return a;
        },
        {},
      );
      /**
       * Add what needs to be added or just increment the units if we have detected
       * a duplicate.
       */
      items.forEach((item) => {
        const key = genDupeKey(item);
        if (nextKeys[key]) {
          nextKeys[key] = nextKeys[key] + item.units;
        } else {
          nextItems.push(item);
        }
      });
      /**
       * True up the units on the items from the hash.
       */
      nextItems.forEach((item: PatientTransactionItemType) => {
        const key = genDupeKey(item);
        if (nextKeys[key]) {
          item.units = nextKeys[key];
        }
      });
      newobj.items = nextItems;
      /*** end of keeping out the dupes */
      const percentDiscountApplied = newobj?.items?.find(
        (item: { subtype: string; adjustment?: { percentOff: number } }) =>
          item.subtype === 'Adjustment' && item?.adjustment?.percentOff,
      );
      const overrideApplied = newobj?.items?.find(
        (item: { subtype: string; adjustment?: { structure: string } }) =>
          item.subtype === TransactionItemSubtypeEnum.Override,
      );
      if (percentDiscountApplied && !overrideApplied) {
        const returnedItems = findNewDiscount(newobj.items);
        newobj.items = returnedItems;
      }
      const servicesToAdd = items?.filter(
        (obj: PatientTransactionItemType) =>
          obj.subtype === TransactionItemSubtypeEnum.Service ||
          obj.subtype === TransactionItemSubtypeEnum.PatientService,
      );
      if (servicesToAdd && servicesToAdd.length) {
        setTriggerAppendItems((p) => ({ ...p, services: servicesToAdd }));
      }
      setLocalRow(newobj);

      setActiveSubtype('');
    },
    [localRow, setActiveSubtype, setTriggerAppendItems],
  );

  const applyPackage = useCallback(
    (id: number) => {
      const packageToApply = patientPackages?.data?.packages?.find(
        (pkg) => pkg.ID === id,
      ) as any;

      const packageName = packageToApply?.packageName;
      const newCredit = findThePackageCredit?.({
        packageId: packageToApply?.ID,
        items: row?.items || [],
      });

      newCredit.id = v4();
      const itemsToAdd = [newCredit];
      const anyAdditionalFee = packageToApply?.additionalPayment;
      if (anyAdditionalFee && anyAdditionalFee > 0) {
        //need to add a Debit.......
        const additionalFee = toTransactionItemSubtype(
          TransactionItemSubtypeEnum.AdditionalFee,
          {
            amount: anyAdditionalFee,
            description: `${packageName} - (additional fee)`,
            packageId: id,
          },
        );
        additionalFee.id = v4();
        itemsToAdd.push(additionalFee);
      }

      return addPendingItems(itemsToAdd);
    },
    [
      patientPackages?.data?.packages,
      findThePackageCredit,
      row?.items,
      addPendingItems,
    ],
  );

  /**
   * This encapsulates the business rules as to whether the stupid
   * "Pay" button appears or not. You would think that would be easy
   * to determine, but you would be wrong.
   *
   * If it is an adhoc transaction (supplies or supplements), the
   * balance rules. Otherwise, the patient responsibility rules.
   *
   * @param transaction
   * @returns
   */
  // const getTransactionIsPaid = useCallback(
  //   (transaction: PatientTransaction) => {
  //     if (transaction?.type === TransactionTypeEnum.AdHoc) {
  //       const balance = transaction.balance ?? 0;
  //       if (balance > 0) {
  //         return false;
  //       }
  //       return true;
  //     } else {
  //       if (Number(transaction.balance || 0) <= 0) {
  //         return true;
  //       }
  //       if (Number(transaction.patientBalance || 0) <= 0) {
  //         return true;
  //       }
  //       if (patientBalance.equals(0)) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   },
  //   [patientBalance],
  // );

  /**
   * Not sure this is better, but the idea is to make the
   * JSX easier to read by encapsulating the button state
   * logic in one place.
   *
   * We also use the same buttons multiple places.
   *
   */
  const BUTTON = useMemo(() => {
    return {
      writeOffBillable: {
        text: 'Write-Off',
        className: 'flex-none',
      },
      writeOff: {
        text: 'Write-off',
        color: ButtonColors.plainWithBorder,
        onClick: () => setShowWriteOffNonBillableModal(true),
        trivialTooltip: {
          text: !allocatableBalance ? 'There is no allocatable balance.' : '',
          type: 'info',
          iconClassName: 'h-3 w-3 text-black inline-block ml-2 align-text-top',
        },
        disabled:
          currentPhase.name !== 'View' ||
          !allocatableBalance ||
          isFetching ||
          isSaving ||
          fetchingAllocatableBalance ||
          isWritingOff ||
          isAllocating,
      },
      addPackage: {
        text: 'Add Package',
        className: 'flex-none', // Use flex-none to prevent the button from growing
        icon: <PlusCircleIcon className="h-6 w-6" />,
        color: ButtonColors.plainWithBorder,
        disabled:
          !localRow ||
          isFetching ||
          isSaving ||
          isTransactionLocked ||
          localRow?.items?.some(
            (item: PatientTransactionItemType) =>
              item.type === TransactionItemTypeEnum.Debit &&
              item?.subtype !== TransactionItemSubtypeEnum.Tax,
          ),
      },
      allocateBalance: {
        text: 'Allocate Balance',
        color: ButtonColors.plainWithBorder,
        className: 'flex-none',
        disabled:
          currentPhase.name === 'Edit' ||
          isNotReadOnly ||
          !allocatableBalance ||
          !localRow ||
          isFetching ||
          isSaving ||
          fetchingAllocatableBalance ||
          isWritingOff ||
          isAllocating,
      },
      applyPackage: {
        text: 'Apply package',
        color: ButtonColors.plainWithBorder,
        className: 'flex-none', // Use flex-none to prevent the button from growing
        trivialTooltip: insurances.length
          ? {
              text: 'Only non billable services can be covered by a package',
            }
          : {
              text: '',
            },
        disabled: packageDisabled,
      },
      discount: {
        text: 'Discount',
        className: 'flex-none', // Use flex-none to prevent the button from growing
        disabled:
          !localRow ||
          isFetching ||
          isSaving ||
          isTransactionLocked ||
          discountAlreadyApplied,
      },
      pay: {
        text: 'Pay',
        color: ButtonColors.plainWithBorder,
        className: 'flex-none -ml-4', // Use flex-none to prevent the button from growing
        disabled:
          currentPhase.name === 'Edit' ||
          isNotReadOnly ||
          !localRow ||
          isFetching ||
          isSaving ||
          isWritingOff ||
          isAllocating ||
          isTransactionPaid,
      },
      supplementsAndSupplies: {
        text: 'Add Supplements/Supplies',
        className: 'flex-none', // Use flex-none to prevent the button from growing
        icon: <PlusCircleIcon className="h-6 w-6" />,
        color: ButtonColors.plainWithBorder,
        disabled:
          !localRow ||
          isFetching ||
          isSaving ||
          isTransactionLocked ||
          localRow?.items?.some(
            (item: PatientTransactionItemType) =>
              item.subtype === TransactionItemSubtypeEnum.Package,
          ),
      },
    };
  }, [
    localRow,
    isFetching,
    isSaving,
    isTransactionLocked,
    currentPhase.name,
    isNotReadOnly,
    allocatableBalance,
    fetchingAllocatableBalance,
    isWritingOff,
    isAllocating,
    insurances.length,
    packageDisabled,
    discountAlreadyApplied,
    isTransactionPaid,
  ]);

  const CLICKS = {
    addLineItem: (subtype: TransactionItemSubtypeEnum | string) => {
      setActiveSubtype(subtype);
    },
    new: (e: any) => {
      const newTxn = JSON.parse(JSON.stringify(localRow));
      newTxn.items.push({});
      newTxn.balance = balanceFromItems({
        startingBalance: newTxn.startingBalance,
        items: newTxn.items,
        productTaxRate: selectedLocationFull?.productTaxRate,
        serviceTaxRate: selectedLocationFull?.serviceTaxRate,
        transactionType: newTxn.type,
      }).balance;
      setLocalRow(newTxn);
    },
    add: (e: any) => {
      const newTxn = JSON.parse(JSON.stringify(localRow));
      newTxn.items.push({});
      newTxn.balance = balanceFromItems({
        startingBalance: newTxn.startingBalance,
        items: newTxn.items,
        productTaxRate: selectedLocationFull?.productTaxRate,
        serviceTaxRate: selectedLocationFull?.serviceTaxRate,
        transactionType: newTxn.type,
      }).balance;
      setLocalRow(newTxn);
    },
  };

  // This is done once. Lots of dependencies, easier than making a real component.
  const actionButtonsComponent = useMemo(() => {
    return (
      <div className="w-full flex flex-row justify-end space-x-4 mt-4">
        {!!canApplyPackage?.length && (
          <Button
            {...BUTTON.applyPackage}
            onClick={() =>
              canApplyPackage?.length > 1
                ? setIsChoosePackageModal(true)
                : applyPackage(canApplyPackage?.[0])
            }
          />
        )}
        {currentPhase.name === 'Edit' ? null : (
          <Button {...BUTTON.pay} onClick={() => setOpenPaymentModal(true)} />
        )}
        {nonBillable &&
          !!allocatableBalance &&
          currentPhase.name !== 'Edit' && (
            <Button
              {...BUTTON.allocateBalance}
              onClick={() => setShowAllocateNonBillableModal(true)}
            />
          )}
        {!nonBillable && !isAdHoc && (
          <Button
            {...BUTTON.writeOffBillable}
            onClick={() => setIsWriteOffClaimsModalOpen(true)}
          />
        )}
        {editingNew ||
        activeTabName === 'Activity' ? null : isBalanceTransfer ? (
          <div>... balance transfers are not supported in this view ...</div>
        ) : null}
      </div>
    );
  }, [
    canApplyPackage,
    BUTTON.applyPackage,
    BUTTON.pay,
    BUTTON.allocateBalance,
    BUTTON.writeOffBillable,
    currentPhase.name,
    nonBillable,
    allocatableBalance,
    isAdHoc,
    editingNew,
    activeTabName,
    isBalanceTransfer,
    applyPackage,
  ]);

  const showDueToTrace = useMemo(() => {
    return localRow?.merged && localRow?.items?.length === 0 && trace;
  }, [localRow?.items?.length, localRow?.merged, trace]);

  /**
   * R e t u r n s
   */
  if (localRow?.merged && localRow?.items?.length === 0 && !trace) {
    return null;
  }

  if (isEncounterMode && !localRow?.hasEncounter) {
    return null;
  }

  if (isEncounterMode && !myEncounter) {
    return isFetching ? null : (
      <div className={ClassNames.bg}>
        <cite>No such encounter.</cite>
      </div>
    );
  }

  if (isEncounterMode) {
    return (
      <TransactionContext.Provider
        value={{
          status,
          data,
          isFetching,
          refetch,
          insurances,
          payors,
          snapshot,
          locked,
          services,
          setServices,
          setSnapshot,
          setInsurances,
          setLocked,
          setPayors,
          setCourtesyBilling,
          setSuperBill,
          allowBillingPriorityChange,
          courtesyBilling,
          superBill,
          transactionReadonly,
          listofServices,
          setListofServices,
        }}
      >
        <div
          data-component="in-encounter-mode"
          className={classNames('flex flex-row space-x-8', ClassNames.bg)}
          ref={myRef}
        >
          <div className="rounded-lg border overflow-hidden grow shadow-inner pt-0 px-4 pb-4 bg-white">
            <CodeEncounterDiagnosisServices
              {...encounterContext}
              codeEncounterDiagnosisServicesListProps={{
                componentClassName: 'w-full',
              }}
              patientId={encounterContext?.patientId || ''}
              clinicId={me?.selectedClinic?.ID ?? -1}
              parentIsa={'slot'}
              horizontal={true}
              consolidatedView={true}
              setPatientTransaction={setLocalRow}
              // setConsolidatedData={setConsolidatedData}
              instanceComponents={{
                lockButton: lockButtonStateByProviderId?.[
                  localRow?.provider?.id as string
                ] ? (
                  lockButtonStateByProviderId?.[
                    localRow?.provider?.id as string
                  ].available ? (
                    <LockButtonContent
                      state={
                        lockButtonStateByProviderId?.[
                          localRow?.provider?.id as string
                        ]
                      }
                      transaction={localRow}
                      readonly={isLocalReadOnly}
                      isBillingStarted={localRow?.isBillingStarted}
                      onClick={(e: any) => {
                        actionsOnClick(ActionsOnClick.Lock, localRow);
                      }}
                      componentClassName={[
                        'absolute',
                        'flex-row',
                        'flex',
                        'font-medium',
                        'font-sans',
                        'justify-end',
                        'mb-4',
                        'p-1',
                        'pl-1',
                        'right-0',
                        'space-x-2',
                        'text-sm',
                        'top-2',
                        'w-24',
                      ].join(' ')}
                    />
                  ) : null
                ) : null,
                unMergeButton: <div data-message="unused-unmerge-button" />,
              }}
              // onChangeConsolidatedTransaction={onChangeConsolidatedTransaction}
              policies={policies}
            />
            {visitForm ? (
              <div className="mt-6">
                <div
                  className="flex flex-row space-x-2 text-primary-600 cursor-pointer"
                  onClick={(e: any) => {
                    e?.stopPropagation?.();
                    e?.preventDefault?.();
                    setExpandedClaimInfo(!expandedClaimInfo);
                  }}
                >
                  <div className="mb-2.5 block relative text-lgfont-medium leading-5 sm:mt-px sm:pt-2 ">
                    Claim Info
                  </div>
                  <div className="pt-2">
                    {expandedClaimInfo ? (
                      <ChevronUpIcon className="w-5 h-5" />
                    ) : (
                      <ChevronDownIcon className="w-5 h-5" />
                    )}
                  </div>
                </div>
                {!!visitForm?.insuranceSubmitted?.length && (
                  <AlertBlock
                    level="caution"
                    message={ChiroUpTransactionCommon.billingStarted}
                  />
                )}
                {expandedClaimInfo ? (
                  <form>
                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300 p-4 mb-6">
                      <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
                        <ClaimInfoForm
                          value={visitForm}
                          setVisitForm={encounterContext?.setVisitForm}
                        />
                      </div>
                    </div>
                    <div
                      className={`mt-6 grid gap-3 grid-flow-row-dense ${
                        visitForm?.signed ? 'w-full' : 'grid-cols-2'
                      }`}
                    ></div>
                  </form>
                ) : null}
              </div>
            ) : null}
            {trace ? (
              <div className="mt-4">
                <Button
                  onClick={() => setTraceOpen(!traceOpen)}
                  text="Toggle #1"
                  color={ButtonColors.plainWithBorder}
                />
                <div className={classNames('p-4', traceOpen ? '' : 'hidden')}>
                  {/* <pre>{ChiroUpJSON.pretty(localRow)}</pre> */}
                  <h1>localRow</h1>
                  <pre>
                    {ChiroUpJSON.pretty({
                      id: localRow?.id,
                      billingKey: localRow?.billingKey,
                      provider: localRow?.provider,
                      type: localRow?.type,
                      merged: localRow?.merged,
                      hasPayments,
                      // encounterContext,
                    })}
                  </pre>
                  <hr />
                  <h1>data</h1>
                  <pre>
                    {ChiroUpJSON.pretty({
                      id: data?.id,
                      billingKey: data?.billingKey,
                      provider: data?.provider,
                    })}
                  </pre>
                  <hr />
                  <pre>
                    {ChiroUpJSON.pretty({ claimInfo, incomingClaimInfo })}
                  </pre>
                  <hr />
                  <pre>
                    {ChiroUpJSON.pretty({
                      localRowInsurances: localRow?.insurances,
                    })}
                  </pre>
                </div>
              </div>
            ) : null}{' '}
          </div>
        </div>
        <MakeBrowserWait isWaiting={isFetching || isSaving} />
      </TransactionContext.Provider>
    );
  }

  if (isAdHoc) {
    return (
      <TransactionContext.Provider
        value={{
          status,
          data,
          isFetching,
          refetch,
          insurances,
          payors,
          snapshot,
          locked,
          services,
          setServices,
          setSnapshot,
          setInsurances,
          setLocked,
          setPayors,
          setCourtesyBilling,
          setSuperBill,
          allowBillingPriorityChange,
          courtesyBilling,
          superBill,
          transactionReadonly,
          listofServices,
          setListofServices,
        }}
      >
        <div
          className={classNames('flex flex-row space-x-8', ClassNames.bg)}
          ref={myRef}
        >
          <div className="rounded-lg border overflow-hidden grow shadow-inner pt-0 px-4 pb-4 bg-white">
            <SingleAdHoc
              actionsOnClick={actionsOnClick}
              context={context}
              currentPhase={currentPhase}
              data={data}
              isFetching={isFetching}
              isReadOnly={isLocalReadOnly}
              isSaving={isSaving}
              lockButtonStateByProviderId={lockButtonStateByProviderId}
              parentProperties={{
                BUTTON,
                actionButtonsComponent,
                activeSubtype,
                activeTabName,
                addPendingItems,
                balanceAllocatedToPatient,
                // clear,
                data,
                editingNew,
                fetchingAllocatableBalance,
                findThePackageCredit,
                // insurances,
                // insurancesToUse,
                isAllocating,
                isTransactionPaid,
                isWritingOff,
                nonBillable,
                // onChangeConsolidatedTransaction,
                openPaymentModal,
                packageDisabled,
                patientPackages,
                patientResponsibility,
                // payment,
                payorResponsibilityTotal,
                // refetchBalance,
                responsibilities,
                // save,
                // selectedPaymentCard,
                setActiveSubtype,
                // setDiagnosesOrder,
                setIsChoosePackageModal,
                setLocalRow,
                setOpenPaymentModal,
                // setSelectedPaymentCard,
                setShowAllocateNonBillableModal,
                setShowSupplementsModal,
                showSupplementsModal,
                setIsPurchasePackageModal,
                triggerAppendItems,
                isTransactionLocked,
                hasAccess,
                discountAlreadyApplied,
                CLICKS,
              }}
              row={localRow}
              setCurrentPhase={setCurrentPhase}
              setLocalRow={setLocalRow}
              trace={trace}
            />
          </div>
        </div>
        <SupplementsModal
          patientId={localRow?.patient?.id || ''}
          transaction={localRow}
          addPendingItems={addPendingItems}
          isOpen={showSupplementsModal}
          isRouted={false}
          onClose={() => setShowSupplementsModal(false)}
        />
        <PurchasingPackagesModal
          patientId={localRow?.patient?.id || ''}
          transaction={localRow}
          isRouted={false}
          isOpen={isPurchasePackageModal}
          addPendingItems={addPendingItems}
          onClose={() => setIsPurchasePackageModal(false)}
        />
        <MakeBrowserWait isWaiting={isFetching || isSaving} />
      </TransactionContext.Provider>
    );
  }

  return (
    <TransactionContext.Provider
      value={{
        status,
        data,
        isFetching,
        refetch,
        insurances,
        payors,
        snapshot,
        locked,
        services,
        setServices,
        setSnapshot,
        setInsurances,
        setLocked,
        setPayors,
        setCourtesyBilling,
        setSuperBill,
        allowBillingPriorityChange,
        courtesyBilling,
        superBill,
        transactionReadonly,
        listofServices,
        setListofServices,
      }}
    >
      <div
        className={classNames('flex flex-row space-x-8', ClassNames.bg)}
        ref={myRef}
      >
        <div className="flex w-8 justify-end">
          <ul className="space-y-1">{nav.map((item) => item)}</ul>
        </div>
        <div
          className={classNames(
            'rounded-lg border overflow-hidden grow shadow-inner pt-0 px-4 pb-4',
            myEncounter
              ? 'bg-primary-50'
              : showDueToTrace
                ? 'bg-yellow-200'
                : 'bg-white',
          )}
        >
          <TransactionEditorTabs
            activeTabName={activeTabName}
            setActiveTabName={setActiveTabName}
            consolidatedView={true}
            switches={{
              Activity: !editingNewTransaction,
              Invoices: !editingNewTransaction,
              Detail: !editingNewTransaction,
            }}
          />

          <div>
            {editingNew ? (
              <div>... new transactions not supported in this view ...</div>
            ) : (
              <div className="relative">
                <div className="p-4">
                  <ButtonGroup
                    buttons={buttonGroupButtons}
                    disabled={isFetching || isSaving || !localRow}
                    isEmptyOkay={true}
                  />
                </div>

                <div className="p-4">
                  {isFetching && !isSaving ? (
                    <div className="flex justify-center p-4">
                      <Loading color="text-gray-400" />
                    </div>
                  ) : (
                    <>
                      {/* Shut the hell up empty <></> error! */}
                      <TransactionEditorTabContent
                        activeSubtype={activeSubtype}
                        activeTabName={activeTabName}
                        consolidatedView={true}
                        editing={currentPhase.name === 'Edit'}
                        isLoading={isFetching}
                        noCodesMessage="No codes are associated with this purchase."
                        policies={policies}
                        readonly={isLocalReadOnly}
                        setActiveSubtype={setActiveSubtype}
                        // setConsolidatedData={setConsolidatedData}
                        // setDiagnosesOrder={setDiagnosesOrder}
                        setWorkingTransaction={setLocalRow}
                        workingTransaction={localRow}
                        saveServicesCallback={() => {
                          setCurrentPhase(phases[currentPhase.next]);
                        }}
                        absoluteServiceItems={localRow?.services}
                        // onChangeConsolidatedTransaction={
                        //   onChangeConsolidatedTransaction
                        // }
                        patientPackages={patientPackages}
                        remoteControl={null} /* remoteControl */
                        triggerAppendItems={triggerAppendItems}
                        addPendingItems={addPendingItems}
                        findThePackageCredit={findThePackageCredit}
                        insurancesToUse={insurancesToUse}
                        responsibilities={responsibilities}
                        payorResponsibilityTotal={payorResponsibilityTotal}
                        patientResponsibility={patientResponsibility}
                        balanceAllocatedToPatient={balanceAllocatedToPatient}
                        instanceComponents={{
                          lockButton: lockButtonStateByProviderId?.[
                            localRow?.provider?.id as string
                          ] ? (
                            lockButtonStateByProviderId?.[
                              localRow?.provider?.id as string
                            ].available ? (
                              <LockButtonContent
                                state={
                                  lockButtonStateByProviderId?.[
                                    localRow?.provider?.id as string
                                  ]
                                }
                                transaction={localRow}
                                readonly={isLocalReadOnly}
                                isBillingStarted={localRow?.isBillingStarted}
                                onClick={(e: any) => {
                                  actionsOnClick(ActionsOnClick.Lock, localRow);
                                }}
                              />
                            ) : null
                          ) : null,
                          unMergeButton:
                            hasPayments ||
                            showDueToTrace ||
                            !mergeContext?.into?.[
                              localRow?.billingKey ?? ''
                            ] ? null : (
                              <UnMergeButtonContent
                                transaction={localRow}
                                state={
                                  lockButtonStateByProviderId?.[
                                    localRow?.provider?.id as string
                                  ]
                                }
                                isBillingStarted={localRow?.isBillingStarted}
                                readonly={isLocalReadOnly}
                                onClick={(e: any) => {
                                  e?.stopPropagation?.();
                                  e?.preventDefault?.();
                                  actionsOnClick(
                                    ActionsOnClick.UnMerge,
                                    localRow,
                                  );
                                }}
                              />
                            ),
                        }}
                        // refetchBalance={refetchBalance}
                        actionButtonsComponent={
                          <div className="w-full flex flex-row justify-end space-x-4 mt-4">
                            {!!canApplyPackage?.length && (
                              <Button
                                {...BUTTON.applyPackage}
                                onClick={() =>
                                  canApplyPackage?.length > 1
                                    ? setIsChoosePackageModal(true)
                                    : applyPackage(canApplyPackage?.[0])
                                }
                              />
                            )}
                            {currentPhase.name === 'Edit' ? null : (
                              <>
                                <Button
                                  {...BUTTON.pay}
                                  onClick={() => setOpenPaymentModal(true)}
                                />
                                {nonBillable ? (
                                  <Button
                                    {...BUTTON.writeOff}
                                    onClick={() =>
                                      setShowWriteOffNonBillableModal(true)
                                    }
                                  />
                                ) : isAdHoc ? null : (
                                  <Button
                                    {...BUTTON.writeOffBillable}
                                    onClick={() =>
                                      setIsWriteOffClaimsModalOpen(true)
                                    }
                                  />
                                )}
                                {/* <pre>
                                  {ChiroUpJSON.pretty({
                                    currentPhase,
                                    isNotReadOnly,
                                    allocatableBalance,
                                    isFetching,
                                    isSaving,
                                    fetchingAllocatableBalance,
                                    isWritingOff,
                                    isAllocating,
                                  })}
                                </pre> */}
                              </>
                            )}
                            {nonBillable &&
                              !!allocatableBalance &&
                              currentPhase.name !== 'Edit' && (
                                <Button
                                  {...BUTTON.allocateBalance}
                                  onClick={() =>
                                    setShowAllocateNonBillableModal(true)
                                  }
                                />
                              )}
                            {editingNew ||
                            activeTabName ===
                              'Activity' ? null : isBalanceTransfer ? (
                              <div>
                                ... balance transfers are not supported in this
                                view ...
                              </div>
                            ) : null}
                          </div>
                        }
                      />
                    </>
                  )}
                </div>
                {activeTabName === 'Detail' &&
                isNotReadOnly &&
                activeSubtype === '' ? (
                  <div className="flex flex-row flex-wrap gap-2 m-4 justify-start">
                    {/* <div className="flex-grow"></div>{' '} */}
                    <div className="flex flex-none gap-2">
                      {!insurances.length &&
                        !localRow?.items?.filter(
                          (i) =>
                            i.subtype === TransactionItemSubtypeEnum.Service ||
                            i.subtype ===
                              TransactionItemSubtypeEnum.PatientService,
                        )?.length && (
                          <Button
                            {...BUTTON.discount}
                            onClick={() =>
                              CLICKS.addLineItem(
                                TransactionItemSubtypeEnum.Adjustment,
                              )
                            }
                          />
                        )}
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
          {localRow && openPaymentModal && (
            <ConsolidatedPaymentModal
              passedTransactions={[localRow]}
              isOpen={true}
              callbacks={{
                onClose: () => setOpenPaymentModal(false),
              }}
              disableCreateCredit={true}
            />
          )}
          <MakeBrowserWait isWaiting={isFetching || isSaving} />
          {localRow?.billingKey && currentPhase.name !== 'Edit' && (
            <>
              <AllocateNonBillableToPatientModal
                isOpen={showAllocacteNonBillableModal}
                close={() => setShowAllocateNonBillableModal(false)}
                amount={allocatableBalance}
                billingKey={localRow?.billingKey}
                refetch={refetch}
                patientId={localRow?.patient?.id || ''}
                isAllocating={isAllocating}
                setIsAllocating={setIsAllocating}
              />
              <WriteOffNonBillableBalanceModal
                isOpen={showWriteOffNonBillableModal}
                close={() => setShowWriteOffNonBillableModal(false)}
                amount={allocatableBalance}
                billingKey={localRow?.billingKey}
                refetch={refetch}
                patientId={localRow?.patient?.id || ''}
                isProcessingWriteOff={isWritingOff}
                setIsProcessingWriteOff={setIsWritingOff}
              />
            </>
          )}

          <ChoosePackageModal
            isOpen={isChoosePackageModal}
            close={() => setIsChoosePackageModal(false)}
            applyPackage={applyPackage}
            canApplyPackages={canApplyPackage}
            packages={patientPackages?.data?.packages}
          />

          {trace ? (
            <div className="mt-4">
              <Button
                onClick={() => setTraceOpen(!traceOpen)}
                text="Toggle #2"
                color={ButtonColors.plainWithBorder}
              />
              <div className={classNames('p-4', traceOpen ? '' : 'hidden')}>
                {/* <pre>{ChiroUpJSON.pretty(localRow)}</pre> */}
                <h1>localRow</h1>
                <pre>
                  {ChiroUpJSON.pretty({
                    id: localRow?.id,
                    billingKey: localRow?.billingKey,
                    provider: localRow?.provider,
                    type: localRow?.type ? localRow.type : '- no type -',
                    merged: localRow?.merged,
                    hasPayments,
                    encounterContext,
                    isLocalReadOnly,
                    isTransactionLocked,
                    lockButtonStateByProviderId,
                    services: localRow?.services,
                    items: localRow?.items,
                  })}
                </pre>
                <hr />
                <h1>data</h1>
                <pre>
                  {ChiroUpJSON.pretty({
                    id: data?.id,
                    billingKey: data?.billingKey,
                    provider: data?.provider,
                    merged: data?.merged,
                    services: data?.services,
                  })}
                </pre>
                <hr />
                <pre>
                  {ChiroUpJSON.pretty({ services: localRow?.services })}
                </pre>
                <pre>{ChiroUpJSON.pretty({ items: localRow?.items })}</pre>
                <hr />
                <pre>{ChiroUpJSON.pretty(lockButtonStateByProviderId)}</pre>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <SupplementsModal
        patientId={localRow?.patient?.id || ''}
        transaction={localRow}
        addPendingItems={addPendingItems}
        isOpen={showSupplementsModal}
        isRouted={false}
        onClose={() => setShowSupplementsModal(false)}
      />
      {isWriteOffClaimsModalOpen ? (
        <WriteOffClaimsModal
          billingKey={localRow?.billingKey}
          isNew={editingNew}
          patientResponsibility={createDecimal(patientResponsibility)}
          callbacks={{
            onClose: () => setIsWriteOffClaimsModalOpen(false),
            onSuccess: () => {
              refetch();
              setBackgroundDirty(true);
            },
          }}
        />
      ) : null}
    </TransactionContext.Provider>
  );
};

export default SingleTransaction;
