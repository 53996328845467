import React, { useId, useMemo } from 'react';
import { classNames } from '@chiroup/core/functions/classNames';
import { TrivialTooltip } from './TrivialTooltip';
import {
  STRING_ANY_HASH,
  STRING_STRING_HASH,
} from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { hasProperty } from '@chiroup/core/functions/hasProperty';

type Props = {
  hash?: STRING_ANY_HASH | null | undefined;
  valueClassNames?: STRING_STRING_HASH | null | undefined;
  labelClassNames?: STRING_STRING_HASH | null | undefined;
  header?: React.ReactNode | null;
  containerClassName?: string;
  trace?: boolean;
  headerComponentRight?: React.ReactNode;
  disabled?: boolean;
  skipNullValues?: boolean;
  components?: { [key: string]: React.ReactNode };
  tips?: { [key: string]: React.ReactNode };
  noHover?: boolean;
  addClassName?: string;
  grid?: { cols: number; labelSpan: number; valueSpan: number };
  square?: boolean;
  border?: string;
  topBorder?: string;
  headerClassName?: string;
  bodyClassName?: string;
  hashCardDisplayColors?: string[];
};
export const HashCardDisplayColors = ['bg-white', 'bg-primary-50'];

export const HashCardDisplay: React.FC<Props> = ({
  hash,
  header = null,
  valueClassNames = {},
  labelClassNames = {},
  containerClassName = 'w-full',
  trace = false,
  headerComponentRight = null,
  disabled = false,
  skipNullValues = false,
  components = {},
  tips = {},
  noHover = false,
  addClassName = '',
  grid = { cols: 2, labelSpan: 1, valueSpan: 1 },
  square = false,
  border = 'border border-gray-400',
  topBorder = '',
  headerClassName = 'overflow-hidden border-b-2 flex flex-row bg-gray-100 text-gray-800 px-4 py-2 font-bold',
  bodyClassName = 'bg-white text-gray-800 overflow-hidden',
  hashCardDisplayColors,
}) => {
  hash = hash || components;
  const keyPrefix = useId();
  const hashKeys = useMemo(() => (hash ? Object.keys(hash) : []), [hash]);

  /**
   * What the heck is this? For aesthetic reason, we want the bottom of the
   * LAST row in the card to be rounded. The problem is, if you don't show
   * a value due to the skipNullValues flag, then the detection of when the
   * last row is being rendered is off. So, we create a delta of null values
   * to make sure we round the correct bottom...so to speak.
   */
  const keyDelta = useMemo(() => {
    let res = 0;
    if (hash && skipNullValues) {
      res = Object.keys(hash).reduce((acc, key) => {
        if (!hasProperty(components, key)) return acc;
        return acc + 1;
      }, 0);
    }
    return -1 * res;
  }, [hash, skipNullValues, components]);

  const colors = useMemo(() => {
    if (hashCardDisplayColors) return hashCardDisplayColors;
    // console.log('hashcard display colors is not defined');
    return HashCardDisplayColors;
  }, [hashCardDisplayColors]);

  if (!hash) return null;

  if (!hash) return null;

  let visibleLines = 0;

  return (
    <div data-component="hash-card-display" className={containerClassName}>
      <div
        className={classNames(
          'flex flex-col text-sm ',
          border,
          topBorder,
          square ? '' : 'rounded-md',
          disabled
            ? ''
            : noHover
              ? ''
              : 'hover:ring-2 hover:ring-primary-500 shadow-xl hover:shadow-sm',
          addClassName,
        )}
      >
        {header ? (
          <div
            className={classNames(
              square ? '' : 'rounded-md rounded-b-none',
              headerClassName,
            )}
          >
            <div className="flex grow">{header}</div>
            {headerComponentRight}
          </div>
        ) : null}
        <div className={classNames(square ? '' : 'rounded-md', bodyClassName)}>
          {Object.entries(hash).map(([key, value], idx) => {
            if (value === null && skipNullValues) {
              return <div key={`${keyPrefix}-${idx}`}></div>;
            }
            /**
             * It is possible for a component value to be null, so this allows
             * for that use case.
             */
            if (hasProperty(components, key)) {
              return <div key={`${keyPrefix}-${idx}`}>{components[key]}</div>;
            }
            visibleLines++;
            const tip = tips[key] ?? null;
            return (
              <div
                className={classNames(
                  `grid grid-cols-${grid.cols}`,
                  colors[visibleLines % 2],
                  idx === hashKeys.length - 1 + keyDelta
                    ? square
                      ? ''
                      : 'rounded-b-md'
                    : '',
                )}
                key={`${keyPrefix}-${idx}`}
              >
                <div
                  className={classNames(
                    labelClassNames?.[key] ??
                      labelClassNames?._ ??
                      'px-4 py-1 text-sm flex flex-row',
                    grid.labelSpan !== 1 ? `col-span-${grid.labelSpan}` : '',
                  )}
                >
                  <div className="italic">{key}</div>
                  <div>
                    {tip ? (
                      typeof tip === 'string' ? (
                        <TrivialTooltip
                          text={tip}
                          tipClassName="font-normal w-72"
                        />
                      ) : (
                        <TrivialTooltip component={tip} />
                      )
                    ) : null}
                  </div>
                </div>
                <div
                  className={
                    valueClassNames?.[key] ??
                    valueClassNames?._default ??
                    `px-4 py-1 text-sm col-span-${grid.valueSpan}`
                  }
                >
                  {typeof value === 'object'
                    ? Array.isArray(value)
                      ? value.map((v, key) => (
                          <div key={`${keyPrefix}-${key}`}>{v}</div>
                        ))
                      : React.isValidElement(value)
                        ? value
                        : ChiroUpJSON.stringify(value)
                    : typeof value === 'boolean'
                      ? value
                        ? 'true'
                        : 'false'
                      : value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {trace ? (
        <pre>{ChiroUpJSON.pretty({ hash, header, keyDelta })}</pre>
      ) : null}
    </div>
  );
};
