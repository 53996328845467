import dayjs from 'dayjs';
import {
  PLB,
  C003,
  CAS,
  NM1,
  PER,
  AMT,
  BPR,
  CLP,
  CUR,
  DTM,
  LQ,
  LX,
  MIA,
  MOA,
  N1,
  N3,
  N4,
  // ParsedSegment,
  QTY,
  RDM,
  REF,
  SE,
  ST,
  SVC,
  TRN,
  X12Segment,
} from '../../lib/types/EDIDocument.type';
import { PayorAdjustmentType, PayorRemarkType } from './Payor.type';
import { EDIElementsEnum } from '../../lib/types/EDIElementsEnum';
import {
  GetC042AdjustmentReason,
  GetClaimAdjustmentGroupCode,
  GetClaimAdjustmentReasonCode,
} from '../constants/EdiCodes/ClaimAdjustmentCodes';
import {
  GetEntityIdentifier,
  GetIdentification,
  GetContactFunction,
  GetCommunicationNumberType,
} from '../constants/EdiCodes/EntityIdentifierCodes';
import { GetProductServiceQualifier } from '../constants/EdiCodes/ProductServiceCodes';
import { GetReferenceInfoQualifier } from '../constants/EdiCodes/ReferenceInfoQualifierCodes';
import { GetRemittanceDeliveryMethod } from '../constants/EdiCodes/RemittanceCodes';
import { numOrBust } from '../functions/numOrBust';
import { InvoiceStatusEnum } from './Invoice.type';

export type EraType = {
  id?: number;
  clinicId: number;
  exchangeId?: string;
  payorId?: number;
  controlNumber: string;
  status: EraStatus;
  billingProfileId?: number;
  payorName?: string;
  paymentAmount?: number;

  financialDetails?: ResolvedFinancialInformation;
  fullResponse?: ResolvedAdvice;

  createdAt: number;
  tz?: string;

  verified?: number;
  verifiedBy?: string;
  hasOrphanedClaims?: boolean;
};

//TODO: review statuses with team
export enum EraStatus {
  Unposted = 'Unposted',
  InProgress = 'In Progress',
  Complete = 'Complete',
}

export type EraWithClaimsType = EraType & {
  claims: ClaimResponseEraType[];
  incentives: ClaimResponseIncentiveType[];
  payorID?: string;
};

export type OrphanedClaimResponseEraType = {
  id?: number;
  clinicId: number;
  patientId: string;
  billingKey: string;
  exchangeId: string;
  eraId: number;
  claimControlNumber: number;
  payorControlNumber: string;
  adviceJson: ResolvedClaimPaymentInformation;
  payorInfo: ResolvedLoop1000;
  createdAt?: number;
  worked: boolean;
};

export enum ClaimResponseEraStatus {
  Applied = 'Applied',
  Unapplied = 'Unapplied',
  Posted = 'Posted',
  Reprocessed = 'Reprocessed',
}

export enum ClaimIncentiveStatus {
  Posted = 'Posted',
  Unposted = 'Un-posted',
  Orphaned = 'Orphaned',
}

export type ClaimResponseIncentiveType = {
  id: number;
  eraId: number;
  clinicId: number;
  exchangeId?: string;
  patientId?: string;
  payorId?: number;
  billingKey?: string;
  controlNumber: string;
  incentiveAmount: number;
  providerId?: string;
  status: ClaimIncentiveStatus;
  adjustmentGroupCode: string;
  adjustmentCode: string;
  remarks?: PayorRemarkType[];
  serviceDate: number;
  createdAt: number;
  postedAt?: number;

  tz?: string;
};

export type ClaimResponseIncentiveViewType = ClaimResponseIncentiveType & {
  patientNameFirst: string;
  patientNameLast: string;
};

export type ClaimResponseEraType = {
  id: number;
  eraId: number;
  clinicId: number;
  exchangeId?: string;
  invoiceId?: number;
  patientId?: string;
  payorId?: number;
  billingKey?: string;
  controlNumber: string;
  billedAmount: number;
  paymentAmount: number;
  paymentMethod: string;
  status: ClaimResponseEraStatus;
  patientName?: string;

  payorClaimControlNumber?: string;

  serviceItems: ClaimServiceItemType[];
  fullClaimResponse?: ResolvedClaimPaymentInformation;

  createdAt: number;
  postedAt?: number;

  tz?: string;
  invoiceStatus?: InvoiceStatusEnum;
};

export type ClaimServiceItemType = {
  controlNumber: string;
  payorLineItemId?: number;

  originalServiceCode: string;
  originalServiceCodeModifier1: string;
  originalServiceCodeModifier2: string;
  originalServiceCodeModifier3: string;
  originalServiceCodeModifier4: string;
  originalServiceUnits?: number;
  originalServiceCodeQualifier: string;
  originalServiceDescription: string;

  adjudicatedServiceCode: string;
  adjudicatedServiceCodeModifier1: string;
  adjudicatedServiceCodeModifier2: string;
  adjudicatedServiceCodeModifier3: string;
  adjudicatedServiceCodeModifier4: string;
  adjudicatedServiceUnits?: number;
  adjudicatedServiceCodeQualifier: string;
  adjudicatedServiceDescription: string;

  billedAmount: number;
  remitAmount: number;
  adjustments?: PayorAdjustmentType[];
  remarks?: PayorRemarkType[];
  internalNotes?: string;
  description?: string;
};

export type ClaimResponseViewType = ClaimResponseEraType & {
  patientNameFirst: string;
  patientNameLast: string;
  remainingUnallocatedBalance: number;
  patientResponsibility: number;
  adjudicatedContractualAdjustmentAmount: number;
};

export type EraBatchManualAddType = {
  id?: number;
  clinicId: number;
  payorId: number;
  controlNumber: string;
  totalPaymentAmount: number;
  remittanceDate?: string;
  paymentMethod?: string;
  referenceNumber?: string;
  billingProfileId?: number;
  tz?: string;
  claims: EraManualAddType[];
};

export type EraManualAddType = {
  id?: number;
  clinicId: number;
  controlNumber: string;
  status?: string;
  invoiceId: number;
  payorId?: number | string;
  remittanceDate?: string;
  paymentAmount?: number;
  paymentMethod?: string;
  referenceNumber?: string;
  tz?: string;

  payorControlNumber?: string;

  services: ClaimServiceItemType[];
};

export type ResolvedFinancialInformation = {
  transactionHandlingCode?: string;
  transactionHandlingDescription?: string;
  paymentEffectiveDate?: string;
  creditDebitFlag?: string;
  paymentAmount?: number;
  paymentMethod?: string;
  paymentMethodCode?: string;
  paymentFormatCode?: string;
  paymentFormatDescription?: string;
  originatingAccountNumber?: string;
  originatingAccountNumberQualifier?: string;
  originatingDfiId?: string;
  originatingDfiQualifier?: string;
  receivingAccountNumber?: string;
  receivingAccountNumberQualifier?: string;
  receivingDfiId?: string;
  receivingDfiQualifier?: string;
  returnAccountNumber?: string;
  returnAccountNumberQualifier?: string;
  returnDfiId?: string;
  returnDfiQualifier?: string;
  originatingCompany?: string;
  originatingCompanySupplementalCode?: string;

  //manual entry only
  manualReferenceNumber?: string;
};

export type Resolved835 = {
  advice: ResolvedAdvice[];
};

export type ResolvedAdvice = {
  header: ResolvedAdviceHeader;
  details: ResolvedAdviceDetail[];
  providerAdjustments: ResolvedProviderAdjustment[];
  trailer: ResolvedTransactionSetTrailer;
};

export type ResolvedTransactionSetTrailer = {
  segmentCount: number;
  transactionSetControlNumber: string;
};

export type ResolvedAdviceHeader = {
  transactionSetControlNumber: string;
  traceNumber?: string;
  financialInformation: ResolvedFinancialInformation;
  payor: ResolvedLoop1000;
  payee: ResolvedLoop1000;
};

export type ResolvedAdviceDetail = {
  detailNumber: string;
  claimPaymentInformation: ResolvedClaimPaymentInformation[];
};

export type ResolvedClaimPaymentInformation = {
  patientControlNumber: string;
  statusCode: string;
  statusDescription: string;
  totalCharge: number;
  paymentAmount: number;
  patientResponsibility: number;
  claimFilingIndicatorCode: string;
  claimFilingIndicatorDescription: string;
  payerClaimControlNumber: string;
  facilityTypeCode: string;
  claimFrequencyTypeCode: string;
  patientStatusCode: string;
  diagnosisRelatedGroupCode: any;
  quantity: number;
  percentageAsDecimal: number;
  yesNoConditionOrResponseCode: string;
  yesNoConditionOrResponseDescription: string;

  patientName: ResolvedIndividualOrOrganizationName;
  insuredName?: ResolvedIndividualOrOrganizationName | null;
  correctedPatientName?: ResolvedIndividualOrOrganizationName | undefined;
  serviceProviderName?: ResolvedIndividualOrOrganizationName | undefined;
  crossoverCarrierName?: ResolvedIndividualOrOrganizationName | undefined;
  correctedPriorityPayerName?: ResolvedIndividualOrOrganizationName | undefined;
  otherSubscriberName?: ResolvedIndividualOrOrganizationName | undefined;

  claimAdjustments: ResolvedClaimOrServiceAdjustment[];

  inpatientAdjudicationInformation: ResolvedInpatientAdjudicationInformation;
  outpatientAdjudicationInformation: ResolvedOutpatientAdjudicationInformation;
  additionalInformation: ResolvedAdditionalInformation[];

  claimStatementPeriodStartDate: string;
  claimStatementPeriodEndDate: string;
  coverageExpirationDate: string;
  claimReceivedDate: string;

  contacts: ResolvedContact[];

  supplementalAmountInformation: ResolvedSupplementalAmountInformation[];
  supplementalQuantityInformation: ResolvedSupplementalQuantityInformation[];
  servicePaymentInformation: ResolvedServicePaymentInformation[];
};

export type ResolvedAdditionalInformation = {
  referenceId: string;
  referenceIdQualifier: string;
  description: string;
};

export type ResolvedSupplementalAmountInformation = {
  amountCode: string;
  amountDescription?: string;
  amount: number;
};

export type ResolvedSupplementalQuantityInformation = {
  quantityCode: string;
  quantityDescription: string;
  quantity: number;
};

export type ResolvedServicePaymentInformation = {
  adjudicatedProcedureIdentifier: ResolvedCompositeMedicalProcedureIdentifier;
  submittedChargeAmount: number;
  paidAmount: number;
  nationalUniformBillingCommitteeRevenueCode: string;
  paidUnitsOfService: number;
  originalProcedureIdentifier: ResolvedCompositeMedicalProcedureIdentifier;
  originalUnitsOfService: number;
  servicePeriodStartDate: string;
  servicePeriodEndDate: string;
  serviceDate: string;
  serviceAdjustments: ResolvedClaimOrServiceAdjustment[];
  lineItemControlNumber: string;
  healthCarePolicyIdentifier: string;
  additionalReferences: ResolvedAdditionalInformation[];
  serviceSupplementalAmounts: ResolvedSupplementalAmountInformation[];
  serviceSupplementalQuantities: ResolvedSupplementalQuantityInformation[];
  healthCareRemarks: ResolvedHealthCareRemarks[];
};

export type ResolvedHealthCareRemarks = {
  remarkQualifier: string;
  remarkCode: string;
  remarkDescription: string;
};

export type ResolvedInpatientAdjudicationInformation = {
  coveredDays: number;
  ppsOperatingOutlierAmount: number;
  lifeTimePsychiatricDays: number;
  diagnosisRelatedGroupAmount: number;
  claimPaymentRemarkCode: string;
  claimPaymentRemarkDescription: string;
  disproportionateShareAmount: number;
  medicareSecondaryPayerPassThroughAmount: number;
  totalPpsCapitalAmount: number;
  ppsCapitalFSPDRGAmount: number;
  ppsCapitalHSPDRGAmount: number;
  ppsCapitalDSHAmount: number;
  oldCapitalAmount: number;
  ppsCapitalIMEAmount: number;
  hospitalSpecificDRGAmount: number;
  costReportDayCount: number;
  federalSpecificDRGAmount: number;
  ppsCapitalOutlierAmount: number;
  indirectTeachingAmount: number;
  professionalComponentAmountBilled: number;
  claimPaymentRemarkCode2: string;
  claimPaymentRemarkDescription2: string;
  claimPaymentRemarkCode3: string;
  claimPaymentRemarkDescription3: string;
  claimPaymentRemarkCode4: string;
  claimPaymentRemarkDescription4: string;
  claimPaymentRemarkCode5: string;
  claimPaymentRemarkDescription5: string;
  capitalExceptionAmount: number;
};

export type ResolvedOutpatientAdjudicationInformation = {
  reimbursementPercentageRate: number;
  hcpcsPayableAmount: number;
  claimPaymentRemarkCode: string;
  claimPaymentRemarkDescription: string;
  claimPaymentRemarkCode2: string;
  claimPaymentRemarkDescription2: string;
  claimPaymentRemarkCode3: string;
  claimPaymentRemarkDescription3: string;
  claimPaymentRemarkCode4: string;
  claimPaymentRemarkDescription4: string;
  claimPaymentRemarkCode5: string;
  claimPaymentRemarkDescription5: string;
  esrdPaymentAmount: number;
  nonpayableProfessionalComponentAmount: number;
};

export class ResolvedProviderAdjustment {
  providerIdentifier?: string;
  fiscalPeriodEndDate?: string;
  adjustmentDetails?: any[];

  constructor(plb: PLB) {
    this.providerIdentifier = plb.providerNumberAssignedByPayor;
    this.fiscalPeriodEndDate = plb.lastDayOfProviderFiscalYear
      ? dayjs(plb.lastDayOfProviderFiscalYear, 'YYYYMMDD').format('MM/DD/YYYY')
      : undefined;
    this.adjustmentDetails = [
      {
        providerAdjustmentIdentifier:
          plb.adjustmentIdentifier.referenceIdentification,
        adjustmentAmount: numOrBust(plb.adjustmentAmount),
        adjustmentReasonCode: plb.adjustmentIdentifier?.adjustmentReasonCode,
        adjustmentReasonDescription: plb.adjustmentIdentifier
          ?.adjustmentReasonCode
          ? GetC042AdjustmentReason(
              plb.adjustmentIdentifier.adjustmentReasonCode,
            )
          : undefined,
      },
    ];

    if (plb.adjustmentIdentifier2.adjustmentReasonCode) {
      this.adjustmentDetails.push({
        providerAdjustmentIdentifier:
          plb.adjustmentIdentifier2.referenceIdentification,
        adjustmentAmount: numOrBust(plb.adjustmentAmount2),
        adjustmentReasonCode: plb.adjustmentIdentifier2?.adjustmentReasonCode,
        adjustmentReasonDescription: plb.adjustmentIdentifier2
          ?.adjustmentReasonCode
          ? GetC042AdjustmentReason(
              plb.adjustmentIdentifier2.adjustmentReasonCode,
            )
          : undefined,
      });
    }

    if (plb.adjustmentIdentifier3.adjustmentReasonCode) {
      this.adjustmentDetails.push({
        providerAdjustmentIdentifier:
          plb.adjustmentIdentifier3.referenceIdentification,
        adjustmentAmount: numOrBust(plb.adjustmentAmount3),
        adjustmentReasonCode: plb.adjustmentIdentifier3?.adjustmentReasonCode,
        adjustmentReasonDescription: plb.adjustmentIdentifier3
          ?.adjustmentReasonCode
          ? GetC042AdjustmentReason(
              plb.adjustmentIdentifier3.adjustmentReasonCode,
            )
          : undefined,
      });
    }

    if (plb.adjustmentIdentifier4.adjustmentReasonCode) {
      this.adjustmentDetails.push({
        providerAdjustmentIdentifier:
          plb.adjustmentIdentifier4.referenceIdentification,
        adjustmentAmount: numOrBust(plb.adjustmentAmount4),
        adjustmentReasonCode: plb.adjustmentIdentifier4?.adjustmentReasonCode,
        adjustmentReasonDescription: plb.adjustmentIdentifier4
          ?.adjustmentReasonCode
          ? GetC042AdjustmentReason(
              plb.adjustmentIdentifier4.adjustmentReasonCode,
            )
          : undefined,
      });
    }

    if (plb.adjustmentIdentifier5.adjustmentReasonCode) {
      this.adjustmentDetails.push({
        providerAdjustmentIdentifier:
          plb.adjustmentIdentifier5.referenceIdentification,
        adjustmentAmount: numOrBust(plb.adjustmentAmount5),
        adjustmentReasonCode: plb.adjustmentIdentifier5?.adjustmentReasonCode,
        adjustmentReasonDescription: plb.adjustmentIdentifier5
          ?.adjustmentReasonCode
          ? GetC042AdjustmentReason(
              plb.adjustmentIdentifier5.adjustmentReasonCode,
            )
          : undefined,
      });
    }

    if (plb.adjustmentIdentifier6.adjustmentReasonCode) {
      this.adjustmentDetails.push({
        providerAdjustmentIdentifier:
          plb.adjustmentIdentifier6.referenceIdentification,
        adjustmentAmount: numOrBust(plb.adjustmentAmount6),
        adjustmentReasonCode: plb.adjustmentIdentifier6?.adjustmentReasonCode,
        adjustmentReasonDescription: plb.adjustmentIdentifier6
          ?.adjustmentReasonCode
          ? GetC042AdjustmentReason(
              plb.adjustmentIdentifier6.adjustmentReasonCode,
            )
          : undefined,
      });
    }
  }
}

export class ResolvedCompositeMedicalProcedureIdentifier {
  productOrServiceIdQualifier?: string;
  productOrServiceIdDescription?: string;
  productOrServiceId?: string;

  procedureModifier?: string;
  procedureModifier2?: string;
  procedureModifier3?: string;
  procedureModifier4?: string;

  description?: string;

  constructor(c003: C003) {
    this.productOrServiceIdQualifier = c003.productOrServiceIdQualifier;
    this.productOrServiceIdDescription = c003.productOrServiceIdQualifier
      ? GetProductServiceQualifier(c003.productOrServiceIdQualifier)
      : undefined;
    this.productOrServiceId = c003.productOrServiceId;
    this.procedureModifier = c003.procedureModifier;
    this.procedureModifier2 = c003.procedureModifier2;
    this.procedureModifier3 = c003.procedureModifier3;
    this.procedureModifier4 = c003.procedureModifier4;
    this.description = c003.description;
  }
}

export class ResolvedClaimOrServiceAdjustment {
  groupCode: string;
  groupDescription: string;
  details: ClaimAdjustmentDetail[];

  constructor(cas: CAS) {
    this.groupCode = cas.claimAdjustmentGroupCode;
    this.groupDescription = GetClaimAdjustmentGroupCode(
      cas.claimAdjustmentGroupCode,
    );

    this.details = [
      {
        reasonCode: cas.claimAdjustmentReasonCode,
        reasonDescription: GetClaimAdjustmentReasonCode(
          cas.claimAdjustmentReasonCode,
        ),
        adjustmentAmount: numOrBust(cas.adjustmentAmount),
        adjustmentQuantity: numOrBust(cas.adjustmentQuantity),
      },
    ];

    if (cas.adjustmentAmount2) {
      this.details.push({
        reasonCode: cas.claimAdjustmentReasonCode2,
        reasonDescription: GetClaimAdjustmentReasonCode(
          cas.claimAdjustmentReasonCode2,
        ),
        adjustmentAmount: numOrBust(cas.adjustmentAmount2),
        adjustmentQuantity: numOrBust(cas.adjustmentQuantity2),
      });
    }

    if (cas.adjustmentAmount3) {
      this.details.push({
        reasonCode: cas.claimAdjustmentReasonCode3,
        reasonDescription: GetClaimAdjustmentReasonCode(
          cas.claimAdjustmentReasonCode3,
        ),
        adjustmentAmount: numOrBust(cas.adjustmentAmount3),
        adjustmentQuantity: numOrBust(cas.adjustmentQuantity3),
      });
    }

    if (cas.adjustmentAmount4) {
      this.details.push({
        reasonCode: cas.claimAdjustmentReasonCode4,
        reasonDescription: GetClaimAdjustmentReasonCode(
          cas.claimAdjustmentReasonCode4,
        ),
        adjustmentAmount: numOrBust(cas.adjustmentAmount4),
        adjustmentQuantity: numOrBust(cas.adjustmentQuantity4),
      });
    }

    if (cas.adjustmentAmount5) {
      this.details.push({
        reasonCode: cas.claimAdjustmentReasonCode5,
        reasonDescription: GetClaimAdjustmentReasonCode(
          cas.claimAdjustmentReasonCode5,
        ),
        adjustmentAmount: numOrBust(cas.adjustmentAmount5),
        adjustmentQuantity: numOrBust(cas.adjustmentQuantity5),
      });
    }

    if (cas.adjustmentAmount6) {
      this.details.push({
        reasonCode: cas.claimAdjustmentReasonCode6,
        reasonDescription: GetClaimAdjustmentReasonCode(
          cas.claimAdjustmentReasonCode6,
        ),
        adjustmentAmount: numOrBust(cas.adjustmentAmount6),
        adjustmentQuantity: numOrBust(cas.adjustmentQuantity6),
      });
    }
  }
}

export type ClaimAdjustmentDetail = {
  reasonCode: string;
  reasonDescription: string;
  adjustmentAmount: number | null;
  adjustmentQuantity: number | null;
};

export class ResolvedIndividualOrOrganizationName {
  entityCode: string;
  entityDescription?: string;
  nameLastOrOrganizationName: string;
  nameFirst?: string;
  nameMiddle?: string;
  namePrefix?: string;
  idQualifier?: string;
  id?: string;

  constructor(nm1: NM1) {
    this.entityCode = nm1.entityIdentifierCode;
    this.entityDescription = GetEntityIdentifier(nm1.entityIdentifierCode);
    this.nameLastOrOrganizationName = nm1.nameLastOrOrganizationName;
    this.nameFirst = nm1.nameFirst;
    this.nameMiddle = nm1.nameMiddle;
    this.namePrefix = nm1.namePrefix;
    this.idQualifier = nm1.identificationCodeQualifier
      ? GetIdentification(nm1.identificationCodeQualifier)
      : undefined;
    this.id = nm1.identificationCode;
  }
}

export class ResolvedLoop1000 {
  name: string;
  identificationCode?: string;
  identificationCodeQualifier?: string;
  entity?: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  postCodeFormatted?: string;
  additionalInformation?: any[];
  contacts?: ResolvedContact[];

  //payee only
  remittanceDeliveryMethod?: {
    transmissionMethodCode?: string;
    transmissionMethod?: string;
    thirdPartyProcessorName?: string;
    remittanceDeliveryMethodCommunicationNumber?: string;
  }[];

  constructor(loop: Loop1000) {
    this.name = loop.N1.name;

    this.identificationCode = loop.N1.identificationCode;
    this.identificationCodeQualifier = loop.N1.identificationCodeQualifier
      ? GetIdentification(loop.N1.identificationCodeQualifier)
      : undefined;

    this.entity = GetEntityIdentifier(loop.N1.entityIdentifierCode);

    this.address1 = loop.N3.addressInformation;
    this.address2 = loop.N3.addressInformation2;
    this.city = loop.N4.city;
    this.state = loop.N4.state;
    this.postalCode = loop.N4.postalCode;
    this.postCodeFormatted = loop.N4.postalCodeFormatted;

    this.additionalInformation = loop.REF.map((r) => {
      return {
        referenceId: r.referenceIdentification,
        referenceIdQualifier: r.referenceIdentificationQualifier
          ? GetReferenceInfoQualifier(r.referenceIdentificationQualifier)
          : undefined,
        description: r.description,
      };
    });

    this.contacts = loop.PER?.map((p) => new ResolvedContact(p)) || [];

    this.remittanceDeliveryMethod = loop.RDM?.map((r) => {
      return {
        transmissionMethodCode: r.reportTransmissionCode,
        transmissionMethod: r.reportTransmissionCode
          ? GetRemittanceDeliveryMethod(r.reportTransmissionCode)
          : undefined,
        thirdPartyProcessorName: r.thirdPartProcessorName,
        remittanceDeliveryMethodCommunicationNumber: r.communicationNumber,
        //TODO: resolve the C040 if needed
      };
    });
  }
}

export class ResolvedContact {
  name: string;
  inquiryReference: string;
  contactFunction?: string;
  numbers: ResolvedContactNumber[];

  constructor(contact: PER) {
    this.name = contact.name;
    this.inquiryReference = contact.contactInquiryReference;
    this.contactFunction = GetContactFunction(contact.contactFunctionCode);
    this.numbers = [
      {
        number: contact.communicationNumber,
        type: contact.communicationNumberQualifier
          ? GetCommunicationNumberType(contact.communicationNumberQualifier)
          : undefined,
      },
    ];

    if (contact.communicationNumber2) {
      this.numbers.push({
        number: contact.communicationNumber2,
        type: contact.communicationNumberQualifier2
          ? GetCommunicationNumberType(contact.communicationNumberQualifier2)
          : undefined,
      });
    }

    if (contact.communicationNumber3) {
      this.numbers.push({
        number: contact.communicationNumber3,
        type: contact.communicationNumberQualifier3
          ? GetCommunicationNumberType(contact.communicationNumberQualifier3)
          : undefined,
      });
    }
  }
}

export type ResolvedContactNumber = {
  number: string;
  type?: string;
};

//The following types are used in the 837 parsing

export class TransactionSet835 {
  ST: ST;
  BPR: BPR;
  TRN: TRN;
  CUR?: CUR;
  REF?: REF[];
  DTM?: DTM[];
  //Payer
  Loop1000A: Loop1000;
  //Payee
  Loop1000B: Loop1000;

  Loop2000: Loop2000[];

  PLB?: PLB[];

  SE: SE;

  constructor(segments: X12Segment[], COMP_SEP: string) {
    //Detail starts at first LX element.
    const headerSegments = segments.slice(
      0,
      segments.findIndex(
        (s) =>
          s.identifier === EDIElementsEnum.LX ||
          s.identifier === EDIElementsEnum.N1,
      ),
    );

    this.ST = new ST(
      headerSegments.find((s) => s.identifier === EDIElementsEnum.ST)
        ?.elements || [],
    );
    this.BPR = new BPR(
      headerSegments.find((s) => s.identifier === EDIElementsEnum.BPR)
        ?.elements || [],
    );
    this.TRN = new TRN(
      headerSegments.find((s) => s.identifier === EDIElementsEnum.TRN)
        ?.elements || [],
    );
    this.CUR = new CUR(
      headerSegments.find((s) => s.identifier === EDIElementsEnum.CUR)
        ?.elements || [],
    );
    this.REF = headerSegments
      .filter((s) => s.identifier === EDIElementsEnum.REF)
      .map((s) => new REF(s.elements, COMP_SEP));

    this.DTM = headerSegments
      .filter((s) => s.identifier === EDIElementsEnum.DTM)
      .map((s) => new DTM(s.elements));

    const plbSegs = segments.filter(
      (s) => s.identifier === EDIElementsEnum.PLB,
    );

    this.PLB = plbSegs.map((s) => new PLB(s.elements, COMP_SEP)) || [];

    this.SE = new SE(
      segments.find((s) => s.identifier === EDIElementsEnum.SE)?.elements || [],
    );

    //Loop 1000A
    const l1000A = segments.find(
      (s) => s.identifier === EDIElementsEnum.N1 && s.elements[0] === 'PR',
    );

    if (l1000A) {
      const l100AIndex = segments.indexOf(l1000A);
      const endOfL100AIndex = segments.findIndex(
        (sA, indexA) =>
          indexA > l100AIndex && sA.identifier === EDIElementsEnum.NM1,
      );

      this.Loop1000A = new Loop1000(
        segments.slice(l100AIndex, endOfL100AIndex) || [],
        COMP_SEP,
      );
    } else {
      //This should not happen
      this.Loop1000A = new Loop1000([], COMP_SEP);
    }
    //End Loop 1000A

    //Loop 1000B
    const l1000B = segments.find(
      (s) => s.identifier === EDIElementsEnum.N1 && s.elements[0] === 'PE',
    );

    if (l1000B) {
      const l1000BIndex = segments.indexOf(l1000B);
      const endOfl1000BIndex = segments.findIndex(
        (sB, indexB) =>
          indexB > l1000BIndex && sB.identifier === EDIElementsEnum.LX,
      );

      this.Loop1000B = new Loop1000(
        segments.slice(l1000BIndex, endOfl1000BIndex) || [],
        COMP_SEP,
      );
    } else {
      //This should not happen
      this.Loop1000B = new Loop1000([], COMP_SEP);
    }
    //End Loop 1000B

    this.Loop2000 =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.LX)
        ?.map((s) => {
          const myIndex = segments.indexOf(s);
          const endOfLoopIndex = segments.findIndex(
            (s, index) =>
              index > myIndex &&
              (s.identifier === EDIElementsEnum.LX ||
                s.identifier === EDIElementsEnum.PLB ||
                s.identifier === EDIElementsEnum.SE),
          );
          const mySegments = segments.slice(myIndex, endOfLoopIndex);
          return new Loop2000(s.elements, mySegments, COMP_SEP);
        }) || [];
  }
}

export class Loop1000 {
  N1: N1;
  N3: N3;
  N4: N4;
  REF: REF[];
  RDM: RDM[];
  PER: PER[];

  constructor(segments: X12Segment[], COMP_SEP: string) {
    // super();
    this.N1 = new N1(
      segments.find((s) => s.identifier === EDIElementsEnum.N1)?.elements || [],
    );
    this.N3 = new N3(
      segments.find((s) => s.identifier === EDIElementsEnum.N3)?.elements || [],
    );
    this.N4 = new N4(
      segments.find((s) => s.identifier === EDIElementsEnum.N4)?.elements || [],
    );
    this.REF = segments
      .filter((s) => s.identifier === EDIElementsEnum.REF)
      .map((s) => new REF(s.elements, COMP_SEP));
    this.RDM = segments
      .filter((s) => s.identifier === EDIElementsEnum.RDM)
      .map((s) => new RDM(s.elements, COMP_SEP));
    this.PER =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.PER)
        .map((s) => new PER(s.elements)) || [];
  }
}

export class Loop2000 {
  LX: LX;
  //TS3?
  //TS2?
  Loop2100: Loop2100[];

  constructor(elements: string[], segments: X12Segment[], COMP_SEP: string) {
    // super();
    this.LX = new LX(elements || []);

    this.Loop2100 = segments
      .filter((s) => {
        return s.identifier === EDIElementsEnum.CLP;
      })
      .map((s) => {
        const myLoop2110: Loop2110[] = [];
        const myIndex = segments.indexOf(s);
        const endOfLoopIndex = segments.findIndex(
          (s, index) =>
            index > myIndex &&
            (s.identifier === EDIElementsEnum.CLP ||
              s.identifier === EDIElementsEnum.LX ||
              s.identifier === EDIElementsEnum.PLB ||
              s.identifier === EDIElementsEnum.SE),
        );

        const sliceItHere =
          endOfLoopIndex === -1 ? segments.length : endOfLoopIndex;

        const clpSegments = segments.slice(myIndex, sliceItHere);

        clpSegments
          .filter((sv) => {
            return sv.identifier === EDIElementsEnum.SVC;
          })
          .map((svc) => {
            const svcIndex = clpSegments.indexOf(svc);
            const endOfSvcIndex = clpSegments.findIndex(
              (seg, index2110) =>
                index2110 > svcIndex &&
                (seg.identifier === EDIElementsEnum.SVC ||
                  seg.identifier === EDIElementsEnum.CLP ||
                  seg.identifier === EDIElementsEnum.LX ||
                  seg.identifier === EDIElementsEnum.PLB ||
                  seg.identifier === EDIElementsEnum.SE),
            );

            const wheretoSplice =
              (endOfSvcIndex === -1 ? clpSegments.length : endOfSvcIndex) -
              svcIndex;

            const svcSegments = clpSegments.splice(svcIndex, wheretoSplice);

            const l2110 = new Loop2110(svcSegments, COMP_SEP);
            myLoop2110.push(l2110);
          });

        return new Loop2100(clpSegments, myLoop2110, COMP_SEP);
      });
  }
}

export class Loop2100 {
  CLP: CLP;
  CAS: CAS[];
  NM1: NM1[];
  MIA?: MIA;
  MOA?: MOA;
  REF: REF[];
  DTM: DTM[];
  PER: PER[];
  AMT: AMT[];
  QTY: QTY[];

  Loop2110: Loop2110[];

  constructor(
    segments: X12Segment[],
    loop2110: Loop2110[] = [],
    COMP_SEP: string,
  ) {
    // super();
    this.CLP = new CLP(
      segments.find((s) => s.identifier === EDIElementsEnum.CLP)?.elements ||
        [],
      COMP_SEP,
    );
    this.CAS =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.CAS)
        .map((s) => new CAS(s.elements)) || [];

    this.NM1 =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.NM1)
        .map((s) => new NM1(s.elements)) || [];

    this.MIA = new MIA(
      segments.find((s) => s.identifier === EDIElementsEnum.MIA)?.elements ||
        [],
    );

    this.MOA = new MOA(
      segments.find((s) => s.identifier === EDIElementsEnum.MOA)?.elements ||
        [],
    );
    this.REF =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.REF)
        .map((s) => new REF(s.elements, COMP_SEP)) || [];
    this.DTM =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.DTM)
        .map((s) => new DTM(s.elements)) || [];
    this.PER =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.PER)
        .map((s) => new PER(s.elements)) || [];
    this.AMT =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.AMT)
        .map((s) => new AMT(s.elements)) || [];
    this.QTY =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.QTY)
        .map((s) => new QTY(s.elements, COMP_SEP)) || [];

    this.Loop2110 = loop2110;
  }
}

export class Loop2110 {
  SVC: SVC;
  DTM: DTM[];
  CAS: CAS[];
  REF: REF[];
  AMT: AMT[];
  QTY: QTY[];
  LQ: LQ[];

  constructor(segments: X12Segment[], COMP_SEP: string) {
    // super();
    this.SVC = new SVC(
      segments.find((s) => s.identifier === EDIElementsEnum.SVC)?.elements ||
        [],
      COMP_SEP,
    );
    this.DTM =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.DTM)
        .map((s) => new DTM(s.elements)) || [];
    this.CAS =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.CAS)
        .map((s) => new CAS(s.elements)) || [];
    this.REF =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.REF)
        .map((s) => new REF(s.elements, COMP_SEP)) || [];
    this.AMT =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.AMT)
        .map((s) => new AMT(s.elements)) || [];
    this.QTY =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.QTY)
        .map((s) => new QTY(s.elements, COMP_SEP)) || [];
    this.LQ =
      segments
        .filter((s) => s.identifier === EDIElementsEnum.LQ)
        .map((s) => new LQ(s.elements)) || [];
  }
}
