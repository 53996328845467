import { PaymentMethodType } from '@chiroup/core/types/PatientBillling.type';
import PaymentMethodItem from '../../../../patient-billing/PaymentMethodItem';
import useEpayCustomer from '../../../../patient-billing/hooks/useEpayCustomer';
import { useParams } from 'react-router-dom';
import { Loading } from '@chiroup/components';

type Props = {
  merchantId?: number;
  disabled?: boolean;
  selectedPaymentCard?: PaymentMethodType | null;
  setSelectedPaymentCard?: (paymentMethod: PaymentMethodType | null) => void;
  passedPatientId?: string;
};

const PaymentMethods = ({
  merchantId,
  disabled,
  selectedPaymentCard,
  setSelectedPaymentCard,
  passedPatientId,
}: Props) => {
  const { patientId } = useParams();
  const { epayCustomer, refetch, isFetching } = useEpayCustomer({
    patientId: passedPatientId || patientId,
    merchantId,
  });

  if (!merchantId) {
    return null;
  }

  return (
    <div className="border border-gray-300 bg-gray-50 p-6 rounded-md">
      <h3 className="pb-2 font-medium text-md">Saved Payment Methods</h3>
      <div className="grid grid-cols-2 gap-4">
        {isFetching ? (
          <Loading color="text-gray-400" />
        ) : epayCustomer?.payment_methods?.length ? (
          epayCustomer?.payment_methods?.map((method: any) => (
            <PaymentMethodItem
              key={method.key}
              paymentMethod={method}
              merchantId={merchantId}
              selected={selectedPaymentCard}
              refetchEpayCustomer={refetch}
              setSelected={setSelectedPaymentCard}
              patientId={epayCustomer.customerid}
              custKey={epayCustomer.key}
              disabled={disabled}
            />
          ))
        ) : (
          <div className="text-gray-400 italic text-md flex justify-center p-4 sm:px-6">
            No payment methods found.
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentMethods;
