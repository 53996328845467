import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import { orderBy } from '@chiroup/core/functions/orderBy';
import {
  Survey,
  SurveyQuestion,
  SurveyProgress,
  SurveyQuestionAnswer,
} from '@chiroup/core/types/Survey.type';

const surveyService = () => {
  const list = async (selectedClinic?: number) => {
    const res = (await ChiroUpAPI.get(
      'api',
      `/${selectedClinic}/surveys`,
      {},
    )) as any;
    const data = res.data as Survey[];
    const sorted = data?.sort(orderBy('group'));
    return { data: sorted };
  };

  // `${this.v3url}/${clinicId}/patients/${patientId}/surveys`,
  // { surveys: value.questionnaires, createOnly: true, method: 'email', methodValue: value.patient.email }
  const send = (
    questionnaires: string[],
    createOnly: boolean,
    method: string,
    methodValue: string,
    patientId: number,
    selectedClinic?: number,
  ) => {
    return ChiroUpAPI.post(
      'api',
      `/patients/${selectedClinic}/patients/${patientId}/surveys`,
      {
        body: {
          surveys: questionnaires,
          createOnly,
          method,
          methodValue,
        },
      },
    );
  };

  const getProviderSurvey = ({
    patientRequestId,
    patientId,
    encounterId,
    clinicId,
    surveyId,
  }: {
    patientRequestId: string;
    patientId: string;
    encounterId: string;
    clinicId?: number;
    surveyId?: string;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/survey/${patientId}/${patientRequestId}/${encounterId}/${surveyId}`,
      {},
    );
  };


  const submitSurvey = async ({
    requestId,
    patientSurveyId,
    questions,
    kiosk,
    surveyId,
    prMap,
    spanishQuestions,
    ccsFollowups,
    validationCode,
    visitId,
    clinicId,
  }: {
    requestId: string;
    patientSurveyId: string;
    questions: SurveyQuestion[];
    kiosk: boolean;
    surveyId: string;
    prMap?: { [key: string]: string };
    spanishQuestions?: SurveyQuestion[];
    ccsFollowups?: string[];
    validationCode?: string | number;
    visitId?: string;
    clinicId: number;
  }) => {
    const patientRequestId = prMap?.[patientSurveyId] || requestId;
    return ChiroUpAPI.put(
      'api',
      `/encounters/${clinicId}/survey/submit/${patientRequestId}/${patientSurveyId}`,
      {
        body: {
          questions,
          spanishQuestions,
          ccsFollowups,
          validationCode,
          prMap,
          kiosk,
          visitId,
          surveyId,
        },
      },
    );
  };

  const submitSurveyAnswer = async (
    requestId: string,
    patientSurveyId: string,
    patientSurveyQuestionId: string,
    patientSurveyQuestionAnswer: SurveyQuestionAnswer,
    encounterId?: string,
  ): Promise<{ question: SurveyQuestion; progress: SurveyProgress }> => {
    return ChiroUpAPI.put(
      'api',
      `/encounters/requests/${requestId}/survey/${patientSurveyId}/${patientSurveyQuestionId}/${encounterId}`,
      {
        body: { patientSurveyQuestionAnswer },
      },
    );
  };

  return { list, send, getProviderSurvey, submitSurvey, submitSurveyAnswer };
};

export default surveyService();
