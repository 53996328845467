import patientBillingService from '../../../../../services/patientBilling.service';
import { MeContext } from '../../../../../contexts/me.context';
import { useContext, useEffect, useState } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
// import useServices from '../../visits/notes/services/useServices';

const getQuery = function (clinicId: number) {
  return async (context: QueryFunctionContext) => {
    const billingKey = context.queryKey[1] as string;
    if (!billingKey) return;
    if (billingKey === 'new') return {} as any;
    return patientBillingService.getPatientTransaction(clinicId, billingKey);
  };
};

export const useTransaction = function (billingKey?: string) {
  /**
   * [2024-03-26.1116 by Brian]
   * The transaction has everything on it we need to build services
   * (which is not an array of services but an object of things).
   * Anyway, we don't need dueling REST calls, the transaction will
   * have everything on it. Just format it to the useServices format.
   */
  const [services, setServices] = useState<any | null>({});
  const [listofServices, setListofServices] = useState<any | null>([]);
  const { me } = useContext(MeContext);
  const [snapshot, setSnapshot] = useState<string>();
  const [insurances, setInsurances] = useState<
    Partial<AppointmentInsuranceType>[]
  >([]);
  const [allowBillingPriorityChange, setAllowBillingPriorityChange] =
    useState(false);
  const [payors, setPayors] = useState<any[]>([]);
  const [locked, setLocked] = useState(false);
  const [transactionReadonly, setTransactionReadonly] = useState<
    number | boolean
  >(false);
  const [courtesyBilling, setCourtesyBilling] = useState(false);
  const [superBill, setSuperBill] = useState(false);
  const { status, data, isFetching, refetch } = useQuery(
    ['transaction', billingKey],
    getQuery(me?.selectedClinic?.ID || -1),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      staleTime: 5000,
    },
  );

  useEffect(() => {
    if (data) {
      setAllowBillingPriorityChange(data.allowBillingPriorityChange);
      setInsurances(data.insurances || []);
      setPayors(data.payors);
      setSnapshot(JSON.stringify(data));
      setCourtesyBilling(data.courtesyBilling);
      setSuperBill(data.superBill);
      setLocked(data.services?.[0]?.locked || false);
      setTransactionReadonly(data.readonly);
      setListofServices(data.services);
      setServices((p: any) => {
        p = p || {};
        const resp = {
          ...p,
          services: data.services,
          insurances: data.insurances,
          payors: data.payors,
          allowBillingPriorityChange: data.allowBillingPriorityChange,
          courtesyBilling: data.courtesyBilling,
          superBill: data.superBill,
          billingProfileId: data.billingProfileId,
        };
        if (data.readonly) {
          resp.readonly = data.readonly;
        }
        return resp;
      });
    }
  }, [data]);

  return {
    status,
    data,
    isFetching,
    refetch,
    insurances,
    payors,
    snapshot,
    locked,
    services,
    setServices,
    setSnapshot,
    setInsurances,
    setLocked,
    setPayors,
    setCourtesyBilling,
    setSuperBill,
    allowBillingPriorityChange,
    courtesyBilling,
    superBill,
    transactionReadonly,
    listofServices,
    setListofServices,
  };
};
