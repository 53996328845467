export enum NoteImportance {
  General = 'general',
  Alert = 'alert',
  Notification = 'notification',
}

export enum NoteCategory {
  PhoneCall = 'phoneCall',
  Voicemail = 'voiceMail',
  InPerson = 'inPerson',
  General = 'general',
}
//General, Encounters, Appointments, Chart, Exercises, Transactions, Insurance, and/or Appointment
export enum NoteLocation {
  General = 'general',
  Encounter = 'encounter',
  Appointment = 'appointment',
  Chart = 'chart',
  Exercise = 'exercise',
  Transaction = 'transaction',
  Insurance = 'insurance',
}
